export const productInfo_otherPrdGridFormConfig = [
    { type: "input", name: "AC001", hidden: true, value: "0" },
    { type: "input", name: "AC002", hidden: true, value: "0" },
    { type: "input", name: "AC003", hidden: true, value: "0" },
    {
        rows: [
            {
                type: "select",
                label: "*加價產品名稱：",
                labelPosition: "top",
                options: [
                    {
                        value: "0",
                        content: "---請選擇---",
                        disabled: true
                    },
                ],
                name: "AC004"
            },
            {
                type: "select",
                label: "*房間名稱：",
                labelPosition: "top",
                options: [
                    {
                        value: "0",
                        content: "---請選擇---",
                        disabled: true
                    },
                ],
                name: "AC005"
            },
        ],
    },
    {
        cols:[
            {
                type: "combo",
                multiselection: false,
                label: "銷售來源：",
                labelPosition: "top",
                width: "49.6%",
                css:"rightMargin",
                template : ({ group, value, root }) => {
                    const isRoot = root && "list-item__root" || "";
                    return `
                        <div
                            class="list-item ${isRoot}"
                            data-label="${group}">
                            ${value}
                        </div>
                    `;
                },
                readOnly:true,
                name: "AC007",
            },
            {
                type: "input",
                label: "銷售人員：",
                value:"0",
                inputType:"text",
                width: "49.6%",
                css:"rightMargin",
                name: "AC107"
            },
        ],
    },
    {
        rows: [
            {
                cols: [
                    {
                        type: "text",
                        label: "庫存：",
                        labelPosition: "top",
                        value:0,
                        width: "24.5%",
                        css:"rightMargin",
                        name: "remained"
                    },
                    {
                        type: "input",
                        label: "*購買數量：",
                        labelPosition: "top",
                        inputType:"number",
                        min:0,
                        width: "24.5%",
                        css:"rightMargin",
                        // disabled:true,
                        name: "AC103"
                    },
                    {
                        type: "text",
                        label: "單價:",
                        labelPosition: "top",
                        width: "24.5%",
                        css:"rightMargin",
                        name: "AC102"
                    },
                    {
                        type: "text",
                        label: "總金額：",
                        labelPosition: "top",
                        width: "24.5%",
                        css:"rightMargin",
                        name: "total"
                    },
                ],
            },
        ],
    },
    {
        type: "textarea",
        label: "備註：",
        labelPosition: "top",
        height:150,
        name: "AC104"
    },
];