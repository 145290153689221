export const viewGridFormConfig = [
    {
        type: "button",
        value: "新增訂單",
        width: "300px",
        css: "changeBtn",
        name: "addBtn",
        id: "addBtn"
    },
    {
        type: "button",
        value: "修改訂單",
        width: "300px",
        css: "changeBtn",
        name: "modifyBtn",
        id: "modifyBtn"
    },
    {
        type: "button",
        value: "取消訂單",
        width: "300px",
        css: "changeBtn",
        name: "deleteBtn",
        id: "deleteBtn"
    },
    {
        type: "button",
        value: "入　　住",
        width: "300px",
        css: "changeBtn",
        name: "checkInBtn",
        id: "checkInBtn"
    },
    {
        type: "button",
        value: "換　　房",
        width: "300px",
        css: "changeBtn",
        name: "changeRoomBtn",
    },
    {
        type: "button",
        value: "退　　房",
        width: "300px",
        css: "changeBtn",
        name: "checkOutBtn"
    },
    {
        type: "button",
        value: "未 報 到",
        width: "300px",
        css: "changeBtn",
        name: "noShowBtn"
    },
    // {
    //     type: "button",
    //     value: "打　　掃",
    //     css: "changeBtn",
    //     name: "cleanBtn",
    //     width: "300px",
    // },
    {
        type: "button",
        value: "打掃完畢",
        css: "changeBtn",
        name: "finishBtn",
        width: "300px",
    },
    {
        type: "button",
        value: "故障通報",
        width: "300px",
        css: "changeBtn",
        name: "faultReportBtn",
    },
    {
        type: "button",
        value: "故障處理回報",
        width: "300px",
        css: "changeBtn",
        name: "reportDoneBtn",
    },
    // {
    //     type: "button",
    //     value: "保　　留",
    //     width: "300px",
    //     css: "changeBtn",
    //     name: "reserveBtn",
    // },
    // {
    //     type: "button",
    //     value: "解除保留",
    //     width: "300px",
    //     css: "changeBtn",
    //     name: "removeReserveBtn",
    // },
];

export const viewGridForm2_1Config = [
    {type: "input", name: "AG001", hidden: true, value: "0"},
    {
        type: "text",
        label: "訂單來源：",
        labelPosition: "left",
        value: "Agoda",
        css: "viewGridForm2Header rightMargin",
        name:"AA101"
    },
    {
        type: "text",
        label: "訂單編號：",
        labelPosition: "left",
        value: "Agoda",
        css: "viewGridForm2Header rightMargin",
        name:"AA128"
    },
    {
        cols:[
            {
                type: "text",
                label: "訂房人姓名：",
                labelPosition: "left",
                value: "Agoda",
                css: "viewGridForm2Header rightMargin",
                width:"49.7%",
                name:"purchaserFullName"
            },
            {
                type: "text",
                label: "訂房人電話：",
                labelPosition: "left",
                value: "Agoda",
                css: "viewGridForm2Header rightMargin",
                width:"49.7%",
                name:"AA109"
            },
        ]
    },
    {
        cols:[
            {
                type: "text",
                label: "訂單總金額：",
                labelPosition: "left",
                value: "Agoda",
                css: "viewGridForm2Header rightMargin",
                width:"33.2%",
                name:"total"
            },
            {
                type: "text",
                label: "已付款：",
                labelPosition: "left",
                value: "Agoda",
                css: "viewGridForm2Header rightMargin",
                width:"33.2%",
                name:"paid"
            },
            {
                type: "text",
                label: "未付款：",
                labelPosition: "left",
                value: "Agoda",
                css: "viewGridForm2Header rightMargin",
                width:"33.2%",
                name:"unpaid"
            },
        ]
    },
];

export const viewGridForm2BtnConfig = [
    {
        height: "140px",
        cols: [
            {
                align: "evenly",
                width: "24.5%",
                css: "rightMargin",
                rows:[
                    {
                        type: "button",
                        value: "新增訂單",
                        css: "changeBtn",
                        name: "addBtn",
                        id: "addBtn"
                    },
                    {
                        type: "button",
                        value: "修改訂單",
                        css: "changeBtn",
                        name: "modifyBtn",
                        id: "modifyBtn"
                    },
                    {
                        type: "button",
                        value: "取消訂單",
                        css: "changeBtn",
                        name: "deleteBtn",
                        id: "deleteBtn"
                    },
                ]
            },
            {
                align: "evenly",
                width: "24.5%",
                css: "rightMargin",
                rows:[
                    {
                        type: "button",
                        value: "入　　住",
                        css: "changeBtn",
                        name: "checkInBtn",
                        id: "checkInBtn"
                    },
                    {
                        type: "button",
                        value: "換　　房",
                        css: "changeBtn",
                        name: "changeRoomBtn",
                    },
                    {
                        type: "button",
                        value: "退　　房",
                        css: "changeBtn",
                        name: "checkOutBtn"
                    },
                ]
            },
            {
                align: "evenly",
                width: "24.5%",
                css: "rightMargin",
                rows:[
                    {
                        type: "button",
                        value: "未 報 到",
                        css: "changeBtn",
                        name: "noShowBtn"
                    },
                    // {
                    //     type: "button",
                    //     value: "打　　掃",
                    //     css: "changeBtn",
                    //     name: "cleanBtn",
                    //     width: "300px",
                    // },
                    {
                        type: "button",
                        value: "打掃完畢",
                        css: "changeBtn",
                        name: "finishBtn",
                    },
                    {
                        type: "text",
                        value: " ",
                        css: "changeBtn viewGridFormBlockBtn",
                        disabled: true,
                    },
                ]
            },
            {
                align: "evenly",
                width: "24.5%",
                css: "rightMargin",
                rows:[
                    {
                        type: "button",
                        value: "故障通報",
                        css: "changeBtn",
                        name: "faultReportBtn",
                    },
                    {
                        type: "button",
                        value: "故障處理回報",
                        css: "changeBtn",
                        name: "reportDoneBtn",
                    },
                    {
                        type: "text",
                        value: " ",
                        css: "changeBtn viewGridFormBlockBtn",
                        disabled: true,
                    },
                    // {
                    //     type: "button",
                    //     value: "保　　留",
                    //     width: "300px",
                    //     css: "changeBtn",
                    //     name: "reserveBtn",
                    // },
                    // {
                    //     type: "button",
                    //     value: "解除保留",
                    //     width: "300px",
                    //     css: "changeBtn",
                    //     name: "removeReserveBtn",
                    // },
                ]
            },
        ],
    },
];

