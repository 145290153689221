export const handOverDeleteFormConfig = [
    { type: "input", name: "AA001", hidden: true, value: "0" },
    {
        type: "text",
        value: "確定要刪除嗎?",
        height: "50",
        name: "deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        type: "text",
        label: "交班隸屬：",
        name: "handOverType",
        css:"deleteFormName"
    },
    {
        type: "textarea",
        label: "交班內容：",
        height: 100,
        name: "AA101",
        readOnly: true,
        css:"deleteFormName"
    },
    {
        type: "textarea",
        label: "*刪除原因：",
        value: "",
        height: 150,
        name: "AA104",
    },
];