export const doorLockSetGridColumn = [
    { id: "action", header: [{ text: "動作" }],
        htmlEnable: true, align: "center", width:80,
    },
    { id: "item_no", width:60, header: [{ text: "項次" }] },
    { width: 150, id: "doorLock_name", header: [{ text: "門鎖後台備註(名稱)" },{content: "inputFilter"}] },
    { width: 260, id: "doorLock_device_id", header: [{ text: "門鎖ID" },{content: "inputFilter"}] },
    { width: 80, id: "doorLock_type", header: [{ text: "門鎖類別" },{content: "inputFilter"}] },
    { width: 120, id: "doorLock_room", header: [{ text: "房間" },{content: "inputFilter"}] },
    { width: 150, id: "doorLock_room_type", header: [{ text: "房型" },{content: "inputFilter"}] },
    { width: 250, id: "note", header: [{ text: "備註" },{content: "inputFilter"}] },
    { width:120, id: "created_by", header: [{ text: "建立人員" },{content: "inputFilter"}] },
    { width:150, id: "created_at", header: [{ text: "建立時間" },{content: "inputFilter"}] },
    { width:120, id: "updated_by", header: [{ text: "更新人員" },{content: "inputFilter"}] },
    { width:150, id: "updated_at", header: [{ text: "更新時間" },{content: "inputFilter"}] },
];