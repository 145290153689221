
export const mainProductsSetFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {type: "input", name: "AA002", hidden: true, value: "0"},
    {type: "input", name: "AA030", hidden: true, value: "0"},
    {
        cols:[
            {type: "text", label: "主要產品類別：", name: "productType",width: "49.7%", css:"rightMargin"},
            {type: "text", label: "飯店名稱：", name: "hotelName", width: "49.7%", css:"rightMargin"},
        ]
    },
    {
        cols:[
            {type: "input", label: "房型代號：", placeholder: "未填寫由系統自動產生", name: "AA100",width: "49.7%", css:"rightMargin"},
            {type: "input", label: "*房型名稱：", placeholder: "", name: "AA101",width: "49.7%", css:"rightMargin"},
        ]
    },
    {
        cols:[
            {type: "input", label: "HotelNabe房型編號：", placeholder: "", name: "AA109",width: "49.7%", css:"rightMargin"},
            {type: "input", label: "排列序號：", placeholder: "", name: "AA110",width: "49.7%", css:"rightMargin",inputType: "number"},
        ]
    },
    {
        cols:[
            {type: "input", label: "*人數：", placeholder: "", name: "AA104", width: "33.2%", css:"rightMargin"},
            {type: "input", label: "*定價：", placeholder: "", name: "AA108", width: "33.2%", css:"rightMargin"},
            {
                label: " ",
                width: "33%",
                type: "checkbox",
                text: "供非住宿訂房",
                name: "AA111",
                css: "noLabel"
            }
        ],
    },
    { type: "textarea", label: "房型敘述：(將會顯示在未來客棧房型介紹)", value: "", height: 100, name: "AA105", },
    {
        type: "combo",
        multiselection: true,
        label: "*設備：",
        labelPosition: "top",
        name: "device",
    },
    {
        type: "radioGroup",
        label: "*狀態：",
        value: "1",
        options: {
            cols: [
                {
                    type: "radioButton",
                    text: "啟用",
                    value: "1",
                    checked: true,
                },
                {
                    type: "radioButton",
                    text: "停用",
                    value: "0"
                },
            ]
        },
        name: "AA022",
    },
    { type: "textarea", label: "*停用原因：", value: "", height: 100, name: "AA106", disabled:true },
];

export const mainProductsSetStopFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要停用嗎?",
        name: "stopFormTitle",
        css:"stopFormTitle"
    },
    {
        cols:[
            {
                type: "text",
                label: "主要產品類別：",
                labelPosition: "left",
                value:"",
                name: "productType",
                width: "49.7%",
                css:"stopFormName"
            },
            {
                type: "text",
                label: "飯店名稱：",
                labelPosition: "left",
                name: "hotelName",
                width: "49.7%",
                css:"stopFormName"
            },
        ],
    },
    {
        cols:[
            {
                type: "text",
                label: "房型名稱：",
                labelPosition: "left",
                name: "AA101",
                width: "49.7%",
                css:"stopFormName"
            },
            {
                type: "text",
                label: "房型代號：",
                labelPosition: "left",
                name: "AA100",
                width: "49.7%",
                css:"stopFormName"
            },
        ],
    },
    {
        type: "textarea",
        label: "*停用原因",
        value: "",
        height: 150,
        name: "AA106",
    },
];

export const mainProductsSetDeleteFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要刪除嗎?",
        name: "deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        cols:[
            {
                type: "text",
                label: "主要產品類別：",
                labelPosition: "left",
                value:"",
                name: "productType",
                width: "49.7%",
                css:"deleteFormName"
            },
            {
                type: "text",
                label: "飯店名稱：",
                labelPosition: "left",
                name: "hotelName",
                width: "49.7%",
                css:"deleteFormName"
            },
        ],
    },
    {
        cols:[
            {
                type: "text",
                label: "房型名稱：",
                labelPosition: "left",
                value:"",
                name: "AA101",
                width: "49.7%",
                css:"deleteFormName"
            },
            {
                type: "text",
                label: "房型代號：",
                labelPosition: "left",
                name: "AA100",
                width: "49.7%",
                css:"deleteFormName"
            },
        ],
    },
    {
        type: "textarea",
        label: "*刪除原因",
        value: "",
        height: 150,
        name: "AA107",
    },
];