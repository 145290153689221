import React, {useEffect, useRef, useState} from 'react';
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import Sidenav from "pages/Sidenav";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDAlert from "../../../components/MDAlert";
import {
    Grid as DHXGrid,
    Window as DHXWindow,
    Layout as DHXLayout,
    Toolbar as DHXToolbar,
    Form as DHXForm,
    message as DHXMessage,
    Calendar as DHXCalendar,
} from "dhx-suite";
import "dhx-suite/codebase/suite.min.css";
import {CardHeader} from "@mui/material";
import './style.css';
import {toolbar1Config} from "./componentsConfig/toolbar1Config";
import {smartBookingGridColumn} from "./componentsConfig/smartBookingGridColumn";
import {windowFootAccept, windowFootClear, windowFootClose} from "./componentsConfig/windowConfig";
import {smartBookingFormConfig} from "./componentsConfig/smartBookingFormConfig";
import {deleteFormConfig} from "./componentsConfig/deleteFormConfig";
import Image_error from '../../../assets/images/error.png';
import axios from "axios";
import api_url from "../../../context/UrlContext.js"
import {useNavigate} from "react-router-dom";

const SmartBookingSetting = () => {

    const apiServer = api_url;
    const headers = {headers:{authorization: "Bearer "+JSON.parse(localStorage.getItem("digital_token"))}};
    const navigate = useNavigate();

    const [canAccess ,setCanAccess] = useState('');
    const [canEdit ,setCanEdit] = useState('');
    const [canDelete ,setCanDelete] = useState('');
    const [canPrint ,setCanPrint] = useState('');

    //飯店切換
    const [hotel, setHotel] = useState("0");

    const changeHotelHandler = (hotel) => {
        setHotel(hotel);
    };

    const [isLoading,setIsloading] = useState(true);

    const DHXSmartBookingGridLayout = useRef(null);

    let mainPrdSelectedId = "0";
    let smartBookingFormCalendarArray = [];
    let smartBookingFormCalendarDataset = [];

    useEffect(()=>{

        axios.post(apiServer+"/checkLoginState",{itemId1:JSON.parse(localStorage.getItem("digital_token"))})
            .then((response)=>{
                if(response.data == 0){
                    //沒有登入就跳轉到登入頁面
                    navigate('/login');
                }else{
                    axios.post(apiServer+"/userProfileSet/loadPermiss",{itemId1:15},headers)
                        .then((response2)=>{
                            if(response2.status == 200){
                                let p = response2.data.split(',');

                                (p[0] == 1)?setCanAccess(true):setCanAccess(false);
                                (p[1] == 1)?setCanEdit(true):setCanEdit(false);
                                (p[2] == 1)?setCanDelete(true):setCanDelete(false);
                                (p[3] == 1)?setCanPrint(true):setCanPrint(false);

                                setIsloading(false);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });

        const dhxSmartBookingGridLayout = new DHXLayout(DHXSmartBookingGridLayout.current,{
            rows:[
                {id:"smartBookingGridToolbar", height: "content"},
                {id:"smartBookingGrid", height: "500px"},
            ]
        });

        const toolbar1 = new DHXToolbar(null, {
        });
        toolbar1.events.on("click", function(id,e){
            switch (id) {
                case "reload":
                    axios.post(apiServer+"/smartBooking/loadProjectGrid", {
                        itemId1: hotel
                    },headers).then((response) =>{
                        if(response.status === 200){
                            dhxSmartBookingGrid.data.removeAll();
                            dhxSmartBookingGrid.data.parse(response.data);
                        }else{
                            console.log("Server Error")
                        }
                    })
                        .catch((error) => {
                            console.log(error);
                        });
                    break;
                case "add":
                    dhxSmartBookingFormCalendar.setValue(new Date());
                    dhxSmartBookingFormCalendar.clear();
                    smartBookingFormCalendarArray = [];
                    smartBookingFormCalendarDataset = [];
                    dhxSmartBookingFormDateGrid.data.removeAll();
                    smartBookingForm.clear();
                    dhxSmartBookingWindow1.header.data.update("title", {value: "新增專案視窗"});
                    smartBookingForm.setValue({"AA001":"0","AA002":hotel,"AA104_1":30,"AA104_2":1,"AA104_3":1,"AA104_4":1,"AA104_text1":"分鐘","AA104_text2":"小時","AA104_text3":"天","AA104_text4":"月"});
                    mainPrdSelectedId = 0;
                    smartBookingForm.getItem("AA104_1").disable();
                    smartBookingForm.getItem("AA104_2").disable();
                    smartBookingForm.getItem("AA104_3").disable();
                    smartBookingForm.getItem("AA104_4").disable();
                    smartBookingForm.clear("validation");

                    let mainPrd = smartBookingForm.getItem("mainPrd").getWidget();

                    axios.post(apiServer+"/smartBooking/loadMainProductList", {
                        itemId1: hotel
                    },headers).then((data) =>{
                        if(data.status === 200){
                            mainPrd.data.removeAll();
                            mainPrd.data.parse(data.data);
                        }else{
                            console.log("Server Error")
                        }
                    })

                    let otherPrd = smartBookingForm.getItem("otherPrd").getWidget();

                    axios.post(apiServer+"/smartBooking/loadOtherProductList", {
                        itemId1: hotel
                    },headers)
                        .then((response) =>{
                            if(response.status === 200){
                                otherPrd.data.removeAll();
                                otherPrd.data.parse(response.data);
                            }else{
                                console.log("Server Error")
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    dhxSmartBookingWindow1.show();
                    break;
                default:
                    break;
            }
        });
        toolbar1.data.parse(JSON.parse(JSON.stringify(toolbar1Config)));

        const dhxSmartBookingGrid = new DHXGrid(null,{
            columns: smartBookingGridColumn,
            autoWidth: true,
            resizable: true,
            editable: true,
            eventHandlers: {
                onclick: {
                    "smartBookingGrid_edit": function (e, data) {
                        dhxSmartBookingFormCalendar.setValue(new Date());
                        dhxSmartBookingFormCalendar.clear();
                        smartBookingFormCalendarArray = [];
                        smartBookingFormCalendarDataset = [];
                        dhxSmartBookingFormDateGrid.data.removeAll()
                        smartBookingForm.clear();
                        smartBookingForm.setValue({"AA001":"0","AA002":hotel,"AA104_1":30,"AA104_2":1,"AA104_3":1,"AA104_4":1,"AA104_text1":"分鐘","AA104_text2":"小時","AA104_text3":"天","AA104_text4":"月"});
                        mainPrdSelectedId = 0;
                        smartBookingForm.getItem("AA104_1").disable();
                        smartBookingForm.getItem("AA104_2").disable();
                        smartBookingForm.getItem("AA104_3").disable();
                        smartBookingForm.getItem("AA104_4").disable();
                        smartBookingForm.clear("validation");

                        dhxSmartBookingWindow1.header.data.update("title", {value: "編輯專案視窗"});

                        axios.post(apiServer+"/smartBooking/loadSmartBookingFormData", {
                            itemId1: data.row.AA001
                        },headers).then((response) =>{
                            if(response.status === 200){
                                smartBookingForm.setValue(response.data[0]);
                                mainPrdSelectedId = response.data[0].AA003;
                                smartBookingForm.getItem("AA003").setValue(response.data[0].AA003);

                                switch (response.data[0].AA103){
                                    case "1":
                                        smartBookingForm.getItem("AA103_1").setValue("1");
                                        smartBookingForm.getItem("AA104_1").setValue(response.data[0].AA104);
                                        smartBookingForm.getItem("AA104_1").enable();
                                        smartBookingForm.getItem("AA104_2").disable();
                                        smartBookingForm.getItem("AA104_3").disable();
                                        smartBookingForm.getItem("AA104_4").disable();
                                        break;
                                    case "2":
                                        smartBookingForm.getItem("AA103_2").setValue("1");
                                        smartBookingForm.getItem("AA104_2").setValue(response.data[0].AA104);
                                        smartBookingForm.getItem("AA104_1").disable();
                                        smartBookingForm.getItem("AA104_2").enable();
                                        smartBookingForm.getItem("AA104_3").disable();
                                        smartBookingForm.getItem("AA104_4").disable();
                                        break;
                                    case "3":
                                        smartBookingForm.getItem("AA103_3").setValue("1");
                                        smartBookingForm.getItem("AA104_3").setValue(response.data[0].AA104);
                                        smartBookingForm.getItem("AA104_1").disable();
                                        smartBookingForm.getItem("AA104_2").disable();
                                        smartBookingForm.getItem("AA104_3").enable();
                                        smartBookingForm.getItem("AA104_4").disable();
                                        break;
                                    case "4":
                                        smartBookingForm.getItem("AA103_4").setValue("1");
                                        smartBookingForm.getItem("AA104_4").setValue(response.data[0].AA104);
                                        smartBookingForm.getItem("AA104_1").disable();
                                        smartBookingForm.getItem("AA104_2").disable();
                                        smartBookingForm.getItem("AA104_3").disable();
                                        smartBookingForm.getItem("AA104_4").enable();
                                        break;
                                }

                                let mainPrd = smartBookingForm.getItem("mainPrd").getWidget();

                                axios.post(apiServer+"/smartBooking/loadMainProductList", {
                                    itemId1: hotel
                                },headers).then((data) =>{
                                    if(data.status === 200){
                                        mainPrd.data.removeAll();
                                        mainPrd.data.parse(data.data);
                                        smartBookingForm.getItem("mainPrd").setValue(response.data[0].mainPrd);
                                    }else{
                                        console.log("Server Error")
                                    }
                                })

                                let otherPrd = smartBookingForm.getItem("otherPrd").getWidget();

                                axios.post(apiServer+"/smartBooking/loadOtherProductList", {
                                    itemId1: hotel
                                },headers)
                                    .then((response2) =>{
                                        if(response2.status === 200){
                                            otherPrd.data.removeAll();
                                            otherPrd.data.parse(response2.data);
                                            smartBookingForm.getItem("otherPrd").setValue(response.data[0].otherPrd);
                                        }else{
                                            console.log("Server Error")
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });

                                smartBookingFormCalendarArray = response.data[0].nonDate
                                smartBookingFormCalendarDataset = [];
                                for(let i=0;i<smartBookingFormCalendarArray.length;i++){
                                    smartBookingFormCalendarDataset.push({
                                        "action":"<i title='delete' class='fa-solid fa-trash-can dhxSmartBookingFormDateGrid_delete' style='color: #c94747;font-size: 0;'>"+smartBookingFormCalendarArray[i]+"</i>",
                                        "itemCount":i+1,
                                        "date":smartBookingFormCalendarArray[i]
                                    });
                                }

                                dhxSmartBookingFormDateGrid.data.removeAll();
                                dhxSmartBookingFormDateGrid.data.parse(smartBookingFormCalendarDataset);

                            }else{
                                console.log("Server Error")
                            }
                        })
                            .catch((error) => {
                                console.log(error);
                            });

                        dhxSmartBookingWindow1.show();
                    },
                    "smartBookingGrid_delete": function (e, data) {

                        smartBookingDeleteForm.clear();

                        smartBookingDeleteForm.getItem("deleteFormTitle").setValue("確定要刪除嗎?");

                        axios.post(apiServer+"/smartBooking/loadSmartBookingFormData", {
                            itemId1: data.row.AA001
                        },headers).then((response) =>{
                            if(response.status === 200){
                                smartBookingDeleteForm.setValue(response.data[0]);
                            }else{
                                console.log("Server Error")
                            }
                        })
                            .catch((error) => {
                                console.log(error);
                            });

                        dhxSmartBookingWindow2.show();
                    }
                }
            }
        });
        //
        dhxSmartBookingGridLayout.getCell("smartBookingGridToolbar").attach(toolbar1);
        dhxSmartBookingGridLayout.getCell("smartBookingGrid").attach(dhxSmartBookingGrid);

        axios.post(apiServer+"/smartBooking/loadProjectGrid", {
            itemId1: hotel
        },headers).then((response) =>{
            if(response.status === 200){
                dhxSmartBookingGrid.data.removeAll();
                dhxSmartBookingGrid.data.parse(response.data);
            }else{
                console.log("Server Error")
            }
        })
            .catch((error) => {
                console.log(error);
            });

        const dhxSmartBookingWindow1 = new DHXWindow({

            title: "新增專案視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 705,
            width: 1000,
            footer: true,
            css: "dhxSmartBookingWindow1"
        });

        dhxSmartBookingWindow1.footer.data.add({type:"spacer"});
        dhxSmartBookingWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxSmartBookingWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClear)));
        dhxSmartBookingWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxSmartBookingWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    smartBookingForm.clear();
                    smartBookingForm.setValue({"AA001":"0","AA002":hotel,"AA104_1":30,"AA104_2":1,"AA104_3":1,"AA104_4":1,"AA104_text1":"分鐘","AA104_text2":"小時","AA104_text3":"天","AA104_text4":"月"});
                    mainPrdSelectedId = 0;
                    smartBookingForm.getItem("AA104_1").disable();
                    smartBookingForm.getItem("AA104_2").disable();
                    smartBookingForm.getItem("AA104_3").disable();
                    smartBookingForm.getItem("AA104_4").disable();
                    smartBookingForm.clear("validation");
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(smartBookingForm.getItem("AA100").getValue() === ""){
                        DHXMessage({
                            text: "請填寫專案名稱",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(smartBookingForm.getItem("AA110").getValue() === ""){
                        DHXMessage({
                            text: "請填寫專案價格",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(smartBookingForm.getItem("mainPrd").getValue().length === 0){
                        DHXMessage({
                            text: "請選擇主要產品",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(smartBookingForm.getItem("AA101").getValue() == ""){
                        DHXMessage({
                            text: "請填寫專案販售開始日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(smartBookingForm.getItem("AA102").getValue() == ""){
                        DHXMessage({
                            text: "請填寫專案販售結束日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(smartBookingForm.getItem("AA105").getValue() == ""){
                        DHXMessage({
                            text: "請填寫專案可用開始日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(smartBookingForm.getItem("AA106").getValue() == ""){
                        DHXMessage({
                            text: "請填寫專案可用結束日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(smartBookingForm.getItem("AA103_1").getValue() === "" && smartBookingForm.getItem("AA103_2").getValue() === "" &&smartBookingForm.getItem("AA103_3").getValue() === "" &&smartBookingForm.getItem("AA103_4").getValue() === ""){
                        DHXMessage({
                            text: "請選取專案單位",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(smartBookingForm.getItem("AA107").getValue() === ""){
                        DHXMessage({
                            text: "請填寫專案介紹",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(smartBookingForm.getItem("AA108").getValue() === ""){
                        DHXMessage({
                            text: "請填寫專案使用規則",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    let smartBookingFormValue = Object.assign({},smartBookingForm.getValue(), {"nonDate":smartBookingFormCalendarArray});

                    axios.post(apiServer+"/smartBooking/updateProject",
                        smartBookingFormValue
                    ,headers).then((data) =>{

                        if(data.data[0].result){
                            DHXMessage({
                                text: data.data[0].message,
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            smartBookingForm.clear();
                            smartBookingForm.setValue({"AA001":"0","AA104_1":30,"AA104_2":1,"AA104_3":1,"AA104_4":1,"AA104_text1":"分鐘","AA104_text2":"小時","AA104_text3":"天","AA104_text4":"月"});
                            mainPrdSelectedId = 0;
                            smartBookingForm.getItem("AA104_1").disable();
                            smartBookingForm.getItem("AA104_2").disable();
                            smartBookingForm.getItem("AA104_3").disable();
                            smartBookingForm.getItem("AA104_4").disable();
                            smartBookingForm.clear("validation");

                            dhxSmartBookingWindow1.hide();

                            axios.post(apiServer+"/smartBooking/loadProjectGrid", {
                                itemId1: hotel
                            },headers).then((response2) =>{
                                if(response2.status === 200){
                                    dhxSmartBookingGrid.data.removeAll();
                                    dhxSmartBookingGrid.data.parse(response2.data);
                                }else{
                                    console.log("Server Error")
                                }
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{

                        }
                    })

                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxSmartBookingWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const dhxSmartBookingFormLayout = new DHXLayout(null,{
            cols: [
                {
                    id: "smartBookingForm",
                    width: "50%",
                },
                {
                    padding: 8,
                    rows:[
                        {
                            id:"smartBookingFormCalendarTitle", height: "26px",html:"不適用日期：",
                        },
                        {
                            id: "smartBookingFormCalendar", height: "265px",
                        },
                        {
                            id: "smartBookingFormDateGrid",
                        }
                    ],
                },
            ]
        });

        const smartBookingForm = new DHXForm(null,{
            rows: smartBookingFormConfig
        });

        let smartBookingFormProjectStartDate = smartBookingForm.getItem("AA101").getWidget();
        let smartBookingFormProjectEndDate = smartBookingForm.getItem("AA102").getWidget();
        smartBookingFormProjectStartDate.link(smartBookingFormProjectEndDate)

        smartBookingForm.events.on("change", function(name, value) {
            switch (name){
                case "AA103_1":
                    if(value == ""){
                        smartBookingForm.getItem("AA104_1").disable();
                    }else{
                        smartBookingForm.getItem("AA104_1").enable();
                        smartBookingForm.getItem("AA104_2").disable();
                        smartBookingForm.getItem("AA104_3").disable();
                        smartBookingForm.getItem("AA104_4").disable();
                        smartBookingForm.getItem("AA103_2").clear();
                        smartBookingForm.getItem("AA103_3").clear();
                        smartBookingForm.getItem("AA103_4").clear();
                    }
                    break;
                case "AA103_2":
                    if(value == ""){
                        smartBookingForm.getItem("AA104_2").disable();
                    }else{
                        smartBookingForm.getItem("AA104_1").disable();
                        smartBookingForm.getItem("AA104_2").enable();
                        smartBookingForm.getItem("AA104_3").disable();
                        smartBookingForm.getItem("AA104_4").disable();
                        smartBookingForm.getItem("AA103_1").clear();
                        smartBookingForm.getItem("AA103_3").clear();
                        smartBookingForm.getItem("AA103_4").clear();
                    }
                    break;
                case "AA103_3":
                    if(value == ""){
                        smartBookingForm.getItem("AA104_3").disable();
                    }else{
                        smartBookingForm.getItem("AA104_1").disable();
                        smartBookingForm.getItem("AA104_2").disable();
                        smartBookingForm.getItem("AA104_3").enable();
                        smartBookingForm.getItem("AA104_4").disable();
                        smartBookingForm.getItem("AA103_1").clear();
                        smartBookingForm.getItem("AA103_2").clear();
                        smartBookingForm.getItem("AA103_4").clear();
                    }
                    break;
                case "AA103_4":
                    if(value == ""){
                        smartBookingForm.getItem("AA104_4").disable();
                    }else{
                        smartBookingForm.getItem("AA104_1").disable();
                        smartBookingForm.getItem("AA104_2").disable();
                        smartBookingForm.getItem("AA104_3").disable();
                        smartBookingForm.getItem("AA104_4").enable();
                        smartBookingForm.getItem("AA103_1").clear();
                        smartBookingForm.getItem("AA103_2").clear();
                        smartBookingForm.getItem("AA103_3").clear();
                    }
                    break;
            }
        });

        dhxSmartBookingWindow1.attach(dhxSmartBookingFormLayout);
        dhxSmartBookingFormLayout.getCell("smartBookingForm").attach(smartBookingForm);

        const dhxSmartBookingFormCalendar = new DHXCalendar(null,{
            width: "100%",
            css: "dhxSmartBookingFormCalendar",
            dateFormat: "%Y-%m-%d",
        });

        let dateFormatTransport = (selectDate)=>{
            let objectDate = new Date(selectDate);

            let month = objectDate.getMonth()+1;

            let year = objectDate.getFullYear();

            let day = objectDate.getDate();

            if (day < 10) {
                day = '0' + day;
            }

            if (month < 10) {
                month = `0${month}`;
            }

            return year + "-" + month + "-" + day;
        }

        dhxSmartBookingFormLayout.getCell("smartBookingFormCalendar").attach(dhxSmartBookingFormCalendar);

        dhxSmartBookingFormCalendar.events.on("change",function(date, oldDate, byClick){
            let selectItem = dateFormatTransport(date);
            if(smartBookingFormCalendarArray.indexOf(selectItem) == -1){
                smartBookingFormCalendarArray.push(selectItem);
                smartBookingFormCalendarArray.sort();

                smartBookingFormCalendarDataset = [];
                for(let i=0;i<smartBookingFormCalendarArray.length;i++){
                    smartBookingFormCalendarDataset.push({
                        "action":"<i title='delete' class='fa-solid fa-trash-can dhxSmartBookingFormDateGrid_delete' style='color: #c94747;font-size: 0;'>"+smartBookingFormCalendarArray[i]+"</i>",
                        "itemCount":i+1,
                        "date":smartBookingFormCalendarArray[i]
                    });
                }

                dhxSmartBookingFormDateGrid.data.removeAll();
                dhxSmartBookingFormDateGrid.data.parse(smartBookingFormCalendarDataset);

            }

        });

        const dhxSmartBookingFormDateGrid = new DHXGrid(null,{
            autoWidth: true,
            resizable: true,
            columns: [
                { width: 80, id: "action", header: [{ text: "動作" }], htmlEnable: true, },
                { width: 80, id: "itemCount", header: [{ text: "像次" }] },
                { id: "date", header: [{ text: "日期" }] },
            ],
            eventHandlers: {
                onclick: {
                    "dhxSmartBookingFormDateGrid_delete": function (event, data) {

                        smartBookingFormCalendarArray = smartBookingFormCalendarArray.filter(e=>
                            e !== data.row.date
                        );

                        smartBookingFormCalendarDataset = [];
                        for(let i=0;i<smartBookingFormCalendarArray.length;i++){
                            smartBookingFormCalendarDataset.push({
                                "action":"<i title='delete' class='fa-solid fa-trash-can dhxSmartBookingFormDateGrid_delete' style='color: #c94747;font-size: 0;'>"+smartBookingFormCalendarArray[i]+"</i>",
                                "itemCount":i+1,
                                "date":smartBookingFormCalendarArray[i]
                            });
                        }

                        dhxSmartBookingFormDateGrid.data.removeAll();
                        dhxSmartBookingFormDateGrid.data.parse(smartBookingFormCalendarDataset);
                    }
                }
            }
        });

        dhxSmartBookingFormLayout.getCell("smartBookingFormDateGrid").attach(dhxSmartBookingFormDateGrid);

        const dhxSmartBookingWindow2 = new DHXWindow({
            title: "刪除專案視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 700,
            footer: true,
            css:"dhxSmartBookingWindow2",
        });

        dhxSmartBookingWindow2.footer.data.add({type:"spacer"});
        dhxSmartBookingWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxSmartBookingWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxSmartBookingWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":

                    if(smartBookingDeleteForm.getItem("AA111").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    axios.post(apiServer+"/smartBooking/deleteProject",
                        smartBookingDeleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            dhxSmartBookingGrid.data.removeAll();
                            axios.post(apiServer+"/smartBooking/loadProjectGrid", {
                                itemId1: hotel
                            },headers).then((response2) =>{
                                if(response2.status === 200){

                                    dhxSmartBookingGrid.data.parse(response2.data);
                                }else{
                                    console.log("Server Error")
                                }
                            })
                                .catch((error) => {
                                    console.log(error);
                                });

                            dhxSmartBookingWindow2.hide();
                            smartBookingDeleteForm.clear();
                            smartBookingDeleteForm.setValue({"AA001": "0"});
                            smartBookingDeleteForm.clear("validation");
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxSmartBookingWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let smartBookingDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"smartBookingDeleteFormImg",
                    height: 250,
                    html: "<div style='text-align: center;'><img width='50%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "smartBookingDeleteForm"},
            ]
        });

        const smartBookingDeleteForm = new DHXForm(null,{
            rows: deleteFormConfig
        });

        dhxSmartBookingWindow2.attach(smartBookingDeleteFormLayout);
        smartBookingDeleteFormLayout.getCell("smartBookingDeleteForm").attach(smartBookingDeleteForm);

        return () =>{
            dhxSmartBookingGridLayout && dhxSmartBookingGridLayout.destructor();
        }

    },[canAccess,canEdit,canDelete,canPrint,hotel]);


    return (
        <DashboardLayout>
            <DashboardNavbar onHotelChange={changeHotelHandler}/>
            {canAccess &&
                <MDBox py={2}>
                    <MDBox mb={2}>
                        <Grid container>
                            <Grid item xs={12} lg={12}>
                                <Card>
                                    <CardHeader title={"快捷式訂房設定"}/>
                                    <MDBox py={1} px={1}>
                                        <div ref={DHXSmartBookingGridLayout}></div>
                                    </MDBox>
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDBox>
            }

            {!canAccess && !isLoading &&
            <MDAlert color={"error"}>請確認是否擁有檢視的權限</MDAlert>
            }

            {isLoading &&
            <MDAlert color={"warning"}>權限確認中，請稍後...</MDAlert>
            }
        </DashboardLayout>
    );
};

export default SmartBookingSetting;