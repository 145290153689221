export const roomDeviceSetGridColumn = [
    {
        id: "action", header: [{ text: "動作", align: "center" }],
        htmlEnable: true, align: "center", width:100,
    },
    { id: "item_no", width:100, header: [{ text: "項次" }] },
    { id: "roomDevice_status", width:100, header: [{ text: "狀態" },{content: "selectFilter"}] },
    { width:120, id: "roomDevice_deviceNo", header: [{ text: "設備編號" },{content: "inputFilter"}] },
    { width:200, id: "roomDevice_deviceName", header: [{ text: "設備名稱" },{content: "inputFilter"}] },
    { width:200, id: "roomDevice_stopReason", header: [{ text: "停用原因" },{content: "inputFilter"}] },
    { width:200, id: "created_by", header: [{ text: "建立人員" },{content: "inputFilter"}] },
    { width:200, id: "created_at", header: [{ text: "建立時間" },{content: "inputFilter"}] },
    { width:200, id: "updated_by", header: [{ text: "更新人員" },{content: "inputFilter"}] },
    { width:200, id: "updated_at", header: [{ text: "更新時間" },{content: "inputFilter"}] },
];