export const userGridColumn = [
    { minWidth: 80, id: "account_action", gravity: 1.5, header: [{ text: "動作" }],
        htmlEnable: true, align: "center", width:100,
    },
    { width: 80, id: "item_no", header: [{ text: "項次" }] },
    // { minWidth: 125, id: "attach_hotel", header: [
    //     { text: "Attach Hotel" },
    //     { content: "comboFilter", filterConfig: { multiselection: true } }
    // ]},
    { width: 80, id: "account_status", header: [
            { text: "狀態" },
            { content: "inputFilter" }
        ]},
    { width: 120, id: "employee_name", header: [
        { text: "姓名" },
        { content: "inputFilter" }
    ]},
    { width: 120, id: "employee_id", header: [
        { text: "身分證" },
        { content: "inputFilter"}
    ]},
    { width: 120, id: "employee_account", header: [
        { text: "帳號" },
            { content: "inputFilter"}
    ]},
    { width: 120, id: "employee_email", header: [
            { text: "Email" },
            { content: "inputFilter"}
        ]},
    { width: 120, id: "employee_no", header: [
            { text: "員工編號" },
            { content: "inputFilter"}
        ]},
    { width: 120, id: "employee_cellphone", header: [
            { text: "手機" },
            { content: "inputFilter"}
        ]},
    { width: 120, id: "employee_line", header: [
            { text: "Line ID" },
            { content: "inputFilter"}
        ]},
    { width: 120, id: "employee_wechat", header: [
            { text: "WeChat ID" },
            { content: "inputFilter"}
        ]},
    { width: 120, id: "note", header: [
            { text: "備註" },
            { content: "inputFilter"}
        ]},
    { width: 120, id: "stop_reason", header: [
            { text: "停用原因" },
            { content: "inputFilter"}
        ]},
    { width:200, id: "created_by", header: [{ text: "建立人員" },{content: "inputFilter"}] },
    { width:200, id: "created_at", header: [{ text: "建立時間" },{content: "inputFilter"}] },
    { width:200, id: "updated_by", header: [{ text: "更新人員" },{content: "inputFilter"}] },
    { width:200, id: "updated_at", header: [{ text: "更新時間" },{content: "inputFilter"}] },
]