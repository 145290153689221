import React, {useEffect, useRef, useState} from 'react';
import DashboardLayout from "../../../pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../pages/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import MDAlert from "components/MDAlert";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
    Grid as DHXGrid,
    Window as DHXWindow,
    Layout as DHXLayout,
    Toolbar as DHXToolbar,
    Form as DHXForm,
    message as DHXMessage,
} from "dhx-suite";
import "dhx-suite/codebase/suite.min.css";
import axios from "axios";
import {CardHeader} from "@mui/material";
import {paymentMethodGridColumn} from "./componentsConfig/paymentMethodGridColumn";
import {toolbar1Config} from "./componentsConfig/toolbar1Config";
import {paymentMethodFormConfig,paymentMethodStopFormConfig,paymentMethodDeleteFormConfig} from "./componentsConfig/paymentMethodFormConfig";
import {WindowFootAccept, WindowFootClear, WindowFootClose} from "./componentsConfig/windowConfig";
import './style.css';
import Image_error from '../../../assets/images/error.png'
import Image_warning from '../../../assets/images/warning.png'
import api_url from "../../../context/UrlContext.js"
import {useNavigate} from "react-router-dom";

const PaymentMethodSetting = props => {

    const apiServer = api_url;
    const headers = {headers:{authorization: "Bearer "+JSON.parse(localStorage.getItem("digital_token"))}};
    const navigate = useNavigate();

    const [canAccess ,setCanAccess] = useState('');
    const [canEdit ,setCanEdit] = useState('');
    const [canDelete ,setCanDelete] = useState('');
    const [canPrint ,setCanPrint] = useState('');

    //飯店切換
    const [hotel, setHotel] = useState("0");

    const changeHotelHandler = (hotel) => {
        setHotel(hotel);
    };

    const [isLoading,setIsloading] = useState(true);

    const DHXPaymentMethodGridLayout = useRef(null);

    useEffect(()=>{

        axios.post(apiServer+"/checkLoginState",{itemId1:JSON.parse(localStorage.getItem("digital_token"))},headers)
            .then((response)=>{
                if(response.data == 0){
                    navigate('/login');
                }else{
                    axios.post(apiServer+"/userProfileSet/loadPermiss",{itemId1:9},headers)
                        .then((response2)=>{
                            if(response2.status == 200){
                                let p = response2.data.split(',');

                                (p[0] == 1)?setCanAccess(true):setCanAccess(false);
                                (p[1] == 1)?setCanEdit(true):setCanEdit(false);
                                (p[2] == 1)?setCanDelete(true):setCanDelete(false);
                                (p[3] == 1)?setCanPrint(true):setCanPrint(false);

                                setIsloading(false);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });

        const dhxPaymentMethodGridLayout = new DHXLayout(DHXPaymentMethodGridLayout.current,{
            rows:[
                {id:"paymentMethodGridToolbar", height: "content"},
                {id:"paymentMethodGridLayout", height: "500px"},
            ]
        });

        const toolbar1 = new DHXToolbar(null, {
        });
        toolbar1.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){

                        paymentMethodForm.clear();
                        paymentMethodForm.setValue({"AD001": "0","AD022": 1,"AD104": 0});
                        // paymentMethodForm.getItem("AD022").setValue("1");

                        dhxPaymentMethodWindow1.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: false });
                        dhxPaymentMethodWindow1.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }
                    break;

                case "reload":
                    axios.get(apiServer+"/paymentMethod/loadPaymentMethodGrid",headers)
                        .then((response) =>{
                            if(response.status === 200){
                                dhxPaymentMethodGrid.data.removeAll();
                                dhxPaymentMethodGrid.data.parse(response.data);
                            }else{
                                console.log("Server Error")
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    break;

                default:
                    break;
            }
        });
        toolbar1.data.parse(JSON.parse(JSON.stringify(toolbar1Config)));

        const dhxPaymentMethodGrid = new DHXGrid(null,{
            columns: paymentMethodGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "paymentMethodGrid_edit": function (e, data) {
                        if(canEdit){
                            paymentMethodForm.clear();
                            dhxPaymentMethodWindow1.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: true });

                            axios.post(apiServer+"/paymentMethod/loadPaymentMethodData", {
                                itemId1: data.row.AD001
                            },headers).then((response) =>{
                                paymentMethodForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                            dhxPaymentMethodWindow1.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "paymentMethodGrid_stop": function (e, data) {
                        if(canEdit){
                            paymentMethodStopForm.clear();
                            paymentMethodStopForm.getItem("stopFormTitle").setValue("確定要停用嗎?");

                            axios.post(apiServer+"/paymentMethod/loadPaymentMethodData", {
                                itemId1: data.row.AD001
                            },headers).then((response) =>{
                                paymentMethodStopForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                            dhxPaymentMethodWindow2.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "paymentMethodGrid_delete": function (e, data) {
                        if(canDelete){
                            paymentMethodDeleteForm.clear();
                            paymentMethodDeleteForm.getItem("deleteFormTitle").setValue("確定要刪除嗎?");

                            axios.post(apiServer+"/paymentMethod/loadPaymentMethodData", {
                                itemId1: data.row.AD001
                            },headers).then((response) =>{
                                paymentMethodDeleteForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                            dhxPaymentMethodWindow3.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });
        axios.get(apiServer+"/paymentMethod/loadPaymentMethodGrid",headers)
            .then((response) =>{
                if(response.status === 200){
                    dhxPaymentMethodGrid.data.removeAll();
                    dhxPaymentMethodGrid.data.parse(response.data);
                }else{
                    console.log("Server Error")
                }
            })
            .catch((error) => {
                console.log(error);
            });

        dhxPaymentMethodGridLayout.getCell("paymentMethodGridToolbar").attach(toolbar1);
        dhxPaymentMethodGridLayout.getCell("paymentMethodGridLayout").attach(dhxPaymentMethodGrid);

        const dhxPaymentMethodWindow1 = new DHXWindow({

            title: "付款方式視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 520,
            width: 450,
            footer: true,
            css: "dhxPaymentMethodWindow1"
        });

        dhxPaymentMethodWindow1.footer.data.add({type:"spacer"});
        dhxPaymentMethodWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxPaymentMethodWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClear)));
        dhxPaymentMethodWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxPaymentMethodWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    paymentMethodForm.clear();
                    paymentMethodForm.getItem("AD022").setValue("1");
                    paymentMethodForm.clear("validation");
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    // if(paymentMethodForm.getItem("AD100").getValue() === ""){
                    //     DHXMessage({
                    //         text: "請填入收款方式編號",
                    //         icon:"dxi dxi-close",
                    //         css: "dhx_message--error",
                    //         expire:5000
                    //     });
                    //     break;
                    // };

                    if(paymentMethodForm.getItem("AD101").getValue() === ""){
                        DHXMessage({
                            text: "請填入收款方式名稱",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(paymentMethodForm.getItem("AD022").getValue() === "0"){
                        if(paymentMethodForm.getItem("AD102").getValue() === ""){
                            DHXMessage({
                                text: "請填入原因",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }
                    };

                    axios.post(apiServer+"/paymentMethod/checkPaymentMethodNumder",
                        paymentMethodForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "收款編號重複",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });

                        }else{
                            axios.post(apiServer+"/paymentMethod/checkPaymentMethodName",
                                paymentMethodForm.getValue(),headers
                            ).then((response) =>{
                                if(response.data[0].result){
                                    DHXMessage({
                                        text: "收款名稱重複",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }else{
                                    axios.post(apiServer+"/paymentMethod/updatePaymentMethodFormData",
                                        paymentMethodForm.getValue(),headers
                                    ).then((response) =>{
                                        if(response.data[0].result){
                                            DHXMessage({
                                                text: response.data[0].message,
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--success",
                                                expire:5000
                                            });

                                            paymentMethodForm.clear();
                                            paymentMethodForm.getItem("AD001").setValue("0");
                                            paymentMethodForm.getItem("AD022").setValue("1");
                                            paymentMethodForm.clear("validation");
                                            axios.get(apiServer+"/paymentMethod/loadPaymentMethodGrid",headers)
                                                .then((response) =>{
                                                    if(response.status === 200){
                                                        dhxPaymentMethodWindow1.hide();
                                                        dhxPaymentMethodGrid.data.removeAll();
                                                        dhxPaymentMethodGrid.data.parse(response.data);
                                                    }else{
                                                        console.log("Server Error")
                                                    }
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                });
                                        }else{

                                        }
                                    })
                                        .catch((error) => {
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        });
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxPaymentMethodWindow1.hide();
                    break;
                default:
                    break;
            }
        })

        const paymentMethodForm = new DHXForm(null,{
            rows: paymentMethodFormConfig
        });

        paymentMethodForm.events.on("click", function (name, e){
            switch (name){
                case "send":
                    dhxPaymentMethodWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        paymentMethodForm.getItem("AD022").events.on("change", function(value) {
            if(value == 0){
                paymentMethodForm.getItem("AD102").enable();
            }else{
                paymentMethodForm.getItem("AD102").setValue("");
                paymentMethodForm.getItem("AD102").disable();
            }
        });

        dhxPaymentMethodWindow1.attach(paymentMethodForm);

        const dhxPaymentMethodWindow2 = new DHXWindow({
            title: "Delete Payment Method Info Window",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 580,
            footer: true,
            css: "dhxPaymentMethodWindow2"
        });

        dhxPaymentMethodWindow2.footer.data.add({type:"spacer"});
        dhxPaymentMethodWindow2.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxPaymentMethodWindow2.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxPaymentMethodWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":
                    if(paymentMethodStopForm.getItem("AD102").getValue() === ""){
                        DHXMessage({
                            text: "請填寫停用原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/paymentMethod/stopPaymentMethod",
                        paymentMethodStopForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            axios.get(apiServer+"/paymentMethod/loadPaymentMethodGrid",headers)
                                .then((response) =>{
                                    if(response.status === 200){
                                        dhxPaymentMethodWindow2.hide();
                                        dhxPaymentMethodGrid.data.removeAll();
                                        dhxPaymentMethodGrid.data.parse(response.data);
                                    }else{
                                        console.log("Server Error")
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });

                            paymentMethodStopForm.clear();
                            paymentMethodStopForm.setValue({"AD001": "0"});
                            paymentMethodStopForm.clear("validation");
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxPaymentMethodWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let paymentMethodStopFormLayout = new DHXLayout(null,{
            rows:[
                {id:"paymentMethodStopFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_warning+" /></div>",
                    gravity: false
                },
                {id: "paymentMethodStopForm"},
            ]
        });

        const paymentMethodStopForm = new DHXForm(null,{
            rows: paymentMethodStopFormConfig
        });

        dhxPaymentMethodWindow2.attach(paymentMethodStopFormLayout);
        paymentMethodStopFormLayout.getCell("paymentMethodStopForm").attach(paymentMethodStopForm);

        const dhxPaymentMethodWindow3 = new DHXWindow({
            title: "刪除付款方式視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 580,
            footer: true,
            css: "dhxPaymentMethodWindow3"
        });

        dhxPaymentMethodWindow3.footer.data.add({type:"spacer"});
        dhxPaymentMethodWindow3.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxPaymentMethodWindow3.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxPaymentMethodWindow3.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":
                    if(paymentMethodDeleteForm.getItem("AD103").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/paymentMethod/deletePaymentMethod",
                        paymentMethodDeleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            axios.get(apiServer+"/paymentMethod/loadPaymentMethodGrid",headers)
                                .then((response) =>{
                                    if(response.status === 200){
                                        dhxPaymentMethodWindow3.hide();
                                        dhxPaymentMethodGrid.data.removeAll();
                                        dhxPaymentMethodGrid.data.parse(response.data);
                                    }else{
                                        console.log("Server Error")
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                            dhxPaymentMethodWindow3.hide();
                            paymentMethodDeleteForm.clear();
                            paymentMethodDeleteForm.setValue({"AD001": "0"});
                            paymentMethodDeleteForm.clear("validation");
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxPaymentMethodWindow3.hide();
                    break;
                default:
                    break;
            }
        });

        let paymentMethodDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"paymentMethodDeleteFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "paymentMethodDeleteForm"},
            ]
        });

        const paymentMethodDeleteForm = new DHXForm(null,{
            rows: paymentMethodDeleteFormConfig
        });

        dhxPaymentMethodWindow3.attach(paymentMethodDeleteFormLayout);
        paymentMethodDeleteFormLayout.getCell("paymentMethodDeleteForm").attach(paymentMethodDeleteForm);

        return () =>{
            dhxPaymentMethodGridLayout && dhxPaymentMethodGridLayout.destructor();
        }

    },[canAccess,canEdit,canDelete,canPrint,hotel]);

    return (
        <DashboardLayout>
            <DashboardNavbar onHotelChange={changeHotelHandler}/>
            {canAccess &&
                <MDBox py={2}>
                    <MDBox mb={2}>
                        <Grid container>
                            <Grid item xs={12} lg={12}>
                                <Card>
                                    <CardHeader title={"付款方式設定"}/>
                                    <MDBox py={1} px={1}>
                                        <div ref={DHXPaymentMethodGridLayout}></div>
                                    </MDBox>
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDBox>
            }

            {!canAccess && !isLoading &&
            <MDAlert color={"error"}>請確認是否擁有檢視的權限</MDAlert>
            }

            {isLoading &&
            <MDAlert color={"warning"}>權限確認中，請稍後...</MDAlert>
            }

        </DashboardLayout>
    );
};

export default PaymentMethodSetting;