export const toolbar2Config = [
    {
        id: "priceText",
        type: "customHTML",
        html: "<span style='font-weight: 800; font-size: 1rem; margin-left: 12px;'>房價上架狀態：<span id='priceStatus' style='color: red'>未上架</span></span>",
    },
    {
        id: "online",
        type: "button",
        circle: true,
        value: "上架",
        size: "small",
        color:"success",
        icon: "fa-solid fa-up-long",
    },
    {
        id: "offline",
        type: "button",
        circle: true,
        value: "下架",
        size: "small",
        color:"danger",
        icon: "fa-solid fa-down-long",
        disabled: true,
    },
    {
        type: "separator"
    },
    {
        id: "1",
        type: "customHTML",
        html: "<span style='font-weight: 800; font-size: 1rem;'>開放訂房狀態：<span id='openBookingStatus' style='color: red'></span></span>",
    },
    {
        id: "openBookingSetting",
        type: "button",
        circle: true,
        value: "開放訂房設定",
        size: "small",
        color:"success",
        icon: "fa-solid fa-up-long",
    },
]