let getDate = (date)=> {
    let startDateValue = new Date(date);
    let year1 = startDateValue.getFullYear();
    let month1 = startDateValue.getMonth()+1;
    let day1 = startDateValue.getDate();

    return `${year1}-${month1.toString().padStart(2,'0')}-${day1.toString().padStart(2,'0')}`;
}

export const productInfo_restRoomGridFormConfig = [
    { type: "input", name: "AG001", hidden: true, value: "0" },
    { type: "input", name: "AG002", hidden: true, value: "0" },
    { type: "input", name: "AG003", hidden: true, value: "0" },
    { type: "input", name: "AG131", hidden: true, value: "0" },
    { type: "input", name: "AG132", hidden: true, value: "0" },
    { type: "input", name: "AG133", hidden: true, value: "0" },
    {
        cols:[
            {
                type: "datepicker",
                label: "*入住日期時間：",
                timePicker: true,
                timeFormat: 24,
                width: "33.2%",
                mark: function (date) {
                    if (getDate(date) === getDate(new Date)) {
                        return "highlight-date";
                    }
                },
                dateFormat: "%Y-%m-%d %H:%i",
                valueFormat:"string",
                name: "AG119",
            },
            {
                width: "33.2%",
                padding:"0px 0px 0px 10px",
                cols:[
                    {
                        type: "radioGroup",
                        label: " ",
                        height:35,
                        width: 22,
                        options: {
                            cols: [
                                {
                                    type: "radioButton",
                                    value: "1",
                                },
                            ]
                        },
                        name: "AA103_1",
                        css:"newOrder_restRoomGridForm_Radio noLabel",
                    },
                    {
                        type: "select",
                        label: " ",
                        disabled: true,
                        width: 50,
                        options: [
                            {value: "30",content: "30"},
                            {value: "60",content: "60"},
                            {value: "90",content: "90"},
                            {value: "120",content: "120"},
                            {value: "150",content: "150"},
                            {value: "180",content: "180"},
                            {value: "210",content: "210"},
                            {value: "240",content: "240"},
                            {value: "270",content: "270"},
                            {value: "300",content: "300"},
                            {value: "330",content: "330"},
                            {value: "360",content: "360"},
                            {value: "390",content: "390"},
                            {value: "420",content: "420"},
                            {value: "450",content: "450"},
                            {value: "480",content: "480"},
                        ],
                        name: "AA104_1",
                        css: "noLabel"
                    },
                    {
                        type: "text",
                        label: " ",
                        value:"分鐘",
                        css:"rightMargin noLabel",
                        name: "AA104_text1",
                    },
                ]
            },
            {
                width: "33.2%",
                cols:[
                    {
                        type: "radioGroup",
                        label: " ",
                        height:35,
                        width: 22,
                        options: {
                            cols: [
                                {
                                    type: "radioButton",
                                    value: "1",
                                },
                            ]
                        },
                        name: "AA103_2",
                        css:"newOrder_restRoomGridForm_Radio  noLabel",
                    },
                    {
                        type: "select",
                        label: " ",
                        disabled: true,
                        width: 50,
                        options: [
                            {value: "1",content: "1"},
                            {value: "2",content: "2"},
                            {value: "3",content: "3"},
                            {value: "4",content: "4"},
                            {value: "5",content: "5"},
                            {value: "6",content: "6"},
                            {value: "7",content: "7"},
                            {value: "8",content: "8"},
                            {value: "9",content: "9"},
                            {value: "10",content: "10"},
                            {value: "11",content: "11"},
                            {value: "12",content: "12"},
                        ],
                        name: "AA104_2",
                        css: "noLabel"
                    },
                    {
                        type: "text",
                        label: " ",
                        value:"小時",
                        css:"rightMargin  noLabel",
                        name: "AA104_text2",
                    },
                ]
            },
        ]
    },
    {
        cols:[
            {
                type: "combo",
                multiselection: false,
                label: "*房型：",
                labelPosition: "top",
                width: "49.6%",
                css:"rightMargin",
                data: [
                    { value: "---請選擇---", id: "0" },
                ],
                readOnly:true,
                disabled:true,
                name: "AG004",
            },
            {
                type: "combo",
                multiselection: false,
                label: "*房間名稱：",
                labelPosition: "top",
                width: "49.6%",
                css:"rightMargin",
                data: [
                    { value: "---請選擇---", id: "0" },
                ],
                readOnly:true,
                disabled:true,
                name: "AG005",
            },
        ],
    },
    {
        cols:[
            {
                type: "combo",
                multiselection: false,
                label: "銷售來源：",
                labelPosition: "top",
                width: "49.6%",
                css:"rightMargin",
                template : ({ group, value, root }) => {
                    const isRoot = root && "list-item__root" || "";
                    return `
                        <div
                            class="list-item ${isRoot}"
                            data-label="${group}">
                            ${value}
                        </div>
                    `;
                },
                readOnly:true,
                name: "AG007",
            },
            {
                type: "input",
                label: "銷售人員：",
                value:"0",
                inputType:"text",
                width: "49.6%",
                // disabled:true,
                css:"rightMargin",
                name: "AG139"
            },
        ],
    },
    {
        cols: [
            { type: "input", label: "*價格：", value:"0", placeholder:"請填入金額", inputType:"number", width: "49.7%", disabled:true, css:"rightMargin",
                // name: "AB116"
                name: "AG127"
            },
            // { type: "input", label: "加床費用：", value:"0", placeholder:"請填入金額", inputType:"number", width: "49.7%", disabled:true, css:"rightMargin",
            //     // name: "AB118"
            //     name: "AG116"
            // },
        ],
    },
    { type: "text", label: "總金額：",labelPosition:"left", value:"", width: "100%", css:"total", name: "total" },
];