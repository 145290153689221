import i18next from "i18next";
import i18n from "i18next";


export const toolbarConfig = [
    {id: "reload",
        type: "button",
        circle: true,
        value: "重新整理",
        size: "small",
        icon: "fa-solid fa-rotate-right",
        // full: true
    },
    {
        id:"searchTime",
        type: "datePicker",
        label: "查詢月份",
        width: "250",
        icon: "fa-solid fa-calendar-days",
        labelWidth: "50px",
        timePicker: true,
        timeFormat: 24,
        dateFormat: "%Y-%m",
        valueFormat:"string",
        value:Date.now(),
    },
    {id:"exportExcel", type: "button", value: "匯出Excel", size: "small", icon: "fa-solid fa-file-excel", circle:true},

]