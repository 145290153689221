export const reserveGridColumn = [
    { id: "action", header: [{ text: "動作" }],
        htmlEnable: true, align: "center", width:80,
        // template: function (e) {
        //     return '<i title="edit" class="fa-solid fa-pen reserveGrid_edit" style="color: #466fc4;font-size: 0;">'+e+'</i>&nbsp;<i title="delete" class="fa-solid fa-trash-can reserveGrid_delete" style="color: #c94747;font-size: 0;">'+e+'</i>';
        // }
    },
    { id: "item_no", width:80, header: [{ text: "項次" }] },
    { width: 120,id: "hotel_name",header: [{ text: "飯店名稱" },{ content: "inputFilter"}] },
    { width:100, id: "status", header: [{ text: "保留狀態" },{ content: "inputFilter"}],htmlEnable: true,
        template: function (e) {
            if(e == 1){
                return '<span style="color: green;font-weight: 800;">保留中</span>';
            }else{
                return '<span style="color: red;font-weight: 800;">解除保留</span>';
            }
        }
    },
    { width:250, id: "reserve_object", header: [{ text: "保留對象" },{ content: "inputFilter"}]},
    { width:300, id: "reserve_reason", header: [{ text: "保留原因" },{ content: "inputFilter"}]},
    { width:150, id: "reserve_reportDate", header: [{ text: "通報日期" },{ content: "inputFilter"}]},
    { width:150, id: "reserve_lockStartDate", header: [{ text: "保留開始日期" },{ content: "inputFilter"}]},
    { width:150, id: "reserve_lockEndDate", header: [{ text: "保留結束日期" },{ content: "inputFilter"}]},
    { width:300, id: "reserve_note", header: [{ text: "備註" },{ content: "inputFilter"}]},
    { width:150, id: "created_by", header: [{ text: "建立人員" },{content: "inputFilter"}] },
    { width:200, id: "created_at", header: [{ text: "建立時間" },{content: "inputFilter"}] },
    { width:150, id: "updated_by", header: [{ text: "更新人員" },{content: "inputFilter"}] },
    { width:200, id: "updated_at", header: [{ text: "更新時間" },{content: "inputFilter"}] },
]