export const stopFormConfig = [
    {type: "input", name: "CA001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要停用嗎?",
        name: "stopFormTitle",
        css:"stopFormTitle"
    },
    {
        type: "text",
        label: "加價產品名稱：",
        labelPosition: "left",
        value:"",
        name: "CA101",
        css:"stopFormName"
    },
    {
        type: "textarea",
        // required: true,
        value: "",
        label: "*停用原因：",
        placeholder: "",
        height: 150,
        name: "CA108",
    },
    // {
    //     rows:[
    //         {
    //             type: "radioGroup",
    //             options: {
    //                 rows: [
    //                     {
    //                         type: "radioButton",
    //                         text: "",
    //                         value: 1,
    //                     },
    //                     {
    //                         type: "radioButton",
    //                         text: "Date Range",
    //                         value: 2
    //                     },
    //                 ]
    //             },
    //             name: "CA105",
    //         },
    //         {
    //             cols:[
    //
    //                 {
    //                     type: "datepicker",
    //                     label: "Lock Start Date",
    //                     dateFormat: "%Y-%m-%d",
    //                     css:"rightMargin",
    //                     width: "49.7%",
    //                     disabled: true,
    //                     name: "CA106",
    //                 },
    //                 {
    //                     type: "datepicker",
    //                     label: "Lock End Date",
    //                     dateFormat: "%Y-%m-%d",
    //                     width: "49.7%",
    //                     disabled: true,
    //                     name: "CA107",
    //                 },
    //             ],
    //         },
    //         {
    //             type: "textarea",
    //             required: true,
    //             value: "",
    //             label: "Reason",
    //             placeholder: "Disable Reason",
    //             height: 150,
    //             name: "CA108",
    //         },
    //     ],
    // },
];