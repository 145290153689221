export const smartBookingGridColumn = [
    { id: "action", header: [{ text: "動作" }],
        htmlEnable: true, align: "center", width:80,
    },
    { id: "item_no", width:80, header: [{ text: "項次" }] },
    { width:150, id: "project_name", header: [{ text: "專案名稱" },{content: "selectFilter"}]},
    { width:100, id: "project_type", header: [{ text: "專案類型" },{content: "selectFilter"}]},
    { width:150, id: "project_price", header: [{ text: "專案價格" },{ content: "inputFilter"}]},
    { width:150, id: "project_sale_date", header: [{ text: "專案銷售期間" },{ content: "inputFilter"}]},
    { width:150, id: "project_use_date", header: [{ text: "專案可住期間" },{ content: "inputFilter"}]},
    { width:200, id: "project_non_date", header: [{ text: "不適用日期" },{ content: "inputFilter"}]},
    { width:200, id: "other_hotel", header: [{ text: "聯合分館" },{ content: "inputFilter"}]},
    { width:200, id: "description", header: [{ text: "專案介紹" },{ content: "inputFilter"}]},
    { width:200, id: "use_reg", header: [{ text: "專案使用規則" },{ content: "inputFilter"}]},
    { width:200, id: "created_by", header: [{ text: "建立人員" }] },
    { width:200, id: "created_at", header: [{ text: "建立時間" }] },
    { width:200, id: "updated_by", header: [{ text: "更新人員" }] },
    { width:200, id: "updated_at", header: [{ text: "更新時間" }] },
]