export const toolbar7Config = [
    {
        id: "typeInput",
        label: "服務項目名稱：",
        type:"input",
        value:"",
        width: 250,
    },
    {id: "add",
        type: "button",
        circle: true,
        value: "新增/編輯服務項目名稱",
        size: "small",
        icon: "fa-solid fa-plus",
        // full: true
    },
    {id: "reload",
        type: "button",
        circle: true,
        value: "重新整理",
        size: "small",
        icon: "fa-solid fa-rotate-right",
        // full: true
    },

]