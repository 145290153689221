export const orderInfo_purchaserGridFormConfig = [
    { type: "input", name: "AA001", hidden: true, value: "0" },
    {
        cols:[
            {
                type: "input",
                label: "電話：",
                labelPosition: "top",
                width:"33%",
                css:"rightMargin",
                name: "AA109"
            },
            {
                type: "input",
                label: "區域碼：",
                labelPosition: "top",
                width:"33%",
                css:"rightMargin",
                name: "AA112"
            },
            {
                type: "input",
                label: "分機：",
                labelPosition: "top",
                width:"33%",
                css:"rightMargin",
                name: "AA113"
            },
        ],
    },
    {
        cols:[
            {
                type: "input",
                label: "*名字：",
                labelPosition: "top",
                width:"33%",
                css:"rightMargin",
                name: "AA106"
            },
            {
                type: "input",
                label: "中間名：",
                labelPosition: "top",
                width:"33%",
                css:"rightMargin",
                name: "AA107"
            },
            {
                type: "input",
                label: "姓氏：",
                labelPosition: "top",
                width:"33%",
                css:"rightMargin",
                name: "AA108"
            },
        ],
    },
    {
        cols:[
            {
                type: "input",
                label: "前綴字：",
                labelPosition: "top",
                width:"33%",
                css:"rightMargin",
                name: "AA105"
            },
            {
                type: "datepicker",
                label: "生日：",
                timeFormat: 24,
                dateFormat: "%Y-%m-%d",
                valueFormat:"string",
                width:"33%",
                css:"rightMargin",
                name: "AA118",
            },
            {
                type: "input",
                label: "身分證字號：",
                labelPosition: "top",
                width:"33%",
                css:"rightMargin",
                name: "AA116"
            },
        ],
    },
    {
        cols:[
            {
                type: "select",
                label: "性別：",
                labelPosition: "top",
                options: [
                    {
                        value: "0",
                        content: "---請選擇---",
                        disabled: true
                    },
                    {
                        value: "1",
                        content: "無法得知"
                    },
                    {
                        value: "2",
                        content: "男性"
                    },
                    {
                        value: "3",
                        content: "女性"
                    }
                ],
                width:"33%",
                css:"rightMargin",
                name: "AA117"
            },
            {
                type: "select",
                label: "國籍：",
                labelPosition: "top",
                options: [
                    {
                        value: "0",
                        content: "---請選擇---",
                        disabled: true
                    },
                ],
                width:"33%",
                css:"rightMargin",
                name: "AA004"
            },
            {
                type: "input",
                label: "國際碼：",
                labelPosition: "top",
                width:"33%",
                css:"rightMargin",
                name: "AA111"
            },
        ],
    },
    {
        cols:[
            {
                type: "input",
                label: "E-mail",
                labelPosition: "top",
                width:"33%",
                css:"rightMargin",
                name: "AA114"
            },
        ],
    },
];