export const deleteFormConfig = [
    { type: "input", name: "AA001", hidden: true, value: "0" },
    {
        type: "text",
        value: "確定要取消嗎?",
        name: "deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        type: "text",
        label: "訂單編號：",
        labelPosition: "left",
        name: "AA128",
        css:"deleteFormName"
    },
    {
        type: "text",
        label: "訂房人：",
        labelPosition: "left",
        name: "fullName",
        css:"deleteFormName"
    },
    {
        type: "select",
        label: "*取消訂單原因：",
        value: "0",
        css:"deleteFormName",
        options: [
            {
                value: "0",
                content: "---請選擇---",
            },
        ],
        name: "AA005",
    }
];