export const checkIn_canCheckInRoomGridColumn = [
    { id: "isChecked", width:60, header: [{ text: "入住" }],htmlEnable: true,
        tooltip: false,
        template: function (text, row, col) {
            return "<div class='cell__template' style='width: 100%;display: flex;justify-content: center;'><input type='checkbox' style='height: 1.2em;width: 1.2em;'></div>";
        },type: "boolean" },
    { id: "item_no", width:60, header: [{ text: "項次" }] },
    { id: "check_in_out_date", width:300, header: [{ text: "入住/退房日期" }] },
    { id: "room_name", width:150, header: [{ text: "房間名稱" }] },
    { id: "room_type", width:150, header: [{ text: "房型" }] },
];