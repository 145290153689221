export const toolbar9Config = [
    {
        type: "title",
        value: "訂單來源"
    },
    {id: "reload",
        type: "button",
        circle: true,
        value: "重新整理",
        size: "small",
        icon: "fa-solid fa-rotate-right",
    },
    {id: "add",
        type: "button",
        circle: true,
        value: "新增訂單來源",
        size: "small",
        icon: "fa-solid fa-plus",
    },

]