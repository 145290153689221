export const toolbar2Config = [
    {id:"add", type: "button", value: "Verify Ticket", size: "small", icon: "fa-solid fa-plus", circle:true},
    {
        type: "datePicker",
        value: new Date(),
        label: "Project Start Time",
        width: "250",
        icon: "fa-solid fa-calendar-days",
        labelWidth: "50px",
        timePicker: true,
        timeFormat: 24,
        dateFormat: "%Y-%m-%d",
        valueFormat:"string",
        name: "startTime",
    },
    {
        type: "datePicker",
        value: new Date(),
        label: "Project End Time",
        width: "250",
        icon: "fa-solid fa-calendar-days",
        labelWidth: "50px",
        timePicker: true,
        timeFormat: 24,
        dateFormat: "%Y-%m-%d",
        valueFormat:"string",
        name: "endTime",
    },
    {id:"search", type: "button", value: "search", size: "small", icon: "fa-solid fa-magnifying-glass", circle:true},
    {id: "reload", type: "button", circle: true, value: "Reload", size: "small", icon: "fa-solid fa-rotate-right"},
];