export const deleteFormConfig = [
    {type: "input", name: "CA001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要刪除嗎?",
        name: "deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        type: "text",
        label: "加價產品名稱：",
        labelPosition: "left",
        value:"",
        name: "CA101",
        css:"deleteFormName"
    },
    {
        type: "textarea",
        label: "*刪除原因：",
        required: true,
        value: "",
        height: 150,
        name: "CA109",
    },
];