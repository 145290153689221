export const hotelVerbSetStopFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要停用嗎?",
        name: "stopFormTitle",
        css:"stopFormTitle"
    },
    {
        type: "text",
        label: "飯店名稱：",
        value:"",
        labelPosition: "left",
        name: "AA101",
        css:"stopFormName"
    },
    // {
    //     rows:[
    //         {
    //             type: "radioGroup",
    //             label: "停用類型",
    //             labelPosition: "left",
    //             options: {
    //                 cols: [
    //                     {
    //                         type: "radioButton",
    //                         text: "無限期停用",
    //                         value: 3,
    //                     },
    //                     {
    //                         type: "radioButton",
    //                         text: "時間區間範圍停用",
    //                         value: 5
    //                     },
    //                 ]
    //             },
    //             name: "AA041",
    //         },
    //         {
    //             cols:[
    //
    //                 {
    //                     type: "datepicker",
    //                     label: "停用開始時間",
    //                     dateFormat: "%Y-%m-%d",
    //                     css:"rightMargin",
    //                     width: "235px",
    //                     disabled: true,
    //                     name: "AA110",
    //                 },
    //                 {
    //                     type: "datepicker",
    //                     label: "停用結束時間",
    //                     dateFormat: "%Y-%m-%d",
    //                     width: "235px",
    //                     disabled: true,
    //                     name: "AA111",
    //                 },
    //             ],
    //         },
            {
                type: "textarea",
                value: "",
                label: "*停用原因：",
                placeholder: "",
                height: 150,
                name: "AA114",
            },
    //     ],
    // },
    // {
    //     type: "textarea",
    //     label: "原因*",
    //     required: true,
    //     value: "",
    //     height: 150,
    //     name: "AA114",
    // },
];

export const unitsSetStopFormConfig = [
    {type: "input", name: "AD001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要停用嗎?",
        name: "stopFormTitle",
        css:"stopFormTitle"
    },
    {
        type: "text",
        label: "單位名稱：",
        labelPosition: "left",
        value:"",
        name: "AD100",
        css:"stopFormName"
    },
    {
        type: "textarea",
        label: "*停用原因：",
        value: "",
        height: 150,
        name: "AD101",
    },
]

export const orderSourceSetStopFormConfig = [
    {type: "input", name: "AF001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要停用嗎?",
        name: "stopFormTitle",
        css:"stopFormTitle"
    },
    {
        type: "text",
        label: "訂單來源名稱：",
        labelPosition: "left",
        value:"",
        name: "AF100",
        css:"stopFormName"
    },
    {
        type: "textarea",
        label: "*停用原因：",
        value: "",
        height: 150,
        name: "AF101",
    },
]

export const orderSubSourceSetStopFormConfig = [
    {type: "input", name: "AF001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要停用嗎?",
        name: "stopFormTitle",
        css:"stopFormTitle"
    },
    {
        type: "text",
        label: "銷售來源名稱：",
        labelPosition: "left",
        value:"",
        name: "AF100",
        css:"stopFormName"
    },
    {
        type: "textarea",
        label: "*停用原因：",
        value: "",
        height: 150,
        name: "AF101",
    },
]