export const questionClassGridColumn = [
    {
        id: "action", header: [{ text: "動作", align: "center" }],
        htmlEnable: true, align: "center", width:80,
    },
    { id: "item_no", width:80, header: [{ text: "項次" }] },
    { id: "questionClassName", width:150, header: [{ text: "問題類別名稱" },{content: "inputFilter"}] },
    { id: "questionClassOrder", width:150, header: [{ text: "排序" },{content: "inputFilter"}] },
    { id: "created_by", width:200, header: [{ text: "建立人員" },{content: "selectFilter"}] },
    { id: "created_at", width:200, header: [{ text: "建立時間" },{ content: "inputFilter"}] },
    { id: "updated_by", width:200, header: [{ text: "修改人員" },{content: "selectFilter"}] },
    { id: "updated_at", width:200, header: [{ text: "修改時間" },{ content: "inputFilter"}] },
];
