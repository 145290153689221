export const alertSetGrid_deleteFormConfig = [
    { type: "input", name: "AE001", hidden: true, value: "0" },
    { type: "input", name: "AE002", hidden: true, value: "0" },
    { type: "input", name: "AE003", hidden: true, value: "0" },
    {
        type: "text",
        value: "確定要刪除嗎？",
        name: "alertSetGrid_deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        type: "text",
        label: "提醒時間：",
        labelPosition:"top",
        name: "AE100",
        css:"deleteLabel"
    },
    {
        type: "text",
        label: "提醒內容：",
        labelPosition:"top",
        name: "AE101",
        css:"deleteLabel"
    },
    {
        type: "textarea",
        label: "*刪除原因：",
        height: 150,
        name: "AE104",
        css: "deleteLabel"
    },
];