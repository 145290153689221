export const pricesSetGridDeleteFormConfig = [
    {type: "input", name: "BC001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要刪除嗎?",
        name: "deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        type: "text",
        label: "飯店名稱：",
        labelPosition: "left",
        name: "hotelName",
        css:"deleteFormName"
    },
    {
        type: "text",
        label: "房型名稱：",
        labelPosition: "left",
        name: "roomType",
        css:"deleteFormName"
    },
    {
        cols:[
            {
                type: "text",
                label: "日期範圍：",
                labelPosition: "left",
                name: "dataRange",
            },
            // {
            //     type: "text",
            //     value: " ~ ",
            // },
            // {
            //     type: "text",
            //     name: "BC102",
            // },
        ],
    },
    {
        type: "textarea",
        label: "備註：",
        height: 70,
        name: "BC211",
        disabled: true,
    },
    {
        type: "textarea",
        label: "*刪除原因：",
        value: "",
        height: 120,
        name: "BC212",
    },
];