export const paymentMethodGridColumn = [
    {
        id: "action", header: [{ text: "動作", align: "center" }],
        htmlEnable: true, align: "center", width:100,
    },
    { id: "item_no", width:80, header: [{ text: "項次" }] },
    { id: "paymentMethod_no", width:120, header: [{ text: "付款方式編號" },{content: "inputFilter"}] },
    { id: "paymentMethod_name", width:150, header: [{ text: "付款方式名稱" },{content: "inputFilter"}] },
    { id: "status", width:100, header: [{ text: "狀態" },{content: "selectFilter"}],
        htmlEnable: true,
        mark:function (e){
            return e == "使用中"?"use":"nouse";
        }
    },
    { id: "invoice", width:100, header: [{ text: "是否開立發票" },{content: "selectFilter"}],
        htmlEnable: true,
        mark:function (e){
            return e == "是"?"use":"nouse";
        }
    },
    { id: "stop_reason", width:200, header: [{ text: "停用原因" },{content: "inputFilter"}] },
    { id: "created_by", width:200, header: [{ text: "建立人員" },{content: "selectFilter"}] },
    { id: "created_at", width:200, header: [{ text: "建立時間" },{ content: "inputFilter"}] },
    { id: "updated_by", width:200, header: [{ text: "修改人員" },{content: "selectFilter"}] },
    { id: "updated_at", width:200, header: [{ text: "修改時間" },{ content: "inputFilter"}] },
];
