export const pricesSetGridForm1RightConfig = [
    {
        type: "checkbox",
        label: "",
        labelWidth: "0px",
        labelPosition: "left",
        text: "周末假",
        // checked: true,
        name: "BC156",
        id: "weekends",
    },
    {
        css: "row1",
        id: "pricesSetGridForm1LeftRow2",
        name: "pricesSetGridForm1LeftRow2",
        rows: [
            {
                type: "checkboxGroup",
                name: "BC157",
                disabled: true,
                options: {
                    cols: [
                        {
                            text: "週五",
                            type: "checkbox",
                            id: "5"
                        },
                        {
                            text: "週六",
                            type: "checkbox",
                            id: "6"
                        },
                        {
                            text: "週日",
                            type: "checkbox",
                            id: "0"
                        }
                    ]
                }
            },
            {
                cols:[
                    {
                        type: "input",
                        label: "價格",
                        labelPosition: "top",
                        css:"rightMargin",
                        placeholder: "",
                        width: "144px",
                        disabled: true,
                        inputType: "number",
                        name: "BC158"
                    },
                    {
                        type: "input",
                        label: "同業價格",
                        labelPosition: "top",
                        css:"rightMargin",
                        placeholder: "",
                        width: "144px",
                        disabled: true,
                        inputType: "number",
                        name: "BC159"
                    },
                    {
                        type: "input",
                        label: "OTA平台價格",
                        labelPosition: "top",
                        placeholder: "",
                        width: "144px",
                        disabled: true,
                        inputType: "number",
                        name: "BC160"
                    },
                ],
            },
            {
                type: "text",
                label: "小時價格：",
                labelPosition: "left",
            },
            {
                css: "row1",
                id: "pricesSetGridForm1LeftRow2",
                name: "pricesSetGridForm1LeftRow2",
                rows:[
                    {
                        cols:[
                            {
                                type: "checkbox",
                                text: "每小時相同價格",
                                // checked: true,
                                name: "BC161",
                                disabled: true,
                                id: "BC161",
                            },
                            {
                                type: "input",
                                width: "50px",
                                value: "",
                                disabled: true,
                                inputType: "number",
                                name: "BC162"
                            },
                            {
                                type: "text",
                                label:"元",
                                disabled: true,
                                labelPosition: "left",
                                name: "weekends_ntd",
                            },
                        ],
                    },
                    {
                        cols:[
                            {
                                type: "input",
                                label: "00:00",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC163"
                            },
                            {
                                type: "input",
                                label: "00:30",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC164"
                            },
                            {
                                type: "input",
                                label: "01:00",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC165"
                            },
                            {
                                type: "input",
                                label: "01:30",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC166"
                            },
                            {
                                type: "input",
                                label: "02:00",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC167"
                            },
                            {
                                type: "input",
                                label: "02:30",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC168"
                            },
                            {
                                type: "input",
                                label: "03:00",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC169"
                            },
                            {
                                type: "input",
                                label: "03:30",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC170"
                            },
                            {
                                type: "input",
                                label: "04:00",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC171"
                            },
                        ],
                    },
                    {
                        cols:[
                            {
                                type: "input",
                                label: "04:30",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC172"
                            },
                            {
                                type: "input",
                                label: "05:00",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC173"
                            },
                            {
                                type: "input",
                                label: "05:30",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC174"
                            },
                            {
                                type: "input",
                                label: "06:00",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC175"
                            },
                            {
                                type: "input",
                                label: "06:30",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC176"
                            },
                            {
                                type: "input",
                                label: "07:00",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC177"
                            },
                            {
                                type: "input",
                                label: "07:30",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC178"
                            },
                            {
                                type: "input",
                                label: "08:00",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC179"
                            },
                            {
                                type: "input",
                                label: "08:30",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC180"
                            },
                        ],
                    },
                    {
                        cols:[
                            {
                                type: "input",
                                label: "09:00",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC181"
                            },
                            {
                                type: "input",
                                label: "09:30",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC182"
                            },
                            {
                                type: "input",
                                label: "10:00",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC183"
                            },
                            {
                                type: "input",
                                label: "10:30",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC184"
                            },
                            {
                                type: "input",
                                label: "11:00",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC185"
                            },
                            {
                                type: "input",
                                label: "11:30",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC186"
                            },
                            {
                                type: "input",
                                label: "12:00",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC187"
                            },
                            {
                                type: "input",
                                label: "12:30",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC188"
                            },
                            {
                                type: "input",
                                label: "13:00",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC189"
                            },
                        ],
                    },
                    {
                        cols:[
                            {
                                type: "input",
                                label: "13:30",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC190"
                            },
                            {
                                type: "input",
                                label: "14:00",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC191"
                            },
                            {
                                type: "input",
                                label: "14:30",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC192"
                            },
                            {
                                type: "input",
                                label: "15:00",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC193"
                            },
                            {
                                type: "input",
                                label: "15:30",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC194"
                            },
                            {
                                type: "input",
                                label: "16:00",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC195"
                            },
                            {
                                type: "input",
                                label: "16:30",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC196"
                            },
                            {
                                type: "input",
                                label: "17:00",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC197"
                            },
                            {
                                type: "input",
                                label: "17:30",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC198"
                            },
                        ],
                    },
                    {
                        cols:[
                            {
                                type: "input",
                                label: "18:00",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC199"
                            },
                            {
                                type: "input",
                                label: "18:30",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC200"
                            },
                            {
                                type: "input",
                                label: "19:00",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC201"
                            },
                            {
                                type: "input",
                                label: "19:30",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC202"
                            },
                            {
                                type: "input",
                                label: "20:00",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC203"
                            },
                            {
                                type: "input",
                                label: "20:30",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC204"
                            },
                            {
                                type: "input",
                                label: "21:00",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC205"
                            },
                            {
                                type: "input",
                                label: "21:30",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC206"
                            },
                            {
                                type: "input",
                                label: "22:00",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC207"
                            },
                        ],
                    },
                    {
                        cols:[
                            {
                                type: "input",
                                label: "22:30",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC208"
                            },
                            {
                                type: "input",
                                label: "23:00",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC209"
                            },
                            {
                                type: "input",
                                label: "23:30",
                                labelPosition: "top",
                                labelWidth: "40px",
                                width: "40px",
                                disabled: true,
                                css:"priceRightMargin weekendHours",
                                name: "BC210"
                            },
                        ],
                    },
                ],
            },
        ]
    },
];