export const checkIn_guestGridColumn = [
    {
        id: "action", header: [{ text: "動作", align: "center" }],
        htmlEnable: true, align: "center", width:80,
        // template: function (e) {
        //     return;
        // }
    },
    { minWidth: 80, id: "tel", width:120, header: [{ text: "電話" }] },
    { minWidth: 80, id: "gender", width:80, header: [{ text: "性別" }] },
    { minWidth: 80, id: "given_name", width:150, header: [{ text: "名字" }] },
    { minWidth: 80, id: "middle_name", width:150, header: [{ text: "中間名" }] },
    { minWidth: 80, id: "surname", width:150, header: [{ text: "姓氏" }] },
    { minWidth: 80, id: "pId", width:150, header: [{ text: "身分證字號" }] },
    { minWidth: 80, id: "birthday", width:150, header: [{ text: "生日" }] },
    { minWidth: 80, id: "country", width:120, header: [{ text: "國籍" }] },
    { minWidth: 80, id: "country_code", width:120, header: [{ text: "國際碼" }] },
    { minWidth: 80, id: "area_city_code", width:120, header: [{ text: "區域碼" }] },
    { minWidth: 80, id: "extension", width:100, header: [{ text: "分機" }] },
    { minWidth: 80, id: "Email", width:200, header: [{ text: "Email" }] },
    { width:200, id: "created_by", header: [{ text: "建立人員" }] },
    { width:200, id: "created_at", header: [{ text: "建立時間" }] },
    { width:200, id: "updated_by", header: [{ text: "更新人員" }] },
    { width:200, id: "updated_at", header: [{ text: "更新時間" }] },
];