let getDate = (date)=> {
    let startDateValue = new Date(date);
    let year1 = startDateValue.getFullYear();
    let month1 = startDateValue.getMonth()+1;
    let day1 = startDateValue.getDate();

    return `${year1}-${month1.toString().padStart(2,'0')}-${day1.toString().padStart(2,'0')}`;
}

export const handOverFormConfig = [
    { type: "input", name: "AA001", hidden: true, value: "0" },
    { type: "input", name: "AA002", hidden: true, value: "0" },
    { type: "input", name: "AA103", hidden: true, value: "3" },
    {
        type: "text",
        label: "交班人員",
        value: "",
        name: "name",
    },
    {
        type: "textarea",
        label: "*交班內容",
        value: "",
        height: 100,
        name: "AA101",
    },
    {
        type: "radioGroup",
        label: "*是否完成",
        // value: 0,
        options: {
            cols: [
                {
                    type: "radioButton",
                    text: "是",
                    value: "1",
                },
                {
                    type: "radioButton",
                    text: "否",
                    value: "0",
                    // checked: true,
                },
            ]
        },
        name: "AA100",
    },
    {
        type: "datepicker",
        label: "完成時日期",
        timePicker: true,
        timeFormat: 24,
        disabled: true,
        dateFormat: "%Y-%m-%d %H:%i",
        valueFormat:"string",
        mark: function (date) {
            if (getDate(date) === getDate(new Date)) {
                return "highlight-date";
            }
        },
        name: "AA102",
    }
];