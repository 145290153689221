export const alertSetGridFormConfig = [
    { type: "input", name: "AE001", hidden: true, value: "0" },
    { type: "input", name: "AE002", hidden: true, value: "0" },
    { type: "input", name: "AE003", hidden: true, value: "0" },
    { type: "datepicker",
        label: "*提醒時間：",
        labelPosition: "top",
        timePicker: true,
        timeFormat: 24,
        dateFormat: "%Y-%m-%d %H:%i",
        valueFormat:"string",
        name: "AE100",
    },
    {
        type: "textarea",
        label: "*提醒內容：",
        labelPosition: "top",
        height:150,
        name: "AE101"
    },
];