export const userPermissGridColumn = [
    { minWidth: 200, id: "functionName", header: [{ text: "功能名稱" }] },
    // { minWidth: 100, id: "permiss_view", header: [{ text: "檢視" }],htmlEnable: true,type: "boolean"},
    { minWidth: 100, id: "permiss_view", header: [{ text: "檢視" }],htmlEnable: true,
        tooltip: false,
        template: function (text, row, col) {
            return "<div class='cell__template' style='width: 100%;display: flex;justify-content: center;'><input type='checkbox' style='height: 1.2em;width: 1.2em;'" + (text == true ? "checked" : "") + " ></div>";
        },type: "boolean"},
    { minWidth: 100, id: "permiss_edit", header: [{ text: "編輯" }],htmlEnable: true,
        tooltip: false,
        template: function (text, row, col) {
            return "<div class='cell__template' style='width: 100%;display: flex;justify-content: center;'><input type='checkbox' style='height: 1.2em;width: 1.2em;'" + (text == true ? "checked" : "") + " ></div>";
        },type: "boolean" },
    { minWidth: 100, id: "permiss_delete", header: [{ text: "刪除" }],htmlEnable: true,
        tooltip: false,
        template: function (text, row, col) {
            return "<div class='cell__template' style='width: 100%;display: flex;justify-content: center;'><input type='checkbox' style='height: 1.2em;width: 1.2em;'" + (text == true ? "checked" : "") + " ></div>";
        },type: "boolean" },
    { minWidth: 100, id: "permiss_print", header: [{ text: "列印" }],htmlEnable: true,
        tooltip: false,
        template: function (text, row, col) {
            return "<div class='cell__template' style='width: 100%;display: flex;justify-content: center;'><input type='checkbox' style='height: 1.2em;width: 1.2em;'" + (text == true ? "checked" : "") + " ></div>";
        },type: "boolean" },
    { width:200, id: "created_by", header: [{ text: "建立人員" }] },
    { width:200, id: "created_at", header: [{ text: "建立時間" }] },
    { width:200, id: "updated_by", header: [{ text: "更新人員" }] },
    { width:200, id: "updated_at", header: [{ text: "更新時間" }] },
]