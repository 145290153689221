export const WindowFootClear = {
    type: "button",
    view: "link",
    size: "medium",
    color: "primary",
    value: "清除",
    id: "WINDOW_FOOT_CLEAR"
};

export const WindowFootAccept = {
    type: "button",
    view: "flat",
    size: "medium",
    color: "primary",
    value: "儲存",
    id: "WINDOW_FOOT_ACCEPT",
    css: "windowFootAccept"
};

export const WindowFootWait = {
    type: "button",
    view: "flat",
    size: "medium",
    color: "primary",
    value: "儲存並轉為待打掃",
    // hidden:true,
    id: "WINDOW_FOOT_WAIT"
};

export const WindowFootClose = {
    type: "button",
    view: "link",
    size: "medium",
    color: "primary",
    value: "關閉",
    id: "WINDOW_FOOT_CLOSE"
}

export const WindowFootCleanRoom = {
    type: "button",
    view: "flat",
    size: "medium",
    color: "primary",
    value: "是，退房並打掃",
    id: "WINDOW_FOOT_CLEAN_ROOM"
}

export const WindowFootDontCleanRoom = {
    type: "button",
    view: "flat",
    size: "medium",
    color: "primary",
    value: "是，退房但不需要打掃",
    id: "WINDOW_FOOT_DONT_CLEAN_ROOM"
}

export const WindowFootReportLostProperty = {
    type: "button",
    view: "flat",
    size: "medium",
    color: "primary",
    value: "是，通報遺失物",
    id: "WINDOW_FOOT_REPORT_LOST_PROPERTY"
}

export const WindowFootDontReportLostProperty = {
    type: "button",
    view: "flat",
    size: "medium",
    color: "primary",
    value: "否，不須通報",
    id: "WINDOW_FOOT_DONT_REPORT_LOST_PROPERTY"
}