export const salesTargetReportGridColumn = [
    { id: "month", header: [{ text: "民國年(西元)/月" }]},
    { id: "salesTarget",align:"right", type:"number", header: [ { text: "銷售目標" }],format:"#,##.##" },
    { id: "sales",align:"right", type:"number",header: [{ text: "銷售額" }],format:"#,##.##" },
    { id: "percent",align:"right", header: [{ text: "完成百分比" }],
        // template: function (text, row, col) {
        // console.log(text, row, col)
        //     return `${row.percent}%`;
        // }
    },
]