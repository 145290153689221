export const questionClassFormConfig = [
    {type: "input", name: "DA001", hidden: true, value: "0"},
    {type: "input", name: "DA002", hidden: true, value: "0"},
    {
        type: "input",
        label: "*問題類別名稱：",
        labelPosition: "top",
        placeholder: "請輸入問題類別名稱",
        name: "DA101"
    },
    {
        type: "input",
        label: "類別排序：",
        labelPosition: "top",
        placeholder: "",
        name: "DA100"
    },
];

export const questionClassDeleteFormConfig = [
    {type: "input", name: "DA001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要刪除嗎?",
        name: "deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        type: "text",
        label: "問題類別名稱：",
        labelPosition: "left",
        name: "DA101",
        css:"deleteFormName"
    },
    {
        type: "textarea",
        label: "*刪除原因：",
        value: "",
        height: 150,
        name: "DA102",
    },
];
