export const deleteFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {type: "input", name: "AA002", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要刪除嗎?",
        name: "deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        type: "text",
        label: "專案名稱：",
        labelPosition: "left",
        value:"",
        name: "AA100",
        css:"deleteFormName"
    },
    {
        cols:[
            {
                type: "text",
                label: "專案類型：",
                labelPosition: "left",
                value:"",
                width: "49.7%",
                name: "project_type",
                css:"deleteFormName"
            },
            {
                type: "text",
                label: "專案價格：",
                labelPosition: "left",
                value:"",
                width: "49.7%",
                name: "AA110",
                css:"deleteFormName"
            },
        ]
    },
    {
        type: "textarea",
        label: "*刪除原因：",
        value: "",
        height: 150,
        name: "AA111",
    },
];