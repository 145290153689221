export const WindowFootClear = {
    type: "button",
    view: "link",
    size: "medium",
    color: "primary",
    value: "清除",
    id: "WINDOW_FOOT_CLEAR"
};

export const WindowFootAccept = {
    type: "button",
    view: "flat",
    size: "medium",
    color: "primary",
    value: "儲存",
    id: "WINDOW_FOOT_ACCEPT"
};

export const WindowFootClose = {
    type: "button",
    view: "link",
    size: "medium",
    color: "primary",
    value: "關閉",
    id: "WINDOW_FOOT_CLOSE"
};
