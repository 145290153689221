export const remindGridColumn = [
    { id: "item_no", width:80, header: [{ text: "項次" }] },
    { id: "order_no", width:150, header: [{ text: "訂單編號" },{content: "inputFilter"}] },
    { id: "alert_time", width:150, header: [{ text: "提醒時間" },{content: "inputFilter"}] },
    { id: "alert_content", width:250, header: [{ text: "提醒內容" },{content: "selectFilter"}] },
    { width:100, id: "created_by", header: [{ text: "建立人員" },{content: "inputFilter"}] },
    { id: "isChecked", width:100, header: [{ text: "是否確認" },{content: "selectFilter"}],htmlEnable: true,
        tooltip: false,
        template: function (text, row, col) {
            return "<div class='cell__template' style='width: 100%;display: flex;justify-content: center;'><input type='checkbox' style='height: 1.2em;width: 1.2em;'" + (text == true ? "checked" : "") + " ></div>";
        },type: "boolean" },
    { width:100, id: "checked_by", header: [{ text: "確認人員" },{content: "inputFilter"}] },
    { width:200, id: "created_at", header: [{ text: "建立時間" },{content: "inputFilter"}] },
    { width:100, id: "updated_by", header: [{ text: "更新人員" },{content: "inputFilter"}] },
    { width:200, id: "updated_at", header: [{ text: "更新時間" },{content: "inputFilter"}] },
]