export const paymentMethodFormConfig = [
    {type: "input", name: "AD001", hidden: true, value: "0"},
    {
        type: "input",
        label: "付款方式編號",
        labelPosition: "top",
        placeholder: "未填寫由系統自動產生",
        name: "AD100"
    },
    {
        type: "input",
        label: "付款方式名稱",
        labelPosition: "top",
        placeholder: "",
        name: "AD101"
    },
    {
        type: "radioGroup",
        label: "是否開立發票",
        value: 0,
        options: {
            cols: [
                {
                    type: "radioButton",
                    text: "是",
                    value: 1,
                    checked: true,
                },
                {
                    type: "radioButton",
                    text: "否",
                    value: 0
                },
            ]
        },
        name: "AD104",
    },
    {
        type: "radioGroup",
        label: "狀態",
        value: 1,
        options: {
            cols: [
                {
                    type: "radioButton",
                    text: "啟用中",
                    value: 1,
                    checked: true,
                },
                {
                    type: "radioButton",
                    text: "停用中",
                    value: 0
                },
            ]
        },
        name: "AD022",
    },
    {
        type: "textarea",
        placeholder: "停用原因",
        height: 100,
        name: "AD102",
    },
];

export const paymentMethodStopFormConfig = [
    {type: "input", name: "AD001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要停用嗎?",
        name: "stopFormTitle",
        css:"stopFormTitle"
    },
    {
        type: "text",
        label: "付款方式名稱：",
        labelPosition: "left",
        name: "AD101",
        css:"stopFormName"
    },
    {
        type: "textarea",
        label: "停用原因：",
        required: true,
        value: "",
        height: 150,
        name: "AD102",
    },
];

export const paymentMethodDeleteFormConfig = [
    {type: "input", name: "AD001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要刪除嗎?",
        name: "deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        type: "text",
        label: "付款方式名稱：",
        labelPosition: "left",
        name: "AD101",
        css:"deleteFormName"
    },
    {
        type: "textarea",
        label: "刪除原因：",
        required: true,
        value: "",
        height: 150,
        name: "AD103",
    },
];
