export const checkIn_orderInfoGridColumn = [
    { width: 120, id: "order_status", header: [{ text: "訂單狀態" }] },
    {
        width: 250, id: "order_no", header: [{ text: "訂單編號" }],
    },
    { width: 80, id: "total", header: [{ text: "總金額" }] },
    { width: 80, id: "discount", header: [{ text: "折扣" }] },
    { width: 80, id: "paid", header: [{ text: "已付" }] },
    { width: 80, id: "unpaid", header: [{ text: "未付" }] },
    { width: 150, id: "order_source", header: [{ text: "訂單來源" }] },
    { width: 200, id: "source_order_no", header: [{ text: "訂單來源編號" }] },
    { width: 150, id: "sale_source", header: [{ text: "銷售來源" }] },
    { width: 150, id: "invoice", header: [{ text: "發票號碼" }] },
    { width: 150, id: "order_date", header: [{ text: "訂購日期" }] },
    { width: 250, id: "note", header: [{ text: "訂單備註" }] },
];