export const newOrder_roomGridColumn = [
    {
        id: "action", header: [{ text: "動作", align: "center" }],
        htmlEnable: true, align: "center", width:80,
        footer: [{ text: "總金額" }]
    },
    { id: "item_no", width:60, header: [{ text: "項次" }] },
    { id: "check_in_out_date", width:250, header: [{ text: "入住/退房日期" }] },
    { id: "room_type", width:150, editable: true, header: [{ text: "房型" }] },
    { id: "room_name", width:150, editable: true, header: [{ text: "房間名稱" }] },
    { id: "quantity", width:60, header: [{ text: "數量" }] },
    { id: "price", width:80, header: [{ text: "價格" }] },
    { id: "additionalGuestPrice", width:80, header: [{ text: "加床費用" }] },
    { id: "totalPrice", width:80, header: [{ text: "小計" }], footer: [{ content: "sum" }], format: "#,#.##" },
    { id: "sale_source", width: 150, header: [{ text: "銷售來源" }] },
    { id: "seller", width: 150, header: [{ text: "銷售人員" }] },
    { id: "created_by", width:120, header: [{ text: "建立人員" }] },
    { id: "created_at", width:150, header: [{ text: "建立時間" }] },
    { id: "updated_by", width:120, header: [{ text: "更新人員" }] },
    { id: "updated_at", width:150, header: [{ text: "更新時間" }] },
];