export const productInfo_otherPrdGridDeleteFormConfig = [
    { type: "input", name: "AC001", hidden: true, value: "0" },
    { type: "input", name: "AC002", hidden: true, value: "0" },
    { type: "input", name: "AC003", hidden: true, value: "0" },
    { type: "input", name: "AC004", hidden: true, value: "0" },
    {
        type: "text",
        value: "確定要刪除嗎?",
        name: "otherPrdGrid_deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        cols:[
            {
                type: "text",
                label: "加價產品名稱：",
                labelPosition:"top",
                name: "AC101",
                width: "49.7%",
                css:"deleteFormName"
            },
            {
                type: "text",
                label: "數量：",
                labelPosition:"top",
                name: "AC103",
                width: "49.7%",
                css:"deleteFormName"
            },
        ]
    },
    {
        cols:[
            {
                type: "text",
                label: "價格：",
                labelPosition:"top",
                name: "AC102",
                width: "49.7%",
                css:"deleteFormName"
            },
            {
                type: "text",
                label: "總金額：",
                labelPosition:"top",
                name: "total",
                width: "49.7%",
                css:"deleteFormName"
            },
        ]
    },
    {
        type: "textarea",
        label: "*刪除原因：",
        required: true,
        value: "",
        height: 150,
        name: "AC105",
    },
];