import i18n from "i18next";

export const toolbar1Config = [
    {
        id: "reload",
        type: "button",
        circle: true,
        value: "重新整理",
        size: "small",
        icon: "fa-solid fa-rotate-right",
    },
    {
        id:"add1",
        type: "button",
        value: "新增預設價格",
        size: "small",
        icon: "fa-solid fa-plus",
        circle:true
    },
    {
        id:"add2",
        type: "button",
        value: "新增日期範圍價格",
        size: "small",
        icon: "fa-solid fa-plus",
        circle:true
    },
    // {
    //     id:"viewPricesTable",
    //     type: "button",
    //     value: "瀏覽價格表",
    //     size: "small",
    //     icon: "fa-solid fa-plus",
    //     circle:true
    // },
]