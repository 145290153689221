export const productInfo_payLogGridDeleteFormConfig = [
    { type: "input", name: "AH001", hidden: true, value: "0" },
    { type: "input", name: "AH002", hidden: true, value: "0" },
    { type: "input", name: "AH003", hidden: true, value: "0" },
    {
        type: "text",
        value: "確定要刪除嗎?",
        name: "otherPrdGrid_deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        cols:[
            {
                type: "text",
                label: "付款金額：",
                labelPosition:"top",
                name: "AH100",
                width: "49.7%",
                css:"deleteFormName"
            },
            {
                type: "text",
                label: "折扣：",
                labelPosition:"top",
                name: "AH101",
                width: "49.7%",
                css:"deleteFormName"
            },
        ]
    },
    {
        cols:[
            {
                type: "text",
                label: "備註：",
                labelPosition:"top",
                name: "AH102",
                css:"deleteFormName"
            },
        ]
    },
    {
        type: "textarea",
        label: "*刪除原因：",
        value: "",
        height: 150,
        name: "AH103",
    },
];