export const faultReportDoneFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {type: "input", name: "AA002", hidden: true, value: "0"},
    {
        type: "datepicker",
        label: "*處理完成日期：",
        dateFormat: "%Y-%m-%d",
        width:"33%",
        css:"rightMargin",
        name: "AA109",
        value:Date.now(),
    },
    {
        type: "textarea",
        label: "*處理完成備註：",
        value: "",
        height: 100,
        name: "AA110",
    },
]