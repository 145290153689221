let startDateValue = new Date(Date.now());
let year1 = startDateValue.getFullYear();
let month1 = startDateValue.getMonth()+1;
let day1 = startDateValue.getDate();

if (day1 < 10) {
    day1 = '0' + day1;
}

if (month1 < 10) {
    month1 = `0${month1}`;
}

let format1 = `${year1}-${month1}-${day1}`;

let endDateValue = new Date(Date.now());
let year2 = endDateValue.getFullYear();
let month2 = endDateValue.getMonth()+1;
let day2 = endDateValue.getDate();

if (day2 < 10) {
    day2 = '0' + day2;
}

if (month2 < 10) {
    month2 = `0${month2}`;
}

let format2 = `${year2}-${month2}-${day2}`;

export const toolbar1Config = [
    {id: "reload",
        type: "button",
        circle: true,
        value: "重新整理",
        size: "small",
        icon: "fa-solid fa-rotate-right",
    },
    {id:"exportExcel", type: "button", value: "匯出Excel", size: "small", icon: "fa-solid fa-file-excel", circle:true},
];