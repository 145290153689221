export const checkIn_otherPrdGridColumn = [
    { id: "item_no", width:60, header: [{ text: "項次" }],footer: [{ text: "總金額" }] },
    { id: "roomName", width:150, header: [{ text: "房間名稱" }] },
    { id: "otherPrdName", width:150, header: [{ text: "加價產品名稱" }] },
    { id: "quantity", width:60, header: [{ text: "數量" }] },
    { id: "price", width:80, header: [{ text: "價格" }] },
    { id: "note", width:250, header: [{ text: "備註" }] },
    { id: "subtotal", width:80, header: [{ text: "小計" }], footer: [{ content: "sum" }], format: "#,#" },
    { id: "sale_source", width: 150, header: [{ text: "銷售來源" }] },
    { id: "seller", width: 150, header: [{ text: "銷售人員" }] },
    { id: "created_by", width:120, header: [{ text: "建立人員" }] },
    { id: "created_at", width:150, header: [{ text: "建立時間" }] },
    { id: "updated_by", width:120, header: [{ text: "更新人員" }] },
    { id: "updated_at", width:150, header: [{ text: "更新時間" }] },
];