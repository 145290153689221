/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useEffect, useRef, useState} from "react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Authentication layout components
import IllustrationLayout from "./components/IllustrationLayout";

import {
  message as DHXMessage,
} from 'dhx-suite';
// Image
import bgImage from "assets/images/illustrations/illustration-reset.jpg";
import "./style.css";
import axios from "axios";

import {useNavigate} from "react-router-dom";

import {LoginSessionContext} from "../../context";
import {useContext} from "react";
import api_url from "../../context/UrlContext.js"

const Login = ()=> {

  const apiServer = api_url;

  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const accountRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();

  //先清除token
  localStorage.removeItem("digital_token");

  const submitHandler = ()=>{
    if(accountRef.current.value == "" || passwordRef.current.value == ""){
      DHXMessage({
        text: "帳號或密碼未填",
        icon:"dxi dxi-close",
        css: "dhx_message--error",
        expire:5000
      });
    }else{
      axios.defaults.withCredentials = true;
      axios.post(apiServer+"/login", {
        username: accountRef.current.value, password: passwordRef.current.value
      }).then((response2) =>{

        if(response2.status == 200){

          if(response2.data.status == "success"){
            DHXMessage({
              text: "登入成功，畫面轉換中",
              icon:"dxi dxi-close",
              css: "dhx_message--success",
              expire:5000
            });

            //儲存token
            localStorage.setItem("digital_token",JSON.stringify(response2.data.content.access_token));

            //畫面跳轉
            navigate("/dashboard");
          }else{
            DHXMessage({
              text: "帳號或密碼錯誤",
              icon:"dxi dxi-close",
              css: "dhx_message--error",
              expire:5000
            });
          }
        }else{
          localStorage.removeItem("digital_token");
          DHXMessage({
            text: "連線異常，請重新整理",
            icon:"dxi dxi-close",
            css: "dhx_message--error",
            expire:5000
          });
        }

      })
          .catch((error) => {
            DHXMessage({
              text: "連線異常，請重新整理",
              icon:"dxi dxi-close",
              css: "dhx_message--error",
              expire:5000
            });
          });
    }
  };

  return (
    <IllustrationLayout
      title="萬步數位櫃台管理系統"
      illustration={bgImage}
    >
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <label htmlFor="account">帳號：</label>
          <input id="account" className={"form_input"} type="text" label="帳號" ref={accountRef} />
        </MDBox>
        <MDBox mb={2}>
          <label htmlFor="password">密碼：</label>
          <input id="password" className={"form_input"} type="password" label="密碼" ref={passwordRef} />
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton onClick={submitHandler} variant="gradient" color="info" size="large" fullWidth>
            登入
          </MDButton>
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
}

export default Login;
