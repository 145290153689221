export const toolbar10Config = [
    {
        type: "title",
        value: "銷售來源"
    },
    {id: "reload",
        type: "button",
        circle: true,
        value: "重新整理",
        size: "small",
        icon: "fa-solid fa-rotate-right",
    },
    {id: "add",
        type: "button",
        circle: true,
        value: "新增銷售來源",
        size: "small",
        icon: "fa-solid fa-plus",
    },

]