export const quesAndAnsFormConfig = [
    {type: "input", name: "DB001", hidden: true, value: "0"},
    {type: "input", name: "DB002", hidden: true, value: "0"},
    {
        cols:[
            {

                type: "select",
                label: "*問題類別名稱：",
                value: "0",
                options: [
                    {
                        value: "0",
                        content: "---請選擇---",
                    },
                ],
                width: "49.7%",
                css:"rightMargin",
                name: "DB003",
            },
            {
                type: "input",
                label: "*問題排序：",
                labelPosition: "top",
                width: "49.7%",
                css:"rightMargin",
                name: "DB102"
            },
        ]
    },
    {
        type: "textarea",
        label: "*問題：",
        labelPosition: "top",
        placeholder: "請填入問題",
        height: 150,
        name: "DB100"
    },
    {
        type: "textarea",
        label: "*答案：",
        labelPosition: "top",
        placeholder: "請填入答案",
        height: 150,
        name: "DB101"
    },
];

export const quesAndAnsDeleteFormConfig = [
    {type: "input", name: "DB001", hidden: true, value: "0"},
    // {
    //     type: "text",
    //     value: "確定要刪除嗎?",
    //     name: "deleteFormTitle",
    //     css:"deleteFormTitle"
    // },
    {
        type: "text",
        label: "問題：",
        labelPosition: "left",
        name: "DB100",
        css:"deleteFormName"
    },
    {
        type: "textarea",
        label: "答案：",
        value: "",
        height: 100,
        name: "DB101",
    },
    {
        type: "textarea",
        label: "*刪除原因：",
        value: "",
        height: 100,
        name: "DB103",
    },
];
