export const openBookingSettingFormConfig = [
    {type: "input", name: "BG001", hidden: true, value: "0"},
    {type: "input", name: "BG002", hidden: true, value: "0"},
    {
        type: "radioGroup",
        label: "*開放訂房設定類型：",
        value: "1",
        options: {
            cols: [
                {
                    type: "radioButton",
                    text: "自動延期",
                    width: "49.7%",
                    value: "1",
                    checked: true,
                },
                {
                    type: "radioButton",
                    text: "日期範圍",
                    width: "49.7%",
                    value: "2"
                },
            ]
        },
        name: "BG100",
    },
    {
        cols:[
            {
                width: "50%",
                cols:[
                    {type: "text", width: "70px", label:"自今日起",labelPosition:"left"},
                    {
                        type: "input",
                        placeholder: "",
                        inputType: "number",
                        width: "80px",
                        name: "BG101"
                    },
                    {type: "text", width: "50px", label:"  天",labelPosition:"left"},
                ],
            },
            {
                width: "50%",
                rows:[
                    {
                        type: "datepicker",
                        label: "開始日期",
                        labelPosition: "left",
                        labelWidth: "70px",
                        dateFormat: "%Y-%m-%d",
                        width: "270px",
                        name: "BG102",
                        css:"rightMargin",
                        disabled:true
                    },
                    {
                        type: "datepicker",
                        label: "結束日期",
                        labelPosition: "left",
                        labelWidth: "70px",
                        dateFormat: "%Y-%m-%d",
                        width: "270px",
                        name: "BG103",
                        disabled:true
                    },
                ],
            },
        ],
    },
];