export const productInfo_roomGridDeleteFormConfig = [
    { type: "input", name: "AG001", hidden: true, value: "0" },
    { type: "input", name: "AG002", hidden: true, value: "0" },
    { type: "input", name: "AG003", hidden: true, value: "0" },
    {
        type: "text",
        value: "確定要刪除嗎?",
        name: "roomGrid_deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        type: "text",
        label: "房型名稱：",
        labelPosition:"left",
        name: "roomTypeName",
        css:"deleteFormName"
    },
    {
        type: "text",
        label: "房間名稱：",
        labelPosition:"left",
        name: "roomName",
        css:"deleteFormName"
    },
    {
        type: "text",
        label: "入住/退房日期：",
        labelPosition:"left",
        name: "checkInOutDate",
        css:"deleteFormName"
    },
    {
        type: "textarea",
        label: "*刪除原因：",
        // required: true,
        height: 150,
        name: "AG129",
    },
];