export const roomDeviceSetFormConfig = [
    {type: "input", name: "BE001", hidden: true, value: "0"},
    { type: "input", label: "*設備名稱：", placeholder: "", name: "BE100" },
    { type: "input", label: "*設備編號：", placeholder: "未填寫由系統自動產生", name: "BE101" },
    {
        type: "radioGroup",
        label: "*狀態：",
        value: "1",
        options: {
            cols: [
                {
                    type: "radioButton",
                    text: "啟用",
                    value: "1",
                    checked: true,
                },
                {
                    type: "radioButton",
                    text: "停用",
                    value: "0"
                },
            ]
        },
        name: "BE022",
    },
    { type: "textarea", label: "*停用原因：", value: "", placeholder: "", height: 100, disabled: true, name: "BE102", },
];

export const roomDeviceStopFormConfig = [
    {type: "input", name: "BE001", hidden: true, value: "0"},
    { type: "text", value: "確定要停用嗎?", name: "stopFormTitle", css:"stopFormTitle" },
    { type: "text", label:"設備名稱：", labelPosition:"left", value:"", name: "BE100", css:"stopFormName" },
    { type: "text", label:"設備編號：", labelPosition:"left", value:"", name: "BE101", css:"stopFormName" },
    { type: "textarea", label: "*停用原因：", value: "", height: 150, name: "BE102" },
];

export const roomDeviceDeleteFormConfig = [
    {type: "input", name: "BE001", hidden: true, value: "0"},
    { type: "text", value: "確定要刪除嗎?", name: "deleteFormTitle", css:"deleteFormTitle" },
    { type: "text", label:"設備名稱：", labelPosition:"left", value:"", name: "BE100", css:"deleteFormName" },
    { type: "text", label:"設備編號：", labelPosition:"left", value:"", name: "BE101", css:"deleteFormName" },
    { type: "textarea", label: "*刪除原因：", value: "", height: 150, name: "BE103", },
];