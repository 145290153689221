import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import PropTypes from 'prop-types';
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";
import MDAlert from "../../../components/MDAlert";
import {CardHeader} from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import {
    Tabbar as DHXTabbar,
    Grid as DHXGrid,
    Toolbar as DHXToolbar,
    Layout as DHXLayout,
    Form as DHXForm,
    Window as DHXWindow,
    DataView as DHXDataview,
    message as DHXMessage,
} from "dhx-suite";
import {WindowFootAccept,WindowFootClear,WindowFootClose} from "./componentsConfig/windowConfig";
import {toolbar1Config} from "./componentsConfig/toolbar1Config";
import {doorLockGridColumn} from "./componentsConfig/doorLockGridColumn";
import {openDoorEventsGridColumn} from "./componentsConfig/openDoorEventsGridColumn";
import './style.css';
import Progress from '../../../assets/images/icons8-iphone-spinner.gif';
import axios from "axios";
import api_url from "../../../context/UrlContext";
import Image_error from "../../../assets/images/error.png";

const EventsBasicSetting = props => {

    const apiServer = api_url;
    const headers = {headers:{authorization: "Bearer "+JSON.parse(localStorage.getItem("digital_token"))}};
    const navigate = useNavigate();

    const [canAccess ,setCanAccess] = useState('');
    const [canEdit ,setCanEdit] = useState('');
    const [canDelete ,setCanDelete] = useState('');
    const [canPrint ,setCanPrint] = useState('');

    //飯店切換
    const [hotel, setHotel] = useState("0");

    const changeHotelHandler = (hotel) => {
        setHotel(hotel);
    };

    const [isLoading,setIsloading] = useState(true);

    const DHXEventsBasicSettingLayout = useRef(null);

    let doorLockGridId = "0";

    useEffect(() => {

        const dhxProgressWindow1 = new DHXWindow({
            modal: true,
            resizable: true,
            closable:false,
            height: 150,
            width:150,
            html:"<div style='text-align: center;'><img src="+Progress+" /><br/><span>請耐心等候</span></div>",
            css: "dhxProgressWindow1"
        });

        axios.post(apiServer+"/checkLoginState",{itemId1:JSON.parse(localStorage.getItem("digital_token"))},headers)
            .then((response)=>{
                if(response.data == 0){
                    navigate('/login');
                }else{
                    axios.post(apiServer+"/userProfileSet/loadPermiss",{itemId1:23},headers)
                        .then((response2)=>{
                            if(response2.status === 200){
                                if(response2.data){
                                    let p = response2.data.split(',');

                                    (p[0] == 1)?setCanAccess(true):setCanAccess(false);
                                    (p[1] == 1)?setCanEdit(true):setCanEdit(false);
                                    (p[2] == 1)?setCanDelete(true):setCanDelete(false);
                                    (p[3] == 1)?setCanPrint(true):setCanPrint(false);

                                    setIsloading(false);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                }
            })
            .catch((error) => {
                DHXMessage({
                    text: "連線異常，請重新整理",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            });

        const dhxEventsBasicSettingLayout = new DHXLayout(DHXEventsBasicSettingLayout.current,{
            type: "line",
            rows: [
                {
                    id: "eventsBasicSettingToolbar",
                    height: "50px",
                },
                {
                    cols: [
                        {
                            id:"doorLockGrid",
                            width: "330px",
                            resizable: true,
                        },
                        {id:"openDoorEventsGrid"}
                    ]
                },
            ]
        });

        const doorLockGrid = new DHXGrid(null,{
            columns: doorLockGridColumn,
            selection: "row",
            autoWidth: true,
            resizable: true,
        });

        doorLockGrid.selection.events.on("AfterSelect", function(row, col){
            doorLockGridId = row.id;

            dhxProgressWindow1.show();

            axios.post(apiServer+"/accAndDev/loadOpenEventList", {
                itemId1: row.id
            },headers)
            .then((response) =>{

                if(response.status === 200){
                    if(response.data){
                        openDoorEventsGrid.data.removeAll();
                        openDoorEventsGrid.data.parse(response.data);
                        dhxProgressWindow1.hide();
                    }else{
                        dhxProgressWindow1.hide();
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                }else{
                    dhxProgressWindow1.hide();
                    DHXMessage({
                        text: "連線異常，請重新整理",
                        icon:"dxi dxi-close",
                        css: "dhx_message--error",
                        expire:5000
                    });
                }

            })
            .catch((error) => {
                dhxProgressWindow1.hide();
                DHXMessage({
                    text: "連線異常，請重新整理",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            });
        });

        axios.get(apiServer+"/accAndDev/loadDoorLockGrid",headers)
            .then((response) =>{
                if(response.status === 200){
                    if(response.data){
                        doorLockGrid.data.removeAll();
                        doorLockGrid.data.parse(response.data);
                    }else{
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                }else{
                    DHXMessage({
                        text: "連線異常，請重新整理",
                        icon:"dxi dxi-close",
                        css: "dhx_message--error",
                        expire:5000
                    });
                }
            })
            .catch((error) => {
                DHXMessage({
                    text: "連線異常，請重新整理",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            });

        const toolbar1 = new DHXToolbar(null, {
        });
        toolbar1.events.on("click", function(id,e){
            switch (id) {
                case "reload":
                    openDoorEventsGrid.data.removeAll();
                    doorLockGrid.data.removeAll();
                    dhxProgressWindow1.show();
                    doorLockGrid.selection.removeCell();
                    axios.get(apiServer+"/accAndDev/loadDoorLockGrid",headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    doorLockGrid.data.parse(response.data);
                                    dhxProgressWindow1.hide();
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                default:
                    break;
            }
        });

        toolbar1.data.parse(JSON.parse(JSON.stringify(toolbar1Config)));

        const openDoorEventsGrid = new DHXGrid(null,{
            columns: openDoorEventsGridColumn,
            autoWidth: true,
            resizable: true,
        });

        dhxEventsBasicSettingLayout.getCell("doorLockGrid").attach(doorLockGrid);
        dhxEventsBasicSettingLayout.getCell("openDoorEventsGrid").attach(openDoorEventsGrid);
        dhxEventsBasicSettingLayout.getCell("eventsBasicSettingToolbar").attach(toolbar1);

        return () =>{
            dhxEventsBasicSettingLayout && dhxEventsBasicSettingLayout.destructor();
        }

    }, [canAccess,canEdit,canDelete,canPrint,hotel]);


    return (
        <DashboardLayout>
            <DashboardNavbar onHotelChange={changeHotelHandler}/>
            {canAccess &&
            <MDBox py={3}>
                <Grid container>
                    <Grid item xs={12} lg={12}>
                        <Card>
                            <h4 className={"gridTitle"}>門鎖開門事件</h4>
                            <MDBox py={1} px={1}>
                                <div ref={DHXEventsBasicSettingLayout} style={{height:"800px"}}></div>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            }

            {!canAccess && !isLoading &&
            <MDAlert color={"error"}>請確認是否擁有檢視的權限</MDAlert>
            }

            {isLoading &&
            <MDAlert color={"warning"}>權限確認中，請稍後...</MDAlert>
            }
        </DashboardLayout>
    );
};

export default EventsBasicSetting;