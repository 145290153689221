import React, {useEffect, useRef, useState} from 'react';
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import {
    Grid as DHXGrid,
    DataCollection,
    Tree as DHXTree,
    TreeCollection,
    Window as DHXWindow,
    Layout as DHXLayout,
    Toolbar as DHXToolbar,
    Form as DHXForm,
    message as DHXMessage,
    Combobox as DHXCombobox
} from "dhx-suite";
import "dhx-suite/codebase/suite.min.css"
import {CardHeader} from "@mui/material";
import {userGridColumn} from "./compomentConfig/userGridColumn";
import {toolbar1Config} from "./compomentConfig/toolbar1Config";
import {userPermissGridColumn} from "./compomentConfig/userPermissGridColumn";
import {userFormConfig,userStopFormConfig,userDeleteFormConfig} from "./compomentConfig/userFormConfig";
import {userWindowFootAccept, userWindowFootClear, userWindowFootClose} from "./compomentConfig/userWindowConfig";
import './style.css';
import axios from "axios";
import api_url from "../../../context/UrlContext.js"
import {useNavigate} from "react-router-dom";
import Image_warning from "../../../assets/images/warning.png";
import Image_error from "../../../assets/images/error.png";

const AccountProfileSetting = () => {

    const apiServer = api_url;
    const headers = {headers:{authorization: "Bearer "+JSON.parse(localStorage.getItem("digital_token"))}};
    const navigate = useNavigate();

    const [canAccess ,setCanAccess] = useState('');
    const [canEdit ,setCanEdit] = useState('');
    const [canDelete ,setCanDelete] = useState('');
    const [canPrint ,setCanPrint] = useState('');

    //飯店切換
    const [hotel, setHotel] = useState("0");

    const changeHotelHandler = (hotel) => {
        setHotel(hotel);
    };

    const [isLoading,setIsloading] = useState(true);

    let moduleFunTree = "";
    let userPermissGrid = "";
    let permissHotelCombobox = "";
    let menuTreeId = 0;
    let userGridId = 0;
    let permissHotelSelectId = 0;

    const DHXUserGridLayout = useRef(null);
    const permissHotelSelectRef = useRef();
    const DHXMFtree = useRef(null);
    const DHXUserPermissGrid = useRef(null);

    useEffect(()=>{

        axios.post(apiServer+"/checkLoginState",{itemId1:JSON.parse(localStorage.getItem("digital_token"))},headers)
            .then((response)=>{
                if(response.data == 0){
                    navigate('/login');
                }else{
                    axios.post(apiServer+"/userProfileSet/loadPermiss",{itemId1:13},headers)
                        .then((response2)=>{
                            if(response2.status == 200){
                                let p = response2.data.split(',');

                                (p[0] == 1)?setCanAccess(true):setCanAccess(false);
                                (p[1] == 1)?setCanEdit(true):setCanEdit(false);
                                (p[2] == 1)?setCanDelete(true):setCanDelete(false);
                                (p[3] == 1)?setCanPrint(true):setCanPrint(false);

                                setIsloading(false);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });

        const dhxUserGridLayout = new DHXLayout(DHXUserGridLayout.current,{
            rows:[
                {id:"userGridToolbar", height: "content"},
                {id:"usrGridLayout", height: "500px"},
            ]
        });

        const toolbar1 = new DHXToolbar(null, {
        });
        toolbar1.events.on("click", function(id,e){
            switch (id) {
                case "reload":
                    axios.get(apiServer+"/userProfileSet/loadUserData",headers)
                        .then((response) =>{
                            if(response.status === 200){
                                userGridId = 0;
                                dhxUserGrid.selection.removeCell();
                                dhxUserGrid.data.removeAll();
                                dhxUserGrid.data.parse(response.data);
                            }else{
                                console.log("Server Error")
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    axios.get(apiServer+"/userProfileSet/loadMenuTree",headers)
                        .then((response) =>{
                            if(response.status === 200){
                                menuTreeId = 0;
                                moduleFunTree.selection.remove();
                                moduleFunTree.focusItem();
                            }else{
                                console.log("Server Error")
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    permissHotelCombobox.setValue("0");
                    permissHotelSelectId = "0";
                    userPermissGrid.data.removeAll();
                    break;
                case "add":
                    if(canEdit){
                        dhxUserWindow.header.data.update("title", {value: "新增使用者視窗"});
                        dhxUserWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: false });
                        userForm.clear();
                        userForm.getItem("AA102").enable();
                        userForm.setValue({"AA001":"0","AA022":"1"});
                        dhxUserWindow.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                    break;
                default:
                    break;
            }
        });
        toolbar1.data.parse(JSON.parse(JSON.stringify(toolbar1Config)));

        const dhxUserGrid = new DHXGrid(null,{
            columns: userGridColumn,
            autoWidth: true,
            resizable: true,
            selection: "row",
            eventHandlers: {
                onclick: {
                    "userGrid_edit": function (e, data) {
                        if(canEdit){
                            dhxUserWindow.header.data.update("title", {value: "編輯使用者視窗"});
                            dhxUserWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: true });
                            userForm.getItem("AA102").disable();
                            axios.post(apiServer+"/userProfileSet/loadUserFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                userForm.setValue(response.data[0]);
                                dhxUserWindow.show();
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "userGrid_stop": function (e, data) {
                        if(canEdit){
                            axios.post(apiServer+"/userProfileSet/loadUserFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                userStopForm.setValue(response.data[0]);
                                dhxUserWindow2.show();
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "userGrid_delete": function (e, data) {
                        if(canDelete){
                            axios.post(apiServer+"/userProfileSet/loadUserFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                userDeleteForm.setValue(response.data[0]);
                                dhxUserWindow3.show();
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });

        dhxUserGrid.events.on("cellClick", function(row,column,e){
            userGridId = row.id;
            if(menuTreeId !== 0 && permissHotelSelectId !== "0"){
                axios.post(apiServer+"/userProfileSet/checkPermiss", {
                    itemId1: menuTreeId,itemId2:userGridId,itemId3:permissHotelSelectId
                },headers).then((response) =>{
                    console.log(response);
                    if(response.data){
                        axios.post(apiServer+"/userProfileSet/loadPermissGrid", {
                            itemId1: menuTreeId,itemId2:userGridId,itemId3:permissHotelSelectId
                        },headers).then((response2) =>{
                            userPermissGrid.data.removeAll();
                            userPermissGrid.data.parse(response2.data);
                        })
                            .catch((error) => {
                                console.log(error);
                            });
                    }
                })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        });

        axios.get(apiServer+"/userProfileSet/loadUserData",headers)
            .then((response) =>{
                if(response.status === 200){
                    dhxUserGrid.data.removeAll();
                    dhxUserGrid.data.parse(response.data);
                }else{
                    console.log("Server Error")
                }
            })
            .catch((error) => {
                console.log(error);
            });

        dhxUserGridLayout.getCell("userGridToolbar").attach(toolbar1);
        dhxUserGridLayout.getCell("usrGridLayout").attach(dhxUserGrid);

        const dhxUserWindow = new DHXWindow({
            title: "新增使用者視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 700,
            width: 650,
            footer: true,
            css: "dhxUserWindow",
        });

        dhxUserWindow.footer.data.add({type:"spacer"});
        dhxUserWindow.footer.data.add( JSON.parse( JSON.stringify(userWindowFootClose)));
        dhxUserWindow.footer.data.add( JSON.parse( JSON.stringify(userWindowFootClear)));
        dhxUserWindow.footer.data.add( JSON.parse( JSON.stringify(userWindowFootAccept)));

        dhxUserWindow.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(userForm.getItem("AA102").getValue() === ""){
                        DHXMessage({
                            text: "請填寫帳號",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(userForm.getItem("AA101").getValue() === ""){
                        DHXMessage({
                            text: "請填寫姓名",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(userForm.getItem("AA022").getValue() === "0" && userForm.getItem("AA110").getValue() === ""){
                        DHXMessage({
                            text: "請填入原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/userProfileSet/checkUserAccount",
                        userForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "使用者帳號重複",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }else{
                            axios.post(apiServer+"/userProfileSet/updateUserFormData",
                                userForm.getValue(),headers
                            ).then((response) =>{
                                if(response.data[0].result){
                                    DHXMessage({
                                        text: response.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--success",
                                        expire:5000
                                    });

                                    userForm.clear();
                                    userForm.setValue({"AA001": "0"});
                                    userForm.getItem("AA022").setValue("1");
                                    userForm.clear("validation");

                                    axios.get(apiServer+"/userProfileSet/loadUserData",headers)
                                        .then((data) =>{
                                            dhxUserWindow.hide();
                                            dhxUserGrid.data.removeAll();
                                            dhxUserGrid.data.parse(data.data);
                                        });
                                }else{

                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxUserWindow.hide();
                    break;
                default:
                    break;
            }
        });

        const userForm = new DHXForm(null,{
            rows: userFormConfig
        });

        userForm.getItem("AA022").events.on("change", function(value) {
            if(value == 0){
                userForm.getItem("AA110").enable();
            }else{
                userForm.getItem("AA110").setValue("");
                userForm.getItem("AA110").disable();
            }
        });

        dhxUserWindow.attach(userForm);

        const dhxUserWindow2 = new DHXWindow({
            title: "停用使用者視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 580,
            footer: true,
            css: "dhxUserWindow2",
        });

        dhxUserWindow2.footer.data.add({type:"spacer"});
        dhxUserWindow2.footer.data.add( JSON.parse( JSON.stringify(userWindowFootClose)));
        dhxUserWindow2.footer.data.add( JSON.parse( JSON.stringify(userWindowFootAccept)));

        dhxUserWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(userStopForm.getItem("AA110").getValue() === ""){
                        DHXMessage({
                            text: "請填寫停用原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/userProfileSet/stopUser",
                        userStopForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: response.data[0].message,
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            userStopForm.clear();
                            userStopForm.setValue({"AA001": "0"});
                            userStopForm.clear("validation");

                            axios.get(apiServer+"/userProfileSet/loadUserData",headers)
                                .then((data) =>{
                                    dhxUserWindow2.hide();
                                    dhxUserGrid.data.removeAll();
                                    dhxUserGrid.data.parse(data.data);
                                });
                        }else{

                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxUserWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let userStopFormLayout = new DHXLayout(null,{
            rows:[
                {id:"userStopFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_warning+" /></div>",
                    gravity: false
                },
                {id: "userStopForm"},
            ]
        });

        const userStopForm = new DHXForm(null,{
            rows: userStopFormConfig
        });

        dhxUserWindow2.attach(userStopFormLayout);
        userStopFormLayout.getCell("userStopForm").attach(userStopForm);



        const dhxUserWindow3 = new DHXWindow({
            title: "刪除使用者視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 580,
            footer: true,
            css: "dhxUserWindow3",
        });

        dhxUserWindow3.footer.data.add({type:"spacer"});
        dhxUserWindow3.footer.data.add( JSON.parse( JSON.stringify(userWindowFootClose)));
        dhxUserWindow3.footer.data.add( JSON.parse( JSON.stringify(userWindowFootAccept)));

        dhxUserWindow3.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(userDeleteForm.getItem("AA111").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/userProfileSet/deleteUser",
                        userDeleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: response.data[0].message,
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            userDeleteForm.clear();
                            userDeleteForm.setValue({"AA001": "0"});
                            userDeleteForm.clear("validation");

                            axios.get(apiServer+"/userProfileSet/loadUserData",headers)
                                .then((data) =>{
                                    dhxUserWindow3.hide();
                                    dhxUserGrid.data.removeAll();
                                    dhxUserGrid.data.parse(data.data);
                                });
                        }else{

                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxUserWindow3.hide();
                    break;
                default:
                    break;
            }
        });

        let userDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"userDeleteFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "userDeleteForm"},
            ]
        });

        const userDeleteForm = new DHXForm(null,{
            rows: userDeleteFormConfig
        });

        dhxUserWindow3.attach(userDeleteFormLayout);
        userDeleteFormLayout.getCell("userDeleteForm").attach(userDeleteForm);




        moduleFunTree = new DHXTree(DHXMFtree.current, {
        });

        axios.get(apiServer+"/userProfileSet/loadMenuTree",headers)
            .then((response) =>{
                if(response.status === 200){
                    moduleFunTree.data.removeAll();
                    moduleFunTree.data.parse(response.data);
                }else{
                    console.log("Server Error")
                }
            })
            .catch((error) => {
                console.log(error);
            });

        moduleFunTree.selection.events.on("afterSelect", function(id){
            menuTreeId = id;
            if(userGridId !== 0 && permissHotelSelectId !== "0"){
                axios.post(apiServer+"/userProfileSet/checkPermiss", {
                    itemId1: menuTreeId,itemId2:userGridId,itemId3:permissHotelSelectId
                },headers).then((response) =>{
                    if(response.data){
                        axios.post(apiServer+"/userProfileSet/loadPermissGrid", {
                            itemId1: menuTreeId,itemId2:userGridId,itemId3:permissHotelSelectId
                        },headers).then((response2) =>{
                            userPermissGrid.data.removeAll();
                            userPermissGrid.data.parse(response2.data);
                        })
                            .catch((error) => {
                                console.log(error);
                            });
                    }
                })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        });



        userPermissGrid = new DHXGrid(DHXUserPermissGrid.current,{
            columns: userPermissGridColumn,
            autoWidth: true,
            resizable: true,
            leftSplit:1
        });

        userPermissGrid.events.on("cellClick", function(row,column,e){
            console.log(row.id,column.id,row[column.id]);
            if(canEdit){

                if(column.id == "permiss_view" || column.id == "permiss_edit" || column.id == "permiss_delete" || column.id == "permiss_print"){
                    axios.post(apiServer+"/userProfileSet/changePermiss", {
                        itemId1: row.id,itemId2:column.id,itemId3:row[column.id]
                    },headers).then((response) =>{
                        if(response.data){
                            axios.post(apiServer+"/userProfileSet/loadPermissGrid", {
                                itemId1: menuTreeId,itemId2:userGridId,itemId3:permissHotelSelectId
                            },headers).then((response2) =>{
                                userPermissGrid.data.removeAll();
                                userPermissGrid.data.parse(response2.data);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            }else{
                DHXMessage({
                    text: "請先確認擁有編輯的權限",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            }
        });


        permissHotelCombobox = new DHXCombobox(permissHotelSelectRef.current,{
            readOnly:true,
            css: "hotelCombobox"
        });

        permissHotelCombobox.events.on("change", function(id){
            permissHotelSelectId = id;
            if(permissHotelSelectId !== "0" && menuTreeId !== 0 && userGridId !== 0){
                axios.post(apiServer+"/userProfileSet/checkPermiss", {
                    itemId1: menuTreeId,itemId2:userGridId,itemId3:permissHotelSelectId
                },headers).then((response) =>{
                    if(response.data){
                        axios.post(apiServer+"/userProfileSet/loadPermissGrid", {
                            itemId1: menuTreeId,itemId2:userGridId,itemId3:permissHotelSelectId
                        },headers).then((response2) =>{
                            userPermissGrid.data.removeAll();
                            userPermissGrid.data.parse(response2.data);
                        })
                            .catch((error) => {
                                console.log(error);
                            });
                    }
                })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        });

        axios.get(apiServer+"/userProfileSet/loadAllHotelList", headers)
            .then((response) =>{
                permissHotelCombobox.data.removeAll();
                permissHotelCombobox.data.parse(response.data);
                permissHotelCombobox.setValue("0");
            })
            .catch((error) => {
            });

        return () => {
            dhxUserGridLayout && dhxUserGridLayout.destructor();
            moduleFunTree && moduleFunTree.destructor();
            userPermissGrid && userPermissGrid.destructor();
            permissHotelCombobox.destructor();
        };
    }, [canAccess,canEdit,canDelete,canPrint,hotel]);


    return (
        <DashboardLayout>
            <DashboardNavbar onHotelChange={changeHotelHandler}/>
            {canAccess &&
                <MDBox py={2}>
                    <MDBox mb={2}>
                        <Grid container>
                            <Grid item xs={12} lg={12}>
                                <Card>
                                    <CardHeader title={"使用者清單"}/>
                                    <MDBox py={1} px={1}>
                                        <div ref={DHXUserGridLayout}></div>
                                    </MDBox>
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                    <MDBox mb={3}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} lg={3}>
                                <Card>
                                    <MDBox py={1} px={1}>
                                        <MDTypography variant={"h6"} fontWeight={"medium"}>
                                            系統功能
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox py={1} px={1} >
                                        <div
                                            style={{ width: '100%', padding: 0, height: '100%', overflow: "auto" }}
                                            ref={DHXMFtree}
                                        ></div>
                                    </MDBox>
                                </Card>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <Card>
                                    <MDBox py={1} px={1}>
                                        <MDTypography variant={"h6"} fontWeight={"medium"} style={{display: "flex"}}>
                                            <span>使用者權限</span>&nbsp;&nbsp;
                                            設定館別：<div style={{"width":"200px",display: "inline-block"}} ref={permissHotelSelectRef}></div>
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox py={1} px={1} >
                                        <div style={{ width: "100%", height: "250px", overflow: "auto" }} ref={DHXUserPermissGrid}></div>
                                    </MDBox>
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDBox>
            }

            {!canAccess && !isLoading &&
            <MDAlert color={"error"}>請確認是否擁有檢視的權限</MDAlert>
            }

            {isLoading &&
            <MDAlert color={"warning"}>權限確認中，請稍後...</MDAlert>
            }
        </DashboardLayout>
    );
};

export default AccountProfileSetting;