let getDate = (date)=> {
    let startDateValue = new Date(date);
    let year1 = startDateValue.getFullYear();
    let month1 = startDateValue.getMonth()+1;
    let day1 = startDateValue.getDate();

    return `${year1}-${month1.toString().padStart(2,'0')}-${day1.toString().padStart(2,'0')}`;
}

export const faultReportDoneFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {type: "input", name: "AA002", hidden: true, value: "0"},
    {
        type: "datepicker",
        label: "*處理完成日期：",
        dateFormat: "%Y-%m-%d",
        width:"33%",
        css:"rightMargin",
        name: "AA109",
        value:Date.now(),
    },
    {
        type: "textarea",
        label: "*處理完成備註：",
        value: "",
        height: 100,
        name: "AA110",
    },
    {
        cols:[
            {
                type: "checkbox",
                label: "",
                labelWidth: "0px",
                labelPosition: "left",
                text: "房間需打掃",
                // checked: true,
                name: "AA111",
                id: "AA111",
                css: "faultReportDoneForm_checkBox"
            },
            {
                type: "datepicker",
                label: "*打掃時間：",
                labelPosition: "left",
                timePicker: true,
                timeFormat: 24,
                mark: function (date) {
                    if (getDate(date) === getDate(new Date)) {
                        return "highlight-date";
                    }
                },
                disabled: "true",
                dateFormat: "%Y-%m-%d %H:%i",
                valueFormat:"string",
                name: "AA112",
                css: "faultReportDoneForm_cheanTime"
            },
        ]
    }
]