export const orderInfo_guestGridFormConfig = [
    { type: "input", name: "AF001", hidden: true, value: "0" },
    { type: "input", name: "AF002", hidden: true, value: "0" },
    { type: "input", name: "AF003", hidden: true, value: "0" },
    {
        cols:[
            { type: "input", label: "電話：", labelPosition: "top", width:"33%", css:"rightMargin", name: "AF115" },
            { type: "input", label: "區碼：", labelPosition: "top", width:"33%", css:"rightMargin", name: "AF114" },
            { type: "input", label: "分機：", labelPosition: "top", width:"33%", css:"rightMargin", name: "AF116" },
        ],
    },
    {
        cols:[
            { type: "input", label: "*名字：", labelPosition: "top", width:"33%", css: "rightMargin", name: "AF109" },
            { type: "input", label: "中間名：", labelPosition: "top", width:"33%", css: "rightMargin", name: "AF108" },
            { type: "input", label: "姓氏：", labelPosition: "top", width:"33%", css:"rightMargin", name: "AF107" },
        ],
    },
    {
        cols:[
            { type: "input", label: "前綴字：", labelPosition: "top", width:"33%", css:"rightMargin", name: "AF106" },
            { type: "datepicker", label: "生日：", timeFormat: 24, dateFormat: "%Y-%m-%d", valueFormat:"string", width:"33%", css:"rightMargin", name: "AF120", },
            { type: "input", label: "身分證字號：", labelPosition: "top", width:"33%", css:"rightMargin", name: "AF110" },
        ],
    },
    {
        cols:[
            { type: "select", label: "性別：", labelPosition: "top",
                options: [
                    {
                        value: "0",
                        content: "---請選擇---",
                        disabled: true
                    },
                    {
                        value: "1",
                        content: "無法得知"
                    },
                    {
                        value: "2",
                        content: "男性"
                    },
                    {
                        value: "3",
                        content: "女性"
                    }
                ], width:"33%", css:"rightMargin", name: "AF111" },
            { type: "select", label: "國籍：", labelPosition: "top",
                options: [
                    {
                        value: "0",
                        content: "---請選擇---",
                        disabled: true
                    },
                ],
                width:"33%", css:"rightMargin", name: "AF004" },
            { type: "input", label: "國際碼", labelPosition: "top", value:"886", width:"33%", css:"rightMargin", name: "AF113" },
        ],
    },
    {
        cols:[
            { type: "input", label: "E-mail", labelPosition: "top", width:"33%", css:"rightMargin", name: "AF119" },
        ],
    },
];

export const orderInfo_guestGridDeleteFormConfig = [
    { type: "input", name: "AF001", hidden: true, value: "0" },
    { type: "input", name: "AF002", hidden: true, value: "0" },
    { type: "input", name: "AF003", hidden: true, value: "0" },
    { type: "text", value: "確定要刪除嗎?", name: "orderInfo_guestGrid_deleteFormTitle", css:"deleteFormTitle" },
    { type: "text", label: "入住人姓名：", value:"", name: "fullName", css:"deleteLabel" },
    { type: "text", label: "入住人電話：", value:"", name: "AF115", css:"deleteLabel" },
    { type: "textarea", label: "*刪除原因：", value: "", height: 120, name: "AF125", css:"deleteLabel"},
];