import React, {useContext, useEffect, useRef, useState} from 'react';
import DashboardLayout from "../../pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../pages/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import MDAlert from "components/MDAlert";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
    Grid as DHXGrid,
    DataCollection,
    Tree as DHXTree,
    TreeCollection,
    Window as DHXWindow,
    Layout as DHXLayout,
    Toolbar as DHXToolbar,
    Form as DHXForm,
    message as DHXMessage,
    alert as DHXAlert,
    confirm as DHXConfirm,
    Tabbar as DHXTabbar,
    DataView as DHXDataview,
} from "dhx-suite";
import "dhx-suite/codebase/suite.min.css";
import {CardHeader} from "@mui/material";
import axios from "axios";
import {toolbar1Config,toolbar2Config,toolbar3Config,toolbar4Config,toolbar5Config} from "./componentsConfig/toolbarConfig";
import {useNavigate, useLocation} from "react-router-dom";
import api_url from "../../context/UrlContext.js"
import {
    WindowFootAccept,
    WindowFootClear,
    WindowFootClose,
    WindowFootWait,
    WindowFootCleanRoom,
    WindowFootDontCleanRoom,
    WindowFootReportLostProperty, WindowFootDontReportLostProperty
} from "./componentsConfig/dashboardWindowConfig";
import {viewGridFormConfig, viewGridForm2_1Config, viewGridForm2BtnConfig} from "./componentsConfig/viewGridFormConfig";
import './style.css';
import {checkIn_orderInfoGridColumn} from "./componentsConfig/checkIn_orderInfoGridColumn";
import {checkIn_purchaserGridFormConfig} from "./componentsConfig/checkIn_purchaserGridFormConfig";
import {checkIn_purchaserGridColumn} from "./componentsConfig/checkIn_purchaserGridColumn";
import {checkIn_guestGridColumn} from "./componentsConfig/checkIn_guestGridColumn";
import {checkIn_guestGridToolbarConfig} from "./componentsConfig/checkIn_guestGridToolbarConfig";
import {
    checkIn_guestGridDeleteFormConfig,
    checkIn_guestGridFormConfig
} from "./componentsConfig/checkIn_guestGridFormConfig";
import {checkIn_roomGridColumn} from "./componentsConfig/checkIn_roomGridColumn";
import {checkIn_otherPrdGridColumn} from "./componentsConfig/checkIn_otherPrdGridColumn";
import Image_error from "../../assets/images/error.png";
import Image_warning from "../../assets/images/warning.png";
import Image_info from "../../assets/images/info.png";
import {reserveFormConfig} from "./componentsConfig/reserveFormConfig";
import {faultReportFormConfig} from "./componentsConfig/faultReportFormConfig";
import {faultReportDoneFormConfig} from "./componentsConfig/faultReportDoneFormConfig";
import {remindGridColumn} from "./componentsConfig/remindGridColumn";
import {windowFootAccept, windowFootClose, windowFootSend} from "../order/orderBasicSetting/componentsConfig/windowConfig";
import {orderFormConfig} from "../order/orderBasicSetting/componentsConfig/orderFormConfig";
import {orderInfo_purchaserGridColumn} from "../order/orderBasicSetting/componentsConfig/orderInfo_purchaserGridColumn";
import {orderInfo_purchaserGridFormConfig} from "../order/orderBasicSetting/componentsConfig/orderInfo_purchaserGridFormConfig";
import {orderInfo_guestGridColumn} from "../order/orderBasicSetting/componentsConfig/orderInfo_guestGridColumn";
import {orderInfo_guestGridToolbarConfig} from "../order/orderBasicSetting/componentsConfig/orderInfo_guestGridToolbarConfig";
import {
    orderInfo_guestGridDeleteFormConfig,
    orderInfo_guestGridFormConfig
} from "../order/orderBasicSetting/componentsConfig/orderInfo_guestGridFormConfig";
import {productInfo_roomGridColumn} from "../order/orderBasicSetting/componentsConfig/productInfo_roomGridColumn";
import {productInfo_roomGridToolbarConfig} from "../order/orderBasicSetting/componentsConfig/productInfo_roomGridToolbarConfig";
import {productInfo_roomGridFormConfig} from "../order/orderBasicSetting/componentsConfig/productInfo_roomGridFormConfig";
import {productInfo_roomGridDeleteFormConfig} from "../order/orderBasicSetting/componentsConfig/productInfo_roomGridDeleteFormConfig";
import {productInfo_otherPrdGridColumn} from "../order/orderBasicSetting/componentsConfig/productInfo_otherPrdGridColumn";
import {productInfo_otherPrdGridToolbarConfig} from "../order/orderBasicSetting/componentsConfig/productInfo_otherPrdGridToolbarConfig";
import {productInfo_otherPrdGridFormConfig} from "../order/orderBasicSetting/componentsConfig/productInfo_otherPrdGridFormConfig";
import {productInfo_otherPrdGridDeleteFormConfig} from "../order/orderBasicSetting/componentsConfig/productInfo_otherPrdGridDeleteFormConfig";
import {productInfo_payLogGridColumn} from "../order/orderBasicSetting/componentsConfig/productInfo_payLogGridColumn";
import {productInfo_payLogGridToolbarConfig} from "../order/orderBasicSetting/componentsConfig/productInfo_payLogGridToolbarConfig";
import {productInfo_payLogGridFormConfig} from "../order/orderBasicSetting/componentsConfig/productInfo_payLogGridFormConfig";
import {productInfo_payLogGridDeleteFormConfig} from "../order/orderBasicSetting/componentsConfig/productInfo_payLogGridDeleteFormConfig";
import {productInfo_restRoomGridFormConfig} from "../order/orderBasicSetting/componentsConfig/productInfo_restRoomGridFormConfig";
import {alertSetGridColumn} from "../order/orderBasicSetting/componentsConfig/alertSetGridColumn";
import {alertSetGridToolbarConfig} from "../order/orderBasicSetting/componentsConfig/alertSetGridToolbarConfig";
import {alertSetGridFormConfig} from "../order/orderBasicSetting/componentsConfig/alertSetGridFormConfig";
import {alertSetGrid_deleteFormConfig} from "../order/orderBasicSetting/componentsConfig/alertSetGrid_deleteFormConfig";
import {newOrder_purchaserGridFormConfig} from "../order/orderBasicSetting/componentsConfig/newOrder_purchaserGridFormConfig";
import {newOrder_roomGridToolbarConfig} from "../order/orderBasicSetting/componentsConfig/newOrder_roomGridToolbarConfig";
import {newOrder_roomGridColumn} from "../order/orderBasicSetting/componentsConfig/newOrder_roomGridColumn";
import {newOrder_roomGridFormConfig} from "../order/orderBasicSetting/componentsConfig/newOrder_roomGridFormConfig";
import {newOrder_payLogGridColumn} from "../order/orderBasicSetting/componentsConfig/newOrder_payLogGridColumn";
import {newOrder_payLogGridToolbarConfig} from "../order/orderBasicSetting/componentsConfig/newOrder_payLogGridToolbarConfig";
import {newOrder_payLogGridFormConfig} from "../order/orderBasicSetting/componentsConfig/newOrder_payLogGridFormConfig";
import {newOrder_restRoomGridFormConfig} from "../order/orderBasicSetting/componentsConfig/newOrder_restRoomGridFormConfig";
import {productInfo_sendNewPayLinkFormConfig} from "../order/orderBasicSetting/componentsConfig/productInfo_sendNewPayLinkFormConfig";
import {deleteFormConfig} from "../order/orderBasicSetting/componentsConfig/deleteFormConfig";
import Progress from '../../assets/images/icons8-iphone-spinner.gif';
import {viewGridForm_otherRoomGridColumn} from "./componentsConfig/viewGridForm_otherRoomGridColumn";
import {lostPropertyFormConfig} from "./componentsConfig/lostPropertyFormConfig";
import {changeRoom_roomFormConfig} from "./componentsConfig/changeRoom_roomFormConfig";
import {changeRoom_restRoomFormConfig} from "./componentsConfig/changeRoom_restRoomFormConfig";
import {handOverGridColumn} from "./componentsConfig/handOverGridColumn";
import {handOverFormConfig} from "./componentsConfig/handOverFormConfig";
import {handOverDeleteFormConfig} from "./componentsConfig/handOverDeleteFormConfig";
import {checkIn_canCheckInRoomGridColumn} from "./componentsConfig/checkIn_canCheckInRoomGridColumn";
import {checkOut_canCheckOutRoomGridColumn} from "./componentsConfig/checkOut_canCheckOutRoomGridColumn";

let getDate = (date)=> {
    let startDateValue = new Date(date);
    let year1 = startDateValue.getFullYear();
    let month1 = startDateValue.getMonth()+1;
    let day1 = startDateValue.getDate();

    return `${year1}-${month1.toString().padStart(2,'0')}-${day1.toString().padStart(2,'0')}`;
}

let dateTime = new Date();
dateTime = dateTime.setDate(dateTime.getDate()+1);
dateTime = new Date(dateTime);

let DateDiff = function (date1, date2) {
    let strDate, oDate1, oDate2, result;
    strDate = date1.split("-");
    oDate1 = new Date(strDate[1] + '-' + strDate[2] + '-' + strDate[0]);
    strDate = date2.split("-");
    oDate2 = new Date(strDate[1] + '-' + strDate[2] + '-' + strDate[0]);
    result = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24);
    return result;
};

const Dashboard = props =>{
    const apiServer = api_url;
    const headers = {headers:{authorization: "Bearer "+JSON.parse(localStorage.getItem("digital_token"))}};
    const navigate = useNavigate();

    const [canAccess ,setCanAccess] = useState('');
    const [canEdit ,setCanEdit] = useState('');
    const [canDelete ,setCanDelete] = useState('');
    const [canPrint ,setCanPrint] = useState('');

    //飯店切換
    const [hotel, setHotel] = useState("0");

    const changeHotelHandler = (hotel) => {
        setHotel(hotel);
    };
    const { pathname } = useLocation();

    const [isLoading,setIsloading] = useState(true);

    const DHXDashboardTab = useRef(null);

    let status1 = "";
    let status2 = "";
    let status3 = "";
    let status4 = "";
    let status5 = "";
    let status6 = "";
    let status7 = "";
    // let status8 = "";
    let status9 = "";
    let status10 = "";

    let roomTypeName = "";
    let roomName = "";
    let selectDate = "";
    let selectRoomId = "";
    let orderId = "0";
    let orderSourceType = 0;
    let roomStatus = "";
    let roomLogId = 0;
    let bookingType = 0;
    let isCheckOutEarly = 0;
    let dayViewCurrentDate = "";
    let weekViewCurrentDate = "";
    let monthViewCurrentMonth = "";

    let monthViewSelectId = "0";
    let weekViewSelectId = "0";
    let dayViewSelectId = "0";

    let dayViewGridSpanTmp = "";

    let activeTabId = "monthView";

    const stringToHTML = function (str){
        let dom = document.createElement('div');
        dom.innerHTML = str;
        return dom;
    }

    let TMPId = "0";

    let AA103 = 0, AA104 = 0;
    let restTypeText = '',price = '';
    let isNeedFirstChangeEventRest = false;
    let isNeedFirstChangeEvent = false;
    let isSaleSourceNeedChange = false;

    let newOrder_restRoomGridForm_AG004 = "";
    let newOrder_restRoomGridForm_AG005 = "";
    let productInfo_restRoomGridForm_AG004 = "";
    let productInfo_restRoomGridForm_AG005 = "";
    let newOrder_roomGridForm_AG004 = "";
    let newOrder_roomGridForm_AG005 = "";
    let productInfo_roomGridForm_AG004 = "";
    let productInfo_roomGridForm_AG005 = "";
    let changeRoom_roomForm_AG004 = "";
    let changeRoom_roomForm_AG005 = "";
    let changeRoom_restRoomForm_AG004 = "";
    let changeRoom_restRoomForm_AG005 = "";
    let canCheckInRoomArray = [];
    let canCheckOutRoomArray = [];

    useEffect(()=>{

        let objectDate = new Date();

        let month = objectDate.getMonth();

        let year = objectDate.getFullYear();

        let day = objectDate.getDate();

        dayViewCurrentDate = getDate(Date.now());
        weekViewCurrentDate = getDate(Date.now());
        monthViewCurrentMonth = getDate(Date.now());

        axios.post(apiServer+"/checkLoginState",{itemId1:JSON.parse(localStorage.getItem("digital_token"))},headers)
            .then((response)=>{
                if(response.data == 0){
                    navigate('/login');
                }else{
                    axios.post(apiServer+"/userProfileSet/loadPermiss",{itemId1:10},headers)
                        .then((response2)=>{
                            if(response2.status == 200){
                                let p = response2.data.split(',');

                                (p[0] == 1)?setCanAccess(true):setCanAccess(false);
                                (p[1] == 1)?setCanEdit(true):setCanEdit(false);
                                (p[2] == 1)?setCanDelete(true):setCanDelete(false);
                                (p[3] == 1)?setCanPrint(true):setCanPrint(false);

                                setIsloading(false);
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                }
            })
            .catch((error) => {
                DHXMessage({
                    text: "連線異常，請重新整理",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            });

        const dhxProgressWindow1 = new DHXWindow({
            modal: true,
            resizable: true,
            closable:false,
            height: 150,
            width:150,
            html:"<div style='text-align: center;'><img src="+Progress+" /><br/><span>資料載入中</span></div>",
            css: "dhxProgressWindow1"
        });

        const dhxLostPropertyFormWindow1 = new DHXWindow({
            title: "遺失物通報視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 340,
            footer: true,
            css: "dhxLostPropertyFormWindow1",
        });

        dhxLostPropertyFormWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootDontReportLostProperty)));
        dhxLostPropertyFormWindow1.footer.data.add({type:"spacer"});
        dhxLostPropertyFormWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootReportLostProperty)));

        dhxLostPropertyFormWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_REPORT_LOST_PROPERTY":
                    if(lostPropertyForm.getItem("AK103").getValue() === ""){
                        DHXMessage({
                            text: "請填寫遺失物內容",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    dhxProgressWindow1.show();

                    axios.post(apiServer+"/dashboard/reportLostProperty",
                        lostPropertyForm.getValue(),headers
                    ).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                DHXMessage({
                                    text: "更新成功",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });
                                dhxLostPropertyFormWindow1.hide();
                                dhxProgressWindow1.hide();
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    })
                    .catch((error) => {
                        dhxProgressWindow1.hide();
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    });

                    break;
                case "WINDOW_FOOT_DONT_REPORT_LOST_PROPERTY":
                    dhxLostPropertyFormWindow1.hide();
                    lostPropertyForm.clear();
                    lostPropertyForm.setValue({"AK001": "0"});
                    lostPropertyForm.clear("validation");
                    break;
                default:
                    break;
            }
        });

        let lostPropertyFormLayout = new DHXLayout(null,{
            rows:[
                {id: "lostPropertyForm"},
            ]
        });

        const lostPropertyForm = new DHXForm(null,{
            rows: lostPropertyFormConfig
        });

        dhxLostPropertyFormWindow1.attach(lostPropertyFormLayout);
        lostPropertyFormLayout.getCell("lostPropertyForm").attach(lostPropertyForm);

        const dashboardTab = new DHXTabbar(DHXDashboardTab.current,{
            views: [
                {tab: "日", id: "dayView"},
                {tab: "週", id: "weekView"},
                {tab: "月", id: "monthView"},
                {tab: "今日提醒", id: "remindGridLayout"},
                {tab: "交班", id: "handOverLayout"},
            ],
        });

        dashboardTab.setActive("monthView");

        dashboardTab.events.on("change", function(id, prev){
            switch (id){
                case "dayView":
                    activeTabId = "dayView";
                    dhxProgressWindow1.show();
                    reloadGrid();
                    break;

                case "weekView":
                    activeTabId = "weekView";
                    dhxProgressWindow1.show();
                    reloadGrid();
                    break;

                case "monthView":
                    activeTabId = "monthView";
                    dhxProgressWindow1.show();
                    reloadGrid();
                    break;
            }
        });

        //--------------------------------日檢視表--------------------------------
        const dashboardLayout = new DHXLayout(null,{
            rows:[
                {id:"dayViewGridToolbar", height: "content"},
                {id: "dayViewGrid", height:"600px"},
            ]
        });

        const toolbar1 = new DHXToolbar(null, {});
        toolbar1.events.on("click", function(id,e){
            switch (id) {
                case "reload":
                    dhxProgressWindow1.show();
                    reloadGrid();
                    break;

                case "search":
                    if(canEdit){

                        let toolbar1_keyword = toolbar1.getState("toolbar1_keyword").toString().trim();
                        if(toolbar1_keyword != ""){
                            dhxProgressWindow1.show();
                            axios.post(apiServer+"/dashboard/loadKeywordSearchOrderList", {
                                itemId1: hotel,itemId2: toolbar1.getState("toolbar1_keyword")
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        dhxKeywordSearchWindow1.header.data.update("title", {value: "關鍵字："+toolbar1_keyword+" 搜尋結果"});
                                        dhxKeywordSearchDataview.data.removeAll();
                                        dhxKeywordSearchDataview.data.parse(response.data);
                                        dhxKeywordSearchWindow1.attach(dhxKeywordSearchDataview);
                                        dhxProgressWindow1.hide();
                                        dhxKeywordSearchWindow1.show();

                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }
                    }else{
                        dhxProgressWindow1.hide();
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }
                    break;

                default:
                    break;
            }
        });

        toolbar1.events.on("keydown", function(event, id) {
            console.log("You've pressed the key: " + event.key);
        });
        toolbar1.data.parse(JSON.parse(JSON.stringify(toolbar1Config)));

        const dhxDayViewGrid = new DHXGrid(null,{
            leftSplit:2,
            autoWidth: true,
            resizable: true,
            rowHeight: 40,
            sortable:false,
            eventHandlers: {
                onclick: {
                }
            },
            css:"dhxDayViewGrid"
        });

        dhxDayViewGrid.events.on("cellClick", function(row,column,e){

            if(column.id == "roomName" || column.id == "roomType"){

            }else{

                if(dhxDayViewGrid.getSpan(row.id,column.id) == undefined){
                    selectDate = "";
                    roomName = "";
                    roomTypeName = "";
                    orderId = "0";
                    selectRoomId = "0";
                    roomStatus = "0";


                    selectDate = dayViewCurrentDate;
                    roomName = row.roomName;
                    roomTypeName = row.roomType;
                    orderId = "0";
                    selectRoomId = row.id;
                    viewGridForm.getItem("addBtn").enable();
                    viewGridForm.getItem("modifyBtn").disable();
                    viewGridForm.getItem("deleteBtn").disable();
                    viewGridForm.getItem("checkInBtn").disable();
                    viewGridForm.getItem("changeRoomBtn").disable();
                    viewGridForm.getItem("checkOutBtn").disable();
                    viewGridForm.getItem("noShowBtn").disable();
                    viewGridForm.getItem("finishBtn").disable();
                    viewGridForm.getItem("faultReportBtn").enable();
                    viewGridForm.getItem("reportDoneBtn").disable();
                    // viewGridForm.getItem("reserveBtn").disable();
                    // viewGridForm.getItem("removeReserveBtn").disable();

                    dhxViewGridWindow1.header.data.update("title", {value: ""});
                    dhxViewGridWindow1.header.data.update("title", {value: `${dayViewCurrentDate}-${row.roomName}`});
                    dhxViewGridWindow1.show();
                }else{
                    dayViewSelectId = stringToHTML(dhxDayViewGrid.getSpan(row.id,column.id)["text"]).children[0].innerText;
                    let arr = dayViewSelectId.split('-');
                    if(arr[0] == "0"){
                        DHXMessage({
                            text: "此房型未設定房間或價格",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }else{
                        selectDate = "";
                        roomName = "";
                        roomTypeName = "";
                        orderId = "0";
                        selectRoomId = "0";
                        roomStatus = "0";

                        selectDate = arr[0];
                        roomName = row.roomName;
                        roomTypeName = row.roomType;
                        orderId = arr[3];
                        selectRoomId = arr[1];
                        roomStatus = arr[2];

                        if(Date.parse(arr[0]) > Date.parse(year + "/" + (month + 1)+ "/" + day)){
                            switch (arr[2]){
                                case "0":
                                    //訂單完成
                                    viewGridForm2Btn.getItem("addBtn").enable();
                                    viewGridForm2Btn.getItem("modifyBtn").enable();
                                    viewGridForm2Btn.getItem("deleteBtn").disable();
                                    viewGridForm2Btn.getItem("checkInBtn").disable();
                                    viewGridForm2Btn.getItem("changeRoomBtn").enable();
                                    viewGridForm2Btn.getItem("checkOutBtn").disable();
                                    viewGridForm2Btn.getItem("noShowBtn").disable();
                                    viewGridForm2Btn.getItem("finishBtn").disable();
                                    viewGridForm2Btn.getItem("faultReportBtn").enable();
                                    viewGridForm2Btn.getItem("reportDoneBtn").disable();
                                    // viewGridForm2Btn.getItem("reserveBtn").enable();
                                    // viewGridForm2Btn.getItem("removeReserveBtn").disable();

                                    loadViewGridFormData(orderId,selectDate,selectRoomId);
                                    break;
                                case "1":
                                    //空房
                                    viewGridForm.getItem("addBtn").enable();
                                    viewGridForm.getItem("modifyBtn").disable();
                                    viewGridForm.getItem("deleteBtn").disable();
                                    viewGridForm.getItem("checkInBtn").disable();
                                    viewGridForm.getItem("changeRoomBtn").disable();
                                    viewGridForm.getItem("checkOutBtn").disable();
                                    viewGridForm.getItem("noShowBtn").disable();
                                    viewGridForm.getItem("finishBtn").disable();
                                    viewGridForm.getItem("faultReportBtn").enable();
                                    viewGridForm.getItem("reportDoneBtn").disable();
                                    // viewGridForm.getItem("reserveBtn").disable();
                                    // viewGridForm.getItem("removeReserveBtn").disable();

                                    dhxViewGridWindow1.header.data.update("title", {value: ""});
                                    dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                    dhxViewGridWindow1.show();
                                    break;
                                case "2":
                                    //已售出
                                    viewGridForm2Btn.getItem("addBtn").disable();
                                    viewGridForm2Btn.getItem("modifyBtn").enable();
                                    viewGridForm2Btn.getItem("deleteBtn").enable();
                                    viewGridForm2Btn.getItem("checkInBtn").disable();
                                    viewGridForm2Btn.getItem("changeRoomBtn").enable();
                                    viewGridForm2Btn.getItem("checkOutBtn").disable();
                                    viewGridForm2Btn.getItem("noShowBtn").disable();
                                    viewGridForm2Btn.getItem("finishBtn").disable();
                                    viewGridForm2Btn.getItem("faultReportBtn").disable();
                                    viewGridForm2Btn.getItem("reportDoneBtn").disable();
                                    // viewGridForm.getItem("reserveBtn").disable();
                                    // viewGridForm.getItem("removeReserveBtn").disable();
                                    loadViewGridFormData(orderId,selectDate,selectRoomId);
                                    break;
                                case "3":
                                    //故障
                                    viewGridForm.getItem("addBtn").disable();
                                    viewGridForm.getItem("modifyBtn").disable();
                                    viewGridForm.getItem("deleteBtn").disable();
                                    viewGridForm.getItem("checkInBtn").disable();
                                    viewGridForm.getItem("changeRoomBtn").disable();
                                    viewGridForm.getItem("checkOutBtn").disable();
                                    viewGridForm.getItem("noShowBtn").disable();
                                    // viewGridForm.getItem("cleanBtn").disable();
                                    viewGridForm.getItem("finishBtn").disable();
                                    viewGridForm.getItem("faultReportBtn").disable();
                                    viewGridForm.getItem("reportDoneBtn").enable();
                                    // viewGridForm.getItem("reserveBtn").disable();
                                    // viewGridForm.getItem("removeReserveBtn").disable();

                                    dhxViewGridWindow1.header.data.update("title", {value: ""});
                                    dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                    dhxViewGridWindow1.show();
                                    break;
                                case "4":
                                    //保留
                                    viewGridForm.getItem("addBtn").disable();
                                    viewGridForm.getItem("modifyBtn").disable();
                                    viewGridForm.getItem("deleteBtn").disable();
                                    viewGridForm.getItem("checkInBtn").disable();
                                    viewGridForm.getItem("changeRoomBtn").disable();
                                    viewGridForm.getItem("checkOutBtn").disable();
                                    viewGridForm.getItem("noShowBtn").disable();
                                    // viewGridForm.getItem("cleanBtn").disable();
                                    viewGridForm.getItem("finishBtn").disable();
                                    viewGridForm.getItem("faultReportBtn").disable();
                                    viewGridForm.getItem("reportDoneBtn").disable();
                                    // viewGridForm.getItem("reserveBtn").disable();
                                    // viewGridForm.getItem("removeReserveBtn").enable();

                                    dhxViewGridWindow1.header.data.update("title", {value: ""});
                                    dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                    dhxViewGridWindow1.show();
                                    break;
                                case "5":
                                    //已入住
                                    viewGridForm2Btn.getItem("addBtn").disable();
                                    viewGridForm2Btn.getItem("modifyBtn").enable();
                                    viewGridForm2Btn.getItem("deleteBtn").enable();
                                    viewGridForm2Btn.getItem("checkInBtn").disable();
                                    viewGridForm2Btn.getItem("changeRoomBtn").enable();
                                    viewGridForm2Btn.getItem("checkOutBtn").disable();
                                    viewGridForm2Btn.getItem("noShowBtn").disable();
                                    viewGridForm2Btn.getItem("finishBtn").disable();
                                    viewGridForm2Btn.getItem("faultReportBtn").disable();
                                    viewGridForm2Btn.getItem("reportDoneBtn").disable();
                                    // viewGridForm2Btn.getItem("reserveBtn").disable();
                                    // viewGridForm2Btn.getItem("removeReserveBtn").disable();
                                    loadViewGridFormData(orderId,selectDate,selectRoomId);
                                    break;
                            };
                        }else{

                            switch (arr[2]){
                                case "0":
                                    //訂單完成
                                    viewGridForm2Btn.getItem("addBtn").enable();
                                    viewGridForm2Btn.getItem("modifyBtn").enable();
                                    viewGridForm2Btn.getItem("deleteBtn").disable();
                                    viewGridForm2Btn.getItem("checkInBtn").disable();
                                    viewGridForm2Btn.getItem("changeRoomBtn").enable();
                                    viewGridForm2Btn.getItem("checkOutBtn").disable();
                                    viewGridForm2Btn.getItem("noShowBtn").disable();
                                    viewGridForm2Btn.getItem("finishBtn").disable();
                                    viewGridForm2Btn.getItem("faultReportBtn").enable();
                                    viewGridForm2Btn.getItem("reportDoneBtn").disable();
                                    // viewGridForm.getItem("reserveBtn").enable();
                                    // viewGridForm.getItem("removeReserveBtn").disable();

                                    loadViewGridFormData(orderId,selectDate,selectRoomId);
                                    break;
                                case "1":
                                    //空房
                                    viewGridForm.getItem("addBtn").enable();
                                    viewGridForm.getItem("modifyBtn").disable();
                                    viewGridForm.getItem("deleteBtn").disable();
                                    viewGridForm.getItem("checkInBtn").disable();
                                    viewGridForm.getItem("changeRoomBtn").disable();
                                    viewGridForm.getItem("checkOutBtn").disable();
                                    viewGridForm.getItem("noShowBtn").disable();
                                    // viewGridForm.getItem("cleanBtn").disable();
                                    viewGridForm.getItem("finishBtn").disable();
                                    viewGridForm.getItem("faultReportBtn").enable();
                                    viewGridForm.getItem("reportDoneBtn").disable();
                                    // viewGridForm.getItem("reserveBtn").enable();
                                    // viewGridForm.getItem("removeReserveBtn").disable();

                                    dhxViewGridWindow1.header.data.update("title", {value: ""});
                                    dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                    dhxViewGridWindow1.show();
                                    break;
                                case "2":
                                    //已售出
                                    viewGridForm2Btn.getItem("addBtn").disable();
                                    viewGridForm2Btn.getItem("modifyBtn").enable();
                                    viewGridForm2Btn.getItem("deleteBtn").enable();
                                    viewGridForm2Btn.getItem("checkInBtn").enable();
                                    viewGridForm2Btn.getItem("changeRoomBtn").enable();
                                    viewGridForm2Btn.getItem("checkOutBtn").disable();
                                    viewGridForm2Btn.getItem("noShowBtn").enable();
                                    viewGridForm2Btn.getItem("finishBtn").enable();
                                    viewGridForm2Btn.getItem("faultReportBtn").disable();
                                    viewGridForm2Btn.getItem("reportDoneBtn").disable();
                                    // viewGridForm.getItem("reserveBtn").disable();
                                    // viewGridForm.getItem("removeReserveBtn").disable();
                                    loadViewGridFormData(orderId,selectDate,selectRoomId);
                                    break;
                                case "3":
                                    //故障
                                    viewGridForm.getItem("addBtn").disable();
                                    viewGridForm.getItem("modifyBtn").disable();
                                    viewGridForm.getItem("deleteBtn").disable();
                                    viewGridForm.getItem("checkInBtn").disable();
                                    viewGridForm.getItem("changeRoomBtn").disable();
                                    viewGridForm.getItem("checkOutBtn").disable();
                                    viewGridForm.getItem("noShowBtn").disable();
                                    viewGridForm.getItem("finishBtn").disable();
                                    viewGridForm.getItem("faultReportBtn").disable();
                                    viewGridForm.getItem("reportDoneBtn").enable();
                                    // viewGridForm.getItem("reserveBtn").disable();
                                    // viewGridForm.getItem("removeReserveBtn").disable();

                                    dhxViewGridWindow1.header.data.update("title", {value: ""});
                                    dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                    dhxViewGridWindow1.show();
                                    break;
                                case "4":
                                    //保留
                                    viewGridForm.getItem("addBtn").disable();
                                    viewGridForm.getItem("modifyBtn").disable();
                                    viewGridForm.getItem("deleteBtn").disable();
                                    viewGridForm.getItem("checkInBtn").disable();
                                    viewGridForm.getItem("changeRoomBtn").disable();
                                    viewGridForm.getItem("checkOutBtn").disable();
                                    viewGridForm.getItem("noShowBtn").disable();
                                    viewGridForm.getItem("finishBtn").disable();
                                    viewGridForm.getItem("faultReportBtn").enable();
                                    viewGridForm.getItem("reportDoneBtn").disable();
                                    // viewGridForm.getItem("reserveBtn").disable();
                                    // viewGridForm.getItem("removeReserveBtn").disable();

                                    dhxViewGridWindow1.header.data.update("title", {value: ""});
                                    dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                    dhxViewGridWindow1.show();
                                    break;
                                case "5":
                                    //已入住
                                    viewGridForm2Btn.getItem("addBtn").disable();
                                    viewGridForm2Btn.getItem("modifyBtn").enable();
                                    viewGridForm2Btn.getItem("deleteBtn").enable();
                                    viewGridForm2Btn.getItem("checkInBtn").disable();
                                    viewGridForm2Btn.getItem("changeRoomBtn").enable();
                                    viewGridForm2Btn.getItem("checkOutBtn").enable();
                                    viewGridForm2Btn.getItem("noShowBtn").disable();
                                    viewGridForm2Btn.getItem("finishBtn").disable();
                                    viewGridForm2Btn.getItem("faultReportBtn").disable();
                                    viewGridForm2Btn.getItem("reportDoneBtn").disable();
                                    // viewGridForm2Btn.getItem("reserveBtn").disable();
                                    // viewGridForm2Btn.getItem("removeReserveBtn").disable();
                                    loadViewGridFormData(orderId,selectDate,selectRoomId);
                                    break;
                                case "6":
                                    //未售出待打掃
                                    viewGridForm.getItem("addBtn").disable();
                                    viewGridForm.getItem("modifyBtn").disable();
                                    viewGridForm.getItem("deleteBtn").disable();
                                    viewGridForm.getItem("checkInBtn").disable();
                                    viewGridForm.getItem("changeRoomBtn").disable();
                                    viewGridForm.getItem("checkOutBtn").disable();
                                    viewGridForm.getItem("noShowBtn").disable();
                                    viewGridForm.getItem("finishBtn").enable();
                                    viewGridForm.getItem("faultReportBtn").enable();
                                    viewGridForm.getItem("reportDoneBtn").disable();
                                    // viewGridForm.getItem("reserveBtn").disable();
                                    // viewGridForm.getItem("removeReserveBtn").disable();

                                    dhxViewGridWindow1.header.data.update("title", {value: ""});
                                    dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                    dhxViewGridWindow1.show();
                                    break;
                                case "8":
                                    //已售出待打掃
                                    viewGridForm.getItem("addBtn").disable();
                                    viewGridForm.getItem("modifyBtn").disable();
                                    viewGridForm.getItem("deleteBtn").disable();
                                    viewGridForm.getItem("checkInBtn").disable();
                                    viewGridForm.getItem("changeRoomBtn").disable();
                                    viewGridForm.getItem("checkOutBtn").disable();
                                    viewGridForm.getItem("noShowBtn").disable();
                                    viewGridForm.getItem("finishBtn").enable();
                                    viewGridForm.getItem("faultReportBtn").enable();
                                    viewGridForm.getItem("reportDoneBtn").disable();
                                    // viewGridForm.getItem("reserveBtn").disable();
                                    // viewGridForm.getItem("removeReserveBtn").disable();

                                    dhxViewGridWindow1.header.data.update("title", {value: ""});
                                    dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                    dhxViewGridWindow1.show();
                                    break;
                                case "12":
                                    //未報到
                                    viewGridForm2Btn.getItem("addBtn").enable();
                                    viewGridForm2Btn.getItem("modifyBtn").enable();
                                    viewGridForm2Btn.getItem("deleteBtn").enable();
                                    viewGridForm2Btn.getItem("checkInBtn").disable();
                                    viewGridForm2Btn.getItem("changeRoomBtn").enable();
                                    viewGridForm2Btn.getItem("checkOutBtn").disable();
                                    viewGridForm2Btn.getItem("noShowBtn").disable();
                                    viewGridForm2Btn.getItem("finishBtn").disable();
                                    viewGridForm2Btn.getItem("faultReportBtn").enable();
                                    viewGridForm2Btn.getItem("reportDoneBtn").disable();
                                    // viewGridForm2Btn.getItem("reserveBtn").enable();
                                    // viewGridForm2Btn.getItem("removeReserveBtn").disable();

                                    loadViewGridFormData(orderId,selectDate,selectRoomId);
                                    break;
                                default:
                                    viewGridForm.getItem("addBtn").disable();
                                    viewGridForm.getItem("modifyBtn").disable();
                                    viewGridForm.getItem("deleteBtn").disable();
                                    viewGridForm.getItem("checkInBtn").disable();
                                    viewGridForm.getItem("changeRoomBtn").disable();
                                    viewGridForm.getItem("checkOutBtn").disable();
                                    viewGridForm.getItem("noShowBtn").disable();
                                    viewGridForm.getItem("finishBtn").disable();
                                    viewGridForm.getItem("faultReportBtn").disable();
                                    viewGridForm.getItem("reportDoneBtn").disable();
                                    // viewGridForm.getItem("reserveBtn").disable();
                                    // viewGridForm.getItem("removeReserveBtn").disable();

                                    dhxViewGridWindow1.header.data.update("title", {value: ""});
                                    dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                    dhxViewGridWindow1.show();
                                    break;
                            };
                        }
                    }
                }


            }
        });

        toolbar1.events.on("inputChange", function(id, newValue){
            if(id == "toolbar1_startTime"){
                dhxProgressWindow1.show();
                reloadGrid();
            }
        });
        dashboardLayout.getCell("dayViewGridToolbar").attach(toolbar1);
        dashboardLayout.getCell("dayViewGrid").attach(dhxDayViewGrid);
        dashboardTab.getCell("dayView").attach(dashboardLayout);


        //--------------------------------週檢視表--------------------------------
        let weekViewLayout = new DHXLayout(null,{
            rows:[
                {id:"weekViewGridToolbar", height: "content"},
                {id: "weekViewGrid", height:"600px"},
            ]
        });

        let dhxWeekViewGrid = new DHXGrid(null,{
            leftSplit:2,
            autoWidth: true,
            resizable: true,
            rowHeight: 40,
            sortable:false,
            eventHandlers: {
                onclick: {
                }
            },
            css:"dhxWeekViewGrid"
        });

        dhxWeekViewGrid.events.on("cellClick", function(row,column,e){

            if(column.id == "roomName" || column.id == "roomType"){

            }else{
                weekViewSelectId = stringToHTML(row[column.id]).children[0].innerText;
                let arr = weekViewSelectId.split('-');
                if(arr[0] == "0"){
                    DHXMessage({
                        text: "此房型未設定房間或價格",
                        icon:"dxi dxi-close",
                        css: "dhx_message--error",
                        expire:5000
                    });
                }else{
                    selectDate = "";
                    roomName = "";
                    roomTypeName = "";
                    orderId = "0";
                    selectRoomId = "0";
                    roomStatus = "0";

                    selectDate = arr[0];
                    roomName = row.roomName;
                    roomTypeName = row.roomType;
                    orderId = arr[3];
                    selectRoomId = arr[1];
                    roomStatus = arr[2];

                    if(Date.parse(arr[0]) > Date.parse(year + "/" + (month + 1)+ "/" + day)){
                        switch (arr[2]){
                            case "0":
                                //訂單完成
                                viewGridForm2Btn.getItem("addBtn").enable();
                                viewGridForm2Btn.getItem("modifyBtn").enable();
                                viewGridForm2Btn.getItem("deleteBtn").disable();
                                viewGridForm2Btn.getItem("checkInBtn").disable();
                                viewGridForm2Btn.getItem("changeRoomBtn").enable();
                                viewGridForm2Btn.getItem("checkOutBtn").disable();
                                viewGridForm2Btn.getItem("noShowBtn").disable();
                                viewGridForm2Btn.getItem("finishBtn").disable();
                                viewGridForm2Btn.getItem("faultReportBtn").enable();
                                viewGridForm2Btn.getItem("reportDoneBtn").disable();
                                // viewGridForm2Btn.getItem("reserveBtn").enable();
                                // viewGridForm2Btn.getItem("removeReserveBtn").disable();

                                loadViewGridFormData(orderId,selectDate,selectRoomId);
                                break;
                            case "1":
                                //空房
                                viewGridForm.getItem("addBtn").enable();
                                viewGridForm.getItem("modifyBtn").disable();
                                viewGridForm.getItem("deleteBtn").disable();
                                viewGridForm.getItem("checkInBtn").disable();
                                viewGridForm.getItem("changeRoomBtn").disable();
                                viewGridForm.getItem("checkOutBtn").disable();
                                viewGridForm.getItem("noShowBtn").disable();
                                // viewGridForm.getItem("cleanBtn").disable();
                                viewGridForm.getItem("finishBtn").disable();
                                viewGridForm.getItem("faultReportBtn").enable();
                                viewGridForm.getItem("reportDoneBtn").disable();
                                // viewGridForm.getItem("reserveBtn").enable();
                                // viewGridForm.getItem("removeReserveBtn").disable();

                                dhxViewGridWindow1.header.data.update("title", {value: ""});
                                dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                dhxViewGridWindow1.show();
                                break;
                            case "2":
                                //已售出
                                viewGridForm2Btn.getItem("addBtn").disable();
                                viewGridForm2Btn.getItem("modifyBtn").enable();
                                viewGridForm2Btn.getItem("deleteBtn").enable();
                                viewGridForm2Btn.getItem("checkInBtn").disable();
                                viewGridForm2Btn.getItem("changeRoomBtn").enable();
                                viewGridForm2Btn.getItem("checkOutBtn").disable();
                                viewGridForm2Btn.getItem("noShowBtn").disable();
                                viewGridForm2Btn.getItem("finishBtn").disable();
                                viewGridForm2Btn.getItem("faultReportBtn").disable();
                                viewGridForm2Btn.getItem("reportDoneBtn").disable();
                                // viewGridForm.getItem("reserveBtn").disable();
                                // viewGridForm.getItem("removeReserveBtn").disable();
                                loadViewGridFormData(orderId,selectDate,selectRoomId);
                                break;
                            case "3":
                                //故障
                                viewGridForm.getItem("addBtn").disable();
                                viewGridForm.getItem("modifyBtn").disable();
                                viewGridForm.getItem("deleteBtn").disable();
                                viewGridForm.getItem("checkInBtn").disable();
                                viewGridForm.getItem("changeRoomBtn").disable();
                                viewGridForm.getItem("checkOutBtn").disable();
                                viewGridForm.getItem("noShowBtn").disable();
                                viewGridForm.getItem("finishBtn").disable();
                                viewGridForm.getItem("faultReportBtn").disable();
                                viewGridForm.getItem("reportDoneBtn").enable();
                                // viewGridForm.getItem("reserveBtn").disable();
                                // viewGridForm.getItem("removeReserveBtn").disable();

                                dhxViewGridWindow1.header.data.update("title", {value: ""});
                                dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                dhxViewGridWindow1.show();
                                break;
                            case "4":
                                //保留
                                viewGridForm.getItem("addBtn").disable();
                                viewGridForm.getItem("modifyBtn").disable();
                                viewGridForm.getItem("deleteBtn").disable();
                                viewGridForm.getItem("checkInBtn").disable();
                                viewGridForm.getItem("changeRoomBtn").disable();
                                viewGridForm.getItem("checkOutBtn").disable();
                                viewGridForm.getItem("noShowBtn").disable();
                                // viewGridForm.getItem("cleanBtn").disable();
                                viewGridForm.getItem("finishBtn").disable();
                                viewGridForm.getItem("faultReportBtn").disable();
                                viewGridForm.getItem("reportDoneBtn").disable();
                                // viewGridForm.getItem("reserveBtn").disable();
                                // viewGridForm.getItem("removeReserveBtn").enable();

                                dhxViewGridWindow1.header.data.update("title", {value: ""});
                                dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                dhxViewGridWindow1.show();
                                break;
                            case "5":
                                //已入住
                                viewGridForm2Btn.getItem("addBtn").disable();
                                viewGridForm2Btn.getItem("modifyBtn").enable();
                                viewGridForm2Btn.getItem("deleteBtn").enable();
                                viewGridForm2Btn.getItem("checkInBtn").disable();
                                viewGridForm2Btn.getItem("changeRoomBtn").enable();
                                viewGridForm2Btn.getItem("checkOutBtn").disable();
                                viewGridForm2Btn.getItem("noShowBtn").disable();
                                viewGridForm2Btn.getItem("finishBtn").disable();
                                viewGridForm2Btn.getItem("faultReportBtn").disable();
                                viewGridForm2Btn.getItem("reportDoneBtn").disable();
                                // viewGridForm2Btn.getItem("reserveBtn").disable();
                                // viewGridForm2Btn.getItem("removeReserveBtn").disable();
                                loadViewGridFormData(orderId,selectDate,selectRoomId);
                                break;
                        };
                    }else{

                        switch (arr[2]){
                            case "0":
                                //訂單完成
                                viewGridForm2Btn.getItem("addBtn").enable();
                                viewGridForm2Btn.getItem("modifyBtn").enable();
                                viewGridForm2Btn.getItem("deleteBtn").disable();
                                viewGridForm2Btn.getItem("checkInBtn").disable();
                                viewGridForm2Btn.getItem("changeRoomBtn").enable();
                                viewGridForm2Btn.getItem("checkOutBtn").disable();
                                viewGridForm2Btn.getItem("noShowBtn").disable();
                                viewGridForm2Btn.getItem("finishBtn").disable();
                                viewGridForm2Btn.getItem("faultReportBtn").enable();
                                viewGridForm2Btn.getItem("reportDoneBtn").disable();
                                // viewGridForm.getItem("reserveBtn").enable();
                                // viewGridForm.getItem("removeReserveBtn").disable();

                                loadViewGridFormData(orderId,selectDate,selectRoomId);
                                break;
                            case "1":
                                //空房
                                viewGridForm.getItem("addBtn").enable();
                                viewGridForm.getItem("modifyBtn").disable();
                                viewGridForm.getItem("deleteBtn").disable();
                                viewGridForm.getItem("checkInBtn").disable();
                                viewGridForm.getItem("changeRoomBtn").disable();
                                viewGridForm.getItem("checkOutBtn").disable();
                                viewGridForm.getItem("noShowBtn").disable();
                                // viewGridForm.getItem("cleanBtn").disable();
                                viewGridForm.getItem("finishBtn").disable();
                                viewGridForm.getItem("faultReportBtn").enable();
                                viewGridForm.getItem("reportDoneBtn").disable();
                                // viewGridForm.getItem("reserveBtn").enable();
                                // viewGridForm.getItem("removeReserveBtn").disable();

                                dhxViewGridWindow1.header.data.update("title", {value: ""});
                                dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                dhxViewGridWindow1.show();
                                break;
                            case "2":
                                //已售出
                                viewGridForm2Btn.getItem("addBtn").disable();
                                viewGridForm2Btn.getItem("modifyBtn").enable();
                                viewGridForm2Btn.getItem("deleteBtn").enable();
                                viewGridForm2Btn.getItem("checkInBtn").enable();
                                viewGridForm2Btn.getItem("changeRoomBtn").enable();
                                viewGridForm2Btn.getItem("checkOutBtn").disable();
                                viewGridForm2Btn.getItem("noShowBtn").enable();
                                viewGridForm2Btn.getItem("finishBtn").enable();
                                viewGridForm2Btn.getItem("faultReportBtn").disable();
                                viewGridForm2Btn.getItem("reportDoneBtn").disable();
                                // viewGridForm.getItem("reserveBtn").disable();
                                // viewGridForm.getItem("removeReserveBtn").disable();
                                loadViewGridFormData(orderId,selectDate,selectRoomId);
                                break;
                            case "3":
                                //故障
                                viewGridForm.getItem("addBtn").disable();
                                viewGridForm.getItem("modifyBtn").disable();
                                viewGridForm.getItem("deleteBtn").disable();
                                viewGridForm.getItem("checkInBtn").disable();
                                viewGridForm.getItem("changeRoomBtn").disable();
                                viewGridForm.getItem("checkOutBtn").disable();
                                viewGridForm.getItem("noShowBtn").disable();
                                viewGridForm.getItem("finishBtn").disable();
                                viewGridForm.getItem("faultReportBtn").disable();
                                viewGridForm.getItem("reportDoneBtn").enable();
                                // viewGridForm.getItem("reserveBtn").disable();
                                // viewGridForm.getItem("removeReserveBtn").disable();

                                dhxViewGridWindow1.header.data.update("title", {value: ""});
                                dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                dhxViewGridWindow1.show();
                                break;
                            case "4":
                                //保留
                                viewGridForm.getItem("addBtn").disable();
                                viewGridForm.getItem("modifyBtn").disable();
                                viewGridForm.getItem("deleteBtn").disable();
                                viewGridForm.getItem("checkInBtn").disable();
                                viewGridForm.getItem("changeRoomBtn").disable();
                                viewGridForm.getItem("checkOutBtn").disable();
                                viewGridForm.getItem("noShowBtn").disable();
                                viewGridForm.getItem("finishBtn").disable();
                                viewGridForm.getItem("faultReportBtn").enable();
                                viewGridForm.getItem("reportDoneBtn").disable();
                                // viewGridForm.getItem("reserveBtn").disable();
                                // viewGridForm.getItem("removeReserveBtn").disable();

                                dhxViewGridWindow1.header.data.update("title", {value: ""});
                                dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                dhxViewGridWindow1.show();
                                break;
                            case "5":
                                //已入住
                                viewGridForm2Btn.getItem("addBtn").disable();
                                viewGridForm2Btn.getItem("modifyBtn").enable();
                                viewGridForm2Btn.getItem("deleteBtn").enable();
                                viewGridForm2Btn.getItem("checkInBtn").disable();
                                viewGridForm2Btn.getItem("changeRoomBtn").enable();
                                viewGridForm2Btn.getItem("checkOutBtn").enable();
                                viewGridForm2Btn.getItem("noShowBtn").disable();
                                viewGridForm2Btn.getItem("finishBtn").disable();
                                viewGridForm2Btn.getItem("faultReportBtn").disable();
                                viewGridForm2Btn.getItem("reportDoneBtn").disable();
                                // viewGridForm2Btn.getItem("reserveBtn").disable();
                                // viewGridForm2Btn.getItem("removeReserveBtn").disable();
                                loadViewGridFormData(orderId,selectDate,selectRoomId);
                                break;
                            case "6":
                                //未售出待打掃
                                viewGridForm.getItem("addBtn").disable();
                                viewGridForm.getItem("modifyBtn").disable();
                                viewGridForm.getItem("deleteBtn").disable();
                                viewGridForm.getItem("checkInBtn").disable();
                                viewGridForm.getItem("changeRoomBtn").disable();
                                viewGridForm.getItem("checkOutBtn").disable();
                                viewGridForm.getItem("noShowBtn").disable();
                                // viewGridForm.getItem("cleanBtn").enable();
                                viewGridForm.getItem("finishBtn").enable();
                                viewGridForm.getItem("faultReportBtn").enable();
                                viewGridForm.getItem("reportDoneBtn").disable();
                                // viewGridForm.getItem("reserveBtn").disable();
                                // viewGridForm.getItem("removeReserveBtn").disable();

                                dhxViewGridWindow1.header.data.update("title", {value: ""});
                                dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                dhxViewGridWindow1.show();
                                break;
                            case "8":
                                //已售出待打掃
                                viewGridForm.getItem("addBtn").disable();
                                viewGridForm.getItem("modifyBtn").enable();
                                viewGridForm.getItem("deleteBtn").disable();
                                viewGridForm.getItem("checkInBtn").disable();
                                viewGridForm.getItem("changeRoomBtn").enable();
                                viewGridForm.getItem("checkOutBtn").disable();
                                viewGridForm.getItem("noShowBtn").disable();
                                // viewGridForm.getItem("cleanBtn").enable();
                                viewGridForm.getItem("finishBtn").enable();
                                viewGridForm.getItem("faultReportBtn").enable();
                                viewGridForm.getItem("reportDoneBtn").disable();
                                // viewGridForm.getItem("reserveBtn").disable();
                                // viewGridForm.getItem("removeReserveBtn").disable();

                                dhxViewGridWindow1.header.data.update("title", {value: ""});
                                dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                dhxViewGridWindow1.show();
                                break;
                            case "12":
                                viewGridForm2Btn.getItem("addBtn").enable();
                                viewGridForm2Btn.getItem("modifyBtn").enable();
                                viewGridForm2Btn.getItem("deleteBtn").enable();
                                viewGridForm2Btn.getItem("checkInBtn").disable();
                                viewGridForm2Btn.getItem("changeRoomBtn").enable();
                                viewGridForm2Btn.getItem("checkOutBtn").disable();
                                viewGridForm2Btn.getItem("noShowBtn").disable();
                                viewGridForm2Btn.getItem("finishBtn").disable();
                                viewGridForm2Btn.getItem("faultReportBtn").enable();
                                viewGridForm2Btn.getItem("reportDoneBtn").disable();
                                // viewGridForm2Btn.getItem("reserveBtn").enable();
                                // viewGridForm2Btn.getItem("removeReserveBtn").disable();

                                loadViewGridFormData(orderId,selectDate,selectRoomId);
                                break;
                            default:
                                viewGridForm.getItem("addBtn").disable();
                                viewGridForm.getItem("modifyBtn").disable();
                                viewGridForm.getItem("deleteBtn").disable();
                                viewGridForm.getItem("checkInBtn").disable();
                                viewGridForm.getItem("changeRoomBtn").disable();
                                viewGridForm.getItem("checkOutBtn").disable();
                                viewGridForm.getItem("noShowBtn").disable();
                                viewGridForm.getItem("finishBtn").disable();
                                viewGridForm.getItem("faultReportBtn").disable();
                                viewGridForm.getItem("reportDoneBtn").disable();
                                // viewGridForm.getItem("reserveBtn").disable();
                                // viewGridForm.getItem("removeReserveBtn").disable();

                                dhxViewGridWindow1.header.data.update("title", {value: ""});
                                dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                dhxViewGridWindow1.show();
                                break;
                        };
                    }
                }
            }
        });

        const toolbar2 = new DHXToolbar(null, {
        });
        toolbar2.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        axios.get(apiServer+"/orders/getTMPId",headers)
                            .then((data) =>{
                                if(data.status === 200){
                                    if(data.data){
                                        TMPId = data.data[0].TMPId;
                                        newOrder_purchaserInfoForm.clear();
                                        newOrder_purchaserInfoForm.getItem("AA138").setValue("1");
                                        newOrder_purchaserInfoForm.getItem("saleSource").setValue("0");
                                        newOrder_purchaserInfoForm.getItem("saleSource").disable();

                                        newOrder_roomGridWindow1.attach(newOrder_roomGridForm);
                                        newOrder_roomGrid.data.removeAll();
                                        newOrder_payLogGrid.data.removeAll();

                                        axios.get(apiServer+"/orders/loadCountiesList",headers)
                                            .then((response) =>{
                                                newOrder_purchaserInfoForm.getItem("AA004").setOptions(response.data);
                                            })
                                            .catch((error) => {
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            });

                                        axios.get(apiServer+"/orders/loadOrderSourceList",headers)
                                            .then((response) =>{
                                                newOrder_purchaserInfoForm.getItem("orderSource").setOptions(response.data);
                                                newOrder_purchaserInfoForm.getItem("orderSource").setValue("0");
                                            })
                                            .catch((error) => {
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            });

                                        dhxNewOrderWindow1.show();
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }
                    break;
                case "reload":
                    dhxProgressWindow1.show();
                    reloadGrid();
                    break;

                case "search":
                    if(canEdit){

                        let toolbar2_keyword = toolbar2.getState("toolbar2_keyword").toString().trim();
                        if(toolbar2_keyword != ""){
                            dhxProgressWindow1.show();
                            axios.post(apiServer+"/dashboard/loadKeywordSearchOrderList", {
                                itemId1: hotel,itemId2: toolbar2.getState("toolbar2_keyword")
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        dhxKeywordSearchWindow1.header.data.update("title", {value: "關鍵字："+toolbar2_keyword+" 搜尋結果"});
                                        dhxKeywordSearchDataview.data.removeAll();
                                        dhxKeywordSearchDataview.data.parse(response.data);
                                        dhxKeywordSearchWindow1.attach(dhxKeywordSearchDataview);
                                        dhxProgressWindow1.hide();
                                        dhxKeywordSearchWindow1.show();

                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }
                    }else{
                        dhxProgressWindow1.hide();
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }
                    break;
                default:
                    break;
            }
        });
        toolbar2.data.parse(JSON.parse(JSON.stringify(toolbar2Config)));

        toolbar2.events.on("inputChange", function(id, newValue){
            if(id == "toolbar2_startTime"){
                dhxProgressWindow1.show();
                reloadGrid();
            }
        });

        dashboardTab.getCell("weekView").attach(weekViewLayout);
        weekViewLayout.getCell("weekViewGridToolbar").attach(toolbar2);
        weekViewLayout.getCell("weekViewGrid").attach(dhxWeekViewGrid);

        //--------------------------------月檢視表--------------------------------
        let monthViewLayout = new DHXLayout(null,{
            rows:[
                {id:"monthViewToolbar", height: "content"},
                {id: "monthViewGrid", height:"600px"},
            ]
        });

        const toolbar3 = new DHXToolbar(null, {});
        toolbar3.events.on("click", function(id,e){
            switch (id) {
                case "reload":
                    dhxProgressWindow1.show();
                    reloadGrid();

                    reloadStatistics();
                    break;
                case "add":
                    if(canEdit){
                        dhxProgressWindow1.show();
                        axios.get(apiServer+"/orders/getTMPId",headers)
                            .then((data) =>{
                                if(data.status === 200){
                                    if(data.data){
                                        TMPId = data.data[0].TMPId;
                                        newOrder_purchaserInfoForm.clear();
                                        newOrder_purchaserInfoForm.getItem("AA138").setValue("1");
                                        newOrder_purchaserInfoForm.getItem("saleSource").disable();
                                        newOrder_purchaserInfoForm.getItem("saleSource").setValue("0");
                                        dhxProgressWindow1.hide();

                                        newOrder_roomGridWindow1.attach(newOrder_roomGridForm);
                                        newOrder_roomGrid.data.removeAll();
                                        newOrder_payLogGrid.data.removeAll();

                                        axios.get(apiServer+"/orders/loadCountiesList",headers)
                                            .then((response) =>{
                                                newOrder_purchaserInfoForm.getItem("AA004").setOptions(response.data);
                                            })
                                            .catch((error) => {
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            });

                                        axios.get(apiServer+"/orders/loadOrderSourceList",headers)
                                            .then((response) =>{
                                                newOrder_purchaserInfoForm.getItem("orderSource").setOptions(response.data);
                                                newOrder_purchaserInfoForm.getItem("orderSource").setValue("0");
                                            })
                                            .catch((error) => {
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            });

                                        dhxNewOrderWindow1.show();
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }
                    break;

                case "search":
                    if(canEdit){

                        let toolbar3_keyword = toolbar3.getState("toolbar3_keyword").toString().trim();
                        if(toolbar3_keyword != ""){
                            dhxProgressWindow1.show();
                            axios.post(apiServer+"/dashboard/loadKeywordSearchOrderList", {
                                itemId1: hotel,itemId2: toolbar3.getState("toolbar3_keyword")
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        dhxKeywordSearchWindow1.header.data.update("title", {value: "關鍵字："+toolbar3_keyword+" 搜尋結果"});
                                        dhxKeywordSearchDataview.data.removeAll();
                                        dhxKeywordSearchDataview.data.parse(response.data);
                                        dhxKeywordSearchWindow1.attach(dhxKeywordSearchDataview);
                                        dhxProgressWindow1.hide();
                                        dhxKeywordSearchWindow1.show();

                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }
                    }else{
                        dhxProgressWindow1.hide();
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }
                    break;
                default:
                    break;
            }
        });


        toolbar3.events.on("inputChange", function(id, newValue){
            if(id == "toolbar3_startTime"){
                dhxProgressWindow1.show();
                reloadGrid();
            }
        });

        const dhxMonthViewGrid = new DHXGrid(null,{
            leftSplit:2,
            autoWidth: true,
            resizable: true,
            rowHeight: 40,
            sortable:false,
            eventHandlers: {
                onclick: {
                }
            },
            css:"dhxMonthViewGrid"
        });

        let loadViewGridFormData = (orderId,selectDate,selectRoomId)=> {
            dhxProgressWindow1.show();
            viewGridForm2_1.clear();
            viewGridForm2Layout.getCell("viewGridForm2_2").attachHTML('');
            viewGridForm_otherRoomGrid.data.removeAll();
            axios.post(apiServer+"/dashboard/loadViewGridFormData", {
                itemId1:orderId,itemId2:selectDate,itemId3:selectRoomId
            },headers).then((response1) =>{
                if(response1.status === 200){
                    if(response1.data){
                        roomLogId = response1.data[0].AG001;
                        viewGridForm2_1.setValue(response1.data[0]);
                        viewGridForm2Layout.getCell("viewGridForm2_2").attachHTML(response1.data[0].roomStatus);
                        viewGridForm_otherRoomGrid.data.parse(response1.data[0].otherRoomGrid);

                        dhxViewGridWindow2.show();
                        dhxProgressWindow1.hide();
                    }else{
                        dhxProgressWindow1.hide();
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                }else{
                    dhxProgressWindow1.hide();
                    DHXMessage({
                        text: "連線異常，請重新整理",
                        icon:"dxi dxi-close",
                        css: "dhx_message--error",
                        expire:5000
                    });
                }

            })
            .catch((error) => {
                dhxProgressWindow1.hide();
                DHXMessage({
                    text: "連線異常，請重新整理",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            });
        }

        dhxMonthViewGrid.events.on("cellClick", function(row,column,e){

            if(column.id == "roomName" || column.id == "roomType"){

            }else{
                monthViewSelectId = stringToHTML(row[column.id]).children[0].innerText;
                let arr = monthViewSelectId.split('-');
                if(arr[0] == "0"){
                    DHXMessage({
                        text: "此房型未設定房間或價格",
                        icon:"dxi dxi-close",
                        css: "dhx_message--error",
                        expire:5000
                    });
                }else{
                    selectDate = "";
                    roomName = "";
                    roomTypeName = "";
                    orderId = "0";
                    selectRoomId = "0";
                    roomStatus = "0";

                    selectDate = arr[0];
                    roomName = row.roomName;
                    roomTypeName = row.roomType;
                    orderId = arr[3];
                    selectRoomId = arr[1];
                    roomStatus = arr[2];

                    if(Date.parse(arr[0]) > Date.parse(year + "/" + (month + 1)+ "/" + day)){
                        switch (arr[2]){
                            case "0":
                                //訂單完成
                                viewGridForm2Btn.getItem("addBtn").enable();
                                viewGridForm2Btn.getItem("modifyBtn").enable();
                                viewGridForm2Btn.getItem("deleteBtn").disable();
                                viewGridForm2Btn.getItem("checkInBtn").disable();
                                viewGridForm2Btn.getItem("changeRoomBtn").enable();
                                viewGridForm2Btn.getItem("checkOutBtn").disable();
                                viewGridForm2Btn.getItem("noShowBtn").disable();
                                viewGridForm2Btn.getItem("finishBtn").disable();
                                viewGridForm2Btn.getItem("faultReportBtn").enable();
                                viewGridForm2Btn.getItem("reportDoneBtn").disable();
                                // viewGridForm2Btn.getItem("reserveBtn").enable();
                                // viewGridForm2Btn.getItem("removeReserveBtn").disable();

                                loadViewGridFormData(orderId,selectDate,selectRoomId);
                                break;
                            case "1":
                                //空房
                                viewGridForm.getItem("addBtn").enable();
                                viewGridForm.getItem("modifyBtn").disable();
                                viewGridForm.getItem("deleteBtn").disable();
                                viewGridForm.getItem("checkInBtn").disable();
                                viewGridForm.getItem("changeRoomBtn").disable();
                                viewGridForm.getItem("checkOutBtn").disable();
                                viewGridForm.getItem("noShowBtn").disable();
                                // viewGridForm.getItem("cleanBtn").disable();
                                viewGridForm.getItem("finishBtn").disable();
                                viewGridForm.getItem("faultReportBtn").enable();
                                viewGridForm.getItem("reportDoneBtn").disable();
                                // viewGridForm.getItem("reserveBtn").enable();
                                // viewGridForm.getItem("removeReserveBtn").disable();

                                dhxViewGridWindow1.header.data.update("title", {value: ""});
                                dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                dhxViewGridWindow1.show();
                                break;
                            case "2":
                                //已售出
                                viewGridForm2Btn.getItem("addBtn").disable();
                                viewGridForm2Btn.getItem("modifyBtn").enable();
                                viewGridForm2Btn.getItem("deleteBtn").enable();
                                viewGridForm2Btn.getItem("checkInBtn").disable();
                                viewGridForm2Btn.getItem("changeRoomBtn").enable();
                                viewGridForm2Btn.getItem("checkOutBtn").disable();
                                viewGridForm2Btn.getItem("noShowBtn").disable();
                                viewGridForm2Btn.getItem("finishBtn").disable();
                                viewGridForm2Btn.getItem("faultReportBtn").disable();
                                viewGridForm2Btn.getItem("reportDoneBtn").disable();
                                // viewGridForm.getItem("reserveBtn").disable();
                                // viewGridForm.getItem("removeReserveBtn").disable();
                                loadViewGridFormData(orderId,selectDate,selectRoomId);
                                break;
                            case "3":
                                //故障
                                viewGridForm.getItem("addBtn").disable();
                                viewGridForm.getItem("modifyBtn").disable();
                                viewGridForm.getItem("deleteBtn").disable();
                                viewGridForm.getItem("checkInBtn").disable();
                                viewGridForm.getItem("changeRoomBtn").disable();
                                viewGridForm.getItem("checkOutBtn").disable();
                                viewGridForm.getItem("noShowBtn").disable();
                                // viewGridForm.getItem("cleanBtn").disable();
                                viewGridForm.getItem("finishBtn").disable();
                                viewGridForm.getItem("faultReportBtn").disable();
                                viewGridForm.getItem("reportDoneBtn").enable();
                                // viewGridForm.getItem("reserveBtn").disable();
                                // viewGridForm.getItem("removeReserveBtn").disable();

                                dhxViewGridWindow1.header.data.update("title", {value: ""});
                                dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                dhxViewGridWindow1.show();
                                break;
                            case "4":
                                //保留
                                viewGridForm.getItem("addBtn").disable();
                                viewGridForm.getItem("modifyBtn").disable();
                                viewGridForm.getItem("deleteBtn").disable();
                                viewGridForm.getItem("checkInBtn").disable();
                                viewGridForm.getItem("changeRoomBtn").disable();
                                viewGridForm.getItem("checkOutBtn").disable();
                                viewGridForm.getItem("noShowBtn").disable();
                                // viewGridForm.getItem("cleanBtn").disable();
                                viewGridForm.getItem("finishBtn").disable();
                                viewGridForm.getItem("faultReportBtn").disable();
                                viewGridForm.getItem("reportDoneBtn").disable();
                                // viewGridForm.getItem("reserveBtn").disable();
                                // viewGridForm.getItem("removeReserveBtn").enable();

                                dhxViewGridWindow1.header.data.update("title", {value: ""});
                                dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                dhxViewGridWindow1.show();
                                break;
                            case "5":
                                //已入住
                                viewGridForm2Btn.getItem("addBtn").disable();
                                viewGridForm2Btn.getItem("modifyBtn").enable();
                                viewGridForm2Btn.getItem("deleteBtn").enable();
                                viewGridForm2Btn.getItem("checkInBtn").disable();
                                viewGridForm2Btn.getItem("changeRoomBtn").enable();
                                viewGridForm2Btn.getItem("checkOutBtn").disable();
                                viewGridForm2Btn.getItem("noShowBtn").disable();
                                viewGridForm2Btn.getItem("finishBtn").disable();
                                viewGridForm2Btn.getItem("faultReportBtn").disable();
                                viewGridForm2Btn.getItem("reportDoneBtn").disable();
                                // viewGridForm2Btn.getItem("reserveBtn").disable();
                                // viewGridForm2Btn.getItem("removeReserveBtn").disable();
                                loadViewGridFormData(orderId,selectDate,selectRoomId);
                                break;
                            default:
                                viewGridForm.getItem("addBtn").disable();
                                viewGridForm.getItem("modifyBtn").disable();
                                viewGridForm.getItem("deleteBtn").disable();
                                viewGridForm.getItem("checkInBtn").disable();
                                viewGridForm.getItem("changeRoomBtn").disable();
                                viewGridForm.getItem("checkOutBtn").disable();
                                viewGridForm.getItem("noShowBtn").disable();
                                // viewGridForm.getItem("cleanBtn").enable();
                                viewGridForm.getItem("finishBtn").disable();
                                viewGridForm.getItem("faultReportBtn").disable();
                                viewGridForm.getItem("reportDoneBtn").disable();
                                // viewGridForm.getItem("reserveBtn").disable();
                                // viewGridForm.getItem("removeReserveBtn").disable();

                                dhxViewGridWindow1.header.data.update("title", {value: ""});
                                dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                dhxViewGridWindow1.show();
                                break;
                        };
                    }else{

                        switch (arr[2]){
                            case "0":
                                //訂單完成
                                viewGridForm2Btn.getItem("addBtn").enable();
                                viewGridForm2Btn.getItem("modifyBtn").enable();
                                viewGridForm2Btn.getItem("deleteBtn").disable();
                                viewGridForm2Btn.getItem("checkInBtn").disable();
                                viewGridForm2Btn.getItem("changeRoomBtn").enable();
                                viewGridForm2Btn.getItem("checkOutBtn").disable();
                                viewGridForm2Btn.getItem("noShowBtn").disable();
                                viewGridForm2Btn.getItem("finishBtn").disable();
                                viewGridForm2Btn.getItem("faultReportBtn").enable();
                                viewGridForm2Btn.getItem("reportDoneBtn").disable();
                                // viewGridForm.getItem("reserveBtn").enable();
                                // viewGridForm.getItem("removeReserveBtn").disable();

                                loadViewGridFormData(orderId,selectDate,selectRoomId);
                                break;
                            case "1":
                                //空房
                                viewGridForm.getItem("addBtn").enable();
                                viewGridForm.getItem("modifyBtn").disable();
                                viewGridForm.getItem("deleteBtn").disable();
                                viewGridForm.getItem("checkInBtn").disable();
                                viewGridForm.getItem("changeRoomBtn").disable();
                                viewGridForm.getItem("checkOutBtn").disable();
                                viewGridForm.getItem("noShowBtn").disable();
                                // viewGridForm.getItem("cleanBtn").disable();
                                viewGridForm.getItem("finishBtn").disable();
                                viewGridForm.getItem("faultReportBtn").enable();
                                viewGridForm.getItem("reportDoneBtn").disable();
                                // viewGridForm.getItem("reserveBtn").enable();
                                // viewGridForm.getItem("removeReserveBtn").disable();

                                dhxViewGridWindow1.header.data.update("title", {value: ""});
                                dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                dhxViewGridWindow1.show();
                                break;
                            case "2":
                                //已售出

                                viewGridForm2Btn.getItem("addBtn").disable();
                                viewGridForm2Btn.getItem("modifyBtn").enable();
                                viewGridForm2Btn.getItem("deleteBtn").enable();
                                viewGridForm2Btn.getItem("checkInBtn").enable();
                                viewGridForm2Btn.getItem("changeRoomBtn").enable();
                                viewGridForm2Btn.getItem("checkOutBtn").disable();
                                viewGridForm2Btn.getItem("noShowBtn").enable();
                                viewGridForm2Btn.getItem("finishBtn").enable();
                                viewGridForm2Btn.getItem("faultReportBtn").disable();
                                viewGridForm2Btn.getItem("reportDoneBtn").disable();
                                // viewGridForm.getItem("reserveBtn").disable();
                                // viewGridForm.getItem("removeReserveBtn").disable();
                                loadViewGridFormData(orderId,selectDate,selectRoomId);
                                break;
                            case "3":
                                //故障
                                viewGridForm.getItem("addBtn").disable();
                                viewGridForm.getItem("modifyBtn").disable();
                                viewGridForm.getItem("deleteBtn").disable();
                                viewGridForm.getItem("checkInBtn").disable();
                                viewGridForm.getItem("changeRoomBtn").disable();
                                viewGridForm.getItem("checkOutBtn").disable();
                                viewGridForm.getItem("noShowBtn").disable();
                                // viewGridForm.getItem("cleanBtn").disable();
                                viewGridForm.getItem("finishBtn").disable();
                                viewGridForm.getItem("faultReportBtn").disable();
                                viewGridForm.getItem("reportDoneBtn").enable();
                                // viewGridForm.getItem("reserveBtn").disable();
                                // viewGridForm.getItem("removeReserveBtn").disable();

                                dhxViewGridWindow1.header.data.update("title", {value: ""});
                                dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                dhxViewGridWindow1.show();
                                break;
                            case "4":
                                //保留
                                viewGridForm.getItem("addBtn").disable();
                                viewGridForm.getItem("modifyBtn").disable();
                                viewGridForm.getItem("deleteBtn").disable();
                                viewGridForm.getItem("checkInBtn").disable();
                                viewGridForm.getItem("changeRoomBtn").disable();
                                viewGridForm.getItem("checkOutBtn").disable();
                                viewGridForm.getItem("noShowBtn").disable();
                                // viewGridForm.getItem("cleanBtn").disable();
                                viewGridForm.getItem("finishBtn").disable();
                                viewGridForm.getItem("faultReportBtn").disable();
                                viewGridForm.getItem("reportDoneBtn").disable();
                                // viewGridForm.getItem("reserveBtn").disable();
                                // viewGridForm.getItem("removeReserveBtn").enable();

                                dhxViewGridWindow1.header.data.update("title", {value: ""});
                                dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                dhxViewGridWindow1.show();
                                break;
                            case "5":
                                //已入住
                                viewGridForm2Btn.getItem("addBtn").disable();
                                viewGridForm2Btn.getItem("modifyBtn").enable();
                                viewGridForm2Btn.getItem("deleteBtn").enable();
                                viewGridForm2Btn.getItem("checkInBtn").disable();
                                viewGridForm2Btn.getItem("changeRoomBtn").enable();
                                viewGridForm2Btn.getItem("checkOutBtn").enable();
                                viewGridForm2Btn.getItem("noShowBtn").disable();
                                viewGridForm2Btn.getItem("finishBtn").disable();
                                viewGridForm2Btn.getItem("faultReportBtn").disable();
                                viewGridForm2Btn.getItem("reportDoneBtn").disable();
                                // viewGridForm2Btn.getItem("reserveBtn").disable();
                                // viewGridForm2Btn.getItem("removeReserveBtn").disable();
                                loadViewGridFormData(orderId,selectDate,selectRoomId);
                                break;
                            case "6":
                                //未售出待打掃
                                viewGridForm.getItem("addBtn").disable();
                                viewGridForm.getItem("modifyBtn").disable();
                                viewGridForm.getItem("deleteBtn").disable();
                                viewGridForm.getItem("checkInBtn").disable();
                                viewGridForm.getItem("changeRoomBtn").disable();
                                viewGridForm.getItem("checkOutBtn").disable();
                                viewGridForm.getItem("noShowBtn").disable();
                                // viewGridForm.getItem("cleanBtn").enable();
                                viewGridForm.getItem("finishBtn").enable();
                                viewGridForm.getItem("faultReportBtn").enable();
                                viewGridForm.getItem("reportDoneBtn").disable();
                                // viewGridForm.getItem("reserveBtn").disable();
                                // viewGridForm.getItem("removeReserveBtn").disable();

                                dhxViewGridWindow1.header.data.update("title", {value: ""});
                                dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                dhxViewGridWindow1.show();
                                break;
                            case "8":
                                //已售出待打掃
                                viewGridForm.getItem("addBtn").disable();
                                viewGridForm.getItem("modifyBtn").enable();
                                viewGridForm.getItem("deleteBtn").disable();
                                viewGridForm.getItem("checkInBtn").disable();
                                viewGridForm.getItem("changeRoomBtn").enable();
                                viewGridForm.getItem("checkOutBtn").disable();
                                viewGridForm.getItem("noShowBtn").disable();
                                // viewGridForm.getItem("cleanBtn").enable();
                                viewGridForm.getItem("finishBtn").enable();
                                viewGridForm.getItem("faultReportBtn").enable();
                                viewGridForm.getItem("reportDoneBtn").disable();
                                // viewGridForm.getItem("reserveBtn").disable();
                                // viewGridForm.getItem("removeReserveBtn").disable();

                                dhxViewGridWindow1.header.data.update("title", {value: ""});
                                dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                dhxViewGridWindow1.show();
                                break;
                            case "12":
                                //未報到
                                viewGridForm2Btn.getItem("addBtn").enable();
                                viewGridForm2Btn.getItem("modifyBtn").enable();
                                viewGridForm2Btn.getItem("deleteBtn").enable();
                                viewGridForm2Btn.getItem("checkInBtn").disable();
                                viewGridForm2Btn.getItem("changeRoomBtn").enable();
                                viewGridForm2Btn.getItem("checkOutBtn").disable();
                                viewGridForm2Btn.getItem("noShowBtn").disable();
                                viewGridForm2Btn.getItem("finishBtn").disable();
                                viewGridForm2Btn.getItem("faultReportBtn").enable();
                                viewGridForm2Btn.getItem("reportDoneBtn").disable();
                                // viewGridForm2Btn.getItem("reserveBtn").enable();
                                // viewGridForm2Btn.getItem("removeReserveBtn").disable();

                                loadViewGridFormData(orderId,selectDate,selectRoomId);
                                break;
                            default:
                                viewGridForm.getItem("addBtn").disable();
                                viewGridForm.getItem("modifyBtn").disable();
                                viewGridForm.getItem("deleteBtn").disable();
                                viewGridForm.getItem("checkInBtn").disable();
                                viewGridForm.getItem("changeRoomBtn").disable();
                                viewGridForm.getItem("checkOutBtn").disable();
                                viewGridForm.getItem("noShowBtn").disable();
                                // viewGridForm.getItem("cleanBtn").enable();
                                viewGridForm.getItem("finishBtn").disable();
                                viewGridForm.getItem("faultReportBtn").disable();
                                viewGridForm.getItem("reportDoneBtn").disable();
                                // viewGridForm.getItem("reserveBtn").disable();
                                // viewGridForm.getItem("removeReserveBtn").disable();

                                dhxViewGridWindow1.header.data.update("title", {value: ""});
                                dhxViewGridWindow1.header.data.update("title", {value: arr[0]+"-"+row.roomName});
                                dhxViewGridWindow1.show();
                                break;
                        };
                    }
                }
            }
        });

        const dhxViewGridWindow1 = new DHXWindow({
            title: " ",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 540,
            width: 356,
            footer: true,
            css: "dhxViewGridWindow1"
        });
        dhxViewGridWindow1.footer.data.add({type: "spacer",});
        dhxViewGridWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxViewGridWindow1.footer.data.add({type: "spacer",});

        dhxViewGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLOSE":
                    dhxViewGridWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const dhxViewGridWindow2 = new DHXWindow({
            title: " ",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            header: true,
            height: 605,
            width: 690,
            footer: true,
            css: "dhxViewGridWindow2"
        });
        dhxViewGridWindow2.footer.data.add({type: "spacer",});
        dhxViewGridWindow2.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));

        dhxViewGridWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLOSE":
                    dhxViewGridWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        dashboardTab.getCell("monthView").attach(monthViewLayout);
        monthViewLayout.getCell("monthViewToolbar").attach(toolbar3);
        monthViewLayout.getCell("monthViewGrid").attach(dhxMonthViewGrid);

        axios.post(apiServer+"/dashboard/loadMonthViewGridTitle", {
            itemId1:monthViewCurrentMonth
        },headers).then((response) =>{
            if(response.status === 200){
                dhxMonthViewGrid.setColumns(response.data);

                axios.post(apiServer+"/dashboard/loadMonthViewGrid", {
                    itemId1:monthViewCurrentMonth,itemId2:hotel
                },headers).then((response2) =>{
                    if(response2.status === 200){
                        dhxMonthViewGrid.data.removeAll();
                        dhxMonthViewGrid.data.parse(response2.data);
                    }else{
                        // DHXMessage({
                        //     text: "連線異常，請重新整理",
                        //     icon:"dxi dxi-close",
                        //     css: "dhx_message--error",
                        //     expire:5000
                        // });
                    }

                })
                    .catch((error) => {
                        // DHXMessage({
                        //     text: "連線異常，請重新整理",
                        //     icon:"dxi dxi-close",
                        //     css: "dhx_message--error",
                        //     expire:5000
                        // });
                    });
            }else{
                // DHXMessage({
                //     text: "連線異常，請重新整理",
                //     icon:"dxi dxi-close",
                //     css: "dhx_message--error",
                //     expire:5000
                // });
            }

        })
            .catch((error) => {
                // DHXMessage({
                //     text: "連線異常，請重新整理",
                //     icon:"dxi dxi-close",
                //     css: "dhx_message--error",
                //     expire:5000
                // });
            });

        // axios.post(apiServer+"/dashboard/loadDayViewGridTitle",{
        //     itemId1:dayViewCurrentDate
        // },headers).then((response) =>{
        //     if(response.status === 200){
        //         if(response.data){
        //             if(dayViewGridSpanTmp != ""){
        //                 dayViewGridSpanTmp.map((item)=>{
        //                     dhxDayViewGrid.removeSpan(item.row,item.column);
        //                 })
        //             }
        //
        //             dhxDayViewGrid.setColumns(response.data);
        //             axios.post(apiServer+"/dashboard/loadDayViewGrid",{
        //                 itemId1:dayViewCurrentDate,itemId2:hotel
        //             },headers)
        //                 .then((response2) =>{
        //                     if(response2.status === 200){
        //                         if(response2.data){
        //                             dhxDayViewGrid.data.removeAll();
        //                             dhxDayViewGrid.data.parse(response2.data);
        //
        //                             axios.post(apiServer+"/dashboard/loadDayViewGridSpan",{
        //                                 itemId1:dayViewCurrentDate,itemId2:hotel
        //                             },headers)
        //                                 .then((response3) =>{
        //                                     if(response3.status === 200){
        //                                         if(response3.data){
        //                                             dayViewGridSpanTmp = response3.data;
        //                                             setTimeout(()=>{
        //                                                 response3.data.map((item)=>{
        //                                                     dhxDayViewGrid.addSpan(item);
        //                                                     dhxProgressWindow1.hide();
        //                                                 })
        //                                             },1000)
        //                                         }else{
        //                                             dhxProgressWindow1.hide();
        //                                             DHXMessage({
        //                                                 text: "連線異常，請重新整理",
        //                                                 icon:"dxi dxi-close",
        //                                                 css: "dhx_message--danger",
        //                                                 expire:5000
        //                                             });
        //                                         }
        //                                     }else{
        //                                         dhxProgressWindow1.hide();
        //                                         DHXMessage({
        //                                             text: "連線異常，請重新整理",
        //                                             icon:"dxi dxi-close",
        //                                             css: "dhx_message--danger",
        //                                             expire:5000
        //                                         });
        //                                     }
        //
        //                                 })
        //                                 .catch((error) => {
        //                                     dhxProgressWindow1.hide();
        //                                     DHXMessage({
        //                                         text: "連線異常，請重新整理",
        //                                         icon:"dxi dxi-close",
        //                                         css: "dhx_message--danger",
        //                                         expire:5000
        //                                     });
        //                                 });
        //                         }else{
        //                             dhxProgressWindow1.hide();
        //                             DHXMessage({
        //                                 text: "連線異常，請重新整理",
        //                                 icon:"dxi dxi-close",
        //                                 css: "dhx_message--danger",
        //                                 expire:5000
        //                             });
        //                         }
        //
        //                     }else{
        //                         dhxProgressWindow1.hide();
        //                         DHXMessage({
        //                             text: "連線異常，請重新整理",
        //                             icon:"dxi dxi-close",
        //                             css: "dhx_message--danger",
        //                             expire:5000
        //                         });
        //                     }
        //
        //                 })
        //                 .catch((error) => {
        //                     dhxProgressWindow1.hide();
        //                     DHXMessage({
        //                         text: "連線異常，請重新整理",
        //                         icon:"dxi dxi-close",
        //                         css: "dhx_message--danger",
        //                         expire:5000
        //                     });
        //                 });
        //         }else{
        //             dhxProgressWindow1.hide();
        //             DHXMessage({
        //                 text: "連線異常，請重新整理",
        //                 icon:"dxi dxi-close",
        //                 css: "dhx_message--danger",
        //                 expire:5000
        //             });
        //         }
        //     }else{
        //         dhxProgressWindow1.hide();
        //         DHXMessage({
        //             text: "連線異常，請重新整理",
        //             icon:"dxi dxi-close",
        //             css: "dhx_message--danger",
        //             expire:5000
        //         });
        //     }
        // })
        //     .catch((error) => {
        //         dhxProgressWindow1.hide();
        //         DHXMessage({
        //             text: "連線異常，請重新整理",
        //             icon:"dxi dxi-close",
        //             css: "dhx_message--danger",
        //             expire:5000
        //         });
        //     });

        const viewGridForm = new DHXForm(null,{
            align :"evenly",
            rows: viewGridFormConfig,
        });

        const viewGridForm2Layout = new DHXLayout(null,{
            rows:[
                {id:"viewGridForm2_1", height: "content"},
                {id:"viewGridForm2_2", height: "content"},
                {id:"otherRoomGrid", height: "180px",header: "其他房間紀錄"},
                {id:"viewGridForm2Btn", height: "160px"},
            ]
        });



        const viewGridForm2_1 = new DHXForm(null,{
            align :"evenly",
            rows: viewGridForm2_1Config,
        });

        let viewGridForm_otherRoomGrid = new DHXGrid(null,{
            columns: viewGridForm_otherRoomGridColumn,
            autoWidth: true,
            resizable: true,
            rowHeight: 30,
            headerRowHeight: 30,
        });

        const viewGridForm2Btn = new DHXForm(null,{
            padding: "0px",
            align :"evenly",
            rows: viewGridForm2BtnConfig,
        });

        dhxViewGridWindow2.attach(viewGridForm2Layout);
        viewGridForm2Layout.getCell("viewGridForm2_1").attach(viewGridForm2_1);
        viewGridForm2Layout.getCell("otherRoomGrid").attach(viewGridForm_otherRoomGrid);
        viewGridForm2Layout.getCell("viewGridForm2Btn").attach(viewGridForm2Btn);

        let loadViewGridForm = (name)=> {
            switch (name){
                case "addBtn":
                    //新增訂單
                    dhxProgressWindow1.show();
                    axios.get(apiServer+"/orders/getTMPId",headers)
                        .then((data) =>{
                            if(data.status === 200){
                                if(data.data){
                                    TMPId = data.data[0].TMPId;
                                    newOrder_purchaserInfoForm.clear();
                                    newOrder_purchaserInfoForm.getItem("AA138").setValue("1");

                                    newOrder_roomGridWindow1.attach(newOrder_roomGridForm);
                                    newOrder_roomGrid.data.removeAll();
                                    newOrder_payLogGrid.data.removeAll();

                                    axios.get(apiServer+"/orders/loadCountiesList",headers)
                                        .then((response) =>{
                                            newOrder_purchaserInfoForm.getItem("AA004").setOptions(response.data);
                                        })
                                        .catch((error) => {
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        });

                                    axios.get(apiServer+"/orders/loadOrderSourceList",headers)
                                        .then((response) =>{
                                            newOrder_purchaserInfoForm.getItem("orderSource").setOptions(response.data);
                                            newOrder_purchaserInfoForm.getItem("orderSource").setValue("0");
                                            newOrder_purchaserInfoForm.getItem("saleSource").disable();
                                            newOrder_purchaserInfoForm.getItem("saleSource").setValue("0");
                                        })
                                        .catch((error) => {
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        });

                                    axios.post(apiServer+"/orders/autoCreateTMPRoom", {
                                        itemId1: TMPId,itemId2:hotel,itemId3:selectRoomId,itemId4:selectDate
                                    },headers).then((response) =>{
                                        if(response.data[0]){
                                            axios.post(apiServer+"/orders/loadTMPRoomGrid", {
                                                itemId1: TMPId
                                            },headers).then((data) =>{
                                                if(data.status === 200){
                                                    if (data.data){
                                                        newOrder_roomGrid.data.removeAll();
                                                        newOrder_roomGrid.data.parse(data.data);
                                                        dhxProgressWindow1.hide();
                                                        dhxNewOrderWindow1.show();
                                                    }
                                                }
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                                }
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;

                case "modifyBtn":
                    //修改訂單
                    dhxTabbar.setActive("orderInfo");

                    orderForm.clear();

                    orderForm.getItem("AA001").setValue(orderId);

                    isSaleSourceNeedChange = false;

                    axios.post(apiServer+"/orders/loadOrderFormData", {
                        itemId1: orderId
                    },headers).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                orderForm.setValue(response.data[0]);
                                if(response.data[0].unpaid > 0){
                                    productInfo_payLogGridToolbar.enable("sendNewPayLink");
                                }else{
                                    productInfo_payLogGridToolbar.disable("sendNewPayLink");
                                }

                                orderForm.getItem("AA006").setOptions(response.data[0].orderSourceItem);
                                orderForm.getItem("AA006").setValue(response.data[0].AA006)
                                orderForm.getItem("AA008").setOptions(response.data[0].saleSourceItem);
                                orderForm.getItem("AA008").setValue(response.data[0].AA008)

                                isSaleSourceNeedChange = true;

                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    })
                    .catch((error) => {
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    });

                    setTimeout(()=>{
                        productInfo_otherPrdGridForm.getItem("AC002").setValue(orderForm.getItem("AA001").getValue());
                        productInfo_otherPrdGridForm.getItem("AC003").setValue(orderForm.getItem("AA002").getValue());

                        orderInfo_purchaserGrid.data.removeAll();
                        axios.post(apiServer+"/orders/loadPurchaserGrid", {
                            itemId1: orderId
                        },headers).then((data) =>{
                            orderInfo_purchaserGrid.data.parse(data.data);
                        });

                        orderInfo_guestGrid.data.removeAll();
                        axios.post(apiServer+"/orders/loadGuestGrid", {
                            itemId1: orderId
                        },headers).then((data) =>{
                            orderInfo_guestGrid.data.parse(data.data);
                        });

                        productInfo_roomGrid.data.removeAll();
                        axios.post(apiServer+"/orders/loadRoomGrid", {
                            itemId1: orderId
                        },headers).then((data) =>{
                            productInfo_roomGrid.data.parse(data.data);
                        });

                        alertSetGrid.data.removeAll();
                        axios.post(apiServer+"/orders/loadAlertSetGrid", {
                            itemId1: orderId
                        },headers).then((data) =>{
                            alertSetGrid.data.parse(data.data);
                        });

                        productInfo_payLogGrid.data.removeAll();
                        axios.post(apiServer+"/orders/loadPayLogGrid", {
                            itemId1: orderId
                        },headers).then((data) =>{
                            productInfo_payLogGrid.data.parse(data.data);
                        });

                        productInfo_otherPrdGrid.data.removeAll();
                        axios.post(apiServer+"/orders/loadOtherPrdGrid", {
                            itemId1: orderId
                        },headers).then((data) =>{
                            productInfo_otherPrdGrid.data.parse(data.data);
                        });

                        dhxOrderWindow1.show();

                    },500);
                    break;

                case "deleteBtn":
                    if(canDelete){
                        axios.get(apiServer+"/orders/loadHotelBookingCanceTermList",headers)
                            .then((response) =>{
                                orderDeleteForm.getItem("AA005").setOptions(response.data);
                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--danger",
                                    expire:5000
                                });
                            });

                        axios.post(apiServer+"/orders/loadOrderFormData", {
                            itemId1: orderId
                        },headers).then((response) =>{
                            orderDeleteForm.setValue(response.data[0]);
                        })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--danger",
                                    expire:5000
                                });
                            });

                        dhxOrderWindow2.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有刪除的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                    break;

                case "checkInBtn":
                    //入住
                    dhxCheckInWindow.header.data.update("title", {value: selectDate+"-"+roomName+" 入住資訊"});

                    axios.post(apiServer+"/dashboard/loadOrderInfoGrid",{itemId1:orderId},headers)
                        .then((data) =>{
                            if(data.status === 200){
                                if(data.data){
                                    checkIn_orderInfoGrid.data.removeAll();
                                    checkIn_orderInfoGrid.data.parse(data.data);
                                    orderSourceType = data.data[0].order_source_type;
                                    bookingType =  data.data[0].booking_type;
                                    if(data.data[0].unpaid > 0){
                                        checkIn_payLogGridToolbar.enable("sendNewPayLink");
                                    }else{
                                        checkIn_payLogGridToolbar.disable("sendNewPayLink");
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        });

                    checkIn_roomGrid.data.removeAll();
                    axios.post(apiServer+"/orders/loadRoomGrid", {
                        itemId1: orderId
                    },headers).then((data) =>{
                        checkIn_roomGrid.data.parse(data.data);
                    });

                    checkIn_otherPrdGrid.data.removeAll();
                    axios.post(apiServer+"/orders/loadOtherPrdGrid", {
                        itemId1: orderId
                    },headers).then((data) =>{
                        checkIn_otherPrdGrid.data.parse(data.data);
                    });

                    checkIn_purchaserGrid.data.removeAll();
                    axios.post(apiServer+"/dashboard/loadPurchaserGrid", {
                        itemId1: orderId
                    },headers).then((data) =>{
                        checkIn_purchaserGrid.data.parse(data.data);
                    });

                    checkIn_guestGrid.data.removeAll();
                    axios.post(apiServer+"/orders/loadGuestGrid", {
                        itemId1: orderId
                    },headers).then((data) =>{
                        checkIn_guestGrid.data.parse(data.data);
                    });

                    checkIn_payLogGrid.data.removeAll();
                    axios.post(apiServer+"/orders/loadPayLogGrid", {
                        itemId1: orderId
                    },headers).then((data) =>{
                        checkIn_payLogGrid.data.parse(data.data);
                    });

                    dhxCheckInWindow.show();
                    break;

                case "changeRoomBtn":
                    //換房
                    dhxProgressWindow1.show();
                    isNeedFirstChangeEvent = false;
                    isNeedFirstChangeEventRest = false;
                    axios.post(apiServer+"/orders/loadRoomFormData", {
                        itemId1: roomLogId,
                        // itemId2:data.row.bookingType
                    },headers).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                switch (response.data[0].AG132){
                                    case 1:
                                        bookingType =  1;
                                        changeRoom_roomForm.clear();
                                        changeRoom_roomForm.clear("validation");

                                        if(roomStatus == 2 || roomStatus == 5){
                                            changeRoom_roomForm.getItem("needReportFault").enable();
                                        }else{
                                            changeRoom_roomForm.getItem("needReportFault").disable();
                                        }
                                        changeRoom_roomForm.getItem("AA103").disable();
                                        changeRoom_roomForm.getItem("AA104").disable();
                                        changeRoom_roomForm.getItem("AA105").disable();
                                        changeRoom_roomForm.getItem("AA006").disable();
                                        changeRoom_roomForm.getItem("AA106").disable();
                                        changeRoom_roomForm.setValue(response.data[0]);
                                        changeRoom_roomForm.setValue({"AA002":response.data[0].AG003,"AA003":response.data[0].AG004,"AA004":response.data[0].AG005,"AA103":Date.now(),"AA104":getDate(response.data[0].AG119)+" 00:00:00"});

                                        axios.post(apiServer+"/orders/EditLoadRoomTypeList", {
                                            itemId1: hotel,itemId2: response.data[0].AG001,itemId3: response.data[0].AG119,itemId4: response.data[0].AG120
                                        },headers).then((response2) =>{
                                            if(response2.status === 200){
                                                if(response2.data){
                                                    changeRoom_roomForm_AG004 = changeRoom_roomForm.getItem("AG004").getWidget();
                                                    changeRoom_roomForm_AG004.data.removeAll();
                                                    changeRoom_roomForm_AG004.data.parse(response2.data);
                                                    changeRoom_roomForm.getItem("AG004").setValue(response.data[0].AG004);
                                                    changeRoom_roomForm.getItem("AG004").enable();

                                                    axios.post(apiServer+"/orders/EditLoadRoomList", {
                                                        itemId1: hotel,itemId2: response.data[0].AG001,itemId3: response.data[0].AG119,itemId4: response.data[0].AG120,itemId5: response.data[0].AG004
                                                    },headers).then((response3) =>{
                                                        if(response3.status === 200){
                                                            if(response3.data){
                                                                changeRoom_roomForm_AG005 = changeRoom_roomForm.getItem("AG005").getWidget();
                                                                changeRoom_roomForm_AG005.data.removeAll();
                                                                changeRoom_roomForm_AG005.data.parse(response3.data);
                                                                changeRoom_roomForm.getItem("AG005").setValue(response.data[0].AG005);
                                                                changeRoom_roomForm.getItem("AG005").enable();

                                                                changeRoomFormWindow1.attach(changeRoom_roomForm);
                                                                dhxProgressWindow1.hide();
                                                                changeRoomFormWindow1.show();
                                                                isNeedFirstChangeEvent = true;
                                                            }else{
                                                                dhxProgressWindow1.hide();
                                                                DHXMessage({
                                                                    text: "連線異常，請重新整理",
                                                                    icon:"dxi dxi-close",
                                                                    css: "dhx_message--error",
                                                                    expire:5000
                                                                });
                                                            }
                                                        }else{
                                                            dhxProgressWindow1.hide();
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        }
                                                    })
                                                        .catch((error) => {
                                                            dhxProgressWindow1.hide();
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        });
                                                }else{
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }
                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        })
                                            .catch((error) => {
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            });
                                        break;
                                    case 2:
                                        break;
                                    case 3:
                                        bookingType =  3;
                                        changeRoom_restRoomForm.clear();
                                        changeRoom_restRoomForm.clear("validation");

                                        if(roomStatus == 2 || roomStatus == 5){
                                            changeRoom_restRoomForm.getItem("needReportFault").enable();
                                        }else{
                                            changeRoom_restRoomForm.getItem("needReportFault").disable();
                                        }
                                        changeRoom_restRoomForm.getItem("AA103").disable();
                                        changeRoom_restRoomForm.getItem("AA104").disable();
                                        changeRoom_restRoomForm.getItem("AA105").disable();
                                        changeRoom_restRoomForm.getItem("AA006").disable();
                                        changeRoom_restRoomForm.getItem("AA106").disable();
                                        changeRoom_restRoomForm.setValue(response.data[0]);
                                        changeRoom_restRoomForm.setValue({"AA104_text1":"分鐘","AA104_text2":"小時"});

                                        if(response.data[0].AG132 === 3 || (response.data[0].AG132 === 2 && (response.data[0].AG133 === 1 || response.data[0].AG133 === 2))){
                                            if(response.data[0].AG133 === 1){
                                                changeRoom_restRoomForm.getItem("AA103_1").setValue("1");
                                                changeRoom_restRoomForm.getItem("AA104_1").setValue(`${response.data[0].AG134}`);
                                                changeRoom_restRoomForm.getItem("AA104_1").enable();
                                                changeRoom_restRoomForm.getItem("AA104_2").disable();
                                            }else{
                                                changeRoom_restRoomForm.getItem("AA103_2").setValue("1");
                                                changeRoom_restRoomForm.getItem("AA104_2").setValue(`${response.data[0].AG134}`);
                                                changeRoom_restRoomForm.getItem("AA104_2").enable();
                                                changeRoom_restRoomForm.getItem("AA104_1").disable();
                                            }
                                        }

                                        axios.post(apiServer+"/orders/EditLoadRestRoomTypeList", {
                                            itemId1: hotel,itemId2: response.data[0].AG001
                                        },headers).then((response2) =>{
                                            if(response2.status === 200){
                                                if(response2.data){
                                                    changeRoom_restRoomForm_AG004 = changeRoom_restRoomForm.getItem("AG004").getWidget();
                                                    changeRoom_restRoomForm_AG004.data.removeAll();
                                                    changeRoom_restRoomForm_AG004.data.parse(response2.data);
                                                    changeRoom_restRoomForm.getItem("AG004").setValue(response.data[0].AG004);
                                                    changeRoom_restRoomForm.getItem("AG004").enable();

                                                    axios.post(apiServer+"/orders/EditLoadRestRoomList", {
                                                        itemId1: hotel,itemId2: response.data[0].AG001,itemId3: response.data[0].AG119,itemId4: AA103,itemId5:AA104,itemId6:response.data[0].AG004
                                                    },headers).then((response3) =>{
                                                        if(response3.status === 200){
                                                            if(response3.data){
                                                                changeRoom_restRoomForm_AG005 = changeRoom_restRoomForm.getItem("AG005").getWidget();
                                                                changeRoom_restRoomForm_AG005.data.removeAll();
                                                                changeRoom_restRoomForm_AG005.data.parse(response3.data);
                                                                changeRoom_restRoomForm.getItem("AG005").setValue(response.data[0].AG005);
                                                                changeRoom_restRoomForm.getItem("AG005").enable();

                                                                changeRoomFormWindow1.attach(changeRoom_restRoomForm);
                                                                dhxProgressWindow1.hide();
                                                                changeRoomFormWindow1.show();
                                                                isNeedFirstChangeEventRest = true;
                                                            }else{
                                                                dhxProgressWindow1.hide();
                                                                DHXMessage({
                                                                    text: "連線異常，請重新整理",
                                                                    icon:"dxi dxi-close",
                                                                    css: "dhx_message--error",
                                                                    expire:5000
                                                                });
                                                            }
                                                        }else{
                                                            dhxProgressWindow1.hide();
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        }
                                                    })
                                                    .catch((error) => {
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    });
                                                }
                                            }

                                        })
                                            .catch((error) => {
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            });
                                        break;
                                }
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                    .catch((error) => {
                        dhxProgressWindow1.hide();
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    });

                    // productInfo_roomGridForm.getItem("AG001").setValue(data.row.id)
                    // productInfo_roomGridForm.setValue({"AG001":data.row.id,"AG002":orderForm.getItem("AA001").getValue()});

                    // changeRoomFormWindow1.show();
                    break;
                case "checkOutBtn":
                    //退房
                    dhxProgressWindow1.show();
                    dhxCheckOut_canCheckOutRoomGrid.data.removeAll();
                    axios.post(apiServer+"/dashboard/loadCanCheckOutRoomGrid",{
                        itemId1:orderId,itemId2:selectDate
                    },headers).then((response2) =>{
                        if(response2.status === 200){
                            if(response2.data){
                                dhxCheckOut_canCheckOutRoomGrid.data.parse(response2.data);
                                dhxCheckOut_canCheckOutRoomGridWindow1.attach(dhxCheckOut_canCheckOutRoomGrid);
                                dhxCheckOut_canCheckOutRoomGridWindow1.show();
                                canCheckOutRoomArray = [];
                                dhxProgressWindow1.hide();

                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    // axios.post(apiServer+"/dashboard/checkNextDay", {
                    //     itemId1:orderId,itemId2:selectDate,itemId3:selectRoomId
                    // },headers).then((response) =>{
                    //     if(response.status === 200){
                    //         if(response.data){
                    //             isCheckOutEarly = response.data[0].result;
                    //             let htmlStr = '';
                    //             if(response.data[0].result == 1){
                    //                 htmlStr = `<div style='text-align: center;'><img width='35%' src="${Image_warning}" /><br/><span style='font-size: 1.5em;font-weight: bold;'><span style='color: red'>${selectDate}-${roomName}</span> 確定要 <span style='color: red'>提早</span> 退房嗎？</span></div>`;
                    //             }else{
                    //                 htmlStr = `<div style='text-align: center;'><img width='35%' src="${Image_warning}" /><br/><span style='font-size: 1.5em;font-weight: bold;'><span style='color: red'>${selectDate}-${roomName}</span> 確定要退房嗎？</span></div>`;
                    //             }
                    //
                    //             dhxCheckOutWindow1.attachHTML(htmlStr);
                    //             dhxCheckOutWindow1.show();
                    //         }else{
                    //             DHXMessage({
                    //                 text: "連線異常，請重新整理",
                    //                 icon:"dxi dxi-close",
                    //                 css: "dhx_message--danger",
                    //                 expire:5000
                    //             });
                    //         }
                    //     }else{
                    //         DHXMessage({
                    //             text: "連線異常，請重新整理",
                    //             icon:"dxi dxi-close",
                    //             css: "dhx_message--danger",
                    //             expire:5000
                    //         });
                    //     }
                    //
                    // })
                    // .catch((error) => {
                    //     DHXMessage({
                    //         text: "連線異常，請重新整理",
                    //         icon:"dxi dxi-close",
                    //         css: "dhx_message--danger",
                    //         expire:5000
                    //     });
                    // });
                    break;

                case "noShowBtn":
                    dhxProgressWindow1.show();
                    axios.post(apiServer+"/dashboard/updateNoShow",{
                        itemId1:orderId,itemId2:selectDate,itemId3:selectRoomId
                    },headers).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: response.data[0].message,
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });
                            reloadGrid();
                            reloadStatistics();

                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;

                case "finishBtn":
                    //打掃完畢

                    if(roomStatus == 2){
                        //直接退房
                        dhxProgressWindow1.show();
                        axios.post(apiServer+"/dashboard/finishRoom", {
                            itemId1:orderId,itemId2:selectDate,itemId3:selectRoomId
                        },headers).then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    DHXMessage({
                                        text: response.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--success",
                                        expire:5000
                                    });
                                    reloadGrid();
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    }else{
                        dhxProgressWindow1.show();
                        axios.post(apiServer+"/dashboard/cleanFinish", {
                            itemId1:orderId,itemId2:selectDate,itemId3:selectRoomId
                        },headers).then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    DHXMessage({
                                        text: response.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--success",
                                        expire:5000
                                    });
                                    reloadGrid();
                                    reloadStatistics();

                                    faultReportForm.clear();
                                    faultReportForm.clear("validation");
                                    lostPropertyForm.clear();
                                    lostPropertyForm.clear("validation");
                                    lostPropertyForm.setValue(response.data[0]);
                                    dhxLostPropertyFormWindow1.show();
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    }
                    break;

                case "faultReportBtn":
                    //故障通報
                    faultReportForm.setValue({"AA001":"0","AA002":hotel,"AA003":"0","AA004":selectRoomId,"note":"此功能會扣掉庫存，請謹慎選取","AA103":Date.now(),"AA104":selectDate.replace(/\//g,"-")+" 00:00:00","AA105":selectDate.replace(/\//g,"-")+" 23:59:59","roomType":roomTypeName,"roomName":roomName});

                    dhxFaultReportWindow1.show();

                    axios.get(apiServer+"/faultReport/loadFaultTermList",headers).then((response) =>{
                        faultReportForm.getItem("AA006").setOptions(response.data);
                        faultReportForm.clear("validation");
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        });
                    break;

                case "reportDoneBtn":
                    //故障處理回報
                    faultReportDoneForm.clear();
                    faultReportDoneForm.setValue({"AA001":orderId,"AA002":hotel,"AA109":Date.now()});
                    dhxFaultReportWindow2.show();
                    break;

                case "reserveBtn":
                    //保留
                    reserveForm.setValue({"AA001":"0","AA002":hotel,"AA003":"0","AA004":selectRoomId,"AA100":1,"AA103":Date.now(),"AA104":selectDate.replace(/\//g,"-"),"AA105":selectDate.replace(/\//g,"-"),"AA108":"0","roomType":roomTypeName,"roomName":roomName,"note":"此功能會扣掉庫存，請謹慎選取"});

                    dhxReserveWindow1.show();
                    break;

                case "removeReserveBtn":
                    //解除保留
                    dhxProgressWindow1.show();
                    axios.post(apiServer+"/dashboard/removeReserve", {
                        itemId1:orderId
                    },headers).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: response.data[0].message,
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });
                            reloadGrid();

                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    })
                    .catch((error) => {
                        dhxProgressWindow1.hide();
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--danger",
                            expire:5000
                        });
                    });

                    reloadStatistics();
                    break;
            }
        };

        viewGridForm.events.on("click", function(name, events) {
            dhxViewGridWindow1.hide();
            if(canEdit){
                loadViewGridForm(name);
            }else{
                DHXMessage({
                    text: "請先確認擁有編輯的權限",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            }
        });

        viewGridForm2Btn.events.on("click", function(name, events) {
            dhxViewGridWindow2.hide();
            if(canEdit){
                loadViewGridForm(name);
            }else{
                DHXMessage({
                    text: "請先確認擁有編輯的權限",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            }
        });

        toolbar3.data.parse(JSON.parse(JSON.stringify(toolbar3Config)));

        //新增訂單視窗
        const dhxNewOrderWindow1 = new DHXWindow({
            title: "新增訂單視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 700,
            width: 800,
            footer: true,
            css: "dhxNewOrderWindow1"
        });

        dhxNewOrderWindow1.footer.data.add({type:"spacer"});
        dhxNewOrderWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxNewOrderWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxNewOrderWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    // if(newOrder_purchaserInfoForm.getItem("AA109").getValue() === ""){
                    //     DHXMessage({
                    //         text: "請填入電話",
                    //         icon:"dxi dxi-close",
                    //         css: "dhx_message--error",
                    //         expire:5000
                    //     });
                    //     break;
                    // }

                    if(newOrder_purchaserInfoForm.getItem("AA138").getValue() === ""){
                        DHXMessage({
                            text: "請選取訂房類型",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(newOrder_purchaserInfoForm.getItem("AA106").getValue() === ""){
                        DHXMessage({
                            text: "請填入姓名",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(newOrder_roomGrid.data.getLength() === 0){
                        DHXMessage({
                            text: "請新增欲訂購的房間",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    dhxProgressWindow1.show();

                    isSaleSourceNeedChange = false;

                    axios.post(apiServer+"/orders/checkRoomRemains", {
                        itemId1:TMPId
                    },headers).then((response) =>{

                        if(response.status === 200){
                            if(response.data[0].result == 1){
                                let newOrder_purchaserInfoFormValue = Object.assign({},newOrder_purchaserInfoForm.getValue(), {TMPId: TMPId});
                                newOrder_purchaserInfoFormValue = Object.assign({},newOrder_purchaserInfoFormValue, {AA002: hotel});

                                axios.post(apiServer+"/orders/updateOrderFormData",
                                    newOrder_purchaserInfoFormValue
                                    ,headers).then((response2) =>{

                                    if(response2.data[0].result){
                                        DHXMessage({
                                            // text: response.data[0].message,
                                            html:"<div style='color:white;'>"+response2.data[0].message+"</div>",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--success",
                                            expire:5000
                                        });

                                        dhxNewOrderWindow1.hide();

                                        if(newOrder_payLogGrid.data.getLength() !== 0){
                                            newOrder_payLogGridForm.getItem("AH001").setValue("0");
                                            newOrder_payLogGridForm.getItem("AH002").setValue(response2.data[0].orderId);
                                            newOrder_payLogGridForm.getItem("AH003").setValue(hotel);
                                            let newOrder_payLogGridFormValue = Object.assign({},newOrder_payLogGridForm.getValue(), {TMPId: TMPId});
                                            axios.post(apiServer+"/orders/updateProductInfo_payLogGridFormData",
                                                newOrder_payLogGridFormValue,headers
                                            ).then((response3) =>{
                                                reloadGrid();
                                            })
                                                .catch((error) => {
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                });
                                        }else{
                                            reloadGrid();
                                        }

                                        TMPId = "0";

                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }

                                })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }else if(response.data[0].result == 0){

                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    // text: response.data[0].message,
                                    html:response.data[0].message,
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }
                    })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    break;

                case "WINDOW_FOOT_CLOSE":
                    dhxNewOrderWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        //新增訂單
        let newOrderWindow1Layout1 = new DHXLayout(null,{
            rows:[
                {id:"newOrder_purchaserInfoForm", height: "400px", css: "orderInfo_orderInfo"},
                {id:"newOrder_roomGridToolbar", height: "50px"},
                {id:"newOrder_roomGrid", height: "300px"},
                {id:"newOrder_payLogGridToolbar", height: "50px"},
                {id:"newOrder_payLogGrid", height: "300px"},
            ]
        });

        const newOrder_purchaserInfoForm = new DHXForm(null,{
            rows: newOrder_purchaserGridFormConfig,
            css: "newOrder_purchaserInfoForm"
        });

        dhxNewOrderWindow1.attach(newOrderWindow1Layout1);
        newOrderWindow1Layout1.getCell("newOrder_purchaserInfoForm").attach(newOrder_purchaserInfoForm);

        const newOrder_roomGridToolbar = new DHXToolbar(null, {
        });
        newOrder_roomGridToolbar.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    isNeedFirstChangeEvent = false;
                    isNeedFirstChangeEventRest = false;
                    if(canEdit){
                        if(newOrder_purchaserInfoForm.getItem("AA138").getValue() == 1){
                            isNeedFirstChangeEvent = false;
                            newOrder_roomGridForm.clear();
                            newOrder_roomGridForm.setValue({"AG001":"0","AG002":orderForm.getItem("AA001").getValue(),"AG003":hotel,"AG006":TMPId,"AG007":"0","AG116":"0","AG127":"0","AG132":newOrder_purchaserInfoForm.getItem("AA138").getValue(),"AG133":"0","AG134":"0","total":""});

                            newOrder_roomGridForm_AG004 = newOrder_roomGridForm.getItem("AG004").getWidget();
                            newOrder_roomGridForm_AG004.data.removeAll();
                            newOrder_roomGridForm_AG004.data.parse([{
                                "id": "0",
                                "value": "---請選擇---",
                            }]);
                            newOrder_roomGridForm.getItem("AG004").setValue("0");

                            newOrder_roomGridForm_AG005 = newOrder_roomGridForm.getItem("AG005").getWidget();
                            newOrder_roomGridForm_AG005.data.removeAll();
                            newOrder_roomGridForm_AG005.data.parse([{
                                "id": "0",
                                "value": "---請選擇---",
                            }]);
                            newOrder_roomGridForm.getItem("AG005").setValue("0");

                            axios.get(apiServer+"/orders/loadSaleSourceList2",headers
                            ).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        let newOrder_roomGridForm_AG007 = newOrder_roomGridForm.getItem("AG007").getWidget();
                                        if(response.data.length > 1){
                                            newOrder_roomGridForm_AG007.data.removeAll();
                                            newOrder_roomGridForm_AG007.data.parse(response.data);
                                            newOrder_roomGridForm.getItem("AG007").setValue("0");
                                        }else{
                                            newOrder_roomGridForm_AG007.data.removeAll();
                                            newOrder_roomGridForm_AG007.data.parse([{
                                                "id": "0",
                                                "value": "---請選擇---",
                                            }]);
                                            newOrder_roomGridForm.getItem("AG007").setValue("0");
                                        }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });

                            newOrder_roomGridWindow1.attach(newOrder_roomGridForm);
                            newOrder_roomGridWindow1.show();
                            isNeedFirstChangeEvent = true;

                        }else if(newOrder_purchaserInfoForm.getItem("AA138").getValue() == 3){
                            isNeedFirstChangeEventRest = false;

                            // dhxNewOrderWindow1.header.data.update("title", {value: "新增非住宿房間視窗"});
                            newOrder_restRoomGridForm.clear();

                            newOrder_restRoomGridForm.setValue({"AG001":"0","AG002":orderForm.getItem("AA001").getValue(),"AG003":hotel,"AG006":TMPId,"AG007":"0","AA103_2":"1","AA104_1":30,"AA104_2":1,"AA104_text1":"分鐘","AA104_text2":"小時","AG127":"0","AG132":newOrder_purchaserInfoForm.getItem("AA138").getValue(),"total":""});
                            AA103 = 2;
                            AA104 = 1;
                            restTypeText = '小時';
                            price = 0;

                            newOrder_restRoomGridForm_AG004 = newOrder_restRoomGridForm.getItem("AG004").getWidget();
                            newOrder_restRoomGridForm_AG004.data.removeAll();
                            newOrder_restRoomGridForm_AG004.data.parse([{
                                "id": "0",
                                "value": "---請選擇---",
                            }]);
                            newOrder_restRoomGridForm.getItem("AG004").setValue("0");

                            newOrder_restRoomGridForm_AG005 = newOrder_restRoomGridForm.getItem("AG005").getWidget();
                            newOrder_restRoomGridForm_AG005.data.removeAll();
                            newOrder_restRoomGridForm_AG005.data.parse([{
                                "id": "0",
                                "value": "---請選擇---",
                            }]);
                            newOrder_restRoomGridForm.getItem("AG005").setValue("0");

                            axios.get(apiServer+"/orders/loadSaleSourceList2",headers
                            ).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        let newOrder_restRoomGridForm_AG007 = newOrder_restRoomGridForm.getItem("AG007").getWidget();
                                        if(response.data.length > 1){
                                            newOrder_restRoomGridForm_AG007.data.removeAll();
                                            newOrder_restRoomGridForm_AG007.data.parse(response.data);
                                            newOrder_restRoomGridForm.getItem("AG007").setValue("0");
                                        }else{
                                            newOrder_restRoomGridForm_AG007.data.removeAll();
                                            newOrder_restRoomGridForm_AG007.data.parse([{
                                                "id": "0",
                                                "value": "---請選擇---",
                                            }]);
                                            newOrder_restRoomGridForm.getItem("AG007").setValue("0");
                                        }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });

                            newOrder_roomGridWindow1.attach(newOrder_restRoomGridForm);
                            newOrder_roomGridWindow1.show();
                            isNeedFirstChangeEventRest = true;
                        }

                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }
                    break;
                default:
                    break;
            }
        });

        newOrder_purchaserInfoForm.getItem("AA138").events.on("change", function(value) {
            axios.post(apiServer+"/orders/updateChangeBookingType", {
                    itemId1: TMPId
                },headers
            ).then((response) =>{
                if(response.status === 200){
                    newOrder_roomGrid.data.removeAll();
                }else{
                    DHXMessage({
                        text: "連線異常，請重新整理",
                        icon:"dxi dxi-close",
                        css: "dhx_message--error",
                        expire:5000
                    });
                }
            })
                .catch((error) => {
                    DHXMessage({
                        text: "連線異常，請重新整理",
                        icon:"dxi dxi-close",
                        css: "dhx_message--error",
                        expire:5000
                    });
                });
        });

        newOrder_purchaserInfoForm.getItem("orderSource").events.on("change", function(value) {

            if(value != "0" && value != ""){
                axios.post(apiServer+"/orders/loadSaleSourceList", {
                        itemId1: value
                    },headers
                ).then((response) =>{
                    if(response.status === 200){
                        if(response.data){
                            if(response.data.length > 1){
                                newOrder_purchaserInfoForm.getItem("saleSource").setOptions(response.data);
                                newOrder_purchaserInfoForm.getItem("saleSource").setValue("0");
                                newOrder_purchaserInfoForm.getItem("saleSource").enable();
                            }else{
                                newOrder_purchaserInfoForm.getItem("saleSource").setValue("0");
                                newOrder_purchaserInfoForm.getItem("saleSource").disable();
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }else{
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                })
                .catch((error) => {
                    DHXMessage({
                        text: "連線異常，請重新整理",
                        icon:"dxi dxi-close",
                        css: "dhx_message--error",
                        expire:5000
                    });
                });
            }else{
                newOrder_purchaserInfoForm.getItem("saleSource").setValue("0");
                newOrder_purchaserInfoForm.getItem("saleSource").disable();
            }
        });

        newOrder_roomGridToolbar.data.parse(JSON.parse(JSON.stringify(newOrder_roomGridToolbarConfig)));

        newOrderWindow1Layout1.getCell("newOrder_roomGridToolbar").attach(newOrder_roomGridToolbar);

        let newOrder_roomGrid = new DHXGrid(null,{
            columns: newOrder_roomGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "newOrder_roomGrid_edit": function (e, data) {
                        isNeedFirstChangeEventRest = false;
                        isNeedFirstChangeEvent = false;
                        // TMPId = "0";
                        if(canEdit){
                            dhxProgressWindow1.show();
                            // DHXMessage({
                            //     text: "讀取資料中，請稍後",
                            //     icon:"dxi dxi-close",
                            //     css: "dhx_message--error",
                            //     expire:5000
                            // });
                            newOrder_roomGridForm.clear();
                            newOrder_roomGridWindow1.header.data.update("title", {value: "更新房間視窗"});

                            axios.post(apiServer+"/orders/loadTMPRoomFormData", {
                                itemId1: data.row.id,itemId2:data.row.bookingType
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        switch (data.row.bookingType){
                                            case 1:

                                                newOrder_roomGridForm.setValue(response.data[0]);
                                                axios.get(apiServer+"/orders/loadSaleSourceList2",headers
                                                ).then((response2) =>{
                                                    if(response2.status === 200){
                                                        if(response2.data){
                                                            let newOrder_roomGridForm_AG007 = newOrder_roomGridForm.getItem("AG007").getWidget();
                                                            if(response2.data.length > 1){
                                                                newOrder_roomGridForm_AG007.data.removeAll();
                                                                newOrder_roomGridForm_AG007.data.parse(response2.data);
                                                            }else{
                                                                newOrder_roomGridForm_AG007.data.removeAll();
                                                                newOrder_roomGridForm_AG007.data.parse([{
                                                                    "id": "0",
                                                                    "value": "---請選擇---",
                                                                }]);
                                                            }
                                                        }else{
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        }
                                                    }else{
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                })
                                                .catch((error) => {
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                });
                                                newOrder_roomGridForm.getItem("AG001").setValue(response.data[0].AG001);
                                                newOrder_roomGridForm.getItem("AG002").setValue(response.data[0].AG002);
                                                newOrder_roomGridForm.getItem("AG003").setValue(response.data[0].AG003);
                                                newOrder_roomGridForm.getItem("AG006").setValue(response.data[0].AG006);
                                                newOrder_roomGridForm.getItem("AG119").setValue(response.data[0].AG119);
                                                newOrder_roomGridForm.getItem("AG120").setValue(response.data[0].AG120);

                                                axios.post(apiServer+"/orders/EditLoadTMPRoomTypeList", {
                                                    itemId1: hotel,itemId2: response.data[0].AG001,itemId3: response.data[0].AG119,itemId4: response.data[0].AG120
                                                },headers).then((response2) =>{
                                                    if(response2.status == 200){
                                                        if(response2.data.length != 0){
                                                            newOrder_roomGridForm_AG004 = newOrder_roomGridForm.getItem("AG004").getWidget();
                                                            newOrder_roomGridForm_AG004.data.removeAll();
                                                            newOrder_roomGridForm_AG004.data.parse(response2.data);
                                                            newOrder_roomGridForm.getItem("AG004").setValue(response.data[0].AG004);
                                                            newOrder_roomGridForm.getItem("AG004").enable();

                                                            axios.post(apiServer+"/orders/EditLoadTMPRoomList", {
                                                                itemId1: hotel,itemId2: response.data[0].AG001,itemId3: response.data[0].AG119,itemId4: response.data[0].AG120,itemId5: response.data[0].AG004
                                                            },headers).then((response3) =>{
                                                                if(response3.status === 200){
                                                                    if(response3.data.length != 0){
                                                                        newOrder_roomGridForm_AG005 = newOrder_roomGridForm.getItem("AG005").getWidget();
                                                                        newOrder_roomGridForm_AG005.data.removeAll();
                                                                        newOrder_roomGridForm_AG005.data.parse(response3.data);
                                                                        newOrder_roomGridForm.getItem("AG005").setValue(response.data[0].AG005);
                                                                        newOrder_roomGridForm.getItem("AG005").enable();

                                                                        setTimeout(()=>{
                                                                            newOrder_roomGridForm.getItem("AG127").setValue(response.data[0].AG127);
                                                                            newOrder_roomGridForm.getItem("AG116").setValue(response.data[0].AG116);
                                                                            newOrder_roomGridForm.getItem("AG127").enable();
                                                                            newOrder_roomGridForm.getItem("AG116").enable();
                                                                            if(new Date(newOrder_roomGridForm.getItem("AG120").getValue()) == new Date(newOrder_roomGridForm.getItem("AG119").getValue())){
                                                                                dayString = 1;
                                                                            }else{
                                                                                dayString = DateDiff(getDate(newOrder_roomGridForm.getItem("AG119").getValue()),getDate(newOrder_roomGridForm.getItem("AG120").getValue()));
                                                                            }
                                                                            newOrder_roomGridForm.getItem("total").setValue("共 "+dayString+" 晚，"+(Number(newOrder_roomGridForm.getItem("AG127").getValue())+Number(newOrder_roomGridForm.getItem("AG116").getValue()))+"元");
                                                                            newOrder_roomGridForm.getItem("AG007").setValue(response.data[0].AG008);
                                                                            newOrder_roomGridWindow1.attach(newOrder_roomGridForm);
                                                                            newOrder_roomGridWindow1.show();
                                                                            dhxProgressWindow1.hide();
                                                                            isNeedFirstChangeEvent = true;
                                                                        },1000)
                                                                    }
                                                                }
                                                            })
                                                                .catch((error) => {
                                                                    DHXMessage({
                                                                        text: "連線異常，請重新整理",
                                                                        icon:"dxi dxi-close",
                                                                        css: "dhx_message--danger",
                                                                        expire:5000
                                                                    });
                                                                });
                                                        }
                                                    }
                                                })
                                                    .catch((error) => {
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--danger",
                                                            expire:5000
                                                        });
                                                    });
                                                break;
                                            case 2:
                                                break;
                                            case 3:

                                                newOrder_restRoomGridForm.setValue(response.data[0]);
                                                axios.get(apiServer+"/orders/loadSaleSourceList2",headers
                                                ).then((response) =>{
                                                    if(response.status === 200){
                                                        if(response.data){
                                                            let newOrder_restRoomGridForm_AG007 = newOrder_restRoomGridForm.getItem("AG007").getWidget();
                                                            if(response.data.length > 1){
                                                                newOrder_restRoomGridForm_AG007.data.removeAll();
                                                                newOrder_restRoomGridForm_AG007.data.parse(response.data);
                                                            }else{
                                                                newOrder_restRoomGridForm_AG007.data.removeAll();
                                                                newOrder_restRoomGridForm_AG007.data.parse([{
                                                                    "id": "0",
                                                                    "value": "---請選擇---",
                                                                }]);
                                                            }
                                                        }else{
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        }
                                                    }else{
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                })
                                                .catch((error) => {
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                });

                                                newOrder_restRoomGridForm.getItem("AG001").setValue(response.data[0].AG001);
                                                newOrder_restRoomGridForm.getItem("AG002").setValue(response.data[0].AG002);
                                                newOrder_restRoomGridForm.getItem("AG003").setValue(response.data[0].AG003);
                                                newOrder_restRoomGridForm.getItem("AG119").setValue(response.data[0].AG119);

                                                if(response.data[0].AG132 === 3 || (response.data[0].AG132 === 2 && (response.data[0].AG133 === 1 || response.data[0].AG133 === 2))){
                                                    if(response.data[0].AG133 === 1){
                                                        newOrder_restRoomGridForm.getItem("AA103_1").setValue("1");
                                                        newOrder_restRoomGridForm.getItem("AA104_1").setValue(`${response.data[0].AG134}`);
                                                        newOrder_restRoomGridForm.getItem("AA104_1").enable();
                                                        newOrder_restRoomGridForm.getItem("AA104_2").disable();
                                                    }else{
                                                        newOrder_restRoomGridForm.getItem("AA103_2").setValue("1");
                                                        newOrder_restRoomGridForm.getItem("AA104_2").setValue(`${response.data[0].AG134}`);
                                                        newOrder_restRoomGridForm.getItem("AA104_2").enable();
                                                        newOrder_restRoomGridForm.getItem("AA104_1").disable();
                                                    }
                                                }

                                                axios.post(apiServer+"/orders/EditLoadTMPRestRoomTypeList", {
                                                    itemId1: hotel,itemId2: response.data[0].AG001
                                                },headers).then((response2) =>{
                                                    if(response2){
                                                        newOrder_restRoomGridForm_AG004 = newOrder_restRoomGridForm.getItem("AG004").getWidget();
                                                        newOrder_restRoomGridForm_AG004.data.removeAll();
                                                        newOrder_restRoomGridForm_AG004.data.parse(response2.data);
                                                        newOrder_restRoomGridForm.getItem("AG004").setValue(response.data[0].AG004);
                                                        newOrder_restRoomGridForm.getItem("AG004").enable();

                                                        axios.post(apiServer+"/orders/EditLoadTMPRestRoomList", {
                                                            itemId1: hotel,itemId2: response.data[0].AG001,itemId3: response.data[0].AG119,itemId4: AA103,itemId5:AA104,itemId6:response.data[0].AG004
                                                        },headers).then((response3) =>{
                                                            if(response3){

                                                                newOrder_restRoomGridForm_AG005 = newOrder_restRoomGridForm.getItem("AG005").getWidget();
                                                                newOrder_restRoomGridForm_AG005.data.removeAll();
                                                                newOrder_restRoomGridForm_AG005.data.parse(response3.data);
                                                                newOrder_restRoomGridForm.getItem("AG005").setValue(response.data[0].AG005);
                                                                newOrder_restRoomGridForm.getItem("AG005").enable();

                                                                // setTimeout(()=>{
                                                                newOrder_restRoomGridForm.getItem("AG127").setValue(response.data[0].AG127);
                                                                newOrder_restRoomGridForm.getItem("AG127").enable();

                                                                newOrder_restRoomGridForm.getItem("total").setValue(response.data[0].price);
                                                                newOrder_restRoomGridForm.getItem("AG007").setValue(response.data[0].AG008);

                                                                newOrder_roomGridWindow1.attach(newOrder_restRoomGridForm);
                                                                newOrder_roomGridWindow1.show();
                                                                dhxProgressWindow1.hide();
                                                                isNeedFirstChangeEventRest = true;
                                                                // },1000)
                                                            }
                                                        })
                                                            .catch((error) => {
                                                                DHXMessage({
                                                                    text: "連線異常，請重新整理",
                                                                    icon:"dxi dxi-close",
                                                                    css: "dhx_message--danger",
                                                                    expire:5000
                                                                });
                                                            });
                                                    }

                                                })
                                                    .catch((error) => {
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--danger",
                                                            expire:5000
                                                        });
                                                    });
                                                break;
                                        }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--danger",
                                    expire:5000
                                });
                            });

                            newOrder_roomGridForm.getItem("AG001").setValue(data.row.id)
                            newOrder_roomGridForm.setValue({"AG001":data.row.id,"AG002":orderForm.getItem("AA001").getValue()});

                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "newOrder_roomGrid_delete": function (e, data) {
                        if(canDelete){
                            axios.post(apiServer+"/orders/deleteTMPRoom", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        axios.post(apiServer+"/orders/loadTMPRoomGrid", {
                                            itemId1: TMPId
                                        },headers).then((data) =>{
                                            if(data.status === 200){
                                                if(data.data){
                                                    newOrder_roomGrid.data.removeAll();
                                                    newOrder_roomGrid.data.parse(data.data);

                                                    newOrder_roomGridForm.clear();
                                                    newOrder_roomGridWindow1.hide();
                                                }else{
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }
                                            }else{
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        });
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });

        newOrderWindow1Layout1.getCell("newOrder_roomGrid").attach(newOrder_roomGrid);

        //編輯訂單視窗_產品資訊_房間_新增/編輯房間視窗
        const newOrder_roomGridWindow1 = new DHXWindow({
            title: "新增房間視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 460,
            footer: true,
            css:"newOrder_roomGridWindow1"
        });

        newOrder_roomGridWindow1.footer.data.add({type:"spacer"});
        newOrder_roomGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        newOrder_roomGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        newOrder_roomGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    if(newOrder_purchaserInfoForm.getItem("AA138").getValue() == 1){
                        if(newOrder_roomGridForm.getItem("AG119").getValue() === ""){
                            DHXMessage({
                                text: "請選擇入住日期",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        if(newOrder_roomGridForm.getItem("AG120").getValue() === ""){
                            DHXMessage({
                                text: "請選擇退房日期",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        if(Date.parse(newOrder_roomGridForm.getItem("AG119").getValue()).valueOf() > Date.parse(newOrder_roomGridForm.getItem("AG120").getValue()).valueOf()){
                            DHXMessage({
                                text: "入住日期必須早於退房日期",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        newOrder_roomGridForm_AG004 = newOrder_roomGridForm.getItem("AG004").getWidget();
                        if(newOrder_roomGridForm_AG004.getValue() === "0" || newOrder_roomGridForm_AG004.getValue() === ""){
                            DHXMessage({
                                text: "請選擇房型",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        newOrder_roomGridForm_AG005 = newOrder_roomGridForm.getItem("AG005").getWidget();
                        if(newOrder_roomGridForm_AG005.getValue() === "0" || newOrder_roomGridForm_AG005.getValue() === ""){
                            DHXMessage({
                                text: "請選擇房間名稱",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        if(newOrder_roomGridForm.getItem("AG127").getValue() === ""){
                            DHXMessage({
                                text: "請填入金額",
                                icon:"dxi dxi-close",
                                css: "dhx_message--",
                                expire:5000
                            });
                            break;
                        }

                        axios.post(apiServer+"/orders/updateTMPRoomFormData",
                            newOrder_roomGridForm.getValue(),headers
                        ).then((response) =>{
                            if(response.data[0].result){
                                axios.post(apiServer+"/orders/loadTMPRoomGrid", {
                                    itemId1: TMPId
                                },headers).then((data) =>{
                                    newOrder_roomGrid.data.parse(data.data);
                                });

                                newOrder_roomGridForm.clear();
                                newOrder_roomGridWindow1.hide();
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                    }else if(newOrder_purchaserInfoForm.getItem("AA138").getValue() == 3){
                        if(newOrder_restRoomGridForm.getItem("AG119").getValue() === ""){
                            DHXMessage({
                                text: "請選擇入住日期",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        if(newOrder_restRoomGridForm.getItem("AA103_1").getValue() === "" && newOrder_restRoomGridForm.getItem("AA103_2").getValue() === "" ){
                            DHXMessage({
                                text: "請設定分鐘或小時",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        newOrder_restRoomGridForm_AG004 = newOrder_restRoomGridForm.getItem("AG004").getWidget();
                        if(newOrder_restRoomGridForm_AG004.getValue() === "0" || newOrder_restRoomGridForm_AG004.getValue() === ""){
                            DHXMessage({
                                text: "請選擇房型",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        newOrder_restRoomGridForm_AG005 = newOrder_restRoomGridForm.getItem("AG005").getWidget();
                        if(newOrder_restRoomGridForm_AG005.getValue() === "0" || newOrder_restRoomGridForm_AG005.getValue() === ""){
                            DHXMessage({
                                text: "請選擇房間名稱",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        if(newOrder_restRoomGridForm.getItem("AG127").getValue() === ""){
                            DHXMessage({
                                text: "請填入金額",
                                icon:"dxi dxi-close",
                                css: "dhx_message--",
                                expire:5000
                            });
                            break;
                        }

                        axios.post(apiServer+"/orders/updateTMPRoomFormData",
                            newOrder_restRoomGridForm.getValue(),headers
                        ).then((response) =>{
                            if(response.data[0].result){
                                axios.post(apiServer+"/orders/loadTMPRoomGrid", {
                                    itemId1: TMPId
                                },headers).then((data) =>{
                                    newOrder_roomGrid.data.parse(data.data);
                                });

                                newOrder_roomGridForm.clear();
                                newOrder_roomGridWindow1.hide();
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                    }
                    break;

                case "WINDOW_FOOT_CLOSE":
                    newOrder_roomGridWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const newOrder_roomGridForm = new DHXForm(null,{
            rows: newOrder_roomGridFormConfig
        });

        let dayString2 = '',totalString2 = '';

        newOrder_roomGridForm.events.on("change", function(name, value) {
            if(TMPId !== "0"){
                //新增
                switch (name){
                    case "AG119":
                    case "AG120":
                        let newOrder_roomGridForm_AG119 = newOrder_roomGridForm.getItem("AG119").getValue();
                        let newOrder_roomGridForm_AG120 = newOrder_roomGridForm.getItem("AG120").getValue();
                        if(newOrder_roomGridForm_AG119 != '' && newOrder_roomGridForm_AG120 != ''){
                            if(Date.parse(newOrder_roomGridForm_AG119).valueOf() > Date.parse(newOrder_roomGridForm_AG120).valueOf()){

                                newOrder_roomGridForm.getItem("AG004").disable();
                                newOrder_roomGridForm.getItem("AG004").setValue("0");
                                newOrder_roomGridForm.getItem("AG005").disable();
                                newOrder_roomGridForm.getItem("AG005").setValue("0");
                                newOrder_roomGridForm.getItem("AG127").disable();
                                newOrder_roomGridForm.getItem("AG127").setValue("0");
                                newOrder_roomGridForm.getItem("AG116").disable();
                                newOrder_roomGridForm.getItem("AG116").setValue("0");
                                DHXMessage({
                                    text: "入住日期必須早於退房日期",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }else{

                                if(isNeedFirstChangeEvent){
                                    dhxProgressWindow1.show();
                                    axios.post(apiServer+"/orders/loadRoomTypeList", {
                                        itemId1: hotel,itemId2: newOrder_roomGridForm.getItem("AG001").getValue(),itemId3: newOrder_roomGridForm.getItem("AG119").getValue(),itemId4: newOrder_roomGridForm.getItem("AG120").getValue(),itemId5:TMPId
                                    },headers).then((response) =>{
                                        if(response.status === 200){
                                            if(response.data){
                                                newOrder_roomGridForm_AG004 = newOrder_roomGridForm.getItem("AG004").getWidget();
                                                newOrder_roomGridForm_AG004.data.removeAll();
                                                newOrder_roomGridForm_AG004.data.parse(response.data);
                                                newOrder_roomGridForm.getItem("AG004").setValue("0");
                                                dhxProgressWindow1.hide();
                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    })
                                        .catch((error) => {
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        });
                                }

                                if(new Date(newOrder_roomGridForm.getItem("AG120").getValue()) == new Date(newOrder_roomGridForm.getItem("AG119").getValue())){
                                    dayString2 = 1;
                                }else{
                                    dayString2 = DateDiff(getDate(newOrder_roomGridForm.getItem("AG119").getValue()),getDate(newOrder_roomGridForm.getItem("AG120").getValue()));
                                }

                                newOrder_roomGridForm.getItem("total").setValue("共 "+dayString2+" 晚");

                                newOrder_roomGridForm.getItem("AG004").enable();
                                newOrder_roomGridForm.getItem("AG004").setValue("0");
                                newOrder_roomGridForm.getItem("AG005").disable();
                                newOrder_roomGridForm.getItem("AG005").setValue("0");
                                newOrder_roomGridForm.getItem("AG127").disable();
                                newOrder_roomGridForm.getItem("AG127").setValue("0");
                                newOrder_roomGridForm.getItem("AG116").disable();
                                newOrder_roomGridForm.getItem("AG116").setValue("0");
                            }
                        }else{

                            newOrder_roomGridForm.getItem("total").setValue("");
                            newOrder_roomGridForm.getItem("AG004").disable();
                            newOrder_roomGridForm.getItem("AG004").setValue("0");
                            newOrder_roomGridForm.getItem("AG005").disable();
                            newOrder_roomGridForm.getItem("AG005").setValue("0");
                            newOrder_roomGridForm.getItem("AG127").disable();
                            newOrder_roomGridForm.getItem("AG127").setValue("0");
                            newOrder_roomGridForm.getItem("AG116").disable();
                            newOrder_roomGridForm.getItem("AG116").setValue("0");
                        }
                        break;
                    case "AG004":
                        if(newOrder_roomGridForm.getItem("AG004").getValue() != '0' && newOrder_roomGridForm.getItem("AG004").getValue() != ''){

                            if(isNeedFirstChangeEvent){
                                dhxProgressWindow1.show();
                                axios.post(apiServer+"/orders/loadRoomList", {
                                    itemId1: hotel,itemId2: newOrder_roomGridForm.getItem("AG001").getValue(),itemId3: newOrder_roomGridForm.getItem("AG119").getValue(),itemId4: newOrder_roomGridForm.getItem("AG120").getValue(),itemId5: newOrder_roomGridForm.getItem("AG004").getValue(),itemId6: TMPId
                                },headers).then((response) =>{
                                    if(response.status === 200){
                                        if(response.data){
                                            newOrder_roomGridForm_AG005 = newOrder_roomGridForm.getItem("AG005").getWidget();
                                            newOrder_roomGridForm_AG005.data.removeAll();
                                            newOrder_roomGridForm_AG005.data.parse(response.data);
                                            dhxProgressWindow1.hide();
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                            }
                            newOrder_roomGridForm.getItem("AG005").enable();
                            newOrder_roomGridForm.getItem("AG005").setValue("0");
                            newOrder_roomGridForm.getItem("AG127").disable();
                            newOrder_roomGridForm.getItem("AG127").setValue("0");
                            newOrder_roomGridForm.getItem("AG116").disable();
                            newOrder_roomGridForm.getItem("AG116").setValue("0");
                        }else{
                            newOrder_roomGridForm.getItem("AG005").disable();
                            newOrder_roomGridForm.getItem("AG005").setValue("0");
                            newOrder_roomGridForm.getItem("AG127").disable();
                            newOrder_roomGridForm.getItem("AG127").setValue("0");
                            newOrder_roomGridForm.getItem("AG116").disable();
                            newOrder_roomGridForm.getItem("AG116").setValue("0");
                        }
                        break;
                    case "AG005":
                        if(newOrder_roomGridForm.getItem("AG005").getValue() != '0' && newOrder_roomGridForm.getItem("AG005").getValue() != ''){
                            if(isNeedFirstChangeEvent){
                                dhxProgressWindow1.show();
                                axios.post(apiServer+"/orders/loadRoomPrices", {
                                    itemId1: hotel,itemId2: newOrder_roomGridForm.getItem("AG001").getValue(),itemId3: newOrder_roomGridForm.getItem("AG119").getValue(),itemId4: newOrder_roomGridForm.getItem("AG120").getValue(),itemId5: newOrder_roomGridForm.getItem("AG004").getValue(),itemId6: newOrder_roomGridForm.getItem("AG005").getValue()
                                },headers).then((response) =>{
                                    if(response.status === 200){
                                        if(response.data){
                                            newOrder_roomGridForm.getItem("AG127").setValue(response.data);
                                            newOrder_roomGridForm.getItem("AG127").enable();
                                            newOrder_roomGridForm.getItem("AG116").enable();
                                            dhxProgressWindow1.hide();
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }
                        }
                        break;
                    case "AG116":
                    case "AG127":
                        newOrder_roomGridForm.getItem("total").setValue("共 "+dayString2+" 晚，"+(Number(newOrder_roomGridForm.getItem("AG127").getValue())+Number(newOrder_roomGridForm.getItem("AG116").getValue()))+"元");
                        break;
                }
            }
        });

        let newOrder_roomGridFormCheckInDate = newOrder_roomGridForm.getItem("AG119").getWidget();
        let newOrder_roomGridFormCheckOutDate = newOrder_roomGridForm.getItem("AG120").getWidget();

        newOrder_roomGridFormCheckInDate.link(newOrder_roomGridFormCheckOutDate)

        newOrder_roomGridWindow1.attach(newOrder_roomGridForm);

        const newOrder_restRoomGridForm = new DHXForm(null,{
            rows: newOrder_restRoomGridFormConfig
        });

        newOrder_restRoomGridForm.events.on("change", function(name, value) {
            if(TMPId !== "0"){
                //新增
                switch (name){
                    case "AG119":
                        newOrder_restRoomGridForm.getItem("AG004").enable();
                        newOrder_restRoomGridForm.getItem("AG004").setValue("0");
                        newOrder_restRoomGridForm.getItem("AG005").disable();
                        newOrder_restRoomGridForm.getItem("AG005").setValue("0");
                        newOrder_restRoomGridForm.getItem("AG007").setValue("0");
                        newOrder_restRoomGridForm.getItem("AG127").disable();
                        newOrder_restRoomGridForm.getItem("AG127").setValue("");
                        newOrder_restRoomGridForm.getItem("total").setValue("");

                        if(isNeedFirstChangeEventRest){
                            dhxProgressWindow1.show();
                            axios.post(apiServer+"/orders/loadRestRoomTypeList", {
                                itemId1: hotel,itemId2: newOrder_restRoomGridForm.getItem("AG001").getValue(),itemId3: newOrder_restRoomGridForm.getItem("AG119").getValue(),itemId4:AA103,itemId5:AA104,itemId6:TMPId
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        dhxProgressWindow1.hide();
                                        newOrder_restRoomGridForm_AG004 = newOrder_restRoomGridForm.getItem("AG004").getWidget();
                                        newOrder_restRoomGridForm_AG004.data.removeAll();
                                        newOrder_restRoomGridForm_AG004.data.parse(response.data);
                                        newOrder_restRoomGridForm.getItem("AG004").setValue("0");
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                        }
                        break;
                    case "AA103_1":
                        if(value == ""){
                            restTypeText = '';
                            newOrder_restRoomGridForm.getItem("AA104_1").disable();
                        }else{
                            restTypeText = '分鐘';
                            newOrder_restRoomGridForm.getItem("AA104_1").enable();
                            newOrder_restRoomGridForm.getItem("AA104_2").disable();
                            newOrder_restRoomGridForm.getItem("AA103_2").clear();
                        }

                        if (newOrder_restRoomGridForm.getItem("AG119").getValue() == ""){
                            newOrder_restRoomGridForm.getItem("AG004").disable();
                        }else{
                            newOrder_restRoomGridForm.getItem("AG004").enable();
                        }
                        newOrder_restRoomGridForm.getItem("AG004").setValue("0");
                        newOrder_restRoomGridForm.getItem("AG005").disable();
                        newOrder_restRoomGridForm.getItem("AG005").setValue("0");
                        newOrder_restRoomGridForm.getItem("AG127").disable();
                        newOrder_restRoomGridForm.getItem("AG127").setValue("");
                        newOrder_restRoomGridForm.getItem("total").setValue("");
                        break;
                    case "AA103_2":
                        if(value == ""){
                            restTypeText = '';
                            newOrder_restRoomGridForm.getItem("AA104_2").disable();
                        }else{
                            restTypeText = '小時';
                            newOrder_restRoomGridForm.getItem("AA104_1").disable();
                            newOrder_restRoomGridForm.getItem("AA104_2").enable();
                            newOrder_restRoomGridForm.getItem("AA103_1").clear();
                        }

                        if (newOrder_restRoomGridForm.getItem("AG119").getValue() == ""){
                            newOrder_restRoomGridForm.getItem("AG004").disable();
                        }else{
                            newOrder_restRoomGridForm.getItem("AG004").enable();
                        }
                        newOrder_restRoomGridForm.getItem("AG004").setValue("0");
                        newOrder_restRoomGridForm.getItem("AG005").disable();
                        newOrder_restRoomGridForm.getItem("AG005").setValue("0");
                        newOrder_restRoomGridForm.getItem("AG127").disable();
                        newOrder_restRoomGridForm.getItem("AG127").setValue("");
                        newOrder_restRoomGridForm.getItem("total").setValue("");
                        break;
                    case "AA104_1":
                        if(value == ""){
                        }else{

                        }

                        if (newOrder_restRoomGridForm.getItem("AG119").getValue() == ""){
                            newOrder_restRoomGridForm.getItem("AG004").disable();
                        }else{
                            newOrder_restRoomGridForm.getItem("AG004").enable();
                        }
                        newOrder_restRoomGridForm.getItem("AG004").setValue("0");
                        newOrder_restRoomGridForm.getItem("AG005").disable();
                        newOrder_restRoomGridForm.getItem("AG005").setValue("0");
                        newOrder_restRoomGridForm.getItem("AG127").disable();
                        newOrder_restRoomGridForm.getItem("AG127").setValue("");
                        newOrder_restRoomGridForm.getItem("total").setValue("");
                        break;
                    case "AA104_2":
                        if(value == ""){
                            newOrder_restRoomGridForm.getItem("AA104_2").disable();
                        }else{
                            newOrder_restRoomGridForm.getItem("AA104_1").disable();
                            newOrder_restRoomGridForm.getItem("AA104_2").enable();
                            newOrder_restRoomGridForm.getItem("AA103_1").clear();
                        }

                        if (newOrder_restRoomGridForm.getItem("AG119").getValue() == ""){
                            newOrder_restRoomGridForm.getItem("AG004").disable();
                        }else{
                            newOrder_restRoomGridForm.getItem("AG004").enable();
                        }
                        newOrder_restRoomGridForm.getItem("AG004").setValue("0");
                        newOrder_restRoomGridForm.getItem("AG005").disable();
                        newOrder_restRoomGridForm.getItem("AG005").setValue("0");
                        newOrder_restRoomGridForm.getItem("AG127").disable();
                        newOrder_restRoomGridForm.getItem("AG127").setValue("");
                        newOrder_restRoomGridForm.getItem("total").setValue("");
                        break;
                    case "AG004":
                        if(value == "0" || value == ""){
                            newOrder_restRoomGridForm.getItem("AG005").disable();
                            newOrder_restRoomGridForm.getItem("AG005").setValue("0");
                            newOrder_restRoomGridForm.getItem("AG127").disable();
                            newOrder_restRoomGridForm.getItem("AG127").setValue("");
                            newOrder_restRoomGridForm.getItem("total").setValue("");
                        }else{
                            newOrder_restRoomGridForm.getItem("AG005").enable();
                            newOrder_restRoomGridForm.getItem("AG005").setValue("0");
                            newOrder_restRoomGridForm.getItem("AG127").disable();
                            newOrder_restRoomGridForm.getItem("AG127").setValue("");
                            newOrder_restRoomGridForm.getItem("total").setValue("");

                            if(newOrder_restRoomGridForm.getItem("AA103_1").getValue() == 1){
                                AA103 = 1;
                                AA104 = newOrder_restRoomGridForm.getItem("AA104_1").getValue();
                                restTypeText = '分鐘';
                            }else{
                                AA103 = 2;
                                AA104 = newOrder_restRoomGridForm.getItem("AA104_2").getValue();
                                restTypeText = '小時';
                            }

                            if(isNeedFirstChangeEventRest){
                                dhxProgressWindow1.show();
                                axios.post(apiServer+"/orders/loadRestRoomList", {
                                    itemId1: hotel,itemId2: newOrder_restRoomGridForm.getItem("AG001").getValue(),itemId3: newOrder_restRoomGridForm.getItem("AG119").getValue(),itemId4:AA103,itemId5:AA104,itemId6:value,itemId7:TMPId
                                },headers).then((response) =>{
                                    if(response.status === 200){
                                        if(response.data){
                                            newOrder_restRoomGridForm_AG005 = newOrder_restRoomGridForm.getItem("AG005").getWidget();
                                            newOrder_restRoomGridForm_AG005.data.removeAll();
                                            newOrder_restRoomGridForm_AG005.data.parse(response.data);
                                            dhxProgressWindow1.hide();
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }
                        }
                        break;
                    case "AG005":
                        if(value == "0" || value == ""){

                        }else{

                            let AG004 = '';
                            if(newOrder_restRoomGridForm.getItem("AA103_1").getValue() == 1){
                                AA103 = 1;
                                AA104 = newOrder_restRoomGridForm.getItem("AA104_1").getValue();
                                restTypeText = '分鐘';
                                AG004 = newOrder_restRoomGridForm.getItem("AG004").getValue();
                            }else{
                                AA103 = 2;
                                AA104 = newOrder_restRoomGridForm.getItem("AA104_2").getValue();
                                restTypeText = '小時';
                                AG004 = newOrder_restRoomGridForm.getItem("AG004").getValue();
                            }

                            if(isNeedFirstChangeEventRest){
                                dhxProgressWindow1.show();
                                axios.post(apiServer+"/orders/loadRestRoomPrices", {
                                    itemId1: hotel,itemId2: newOrder_restRoomGridForm.getItem("AG001").getValue(),itemId3: newOrder_restRoomGridForm.getItem("AG119").getValue(),itemId4:AA103,itemId5:AA104,itemId6:AG004,itemId7:value
                                },headers).then((response) =>{
                                    if(response.status === 200){
                                        if(response.data){
                                            newOrder_restRoomGridForm.getItem("AG127").setValue(response.data);

                                            newOrder_restRoomGridForm.getItem("AG127").enable();

                                            newOrder_restRoomGridForm.getItem("total").setValue(`共 ${AA104} ${restTypeText}，${response.data} 元`);
                                            dhxProgressWindow1.hide();
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }

                        }
                        break;
                    case "AG127":
                        if(newOrder_restRoomGridForm.getItem("AG005").getValue() == "0"){
                            price = Number(newOrder_roomGridForm.getItem("AG127").getValue());

                            newOrder_restRoomGridForm.getItem("AG127").setValue("0");
                            // newOrder_restRoomGridForm.getItem("AG116").setValue("0");
                            newOrder_restRoomGridForm.getItem("total").setValue("");

                            newOrder_restRoomGridForm.getItem("total").setValue(`共 ${AA104} ${restTypeText}，${value} 元`);
                        }else{

                        }
                        break;
                }
            }else{
                //修改
                switch (name){
                    case "AG119":
                        if(isNeedFirstChangeEventRest){
                            newOrder_restRoomGridForm.getItem("AG004").enable();
                            newOrder_restRoomGridForm.getItem("AG004").setValue("0");
                            newOrder_restRoomGridForm.getItem("AG005").disable();
                            newOrder_restRoomGridForm.getItem("AG005").setValue("0");
                            newOrder_restRoomGridForm.getItem("AG127").disable();
                            newOrder_restRoomGridForm.getItem("AG127").setValue("");
                            newOrder_restRoomGridForm.getItem("total").setValue("");
                        }else{

                        }
                        break;
                    case "AA103_1":
                        if(isNeedFirstChangeEventRest){
                            AA103 = 1;
                            AA104 = newOrder_restRoomGridForm.getItem("AA104_1").getValue();
                            if(value == ""){
                                restTypeText = '';
                                newOrder_restRoomGridForm.getItem("AA104_1").disable();
                            }else{
                                restTypeText = '分鐘';
                                newOrder_restRoomGridForm.getItem("AA104_1").enable();
                                newOrder_restRoomGridForm.getItem("AA104_2").disable();
                                newOrder_restRoomGridForm.getItem("AA103_2").clear();
                            }

                            if (newOrder_restRoomGridForm.getItem("AG119").getValue() == ""){
                                newOrder_restRoomGridForm.getItem("AG004").disable();
                            }else{
                                newOrder_restRoomGridForm.getItem("AG004").enable();
                            }
                            newOrder_restRoomGridForm.getItem("AG004").setValue("0");
                            newOrder_restRoomGridForm.getItem("AG005").disable();
                            newOrder_restRoomGridForm.getItem("AG005").setValue("0");
                            newOrder_restRoomGridForm.getItem("AG127").disable();
                            newOrder_restRoomGridForm.getItem("AG127").setValue("");
                            newOrder_restRoomGridForm.getItem("total").setValue("");
                        }else{

                        }

                        break;
                    case "AA103_2":
                        if(isNeedFirstChangeEventRest){
                            AA103 = 2;
                            AA104 = newOrder_restRoomGridForm.getItem("AA104_2").getValue();
                            if(value == ""){
                                restTypeText = '';
                                newOrder_restRoomGridForm.getItem("AA104_2").disable();
                            }else{
                                restTypeText = '小時';
                                newOrder_restRoomGridForm.getItem("AA104_1").disable();
                                newOrder_restRoomGridForm.getItem("AA104_2").enable();
                                newOrder_restRoomGridForm.getItem("AA103_1").clear();
                            }

                            if (newOrder_restRoomGridForm.getItem("AG119").getValue() == ""){
                                newOrder_restRoomGridForm.getItem("AG004").disable();
                            }else{
                                newOrder_restRoomGridForm.getItem("AG004").enable();
                            }
                            newOrder_restRoomGridForm.getItem("AG004").setValue("0");
                            newOrder_restRoomGridForm.getItem("AG005").disable();
                            newOrder_restRoomGridForm.getItem("AG005").setValue("0");
                            newOrder_restRoomGridForm.getItem("AG127").disable();
                            newOrder_restRoomGridForm.getItem("AG127").setValue("");
                            newOrder_restRoomGridForm.getItem("total").setValue("");
                        }else{

                        }

                        break;
                    case "AA104_1":
                        if(isNeedFirstChangeEventRest){
                            AA103 = 1;
                            AA104 = newOrder_restRoomGridForm.getItem("AA104_1").getValue();
                            if(value == ""){
                            }else{

                            }

                            if (newOrder_restRoomGridForm.getItem("AG119").getValue() == ""){
                                newOrder_restRoomGridForm.getItem("AG004").disable();
                            }else{
                                newOrder_restRoomGridForm.getItem("AG004").enable();
                            }
                            newOrder_restRoomGridForm.getItem("AG004").setValue("0");
                            newOrder_restRoomGridForm.getItem("AG005").disable();
                            newOrder_restRoomGridForm.getItem("AG005").setValue("0");
                            newOrder_restRoomGridForm.getItem("AG127").disable();
                            newOrder_restRoomGridForm.getItem("AG127").setValue("");
                            newOrder_restRoomGridForm.getItem("total").setValue("");
                        }else{

                        }

                        break;
                    case "AA104_2":
                        if(isNeedFirstChangeEventRest){
                            AA103 = 2;
                            AA104 = newOrder_restRoomGridForm.getItem("AA104_2").getValue();
                            if(value == ""){
                                newOrder_restRoomGridForm.getItem("AA104_2").disable();
                            }else{
                                newOrder_restRoomGridForm.getItem("AA104_1").disable();
                                newOrder_restRoomGridForm.getItem("AA104_2").enable();
                                newOrder_restRoomGridForm.getItem("AA103_1").clear();
                            }

                            if (newOrder_restRoomGridForm.getItem("AG119").getValue() == ""){
                                newOrder_restRoomGridForm.getItem("AG004").disable();
                            }else{
                                newOrder_restRoomGridForm.getItem("AG004").enable();
                            }
                            newOrder_restRoomGridForm.getItem("AG004").setValue("0");
                            newOrder_restRoomGridForm.getItem("AG005").disable();
                            newOrder_restRoomGridForm.getItem("AG005").setValue("0");
                            newOrder_restRoomGridForm.getItem("AG127").disable();
                            newOrder_restRoomGridForm.getItem("AG127").setValue("");
                            newOrder_restRoomGridForm.getItem("total").setValue("");
                        }else{

                        }

                        break;
                    case "AG004":
                        if(isNeedFirstChangeEventRest){
                            if(value == "0" || value == ""){
                                newOrder_restRoomGridForm.getItem("AG005").disable();
                                newOrder_restRoomGridForm.getItem("AG005").setValue("0");
                                newOrder_restRoomGridForm.getItem("AG127").disable();
                                newOrder_restRoomGridForm.getItem("AG127").setValue("");
                                newOrder_restRoomGridForm.getItem("total").setValue("");
                            }else{
                                newOrder_restRoomGridForm.getItem("AG005").enable();
                                newOrder_restRoomGridForm.getItem("AG005").setValue("0");
                                newOrder_restRoomGridForm.getItem("AG127").disable();
                                newOrder_restRoomGridForm.getItem("AG127").setValue("");
                                newOrder_restRoomGridForm.getItem("total").setValue("");

                                if(newOrder_restRoomGridForm.getItem("AA103_1").getValue() == 1){
                                    AA103 = 1;
                                    AA104 = newOrder_restRoomGridForm.getItem("AA104_1").getValue();
                                    restTypeText = '分鐘';
                                }else{
                                    AA103 = 2;
                                    AA104 = newOrder_restRoomGridForm.getItem("AA104_2").getValue();
                                    restTypeText = '小時';
                                }

                                dhxProgressWindow1.show();
                                axios.post(apiServer+"/orders/EditloadRestRoomList", {
                                    itemId1: hotel,itemId2: newOrder_restRoomGridForm.getItem("AG001").getValue(),itemId3: newOrder_restRoomGridForm.getItem("AG119").getValue(),itemId4:AA103,itemId5:AA104,itemId6:value
                                },headers).then((response) =>{
                                    if(response.status === 200){
                                        if(response.data){
                                            newOrder_restRoomGridForm_AG005 = newOrder_restRoomGridForm.getItem("AG005").getWidget();
                                            newOrder_restRoomGridForm_AG005.data.removeAll();
                                            newOrder_restRoomGridForm_AG005.data.parse(response.data);
                                            newOrder_restRoomGridForm.getItem("AG005").setValue("0");
                                            dhxProgressWindow1.hide();
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }
                        }else{

                        }

                        break;
                    case "AG005":
                        if(isNeedFirstChangeEventRest){
                            if(value == "0" || value == ""){

                            }else{

                                dhxProgressWindow1.show();
                                let AG004 = newOrder_restRoomGridForm.getItem("AG004").getValue();
                                axios.post(apiServer+"/orders/loadRestRoomPrices", {
                                    itemId1: hotel,itemId2: newOrder_restRoomGridForm.getItem("AG001").getValue(),itemId3: newOrder_restRoomGridForm.getItem("AG119").getValue(),itemId4:AA103,itemId5:AA104,itemId6:AG004,itemId7:value
                                },headers).then((response) =>{
                                    if(response.status === 200){
                                        if(response.data){
                                            newOrder_restRoomGridForm.getItem("AG127").setValue(response.data);

                                            newOrder_restRoomGridForm.getItem("AG127").enable();

                                            newOrder_restRoomGridForm.getItem("total").setValue(`共 ${AA104} ${restTypeText}，${response.data} 元`);
                                            dhxProgressWindow1.hide();
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }
                        }

                        break;
                    case "AG127":
                        if(isNeedFirstChangeEventRest){
                            if(newOrder_restRoomGridForm.getItem("AG005").getValue() == "0"){
                                price = Number(newOrder_roomGridForm.getItem("AG127").getValue());

                                newOrder_restRoomGridForm.getItem("AG127").setValue("0");
                                // newOrder_restRoomGridForm.getItem("AG116").setValue("0");
                                newOrder_restRoomGridForm.getItem("total").setValue("");

                                newOrder_restRoomGridForm.getItem("total").setValue(`共 ${AA104} ${restTypeText}，${value} 元`);
                            }
                        }
                        break;
                }
            }

        });

        //新增訂單視窗_付款紀錄
        let newOrder_payLogGrid = new DHXGrid(null,{
            columns: newOrder_payLogGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "newOrder_payLogGrid_edit": function (e, data) {
                        if(canEdit){

                            dhxNewOrder_payLogGridWindow1.header.data.update("title", {value: "編輯付款紀錄視窗"});
                            axios.post(apiServer+"/orders/loadTMPpayLogGridFormData", {
                                itemId1: data.row.id
                            },headers).then((response1) =>{
                                if(response1.status === 200) {
                                    newOrder_payLogGridForm.setValue(response1.data[0]);
                                    dhxNewOrder_payLogGridWindow1.show();
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "newOrder_payLogGrid_delete": function (e, data) {
                        if(canDelete){
                            axios.post(apiServer+"/orders/deleteTMPPayLog", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                if(response.status == 200){
                                    axios.post(apiServer+"/orders/loadTMPPayLogGrid", {
                                        itemId1: TMPId
                                    },headers).then((data) =>{
                                        if(data.status == 200){
                                            newOrder_payLogGrid.data.removeAll();
                                            newOrder_payLogGrid.data.parse(data.data);
                                        }
                                    });

                                    newOrder_payLogGridForm.clear();
                                    // newOrder_roomGridWindow1.hide();
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });

        const newOrder_payLogGridToolbar = new DHXToolbar(null, {
        });

        newOrder_payLogGridToolbar.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        newOrder_payLogGridForm.clear();
                        dhxNewOrder_payLogGridWindow1.header.data.update("title", {value: "新增付款紀錄視窗"});
                        newOrder_payLogGridForm.setValue({"AH001":"0","AH002":orderForm.getItem("AA001").getValue(),"AH003":hotel,"AH005":TMPId});

                        axios.get(apiServer+"/orders/loadPaymentMethodList", headers)
                            .then((response) =>{
                                if(response.status == 200){
                                    newOrder_payLogGridForm.getItem("AH004").setOptions(response.data);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });

                        dhxNewOrder_payLogGridWindow1.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    break;
                default:
                    break;
            }
        });

        newOrder_payLogGridToolbar.data.parse(JSON.parse(JSON.stringify(newOrder_payLogGridToolbarConfig)));

        newOrderWindow1Layout1.getCell("newOrder_payLogGridToolbar").attach(newOrder_payLogGridToolbar);
        newOrderWindow1Layout1.getCell("newOrder_payLogGrid").attach(newOrder_payLogGrid);

        //新增訂單視窗_付款紀錄_新增/編輯視窗
        const dhxNewOrder_payLogGridWindow1 = new DHXWindow({
            title: "新增付款紀錄視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 355,
            footer: true,
            css:"dhxNewOrder_payLogGridWindow1"
        });

        dhxNewOrder_payLogGridWindow1.footer.data.add({type:"spacer"});
        dhxNewOrder_payLogGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxNewOrder_payLogGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxNewOrder_payLogGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(newOrder_payLogGridForm.getItem("AH004").getValue() === "" || newOrder_payLogGridForm.getItem("AH004").getValue() === "0"){
                        DHXMessage({
                            text: "請選擇付款方式",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(newOrder_payLogGridForm.getItem("AH100").getValue() === ""){
                        DHXMessage({
                            text: "請填入付款金額",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/orders/updateTMPPayLogFormData",
                        newOrder_payLogGridForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            axios.post(apiServer+"/orders/loadTMPPayLogGrid", {
                                itemId1: TMPId
                            },headers).then((data) =>{
                                newOrder_payLogGrid.data.removeAll();
                                newOrder_payLogGrid.data.parse(data.data);

                                dhxNewOrder_payLogGridWindow1.hide();
                                newOrder_payLogGridForm.clear();
                                newOrder_payLogGridForm.clear("validation");
                            });
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxNewOrder_payLogGridWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const newOrder_payLogGridForm = new DHXForm(null,{
            rows: newOrder_payLogGridFormConfig
        });

        dhxNewOrder_payLogGridWindow1.attach(newOrder_payLogGridForm);

        //編輯訂單視窗
        const dhxOrderWindow1 = new DHXWindow({
            title: "訂單詳細視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 780,
            width: 800,
            footer: true,
            css: "dhxOrderWindow1"
        });

        dhxOrderWindow1.footer.data.add({type:"spacer"});
        dhxOrderWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxOrderWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxOrderWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":
                    dhxProgressWindow1.show();
                    let orderFormValue = Object.assign({},orderForm.getValue(), {TMPId: "0"});
                    axios.post(apiServer+"/orders/updateOrderFormData",orderFormValue,headers)
                        .then((response) =>{
                            if(response.data[0].result){
                                DHXMessage({
                                    text: response.data[0].message,
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });

                                isSaleSourceNeedChange = false;
                                dhxOrderWindow1.hide();
                                reloadGrid();
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxOrderWindow1.hide();
                    break;
                default:
                    break;
            }
        })

        let dhxTabbar = new DHXTabbar(null,{
            views: [
                {tab: "訂單資訊", id: "orderInfo"},
                {tab: "產品資訊", id: "productInfo"},
                {tab: "提醒設定", id: "alertSet"},
            ],
        });

        dhxOrderWindow1.attach(dhxTabbar);

        //編輯訂單
        let orderBasicSetTabLayout1 = new DHXLayout(null,{
            rows:[
                {id:"orderInfo_orderInfo", height: "340px", css: "orderInfo_orderInfo"},
                {id:"orderInfo_purchaserGridTitle", height: "30px",html:"<span style='font-weight: bold'>訂房人</span>"},
                {id:"orderInfo_purchaserGrid", height: "100px"},
                {id:"orderInfo_guestGridToolbar", height: "50px", css: "orderInfo_guestGridToolbar"},
                {id:"orderInfo_guestGrid",height: "200px"},
            ]
        });

        //編輯訂單視窗_訂單資訊
        const orderForm = new DHXForm(null,{
            rows: orderFormConfig,
            css: "orderForm"
        });

        orderForm.getItem("AA006").events.on("change", function (name, value){
            console.log(value);
            if(isSaleSourceNeedChange){
                axios.post(apiServer+"/orders/loadSaleSourceList", {
                        itemId1: orderForm.getItem("AA006").getValue()
                    },headers
                ).then((response) =>{
                    if(response.status === 200){
                        if(response.data){
                            orderForm.getItem("AA008").setOptions(response.data);
                            orderForm.getItem("AA008").setValue("0");
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }else{
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                })
                    .catch((error) => {
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    });
            }
        });

        orderBasicSetTabLayout1.getCell("orderInfo_orderInfo").attach(orderForm);

        //編輯訂單視窗_產品資訊_房間
        let orderInfo_purchaserGrid = new DHXGrid(null,{
            columns: orderInfo_purchaserGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "orderInfo_purchaserGrid_edit": function (e, data) {
                        if(canEdit){
                            orderInfo_purchaserGridForm.getItem("AA001").setValue(data.row.id);

                            axios.get(apiServer+"/orders/loadCountiesList",headers).then((response) =>{
                                orderInfo_purchaserGridForm.getItem("AA004").setOptions(response.data);
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });

                            axios.post(apiServer+"/orders/loadOrderFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                orderInfo_purchaserGridForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                            dhxOrderInfo_purchaserGridWindow1.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                }
            }
        });

        //編輯訂單視窗_產品資訊_編輯訂房人視窗
        const dhxOrderInfo_purchaserGridWindow1 = new DHXWindow({
            title: "編輯訂房人視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:700,
            height: 500,
            footer: true,
            css: "dhxOrderInfo_purchaserGridWindow1"
        });

        dhxOrderInfo_purchaserGridWindow1.footer.data.add({type:"spacer"});
        dhxOrderInfo_purchaserGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxOrderInfo_purchaserGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxOrderInfo_purchaserGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":

                    if(orderInfo_purchaserGridForm.getItem("AA106").getValue() === ""){
                        DHXMessage({
                            text: "請填入姓名",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    // if(orderInfo_purchaserGridForm.getItem("AA109").getValue() === ""){
                    //     DHXMessage({
                    //         text: "請填入電話",
                    //         icon:"dxi dxi-close",
                    //         css: "dhx_message--error",
                    //         expire:5000
                    //     });
                    //     break;
                    // }

                    dhxProgressWindow1.show();

                    axios.post(apiServer+"/orders/updatePurchaser",
                        orderInfo_purchaserGridForm.getValue(),
                        headers).then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    DHXMessage({
                                        text: response.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--success",
                                        expire:5000
                                    });

                                    reloadGrid();

                                    orderInfo_purchaserGridForm.clear();
                                    orderInfo_purchaserGridForm.setValue({"AA001": "0"});

                                    dhxOrderInfo_purchaserGridWindow1.hide();

                                    axios.post(apiServer+"/orders/loadPurchaserGrid", {
                                        itemId1: orderForm.getItem("AA001").getValue()
                                    },headers).then((data) =>{
                                        orderInfo_purchaserGrid.data.removeAll();
                                        orderInfo_purchaserGrid.data.parse(data.data);
                                    });

                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }

                    })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxOrderInfo_purchaserGridWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const orderInfo_purchaserGridForm = new DHXForm(null,{
            rows: orderInfo_purchaserGridFormConfig
        });

        dhxOrderInfo_purchaserGridWindow1.attach(orderInfo_purchaserGridForm);

        // 編輯訂單視窗_訂單資訊_入住人
        let orderInfo_guestGrid = new DHXGrid(null,{
            columns: orderInfo_guestGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "orderInfo_guestGrid_edit": function (e, data) {
                        if(canEdit){
                            orderInfo_guestGridForm.clear();
                            dhxOrderInfo_guestGridWindow1.header.data.update("title", {value: "編輯入住人視窗"});

                            orderInfo_guestGridForm.getItem("AF001").setValue(data.row.id);

                            axios.post(apiServer+"/orders/loadGuestFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                orderInfo_guestGridForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });

                            dhxOrderInfo_guestGridWindow1.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    },
                    "orderInfo_guestGrid_delete": function (e, data) {
                        if(canDelete){
                            guestGrid_deleteForm.getItem("AF001").setValue(data.row.id);
                            axios.post(apiServer+"/orders/loadGuestFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                guestGrid_deleteForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                            dhxOrderInfo_guestGridWindow2.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            },
        });

        const orderInfo_guestGridToolbar = new DHXToolbar(null, {
        });
        orderInfo_guestGridToolbar.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){

                        orderInfo_guestGridForm.clear();
                        dhxOrderInfo_guestGridWindow1.header.data.update("title", {value: "新增入住人視窗"});
                        orderInfo_guestGridForm.setValue({"AF001":"0","AF002":hotel,"AF003":orderForm.getItem("AA001").getValue()});
                        axios.get(apiServer+"/orders/loadCountiesList",headers)
                            .then((response) =>{
                                orderInfo_guestGridForm.getItem("AF004").setOptions(response.data);
                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                        dhxOrderInfo_guestGridWindow1.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }
                    break;
                default:
                    break;
            }
        });

        orderInfo_guestGridToolbar.data.parse(JSON.parse(JSON.stringify(orderInfo_guestGridToolbarConfig)));

        orderBasicSetTabLayout1.getCell("orderInfo_guestGridToolbar").attach(orderInfo_guestGridToolbar);

        //編輯訂單視窗_訂單資訊_入住人_新增/編輯入住人視窗
        const dhxOrderInfo_guestGridWindow1 = new DHXWindow({
            title: "新增入住人視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:700,
            height: 500,
            footer: true,
            css: "dhxOrderInfo_guestGridWindow1"
        });

        dhxOrderInfo_guestGridWindow1.footer.data.add({type:"spacer"});
        dhxOrderInfo_guestGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxOrderInfo_guestGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxOrderInfo_guestGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    if(orderInfo_guestGridForm.getItem("AF109").getValue() === ""){
                        DHXMessage({
                            text: "請填入姓名",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    // if(orderInfo_guestGridForm.getItem("AF115").getValue() === ""){
                    //     DHXMessage({
                    //         text: "請填入電話",
                    //         icon:"dxi dxi-close",
                    //         css: "dhx_message--error",
                    //         expire:5000
                    //     });
                    //     break;
                    // }

                    axios.post(apiServer+"/orders/updateGuestFormData",
                        orderInfo_guestGridForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: response.data[0].message,
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            axios.post(apiServer+"/orders/loadGuestGrid", {
                                itemId1: orderForm.getItem("AA001").getValue()
                            },headers).then((data) =>{
                                orderInfo_guestGrid.data.removeAll();
                                orderInfo_guestGrid.data.parse(data.data);
                            });

                            orderInfo_guestGridForm.clear();
                            orderInfo_guestGridForm.setValue({"AF001": "0"});

                            dhxOrderInfo_guestGridWindow1.hide();
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxOrderInfo_guestGridWindow1.hide();
                    orderInfo_guestGridForm.clear();
                    break;
                default:
                    break;
            }
        });

        const orderInfo_guestGridForm = new DHXForm(null,{
            rows: orderInfo_guestGridFormConfig
        });

        dhxOrderInfo_guestGridWindow1.attach(orderInfo_guestGridForm);

        //編輯訂單視窗_訂單資訊_入住人_刪除入住人視窗
        const dhxOrderInfo_guestGridWindow2 = new DHXWindow({
            title: "刪除入住人視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 600,
            footer: true,
            css: "dhxOrderInfo_guestGridWindow2"
        });

        dhxOrderInfo_guestGridWindow2.footer.data.add({type:"spacer"});
        dhxOrderInfo_guestGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxOrderInfo_guestGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxOrderInfo_guestGridWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    if(guestGrid_deleteForm.getItem("AF125").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    guestGrid_deleteFormParams = guestGrid_deleteForm.getValue();
                    axios.post(apiServer+"/orders/deleteGuest",guestGrid_deleteForm.getValue(),headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data[0].result > 0){
                                    DHXMessage({
                                        text: "更新成功",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--success",
                                        expire:5000
                                    });

                                    orderInfo_guestGrid.data.removeAll();

                                    axios.post(apiServer+"/orders/loadGuestGrid", {
                                        itemId1: orderForm.getItem("AA001").getValue()
                                    },headers).then((data) =>{
                                        orderInfo_guestGrid.data.removeAll();
                                        orderInfo_guestGrid.data.parse(data.data);
                                    });

                                    dhxOrderInfo_guestGridWindow2.hide();
                                    guestGrid_deleteForm.clear();
                                    guestGrid_deleteForm.setValue({"AF001": "0","AA001": "0"});
                                    guestGrid_deleteForm.clear("validation");
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--danger",
                                    expire:5000
                                });
                            }

                        }).catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxOrderInfo_guestGridWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let guestGrid_deleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"guestGrid_deleteFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "guestGrid_deleteForm", css:"guestGrid_deleteForm"},
            ]
        });

        const guestGrid_deleteForm = new DHXForm(null,{
            rows: orderInfo_guestGridDeleteFormConfig
        });

        let guestGrid_deleteFormParams = guestGrid_deleteForm.getValue();
        dhxOrderInfo_guestGridWindow2.attach(guestGrid_deleteFormLayout);
        guestGrid_deleteFormLayout.getCell("guestGrid_deleteForm").attach(guestGrid_deleteForm);

        dhxTabbar.getCell("orderInfo").attach(orderBasicSetTabLayout1);
        orderBasicSetTabLayout1.getCell("orderInfo_purchaserGrid").attach(orderInfo_purchaserGrid);
        orderBasicSetTabLayout1.getCell("orderInfo_guestGrid").attach(orderInfo_guestGrid);

        //編輯訂單視窗_產品資訊
        let orderBasicSetTabLayout2 = new DHXLayout(null,{
            rows:[
                {id:"productInfo_roomGridToolbar", height: "50px"},
                {id:"productInfo_roomGrid", height: "300px"},
                {id:"productInfo_otherPrdGridToolbar", height: "50px"},
                {id:"productInfo_otherPrdGrid",height: "250px"},
                {id:"productInfo_payLogGridToolbar", height: "50px"},
                {id:"productInfo_payLogGrid", height: "300px"},
            ]
        });

        //編輯訂單視窗_產品資訊_房間
        let productInfo_roomGrid = new DHXGrid(null,{
            columns: productInfo_roomGridColumn,
            autoWidth: true,
            resizable: true,
            rowCss: function (row) {
                if(row.roomMatchStatus == "0"){
                    return "room_noRoom"
                }
            },
            eventHandlers: {
                onclick: {
                    "productInfo_roomGrid_edit": function (e, data) {
                        isNeedFirstChangeEventRest = false;
                        isNeedFirstChangeEvent = false;
                        TMPId = "0";
                        if(canEdit){
                            DHXMessage({
                                text: "讀取資料中，請稍後",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            dhxProgressWindow1.show();
                            productInfo_roomGridForm.clear();
                            dhxProductInfo_roomGridWindow1.header.data.update("title", {value: "更新房間視窗"});

                            axios.post(apiServer+"/orders/loadRoomFormData", {
                                itemId1: data.row.id,
                                // itemId2:data.row.bookingType
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        switch (data.row.bookingType){
                                            case 1:
                                                productInfo_roomGridForm.setValue(response.data[0]);
                                                productInfo_roomGridForm.getItem("AG001").setValue(response.data[0].AG001);
                                                productInfo_roomGridForm.getItem("AG002").setValue(response.data[0].AG002);
                                                productInfo_roomGridForm.getItem("AG003").setValue(response.data[0].AG003);
                                                productInfo_roomGridForm.getItem("AG119").setValue(response.data[0].AG119);
                                                productInfo_roomGridForm.getItem("AG120").setValue(response.data[0].AG120);

                                                axios.get(apiServer+"/orders/loadSaleSourceList2",headers
                                                ).then((response2) =>{
                                                    if(response2.status === 200){
                                                        if(response2.data){
                                                            let productInfo_roomGridForm_AG007 = productInfo_roomGridForm.getItem("AG007").getWidget();
                                                            if(response2.data.length > 1){
                                                                productInfo_roomGridForm_AG007.data.removeAll();
                                                                productInfo_roomGridForm_AG007.data.parse(response2.data);
                                                            }else{
                                                                productInfo_roomGridForm_AG007.data.removeAll();
                                                                productInfo_roomGridForm_AG007.data.parse([{
                                                                    "id": "0",
                                                                    "value": "---請選擇---",
                                                                }]);
                                                            }
                                                        }else{
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        }
                                                    }else{
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                })
                                                .catch((error) => {
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                });

                                                axios.post(apiServer+"/orders/EditLoadRoomTypeList", {
                                                    itemId1: hotel,itemId2: response.data[0].AG001,itemId3: response.data[0].AG119,itemId4: response.data[0].AG120
                                                },headers).then((response2) =>{
                                                    if(response2.status === 200){
                                                        if(response2.data){
                                                            productInfo_roomGridForm_AG004 = productInfo_roomGridForm.getItem("AG004").getWidget();
                                                            productInfo_roomGridForm_AG004.data.removeAll();
                                                            productInfo_roomGridForm_AG004.data.parse(response2.data);
                                                            productInfo_roomGridForm.getItem("AG004").setValue(response.data[0].AG004);
                                                            productInfo_roomGridForm.getItem("AG004").enable();

                                                            axios.post(apiServer+"/orders/EditLoadRoomList", {
                                                                itemId1: hotel,itemId2: response.data[0].AG001,itemId3: response.data[0].AG119,itemId4: response.data[0].AG120,itemId5: response.data[0].AG004
                                                            },headers).then((response3) =>{
                                                                if(response3.status === 200){
                                                                    if(response3.data){
                                                                        productInfo_roomGridForm_AG005 = productInfo_roomGridForm.getItem("AG005").getWidget();
                                                                        productInfo_roomGridForm_AG005.data.removeAll();
                                                                        productInfo_roomGridForm_AG005.data.parse(response3.data);
                                                                        productInfo_roomGridForm.getItem("AG005").setValue(response.data[0].AG005);
                                                                        productInfo_roomGridForm.getItem("AG005").enable();

                                                                        setTimeout(()=>{
                                                                            productInfo_roomGridForm.getItem("AG127").setValue(response.data[0].AG127);
                                                                            productInfo_roomGridForm.getItem("AG116").setValue(response.data[0].AG116);
                                                                            productInfo_roomGridForm.getItem("AG127").enable();
                                                                            productInfo_roomGridForm.getItem("AG116").enable();
                                                                            if(new Date(productInfo_roomGridForm.getItem("AG120").getValue()) == new Date(productInfo_roomGridForm.getItem("AG119").getValue())){
                                                                                dayString = 1;
                                                                            }else{
                                                                                dayString = DateDiff(getDate(productInfo_roomGridForm.getItem("AG119").getValue()),getDate(productInfo_roomGridForm.getItem("AG120").getValue()));
                                                                            }
                                                                            productInfo_roomGridForm.getItem("total").setValue("共 "+dayString+" 晚，"+(Number(productInfo_roomGridForm.getItem("AG127").getValue())+Number(productInfo_roomGridForm.getItem("AG116").getValue()))+"元");
                                                                            productInfo_roomGridForm.getItem("AG007").setValue(response.data[0].AG007);
                                                                            dhxProductInfo_roomGridWindow1.attach(productInfo_roomGridForm);
                                                                            dhxProgressWindow1.hide();
                                                                            dhxProductInfo_roomGridWindow1.show();
                                                                            isNeedFirstChangeEvent = true;
                                                                        },1000)
                                                                    }else{
                                                                        dhxProgressWindow1.hide();
                                                                        DHXMessage({
                                                                            text: "連線異常，請重新整理",
                                                                            icon:"dxi dxi-close",
                                                                            css: "dhx_message--error",
                                                                            expire:5000
                                                                        });
                                                                    }
                                                                }else{
                                                                    dhxProgressWindow1.hide();
                                                                    DHXMessage({
                                                                        text: "連線異常，請重新整理",
                                                                        icon:"dxi dxi-close",
                                                                        css: "dhx_message--error",
                                                                        expire:5000
                                                                    });
                                                                }
                                                            })
                                                                .catch((error) => {
                                                                    dhxProgressWindow1.hide();
                                                                    DHXMessage({
                                                                        text: "連線異常，請重新整理",
                                                                        icon:"dxi dxi-close",
                                                                        css: "dhx_message--error",
                                                                        expire:5000
                                                                    });
                                                                });
                                                        }
                                                    }
                                                })
                                                    .catch((error) => {
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    });
                                                break;
                                            case 2:
                                                break;
                                            case 3:
                                                productInfo_restRoomGridForm.setValue(response.data[0]);
                                                productInfo_restRoomGridForm.getItem("AG001").setValue(response.data[0].AG001);
                                                productInfo_restRoomGridForm.getItem("AG002").setValue(response.data[0].AG002);
                                                productInfo_restRoomGridForm.getItem("AG003").setValue(response.data[0].AG003);
                                                productInfo_restRoomGridForm.getItem("AG119").setValue(response.data[0].AG119);

                                                axios.get(apiServer+"/orders/loadSaleSourceList2",headers
                                                ).then((response2) =>{
                                                    if(response2.status === 200){
                                                        if(response2.data){
                                                            let productInfo_restRoomGridForm_AG007 = productInfo_restRoomGridForm.getItem("AG007").getWidget();
                                                            if(response2.data.length > 1){
                                                                productInfo_restRoomGridForm_AG007.data.removeAll();
                                                                productInfo_restRoomGridForm_AG007.data.parse(response2.data);
                                                            }else{
                                                                productInfo_restRoomGridForm_AG007.data.removeAll();
                                                                productInfo_restRoomGridForm_AG007.data.parse([{
                                                                    "id": "0",
                                                                    "value": "---請選擇---",
                                                                }]);
                                                            }
                                                        }else{
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        }
                                                    }else{
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                })
                                                    .catch((error) => {
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    });

                                                if(response.data[0].AG132 === 3 || (response.data[0].AG132 === 2 && (response.data[0].AG133 === 1 || response.data[0].AG133 === 2))){
                                                    if(response.data[0].AG133 === 1){
                                                        productInfo_restRoomGridForm.getItem("AA103_1").setValue("1");
                                                        productInfo_restRoomGridForm.getItem("AA104_1").setValue(`${response.data[0].AG134}`);
                                                        productInfo_restRoomGridForm.getItem("AA104_1").enable();
                                                        productInfo_restRoomGridForm.getItem("AA104_2").disable();
                                                    }else{
                                                        productInfo_restRoomGridForm.getItem("AA103_2").setValue("1");
                                                        productInfo_restRoomGridForm.getItem("AA104_2").setValue(`${response.data[0].AG134}`);
                                                        productInfo_restRoomGridForm.getItem("AA104_2").enable();
                                                        productInfo_restRoomGridForm.getItem("AA104_1").disable();
                                                    }
                                                }

                                                axios.post(apiServer+"/orders/EditLoadRestRoomTypeList", {
                                                    itemId1: hotel,itemId2: response.data[0].AG001
                                                },headers).then((response2) =>{

                                                    if(response2.status == 200){
                                                        if(response2.data){
                                                            productInfo_restRoomGridForm_AG004 = productInfo_restRoomGridForm.getItem("AG004").getWidget();
                                                            productInfo_restRoomGridForm_AG004.data.removeAll();
                                                            productInfo_restRoomGridForm_AG004.data.parse(response2.data);
                                                            productInfo_restRoomGridForm.getItem("AG004").setValue(response.data[0].AG004);
                                                            productInfo_restRoomGridForm.getItem("AG004").enable();

                                                            axios.post(apiServer+"/orders/EditLoadRestRoomList", {
                                                                itemId1: hotel,itemId2: response.data[0].AG001,itemId3: response.data[0].AG119,itemId4: AA103,itemId5:AA104,itemId6:response.data[0].AG004
                                                            },headers).then((response3) =>{
                                                                if(response3.status == 200){
                                                                    if(response3.data){
                                                                        productInfo_restRoomGridForm_AG005 = productInfo_restRoomGridForm.getItem("AG005").getWidget();
                                                                        productInfo_restRoomGridForm_AG005.data.removeAll();
                                                                        productInfo_restRoomGridForm_AG005.data.parse(response3.data);
                                                                        productInfo_restRoomGridForm.getItem("AG005").setValue(response.data[0].AG005);
                                                                        productInfo_restRoomGridForm.getItem("AG005").enable();

                                                                        // setTimeout(()=>{
                                                                        productInfo_restRoomGridForm.getItem("AG127").setValue(response.data[0].AG127);
                                                                        productInfo_restRoomGridForm.getItem("AG127").enable();

                                                                        productInfo_restRoomGridForm.getItem("total").setValue(response.data[0].price);
                                                                        productInfo_restRoomGridForm.getItem("AG007").setValue(response.data[0].AG007);

                                                                        dhxProductInfo_roomGridWindow1.attach(productInfo_restRoomGridForm);
                                                                        dhxProgressWindow1.hide();
                                                                        dhxProductInfo_roomGridWindow1.show();
                                                                        isNeedFirstChangeEventRest = true;
                                                                    }else{
                                                                        dhxProgressWindow1.hide();
                                                                        DHXMessage({
                                                                            text: "連線異常，請重新整理",
                                                                            icon:"dxi dxi-close",
                                                                            css: "dhx_message--error",
                                                                            expire:5000
                                                                        });
                                                                    }
                                                                }else{
                                                                    dhxProgressWindow1.hide();
                                                                    DHXMessage({
                                                                        text: "連線異常，請重新整理",
                                                                        icon:"dxi dxi-close",
                                                                        css: "dhx_message--error",
                                                                        expire:5000
                                                                    });
                                                                }
                                                            })
                                                                .catch((error) => {
                                                                    dhxProgressWindow1.hide();
                                                                    DHXMessage({
                                                                        text: "連線異常，請重新整理",
                                                                        icon:"dxi dxi-close",
                                                                        css: "dhx_message--error",
                                                                        expire:5000
                                                                    });
                                                                });
                                                        }
                                                    }

                                                })
                                                    .catch((error) => {
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    });
                                                break;
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });

                            productInfo_roomGridForm.getItem("AG001").setValue(data.row.id)
                            productInfo_roomGridForm.setValue({"AG001":data.row.id,"AG002":orderForm.getItem("AA001").getValue()});

                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "productInfo_roomGrid_delete": function (e, data) {
                        if(canDelete){

                            roomGrid_deleteForm.clear();

                            axios.post(apiServer+"/orders/loadRoomFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{

                                roomGrid_deleteForm.setValue(response.data[0]);
                                roomGrid_deleteForm.getItem("roomGrid_deleteFormTitle").setValue("確定要刪除嗎?");
                                dhxProductInfo_roomGridWindow2.show();

                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--danger",
                                    expire:5000
                                });
                            });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });

        const productInfo_roomGridToolbar = new DHXToolbar(null, {
        });
        productInfo_roomGridToolbar.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        switch (orderForm.getItem("AA138").getValue()){
                            case "1":
                                TMPId = "0";
                                isNeedFirstChangeEvent = false;
                                // dhxProductInfo_roomGridWindow1.header.data.update("title", {value: "新增房間視窗"});
                                productInfo_roomGridForm.clear();
                                productInfo_roomGridForm.getItem("AG001").setValue("0");
                                productInfo_roomGridForm.getItem("AG002").setValue(orderForm.getItem("AA001").getValue());
                                productInfo_roomGridForm.getItem("AG003").setValue(hotel);

                                productInfo_roomGridForm_AG004 = productInfo_roomGridForm.getItem("AG004").getWidget();
                                productInfo_roomGridForm_AG004.data.removeAll();
                                productInfo_roomGridForm_AG004.data.parse([{
                                    "id": "0",
                                    "value": "---請選擇---",
                                }]);
                                productInfo_roomGridForm.getItem("AG004").setValue("0");
                                productInfo_roomGridForm.getItem("AG004").disable();

                                productInfo_roomGridForm_AG005 = productInfo_roomGridForm.getItem("AG005").getWidget();
                                productInfo_roomGridForm_AG005.data.removeAll();
                                productInfo_roomGridForm_AG005.data.parse([{
                                    "id": "0",
                                    "value": "---請選擇---",
                                }]);

                                axios.get(apiServer+"/orders/loadSaleSourceList2",headers
                                ).then((response) =>{
                                    if(response.status === 200){
                                        if(response.data){
                                            let productInfo_roomGridForm_AG007 = productInfo_roomGridForm.getItem("AG007").getWidget();
                                            if(response.data.length > 1){
                                                productInfo_roomGridForm_AG007.data.removeAll();
                                                productInfo_roomGridForm_AG007.data.parse(response.data);
                                                productInfo_roomGridForm.getItem("AG007").setValue("0");
                                            }else{
                                                productInfo_roomGridForm_AG007.data.removeAll();
                                                productInfo_roomGridForm_AG007.data.parse([{
                                                    "id": "0",
                                                    "value": "---請選擇---",
                                                }]);
                                                productInfo_roomGridForm.getItem("AG007").setValue("0");
                                            }
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });

                                productInfo_roomGridForm.getItem("AG005").setValue("0");
                                productInfo_roomGridForm.getItem("AG005").disable();

                                productInfo_roomGridForm.getItem("AG119").setValue(`${getDate(new Date())} 15:00`);
                                productInfo_roomGridForm.getItem("AG120").setValue(`${getDate(dateTime)} 11:00`);
                                productInfo_roomGridForm.getItem("AG119").clear();
                                productInfo_roomGridForm.getItem("AG120").clear();
                                productInfo_roomGridForm.getItem("AG127").setValue("0");
                                productInfo_roomGridForm.getItem("AG131").setValue(orderForm.getItem("AA137").getValue());
                                productInfo_roomGridForm.getItem("AG133").setValue("0");
                                productInfo_roomGridForm.getItem("AG134").setValue("0");
                                productInfo_roomGridForm.getItem("AG116").setValue("0");
                                productInfo_roomGridForm.getItem("total").setValue("");
                                dhxProductInfo_roomGridWindow1.attach(productInfo_roomGridForm);
                                dhxProductInfo_roomGridWindow1.show();

                                isNeedFirstChangeEvent = true;
                                break;
                            case "3":
                                TMPId = "0";
                                isNeedFirstChangeEventRest = false;
                                restTypeText = '';
                                price = 0;

                                // dhxProductInfo_roomGridWindow1.header.data.update("title", {value: "新增非住宿房間視窗"});
                                productInfo_restRoomGridForm.clear();

                                productInfo_restRoomGridForm.setValue({"AG001":"0","AG002":orderForm.getItem("AA001").getValue(),"AG003":hotel,"AG006":TMPId,"AG007":"0","AA103_2":"1","AA104_1":30,"AA104_2":1,"AA104_text1":"分鐘","AA104_text2":"小時","AG127":"0","AG131":orderForm.getItem("AA137").getValue(),"total":""});
                                AA103 = 2;
                                AA104 = 1;

                                productInfo_restRoomGridForm_AG004 = productInfo_restRoomGridForm.getItem("AG004").getWidget();
                                productInfo_restRoomGridForm_AG004.data.removeAll();
                                productInfo_restRoomGridForm_AG004.data.parse([{
                                    "id": "0",
                                    "value": "---請選擇---",
                                }]);
                                productInfo_restRoomGridForm.getItem("AG004").setValue("0");

                                productInfo_restRoomGridForm_AG005 = productInfo_restRoomGridForm.getItem("AG005").getWidget();
                                productInfo_restRoomGridForm_AG005.data.removeAll();
                                productInfo_restRoomGridForm_AG005.data.parse([{
                                    "id": "0",
                                    "value": "---請選擇---",
                                }]);
                                productInfo_restRoomGridForm.getItem("AG005").setValue("0");

                                axios.get(apiServer+"/orders/loadSaleSourceList2",headers
                                ).then((response) =>{
                                    if(response.status === 200){
                                        if(response.data){
                                            let productInfo_restRoomGridForm_AG007 = productInfo_restRoomGridForm.getItem("AG007").getWidget();
                                            if(response.data.length > 1){
                                                productInfo_restRoomGridForm_AG007.data.removeAll();
                                                productInfo_restRoomGridForm_AG007.data.parse(response.data);
                                                productInfo_restRoomGridForm.getItem("AG007").setValue("0");
                                            }else{
                                                productInfo_restRoomGridForm_AG007.data.removeAll();
                                                productInfo_restRoomGridForm_AG007.data.parse([{
                                                    "id": "0",
                                                    "value": "---請選擇---",
                                                }]);
                                                productInfo_restRoomGridForm.getItem("AG007").setValue("0");
                                            }
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                    .catch((error) => {
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });

                                dhxProductInfo_roomGridWindow1.attach(productInfo_restRoomGridForm);
                                dhxProductInfo_roomGridWindow1.show();


                                isNeedFirstChangeEventRest = true;
                                break;
                        }
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }
                    break;
                default:
                    break;
            }
        });

        productInfo_roomGridToolbar.data.parse(JSON.parse(JSON.stringify(productInfo_roomGridToolbarConfig)));

        orderBasicSetTabLayout2.getCell("productInfo_roomGridToolbar").attach(productInfo_roomGridToolbar);

        //編輯訂單視窗_產品資訊_房間_新增/編輯房間視窗
        const dhxProductInfo_roomGridWindow1 = new DHXWindow({
            title: "新增房間視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 460,
            footer: true,
            css:"dhxProductInfo_roomGridWindow1"
        });

        dhxProductInfo_roomGridWindow1.footer.data.add({type:"spacer"});
        dhxProductInfo_roomGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxProductInfo_roomGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxProductInfo_roomGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    switch (orderForm.getItem("AA138").getValue()) {
                        case "1":
                            productInfo_roomGridForm.getItem("AG132").setValue("1");
                            if(productInfo_roomGridForm.getItem("AG119").getValue() === ""){
                                DHXMessage({
                                    text: "請選擇入住日期",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(productInfo_roomGridForm.getItem("AG120").getValue() === ""){
                                DHXMessage({
                                    text: "請選擇退房日期",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(Date.parse(productInfo_roomGridForm.getItem("AG119").getValue()).valueOf() > Date.parse(productInfo_roomGridForm.getItem("AG120").getValue()).valueOf()){
                                DHXMessage({
                                    text: "入住日期必須早於退房日期",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            productInfo_roomGridForm_AG004 = productInfo_roomGridForm.getItem("AG004").getWidget();
                            if(productInfo_roomGridForm_AG004.getValue() === "0"){
                                DHXMessage({
                                    text: "請選擇房型",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            productInfo_roomGridForm_AG005 = productInfo_roomGridForm.getItem("AG005").getWidget();
                            if(productInfo_roomGridForm_AG005.getValue() === "0"){
                                DHXMessage({
                                    text: "請選擇房間名稱",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(productInfo_roomGridForm.getItem("AG127").getValue() === ""){
                                DHXMessage({
                                    text: "請填入金額",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--",
                                    expire:5000
                                });
                                break;
                            }

                            dhxProgressWindow1.show();
                            axios.post(apiServer+"/orders/updateRoomFormData",
                                productInfo_roomGridForm.getValue(),headers
                            ).then((response) =>{
                                if(response.data[0].result == 1){
                                    DHXMessage({
                                        text: response.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--success",
                                        expire:5000
                                    });
                                    axios.post(apiServer+"/orders/loadRoomGrid", {
                                        itemId1: productInfo_roomGridForm.getItem("AG002").getValue()
                                    },headers).then((data) =>{
                                        productInfo_roomGrid.data.parse(data.data);
                                    });
                                    reloadGrid();

                                    axios.post(apiServer+"/orders/loadOrderFormData", {
                                        itemId1: productInfo_roomGridForm.getItem("AG002").getValue()
                                    },headers).then((response2) =>{
                                        if(response2.status === 200){
                                            if(response2.data){
                                                orderForm.setValue(response2.data[0]);
                                                if(response2.data[0].unpaid > 0){
                                                    productInfo_payLogGridToolbar.enable("sendNewPayLink");
                                                }else{
                                                    productInfo_payLogGridToolbar.disable("sendNewPayLink");
                                                }

                                                orderForm.getItem("AA006").setOptions(response2.data[0].orderSourceItem);
                                                orderForm.getItem("AA006").setValue(response2.data[0].AA006)
                                                orderForm.getItem("AA008").setOptions(response2.data[0].saleSourceItem);
                                                orderForm.getItem("AA008").setValue(response2.data[0].AA008)

                                                isSaleSourceNeedChange = true;
                                            }
                                        }

                                    })
                                        .catch((error) => {
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--danger",
                                                expire:5000
                                            });
                                        });

                                    productInfo_roomGridForm.clear();
                                    dhxProductInfo_roomGridWindow1.hide();
                                }else if(response.data[0].result == 9){
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: response.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                            break;
                        case "3":
                            productInfo_restRoomGridForm.getItem("AG132").setValue("3");
                            if(productInfo_restRoomGridForm.getItem("AG119").getValue() === ""){
                                DHXMessage({
                                    text: "請選擇入住日期",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(productInfo_restRoomGridForm.getItem("AA103_1").getValue() === "" && productInfo_restRoomGridForm.getItem("AA103_2").getValue() === "" ){
                                DHXMessage({
                                    text: "請設定分鐘或小時",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            productInfo_restRoomGridForm_AG004 = productInfo_restRoomGridForm.getItem("AG004").getWidget();
                            if(productInfo_restRoomGridForm_AG004.getValue() === "0" || productInfo_restRoomGridForm_AG004.getValue() === ""){
                                DHXMessage({
                                    text: "請選擇房型",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            productInfo_restRoomGridForm_AG005 = productInfo_restRoomGridForm.getItem("AG005").getWidget();
                            if(productInfo_restRoomGridForm_AG005.getValue() === "0" || productInfo_restRoomGridForm_AG005.getValue() === ""){
                                DHXMessage({
                                    text: "請選擇房間名稱",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(productInfo_restRoomGridForm.getItem("AG127").getValue() === ""){
                                DHXMessage({
                                    text: "請填入金額",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--",
                                    expire:5000
                                });
                                break;
                            }

                            dhxProgressWindow1.show();
                            axios.post(apiServer+"/orders/updateRoomFormData",
                                productInfo_restRoomGridForm.getValue(),headers
                            ).then((response) =>{
                                if(response.data[0].result == 1){
                                    DHXMessage({
                                        text: response.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--success",
                                        expire:5000
                                    });
                                    isNeedFirstChangeEventRest = false;
                                    axios.post(apiServer+"/orders/loadRoomGrid", {
                                        itemId1: productInfo_roomGridForm.getItem("AG002").getValue()
                                    },headers).then((data) =>{
                                        productInfo_roomGrid.data.parse(data.data);
                                    });
                                    reloadGrid();

                                    axios.post(apiServer+"/orders/loadOrderFormData", {
                                        itemId1: productInfo_roomGridForm.getItem("AG002").getValue()
                                    },headers).then((response2) =>{
                                        if(response2.status === 200){
                                            if(response2.data){
                                                orderForm.setValue(response2.data[0]);
                                                if(response2.data[0].unpaid > 0){
                                                    productInfo_payLogGridToolbar.enable("sendNewPayLink");
                                                }else{
                                                    productInfo_payLogGridToolbar.disable("sendNewPayLink");
                                                }

                                                orderForm.getItem("AA006").setOptions(response2.data[0].orderSourceItem);
                                                orderForm.getItem("AA006").setValue(response2.data[0].AA006)
                                                orderForm.getItem("AA008").setOptions(response2.data[0].saleSourceItem);
                                                orderForm.getItem("AA008").setValue(response2.data[0].AA008)

                                                isSaleSourceNeedChange = true;
                                            }
                                        }

                                    })
                                        .catch((error) => {
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--danger",
                                                expire:5000
                                            });
                                        });

                                    productInfo_restRoomGridForm.clear();
                                    dhxProductInfo_roomGridWindow1.hide();
                                }else if(response.data[0].result == 9){
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: response.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                            break;
                    }
                    dhxProductInfo_roomGridWindow1.hide();
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxProductInfo_roomGridWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const productInfo_restRoomGridForm = new DHXForm(null,{
            rows: productInfo_restRoomGridFormConfig
        });

        productInfo_restRoomGridForm.events.on("change", function(name, value) {
            if(TMPId !== "0"){
                //新增
                switch (name){
                    case "AG119":
                        dhxProgressWindow1.show();
                        productInfo_restRoomGridForm.getItem("AG004").enable();
                        productInfo_restRoomGridForm.getItem("AG004").setValue("0");
                        productInfo_restRoomGridForm.getItem("AG005").disable();
                        productInfo_restRoomGridForm.getItem("AG005").setValue("0");
                        productInfo_restRoomGridForm.getItem("AG127").disable();
                        productInfo_restRoomGridForm.getItem("AG127").setValue("");
                        productInfo_restRoomGridForm.getItem("total").setValue("");

                        axios.post(apiServer+"/orders/loadRestRoomTypeList", {
                            itemId1: hotel,itemId2: productInfo_restRoomGridForm.getItem("AG001").getValue(),itemId3: value,itemId4:AA103,itemId5:AA104,itemId6:TMPId
                        },headers).then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    productInfo_restRoomGridForm_AG004 = productInfo_restRoomGridForm.getItem("AG004").getWidget();
                                    productInfo_restRoomGridForm_AG004.data.removeAll();
                                    productInfo_restRoomGridForm_AG004.data.parse(response.data);
                                    productInfo_restRoomGridForm.getItem("AG004").setValue("0");
                                    dhxProgressWindow1.hide();
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                }
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--danger",
                                    expire:5000
                                });
                            }
                        })
                            .catch((error) => {
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--danger",
                                    expire:5000
                                });
                            });
                        break;
                    case "AA103_1":
                        AA103 = 1;
                        AA104 = productInfo_restRoomGridForm.getItem("AA104_1").getValue();
                        if(value == ""){
                            restTypeText = '';
                            productInfo_restRoomGridForm.getItem("AA104_1").disable();
                        }else{
                            restTypeText = '分鐘';
                            productInfo_restRoomGridForm.getItem("AA104_1").enable();
                            productInfo_restRoomGridForm.getItem("AA104_2").disable();
                            productInfo_restRoomGridForm.getItem("AA103_2").clear();
                        }

                        if (productInfo_restRoomGridForm.getItem("AG119").getValue() == ""){
                            productInfo_restRoomGridForm.getItem("AG004").disable();
                        }else{
                            productInfo_restRoomGridForm.getItem("AG004").enable();
                        }
                        productInfo_restRoomGridForm.getItem("AG004").setValue("0");
                        productInfo_restRoomGridForm.getItem("AG005").disable();
                        productInfo_restRoomGridForm.getItem("AG005").setValue("0");
                        productInfo_restRoomGridForm.getItem("AG127").disable();
                        productInfo_restRoomGridForm.getItem("AG127").setValue("");
                        productInfo_restRoomGridForm.getItem("total").setValue("");
                        break;
                    case "AA103_2":
                        AA103 = 2;
                        AA104 = productInfo_restRoomGridForm.getItem("AA104_2").getValue();
                        if(value == ""){
                            restTypeText = '';
                            productInfo_restRoomGridForm.getItem("AA104_2").disable();
                        }else{
                            restTypeText = '小時';
                            productInfo_restRoomGridForm.getItem("AA104_1").disable();
                            productInfo_restRoomGridForm.getItem("AA104_2").enable();
                            productInfo_restRoomGridForm.getItem("AA103_1").clear();
                        }

                        if (productInfo_restRoomGridForm.getItem("AG119").getValue() == ""){
                            productInfo_restRoomGridForm.getItem("AG004").disable();
                        }else{
                            productInfo_restRoomGridForm.getItem("AG004").enable();
                        }
                        productInfo_restRoomGridForm.getItem("AG004").setValue("0");
                        productInfo_restRoomGridForm.getItem("AG005").disable();
                        productInfo_restRoomGridForm.getItem("AG005").setValue("0");
                        productInfo_restRoomGridForm.getItem("AG127").disable();
                        productInfo_restRoomGridForm.getItem("AG127").setValue("");
                        productInfo_restRoomGridForm.getItem("total").setValue("");
                        break;
                    case "AA104_1":
                        AA104 = value;
                        if(value == ""){
                        }else{

                        }

                        if (productInfo_restRoomGridForm.getItem("AG119").getValue() == ""){
                            productInfo_restRoomGridForm.getItem("AG004").disable();
                        }else{
                            productInfo_restRoomGridForm.getItem("AG004").enable();
                        }
                        productInfo_restRoomGridForm.getItem("AG004").setValue("0");
                        productInfo_restRoomGridForm.getItem("AG005").disable();
                        productInfo_restRoomGridForm.getItem("AG005").setValue("0");
                        productInfo_restRoomGridForm.getItem("AG127").disable();
                        productInfo_restRoomGridForm.getItem("AG127").setValue("");
                        productInfo_restRoomGridForm.getItem("total").setValue("");
                        break;
                    case "AA104_2":
                        AA104 = value;
                        if(value == ""){
                            productInfo_restRoomGridForm.getItem("AA104_2").disable();
                        }else{
                            productInfo_restRoomGridForm.getItem("AA104_1").disable();
                            productInfo_restRoomGridForm.getItem("AA104_2").enable();
                            productInfo_restRoomGridForm.getItem("AA103_1").clear();
                        }

                        if (productInfo_restRoomGridForm.getItem("AG119").getValue() == ""){
                            productInfo_restRoomGridForm.getItem("AG004").disable();
                        }else{
                            productInfo_restRoomGridForm.getItem("AG004").enable();
                        }
                        productInfo_restRoomGridForm.getItem("AG004").setValue("0");
                        productInfo_restRoomGridForm.getItem("AG005").disable();
                        productInfo_restRoomGridForm.getItem("AG005").setValue("0");
                        productInfo_restRoomGridForm.getItem("AG127").disable();
                        productInfo_restRoomGridForm.getItem("AG127").setValue("");
                        productInfo_restRoomGridForm.getItem("total").setValue("");
                        break;
                    case "AG004":
                        if(value == "0" || value == ""){
                            productInfo_restRoomGridForm.getItem("AG005").disable();
                            productInfo_restRoomGridForm.getItem("AG005").setValue("0");
                            productInfo_restRoomGridForm.getItem("AG127").disable();
                            productInfo_restRoomGridForm.getItem("AG127").setValue("");
                            productInfo_restRoomGridForm.getItem("total").setValue("");
                        }else{
                            productInfo_restRoomGridForm.getItem("AG005").enable();
                            productInfo_restRoomGridForm.getItem("AG005").setValue("0");
                            productInfo_restRoomGridForm.getItem("AG127").disable();
                            productInfo_restRoomGridForm.getItem("AG127").setValue("");
                            productInfo_restRoomGridForm.getItem("total").setValue("");

                            axios.post(apiServer+"/orders/loadRestRoomList", {
                                itemId1: hotel,itemId2: productInfo_restRoomGridForm.getItem("AG001").getValue(),itemId3: productInfo_restRoomGridForm.getItem("AG119").getValue(),itemId4:AA103,itemId5:AA104,itemId6:value,itemId7:TMPId
                            },headers).then((response) =>{
                                newOrder_restRoomGridForm_AG005 = newOrder_restRoomGridForm.getItem("AG005").getWidget();
                                newOrder_restRoomGridForm_AG005.data.removeAll();
                                newOrder_restRoomGridForm_AG005.data.parse(response.data);
                                newOrder_restRoomGridForm.getItem("AG005").setValue("0");
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });
                        }
                        break;
                    case "AG005":
                        if(value == "0" || value == ""){

                        }else{

                            let productInfo_restRoomGridForm_AG004 = productInfo_restRoomGridForm.getItem("AG004").getValue();
                            axios.post(apiServer+"/orders/loadRestRoomPrices", {
                                itemId1: hotel,itemId2: productInfo_restRoomGridForm.getItem("AG001").getValue(),itemId3: productInfo_restRoomGridForm.getItem("AG119").getValue(),itemId4:AA103,itemId5:AA104,itemId6:productInfo_restRoomGridForm_AG004,itemId7:value
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        productInfo_restRoomGridForm.getItem("AG127").setValue(response.data);

                                        productInfo_restRoomGridForm.getItem("AG127").enable();

                                        productInfo_restRoomGridForm.getItem("total").setValue(`共 ${AA104} ${restTypeText}，${response.data} 元`);
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--danger",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--danger",
                                    expire:5000
                                });
                            });
                        }
                        break;
                    case "AG127":
                        if(productInfo_restRoomGridForm.getItem("AG005").getValue() == "0"){
                            price = Number(newOrder_roomGridForm.getItem("AG127").getValue());

                            productInfo_restRoomGridForm.getItem("AG127").setValue("0");
                            // productInfo_restRoomGridForm.getItem("AG116").setValue("0");
                            productInfo_restRoomGridForm.getItem("total").setValue("");

                            productInfo_restRoomGridForm.getItem("total").setValue(`共 ${AA104} ${restTypeText}，${value} 元`);
                        }else{


                        }
                        break;
                }
            }else{
                //修改
                switch (name){
                    case "AG119":
                        if(isNeedFirstChangeEventRest){
                            productInfo_restRoomGridForm.getItem("AG005").setValue("0");
                            productInfo_restRoomGridForm.getItem("AG005").disable();
                            productInfo_restRoomGridForm.getItem("AG004").enable();
                            productInfo_restRoomGridForm.getItem("AG004").setValue("0");
                            productInfo_restRoomGridForm.getItem("AG127").disable();
                            productInfo_restRoomGridForm.getItem("AG127").setValue("");
                            productInfo_restRoomGridForm.getItem("total").setValue("");

                            if(productInfo_restRoomGridForm.getItem("AA103_1").getValue() == 1){
                                AA103 = 1;
                                AA104 = productInfo_restRoomGridForm.getItem("AA104_1").getValue();
                                restTypeText = '分鐘';
                            }else{
                                AA103 = 2;
                                AA104 = productInfo_restRoomGridForm.getItem("AA104_2").getValue();
                                restTypeText = '小時';
                            }
                            axios.post(apiServer+"/orders/EditLoadRestRoomTypeList", {
                                itemId1: hotel,itemId2: productInfo_restRoomGridForm.getItem("AG001").getValue(),itemId3: value,itemId4:AA103,itemId5:AA104
                            },headers).then((response) =>{
                                productInfo_restRoomGridForm_AG004 = productInfo_restRoomGridForm.getItem("AG004").getWidget();
                                productInfo_restRoomGridForm_AG004.data.removeAll();
                                productInfo_restRoomGridForm_AG004.data.parse(response.data);
                                productInfo_restRoomGridForm.getItem("AG004").setValue("0");
                                productInfo_restRoomGridForm.getItem("AG004").enable();
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });
                        }else{
                        }
                        break;
                    case "AA103_1":
                        if(isNeedFirstChangeEventRest){
                            if(value == ""){
                                productInfo_restRoomGridForm.getItem("AA104_1").disable();
                            }else{
                                productInfo_restRoomGridForm.getItem("AA104_1").enable();
                                productInfo_restRoomGridForm.getItem("AA104_2").disable();
                                productInfo_restRoomGridForm.getItem("AA103_2").clear();
                            }

                            if (productInfo_restRoomGridForm.getItem("AG119").getValue() == ""){
                                productInfo_restRoomGridForm.getItem("AG004").disable();
                            }else{
                                productInfo_restRoomGridForm.getItem("AG004").enable();
                            }
                            productInfo_restRoomGridForm.getItem("AG004").setValue("0");
                            productInfo_restRoomGridForm.getItem("AG005").disable();
                            productInfo_restRoomGridForm.getItem("AG005").setValue("0");
                            productInfo_restRoomGridForm.getItem("AG127").disable();
                            productInfo_restRoomGridForm.getItem("AG127").setValue("");
                            productInfo_restRoomGridForm.getItem("total").setValue("");

                            if(productInfo_restRoomGridForm.getItem("AA103_1").getValue() == 1){
                                AA103 = 1;
                                AA104 = productInfo_restRoomGridForm.getItem("AA104_1").getValue();
                                restTypeText = '分鐘';
                            }else{
                                AA103 = 2;
                                AA104 = productInfo_restRoomGridForm.getItem("AA104_2").getValue();
                                restTypeText = '小時';
                            }

                            axios.post(apiServer+"/orders/EditLoadRestRoomTypeList", {
                                itemId1: hotel,itemId2: productInfo_restRoomGridForm.getItem("AG001").getValue(),itemId3: value,itemId4:AA103,itemId5:AA104
                            },headers).then((response) =>{
                                productInfo_restRoomGridForm_AG004 = productInfo_restRoomGridForm.getItem("AG004").getWidget();
                                productInfo_restRoomGridForm_AG004.data.removeAll();
                                productInfo_restRoomGridForm_AG004.data.parse(response.data);
                                productInfo_restRoomGridForm.getItem("AG004").setValue("0");
                                productInfo_restRoomGridForm.getItem("AG004").enable();
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });
                        }else{

                        }

                        break;
                    case "AA103_2":
                        if(isNeedFirstChangeEventRest){
                            if(value == ""){
                                productInfo_restRoomGridForm.getItem("AA104_2").disable();
                            }else{
                                productInfo_restRoomGridForm.getItem("AA104_1").disable();
                                productInfo_restRoomGridForm.getItem("AA104_2").enable();
                                productInfo_restRoomGridForm.getItem("AA103_1").clear();
                            }

                            if (productInfo_restRoomGridForm.getItem("AG119").getValue() == ""){
                                productInfo_restRoomGridForm.getItem("AG004").disable();
                            }else{
                                productInfo_restRoomGridForm.getItem("AG004").enable();
                            }
                            productInfo_restRoomGridForm.getItem("AG004").setValue("0");
                            productInfo_restRoomGridForm.getItem("AG005").disable();
                            productInfo_restRoomGridForm.getItem("AG005").setValue("0");
                            productInfo_restRoomGridForm.getItem("AG127").disable();
                            productInfo_restRoomGridForm.getItem("AG127").setValue("");
                            productInfo_restRoomGridForm.getItem("total").setValue("");

                            if(productInfo_restRoomGridForm.getItem("AA103_1").getValue() == 1){
                                AA103 = 1;
                                AA104 = productInfo_restRoomGridForm.getItem("AA104_1").getValue();
                                restTypeText = '分鐘';
                            }else{
                                AA103 = 2;
                                AA104 = productInfo_restRoomGridForm.getItem("AA104_2").getValue();
                                restTypeText = '小時';
                            }
                            axios.post(apiServer+"/orders/EditLoadRestRoomTypeList", {
                                itemId1: hotel,itemId2: productInfo_restRoomGridForm.getItem("AG001").getValue(),itemId3: value,itemId4:AA103,itemId5:AA104
                            },headers).then((response) =>{
                                productInfo_restRoomGridForm_AG004 = productInfo_restRoomGridForm.getItem("AG004").getWidget();
                                productInfo_restRoomGridForm_AG004.data.removeAll();
                                productInfo_restRoomGridForm_AG004.data.parse(response.data);
                                productInfo_restRoomGridForm.getItem("AG004").setValue("0");
                                productInfo_restRoomGridForm.getItem("AG004").enable();
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });
                        }else{

                        }

                        break;
                    case "AA104_1":
                        if(isNeedFirstChangeEventRest){
                            if(value == ""){
                            }else{

                            }

                            if (productInfo_restRoomGridForm.getItem("AG119").getValue() == ""){
                                productInfo_restRoomGridForm.getItem("AG004").disable();
                            }else{
                                productInfo_restRoomGridForm.getItem("AG004").enable();
                            }
                            productInfo_restRoomGridForm.getItem("AG004").setValue("0");
                            productInfo_restRoomGridForm.getItem("AG005").disable();
                            productInfo_restRoomGridForm.getItem("AG005").setValue("0");
                            productInfo_restRoomGridForm.getItem("AG127").disable();
                            productInfo_restRoomGridForm.getItem("AG127").setValue("");
                            productInfo_restRoomGridForm.getItem("total").setValue("");

                            if(productInfo_restRoomGridForm.getItem("AA103_1").getValue() == 1){
                                AA103 = 1;
                                AA104 = productInfo_restRoomGridForm.getItem("AA104_1").getValue();
                                restTypeText = '分鐘';
                            }else{
                                AA103 = 2;
                                AA104 = productInfo_restRoomGridForm.getItem("AA104_2").getValue();
                                restTypeText = '小時';
                            }
                            axios.post(apiServer+"/orders/EditLoadRestRoomTypeList", {
                                itemId1: hotel,itemId2: productInfo_restRoomGridForm.getItem("AG001").getValue(),itemId3: value,itemId4:AA103,itemId5:AA104
                            },headers).then((response) =>{
                                productInfo_restRoomGridForm_AG004 = productInfo_restRoomGridForm.getItem("AG004").getWidget();
                                productInfo_restRoomGridForm_AG004.data.removeAll();
                                productInfo_restRoomGridForm_AG004.data.parse(response.data);
                                productInfo_restRoomGridForm.getItem("AG004").setValue("0");
                                productInfo_restRoomGridForm.getItem("AG004").enable();
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });
                        }else{

                        }

                        break;
                    case "AA104_2":
                        if(isNeedFirstChangeEventRest){
                            if(value == ""){
                                productInfo_restRoomGridForm.getItem("AA104_2").disable();
                            }else{
                                productInfo_restRoomGridForm.getItem("AA104_1").disable();
                                productInfo_restRoomGridForm.getItem("AA104_2").enable();
                                productInfo_restRoomGridForm.getItem("AA103_1").clear();
                            }

                            if (productInfo_restRoomGridForm.getItem("AG119").getValue() == ""){
                                productInfo_restRoomGridForm.getItem("AG004").disable();
                            }else{
                                productInfo_restRoomGridForm.getItem("AG004").enable();
                            }
                            productInfo_restRoomGridForm.getItem("AG004").setValue("0");
                            productInfo_restRoomGridForm.getItem("AG005").disable();
                            productInfo_restRoomGridForm.getItem("AG005").setValue("0");
                            productInfo_restRoomGridForm.getItem("AG127").disable();
                            productInfo_restRoomGridForm.getItem("AG127").setValue("");
                            productInfo_restRoomGridForm.getItem("total").setValue("");

                            if(productInfo_restRoomGridForm.getItem("AA103_1").getValue() == 1){
                                AA103 = 1;
                                AA104 = productInfo_restRoomGridForm.getItem("AA104_1").getValue();
                                restTypeText = '分鐘';
                            }else{
                                AA103 = 2;
                                AA104 = productInfo_restRoomGridForm.getItem("AA104_2").getValue();
                                restTypeText = '小時';
                            }
                            axios.post(apiServer+"/orders/EditLoadRestRoomTypeList", {
                                itemId1: hotel,itemId2: productInfo_restRoomGridForm.getItem("AG001").getValue(),itemId3: value,itemId4:AA103,itemId5:AA104
                            },headers).then((response) =>{
                                productInfo_restRoomGridForm_AG004 = productInfo_restRoomGridForm.getItem("AG004").getWidget();
                                productInfo_restRoomGridForm_AG004.data.removeAll();
                                productInfo_restRoomGridForm_AG004.data.parse(response.data);
                                productInfo_restRoomGridForm.getItem("AG004").setValue("0");
                                productInfo_restRoomGridForm.getItem("AG004").enable();
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });
                        }else{

                        }

                        break;
                    case "AG004":
                        if(isNeedFirstChangeEventRest){
                            if(value == "0" || value == ""){
                                productInfo_restRoomGridForm.getItem("AG005").disable();
                                productInfo_restRoomGridForm.getItem("AG005").setValue("0");
                                productInfo_restRoomGridForm.getItem("AG127").disable();
                                productInfo_restRoomGridForm.getItem("AG127").setValue("");
                                productInfo_restRoomGridForm.getItem("total").setValue("");
                            }else{
                                productInfo_restRoomGridForm.getItem("AG005").enable();
                                productInfo_restRoomGridForm.getItem("AG005").setValue("0");
                                productInfo_restRoomGridForm.getItem("AG127").disable();
                                productInfo_restRoomGridForm.getItem("AG127").setValue("");
                                productInfo_restRoomGridForm.getItem("total").setValue("");

                                if(productInfo_restRoomGridForm.getItem("AA103_1").getValue() == 1){
                                    AA103 = 1;
                                    AA104 = productInfo_restRoomGridForm.getItem("AA104_1").getValue();
                                    restTypeText = '分鐘';
                                }else{
                                    AA103 = 2;
                                    AA104 = productInfo_restRoomGridForm.getItem("AA104_2").getValue();
                                    restTypeText = '小時';
                                }

                                axios.post(apiServer+"/orders/EditLoadRestRoomList", {
                                    itemId1: hotel,itemId2: productInfo_restRoomGridForm.getItem("AG001").getValue(),itemId3: productInfo_restRoomGridForm.getItem("AG119").getValue(),itemId4:AA103,itemId5:AA104,itemId6:productInfo_restRoomGridForm.getItem("AG004").getValue()
                                },headers).then((response) =>{
                                    if(response.status === 200){
                                        if(response.data){
                                            productInfo_restRoomGridForm.getItem("AG005").getWidget().data.parse(response.data);
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                    .catch((error) => {
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--danger",
                                            expire:5000
                                        });
                                    });
                            }
                        }else{

                        }

                        break;
                    case "AG005":
                        if(isNeedFirstChangeEventRest){
                            if(value == "0" || value == ""){

                            }else{

                                if(productInfo_restRoomGridForm.getItem("AA103_1").getValue() == 1){
                                    AA103 = 1;
                                    AA104 = productInfo_restRoomGridForm.getItem("AA104_1").getValue();
                                    restTypeText = '分鐘';
                                }else{
                                    AA103 = 2;
                                    AA104 = productInfo_restRoomGridForm.getItem("AA104_2").getValue();
                                    restTypeText = '小時';
                                }

                                let productInfo_restRoomGridForm_AG004 = productInfo_restRoomGridForm.getItem("AG004").getValue();

                                axios.post(apiServer+"/orders/loadRestRoomPrices", {
                                    itemId1: hotel,itemId2: productInfo_restRoomGridForm.getItem("AG001").getValue(),itemId3: productInfo_restRoomGridForm.getItem("AG119").getValue(),itemId4:AA103,itemId5:AA104,itemId6:productInfo_restRoomGridForm_AG004,itemId7:value
                                },headers).then((response) =>{
                                    if(response.status === 200){
                                        if(response.data){
                                            productInfo_restRoomGridForm.getItem("AG127").setValue(response.data);

                                            productInfo_restRoomGridForm.getItem("AG127").enable();

                                            productInfo_restRoomGridForm.getItem("total").setValue(`共 ${AA104} ${restTypeText}，${response.data} 元`);
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                    .catch((error) => {
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--danger",
                                            expire:5000
                                        });
                                    });
                            }
                        }else{

                        }

                        break;
                    case "AG127":
                        if(isNeedFirstChangeEventRest){
                            if(productInfo_restRoomGridForm.getItem("AG005").getValue() == "0"){
                                price = Number(newOrder_roomGridForm.getItem("AG127").getValue());

                                productInfo_restRoomGridForm.getItem("AG127").setValue("0");
                                // productInfo_restRoomGridForm.getItem("AG116").setValue("0");
                                productInfo_restRoomGridForm.getItem("total").setValue("");

                                productInfo_restRoomGridForm.getItem("total").setValue(`共 ${AA104} ${restTypeText}，${value} 元`);
                            }else{


                            }
                        }else{

                        }

                        break;
                }
            }

        });

        const productInfo_roomGridForm = new DHXForm(null,{
            rows: productInfo_roomGridFormConfig
        });

        let dayString = '',totalString = '';
        productInfo_roomGridForm.events.on("change", function(name, value) {
            if(TMPId !== "0"){
                //新增
                switch (name){
                    case "AG119":
                    case "AG120":
                        if(productInfo_roomGridForm.getItem("AG119").getValue() != '' && productInfo_roomGridForm.getItem("AG120").getValue() != ''){
                            if(getDate(productInfo_roomGridForm.getItem("AG119").getValue()) == getDate(productInfo_roomGridForm.getItem("AG120").getValue())){
                                DHXMessage({
                                    text: "入住與退房日期必須不同天",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error dhx_message",
                                    expire:5000
                                });
                            }else{
                                dhxProgressWindow1.show();
                                axios.post(apiServer+"/orders/loadRoomTypeList", {
                                    itemId1: hotel,itemId2: productInfo_roomGridForm.getItem("AG001").getValue(),itemId3: productInfo_roomGridForm.getItem("AG119").getValue(),itemId4: productInfo_roomGridForm.getItem("AG120").getValue(),itemId5:TMPId
                                },headers).then((response) =>{
                                    if(response.status === 200){
                                        if(response.data){
                                            productInfo_roomGridForm_AG004 = productInfo_roomGridForm.getItem("AG004").getWidget();
                                            productInfo_roomGridForm_AG004.data.removeAll();
                                            productInfo_roomGridForm_AG004.data.parse(response.data);
                                            productInfo_roomGridForm.getItem("AG004").setValue("0");
                                            dhxProgressWindow1.hide();
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--danger",
                                                expire:5000
                                            });
                                            dhxProgressWindow1.hide();
                                        }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--danger",
                                            expire:5000
                                        });
                                        dhxProgressWindow1.hide();
                                    }
                                })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                    dhxProgressWindow1.hide();
                                });

                                if(new Date(productInfo_roomGridForm.getItem("AG120").getValue()) == new Date(productInfo_roomGridForm.getItem("AG119").getValue())){
                                    dayString = 1;
                                }else{
                                    dayString = DateDiff(getDate(productInfo_roomGridForm.getItem("AG119").getValue()),getDate(productInfo_roomGridForm.getItem("AG120").getValue()));
                                }

                                productInfo_roomGridForm.getItem("total").setValue("共 "+dayString+" 晚");

                                productInfo_roomGridForm.getItem("AG004").enable();
                                productInfo_roomGridForm.getItem("AG004").setValue("0");
                                productInfo_roomGridForm.getItem("AG005").disable();
                                productInfo_roomGridForm.getItem("AG005").setValue("0");
                                productInfo_roomGridForm.getItem("AG127").disable();
                                productInfo_roomGridForm.getItem("AG127").setValue("0");
                                productInfo_roomGridForm.getItem("AG116").disable();
                                productInfo_roomGridForm.getItem("AG116").setValue("0");
                            }
                        }else{
                            productInfo_roomGridForm.getItem("total").setValue("");
                            productInfo_roomGridForm.getItem("AG004").disable();
                            productInfo_roomGridForm.getItem("AG004").setValue("0");
                            productInfo_roomGridForm.getItem("AG005").disable();
                            productInfo_roomGridForm.getItem("AG005").setValue("0");
                            productInfo_roomGridForm.getItem("AG127").disable();
                            productInfo_roomGridForm.getItem("AG127").setValue("0");
                            productInfo_roomGridForm.getItem("AG116").disable();
                            productInfo_roomGridForm.getItem("AG116").setValue("0");
                        }
                        break;

                    case "AG004":

                        if(productInfo_roomGridForm.getItem("AG004").getValue() != '0' && productInfo_roomGridForm.getItem("AG004").getValue() != ''){
                            dhxProgressWindow1.show();
                            axios.post(apiServer+"/orders/loadRoomList", {
                                itemId1: hotel,itemId2: productInfo_roomGridForm.getItem("AG001").getValue(),itemId3: productInfo_roomGridForm.getItem("AG119").getValue(),itemId4: productInfo_roomGridForm.getItem("AG120").getValue(),itemId5: productInfo_roomGridForm.getItem("AG004").getValue(),itemId6: TMPId
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        productInfo_roomGridForm_AG005 = productInfo_roomGridForm.getItem("AG005").getWidget();
                                        productInfo_roomGridForm_AG005.data.removeAll();
                                        productInfo_roomGridForm_AG005.data.parse(response.data);
                                        dhxProgressWindow1.hide();
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--danger",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });
                            productInfo_roomGridForm.getItem("AG005").enable();
                            productInfo_roomGridForm.getItem("AG005").setValue("0");
                            productInfo_roomGridForm.getItem("AG127").disable();
                            productInfo_roomGridForm.getItem("AG127").setValue("0");
                            productInfo_roomGridForm.getItem("AG116").disable();
                            productInfo_roomGridForm.getItem("AG116").setValue("0");
                        }else{
                            productInfo_roomGridForm.getItem("AG005").disable();
                            productInfo_roomGridForm.getItem("AG005").setValue("0");
                            productInfo_roomGridForm.getItem("AG127").disable();
                            productInfo_roomGridForm.getItem("AG127").setValue("0");
                            productInfo_roomGridForm.getItem("AG116").disable();
                            productInfo_roomGridForm.getItem("AG116").setValue("0");
                        }
                        break;
                    case "AG005":
                        if(productInfo_roomGridForm.getItem("AG005").getValue() != '0' && productInfo_roomGridForm.getItem("AG005").getValue() != ''){
                            dhxProgressWindow1.show();
                            axios.post(apiServer+"/orders/loadRoomPrices", {
                                itemId1: hotel,itemId2: productInfo_roomGridForm.getItem("AG001").getValue(),itemId3: productInfo_roomGridForm.getItem("AG119").getValue(),itemId4: productInfo_roomGridForm.getItem("AG120").getValue(),itemId5: productInfo_roomGridForm.getItem("AG004").getValue(),itemId6: productInfo_roomGridForm.getItem("AG005").getValue()
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        productInfo_roomGridForm.getItem("AG127").setValue(response.data);
                                        productInfo_roomGridForm.getItem("AG127").enable();
                                        productInfo_roomGridForm.getItem("AG116").enable();
                                        dhxProgressWindow1.hide();
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--danger",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });
                        }else{
                            productInfo_roomGridForm.getItem("AG127").disable();
                            productInfo_roomGridForm.getItem("AG127").setValue("0");
                            productInfo_roomGridForm.getItem("AG116").disable();
                            productInfo_roomGridForm.getItem("AG116").setValue("0");
                        }
                        break;
                    case "AG127":
                    case "AG116":
                        productInfo_roomGridForm.getItem("total").setValue("共 "+dayString+" 晚，"+(Number(productInfo_roomGridForm.getItem("AG127").getValue())+Number(productInfo_roomGridForm.getItem("AG116").getValue()))+"元");
                        break;
                }
            }else{
                //修改
                switch (name){
                    case "AG119":
                    case "AG120":
                        if(isNeedFirstChangeEvent){
                            if(productInfo_roomGridForm.getItem("AG119").getValue() != '' && productInfo_roomGridForm.getItem("AG120").getValue() != ''){
                                if(getDate(productInfo_roomGridForm.getItem("AG119").getValue()) == getDate(productInfo_roomGridForm.getItem("AG120").getValue())){
                                    DHXMessage({
                                        text: "入住與退房日期必須不同天",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }else{
                                    dhxProgressWindow1.show();
                                    axios.post(apiServer+"/orders/EditLoadRoomTypeList", {
                                        itemId1: hotel,itemId2: productInfo_roomGridForm.getItem("AG001").getValue(),itemId3: productInfo_roomGridForm.getItem("AG119").getValue(),itemId4: productInfo_roomGridForm.getItem("AG120").getValue()
                                    },headers).then((response2) =>{
                                        if(response2.status === 200){
                                            if(response2.data){
                                                productInfo_roomGridForm_AG004 = productInfo_roomGridForm.getItem("AG004").getWidget();
                                                productInfo_roomGridForm_AG004.data.removeAll();
                                                productInfo_roomGridForm_AG004.data.parse(response2.data);
                                                productInfo_roomGridForm.getItem("AG004").setValue("0");
                                                dhxProgressWindow1.hide();
                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    })
                                        .catch((error) => {
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--danger",
                                                expire:5000
                                            });
                                        });

                                    if(new Date(productInfo_roomGridForm.getItem("AG120").getValue()) == new Date(productInfo_roomGridForm.getItem("AG119").getValue())){
                                        dayString = 1;
                                    }else{
                                        dayString = DateDiff(getDate(productInfo_roomGridForm.getItem("AG119").getValue()),getDate(productInfo_roomGridForm.getItem("AG120").getValue()));
                                    }

                                    productInfo_roomGridForm.getItem("total").setValue("共 "+dayString+" 晚");

                                    productInfo_roomGridForm.getItem("AG004").enable();
                                    productInfo_roomGridForm.getItem("AG004").setValue("0");
                                    productInfo_roomGridForm.getItem("AG005").disable();
                                    productInfo_roomGridForm.getItem("AG005").setValue("0");
                                    productInfo_roomGridForm.getItem("AG127").disable();
                                    productInfo_roomGridForm.getItem("AG127").setValue("0");
                                    productInfo_roomGridForm.getItem("AG116").disable();
                                    productInfo_roomGridForm.getItem("AG116").setValue("0");
                                }
                            }else{
                                productInfo_roomGridForm.getItem("total").setValue("");
                                productInfo_roomGridForm.getItem("AG004").disable();
                                productInfo_roomGridForm.getItem("AG004").setValue("0");
                                productInfo_roomGridForm.getItem("AG005").disable();
                                productInfo_roomGridForm.getItem("AG005").setValue("0");
                                productInfo_roomGridForm.getItem("AG127").disable();
                                productInfo_roomGridForm.getItem("AG127").setValue("0");
                                productInfo_roomGridForm.getItem("AG116").disable();
                                productInfo_roomGridForm.getItem("AG116").setValue("0");
                            }
                        }else{
                            if(productInfo_roomGridForm.getItem("AG119").getValue() != '' && productInfo_roomGridForm.getItem("AG120").getValue() != ''){
                                if(getDate(productInfo_roomGridForm.getItem("AG119").getValue()) == getDate(productInfo_roomGridForm.getItem("AG120").getValue())){
                                    DHXMessage({
                                        text: "入住與退房日期必須不同天",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }
                        }
                        break;
                    case "AG004":
                        if(isNeedFirstChangeEvent){
                            if(productInfo_roomGridForm.getItem("AG004").getValue() != '0' && productInfo_roomGridForm.getItem("AG004").getValue() != ''){
                                dhxProgressWindow1.show();
                                axios.post(apiServer+"/orders/EditLoadRoomList", {
                                    itemId1: hotel,itemId2: productInfo_roomGridForm.getItem("AG001").getValue(),itemId3: productInfo_roomGridForm.getItem("AG119").getValue(),itemId4: productInfo_roomGridForm.getItem("AG120").getValue(),itemId5: productInfo_roomGridForm.getItem("AG004").getValue()
                                },headers).then((response3) =>{
                                    if(response3.status === 200){
                                        if(response3.data){
                                            productInfo_roomGridForm_AG005 = productInfo_roomGridForm.getItem("AG005").getWidget();
                                            productInfo_roomGridForm_AG005.data.removeAll();
                                            productInfo_roomGridForm_AG005.data.parse(response3.data);
                                            productInfo_roomGridForm.getItem("AG005").setValue("0");
                                            productInfo_roomGridForm.getItem("AG005").enable();
                                            productInfo_roomGridForm.getItem("AG005").setValue("0");
                                            productInfo_roomGridForm.getItem("AG127").disable();
                                            productInfo_roomGridForm.getItem("AG127").setValue("0");
                                            productInfo_roomGridForm.getItem("AG116").disable();
                                            productInfo_roomGridForm.getItem("AG116").setValue("0");
                                            dhxProgressWindow1.hide();
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--danger",
                                            expire:5000
                                        });
                                    });
                            }else{
                                productInfo_roomGridForm.getItem("AG005").disable();
                                productInfo_roomGridForm.getItem("AG005").setValue("0");
                                productInfo_roomGridForm.getItem("AG127").disable();
                                productInfo_roomGridForm.getItem("AG127").setValue("0");
                                productInfo_roomGridForm.getItem("AG116").disable();
                                productInfo_roomGridForm.getItem("AG116").setValue("0");
                            }
                        }else{

                        }
                        break;
                    case "AG005":
                        if(isNeedFirstChangeEvent){

                            if(productInfo_roomGridForm.getItem("AG005").getValue() != '0' && productInfo_roomGridForm.getItem("AG005").getValue() != ''){
                                dhxProgressWindow1.show();
                                let productInfo_roomGridForm_AG004 = productInfo_roomGridForm.getItem("AG004").getValue();
                                axios.post(apiServer+"/orders/loadRoomPrices", {
                                    itemId1: hotel,itemId2: productInfo_roomGridForm.getItem("AG001").getValue(),itemId3: productInfo_roomGridForm.getItem("AG119").getValue(),itemId4: productInfo_roomGridForm.getItem("AG120").getValue(),itemId5: productInfo_roomGridForm_AG004,itemId6: productInfo_roomGridForm.getItem("AG005").getValue()
                                },headers).then((response) =>{
                                    if(response.status === 200){
                                        if(response.data){
                                            productInfo_roomGridForm.getItem("AG127").setValue(response.data);
                                            productInfo_roomGridForm.getItem("AG127").enable();
                                            productInfo_roomGridForm.getItem("AG116").enable();
                                            dhxProgressWindow1.hide();
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--danger",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--danger",
                                            expire:5000
                                        });
                                    }
                                })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--danger",
                                            expire:5000
                                        });
                                    });
                            }else{
                                productInfo_roomGridForm.getItem("AG127").disable();
                                productInfo_roomGridForm.getItem("AG127").setValue("0");
                                productInfo_roomGridForm.getItem("AG116").disable();
                                productInfo_roomGridForm.getItem("AG116").setValue("0");
                            }
                        }else{

                        }
                        break;
                    case "AG116":
                    case "AG127":
                        if(isNeedFirstChangeEvent){
                            productInfo_roomGridForm.getItem("total").setValue("共 "+dayString+" 晚，"+(Number(productInfo_roomGridForm.getItem("AG127").getValue())+Number(productInfo_roomGridForm.getItem("AG116").getValue()))+"元");
                        }else{

                        }
                        break;
                }
            }
        });

        let productInfo_roomGridFormCheckInDate = productInfo_roomGridForm.getItem("AG119").getWidget();
        let productInfo_roomGridFormCheckOutDate = productInfo_roomGridForm.getItem("AG120").getWidget();

        productInfo_roomGridFormCheckInDate.link(productInfo_roomGridFormCheckOutDate)

        dhxProductInfo_roomGridWindow1.attach(productInfo_roomGridForm);

        //編輯訂單視窗_產品資訊_房間_刪除房間視窗
        const dhxProductInfo_roomGridWindow2 = new DHXWindow({
            title: "刪除房間視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 690,
            footer: true,
            css:"dhxProductInfo_roomGridWindow2"
        });

        dhxProductInfo_roomGridWindow2.footer.data.add({type:"spacer"});
        dhxProductInfo_roomGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxProductInfo_roomGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxProductInfo_roomGridWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(roomGrid_deleteForm.getItem("AG129").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };
                    dhxProgressWindow1.show();
                    axios.post(apiServer+"/orders/deleteRoom",
                        roomGrid_deleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });
                            productInfo_roomGrid.data.removeAll();
                            axios.post(apiServer+"/orders/loadRoomGrid", {
                                itemId1: roomGrid_deleteForm.getItem("AG002").getValue()
                            },headers).then((data) =>{
                                productInfo_roomGrid.data.parse(data.data);
                            });

                            productInfo_otherPrdGrid.data.removeAll();
                            axios.post(apiServer+"/orders/loadOtherPrdGrid", {
                                itemId1: roomGrid_deleteForm.getItem("AG002").getValue()
                            },headers).then((data) =>{
                                productInfo_otherPrdGrid.data.parse(data.data);
                            });

                            axios.post(apiServer+"/orders/loadOrderFormData", {
                                itemId1: roomGrid_deleteForm.getItem("AG002").getValue()
                            },headers).then((response2) =>{
                                if(response2.status === 200){
                                    if(response2.data){
                                        orderForm.setValue(response2.data[0]);
                                        if(response2.data[0].unpaid > 0){
                                            productInfo_payLogGridToolbar.enable("sendNewPayLink");
                                        }else{
                                            productInfo_payLogGridToolbar.disable("sendNewPayLink");
                                        }
                                        // if(response2.data[0].AA137 == "1"){
                                        //     orderForm.getItem("AA101_1").hide();
                                        //     orderForm.getItem("AA101_2").show();
                                        //     orderForm.getItem("AA101_2").setOptions(response2.data[0].orderSourceItem);
                                        //     orderForm.getItem("AA101_2").setValue(response2.data[0].AA006)
                                        // }else if(response2.data[0].AA137 == "2"){
                                        //     orderForm.getItem("AA101_1").show(response2.data[0].AA101);
                                        //     orderForm.getItem("AA101_2").setValue()
                                        //     orderForm.getItem("AA101_2").hide();
                                        // }

                                        orderForm.getItem("AA006").setOptions(response2.data[0].orderSourceItem);
                                        orderForm.getItem("AA006").setValue(response2.data[0].AA006)
                                        orderForm.getItem("AA008").setOptions(response2.data[0].saleSourceItem);
                                        orderForm.getItem("AA008").setValue(response2.data[0].AA008)

                                        isSaleSourceNeedChange = true;
                                        dhxProgressWindow1.hide();
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }

                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });

                            dhxProductInfo_roomGridWindow2.hide();
                            roomGrid_deleteForm.clear();
                            roomGrid_deleteForm.setValue({"AG001": "0"});
                            roomGrid_deleteForm.clear("validation");
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    dhxProductInfo_roomGridWindow2.hide();
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxProductInfo_roomGridWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let roomGrid_deleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"roomGrid_deleteFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "roomGrid_deleteForm"},
            ]
        });

        const roomGrid_deleteForm = new DHXForm(null,{
            rows: productInfo_roomGridDeleteFormConfig
        });

        dhxProductInfo_roomGridWindow2.attach(roomGrid_deleteFormLayout);
        roomGrid_deleteFormLayout.getCell("roomGrid_deleteForm").attach(roomGrid_deleteForm);

        //編輯訂單視窗_產品資訊_加價產品消費
        let productInfo_otherPrdGrid = new DHXGrid(null,{
            columns: productInfo_otherPrdGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "productInfo_otherPrdGrid_edit": function (e, data) {
                        if(canEdit){
                            dhxProgressWindow1.show();
                            dhxProductInfo_otherPrdGridWindow1.header.data.update("title", {value: "編輯加價產品消費視窗"});
                            axios.post(apiServer+"/orders/loadProductInfo_otherPrdGridFormData", {
                                itemId1: data.row.id
                            },headers).then((response1) =>{
                                if(response1.status === 200) {
                                    if(response1.data){
                                        productInfo_otherPrdGridForm.getItem("AC001").setValue(response1.data[0].AC001);
                                        productInfo_otherPrdGridForm.getItem("AC002").setValue(response1.data[0].AC002);
                                        productInfo_otherPrdGridForm.getItem("AC003").setValue(response1.data[0].AC003);
                                        productInfo_otherPrdGridForm.getItem("AC107").setValue(response1.data[0].AC107);

                                        axios.post(apiServer+"/orders/otherPrdGrid_otherProductName", {
                                            itemId1: productInfo_otherPrdGridForm.getItem("AC003").getValue(),itemId2: productInfo_otherPrdGridForm.getItem("AC001").getValue()
                                        },headers).then((response2) =>{
                                            if(response2.status === 200) {
                                                if(response2.data){
                                                    productInfo_otherPrdGridForm.getItem("AC004").setOptions(response2.data);
                                                    productInfo_otherPrdGridForm.getItem("AC004").setValue(response2.data[0].AC004);
                                                    productInfo_otherPrdGridForm.getItem("AC004").enable();
                                                    productInfo_otherPrdGridForm.getItem("AC103").enable();

                                                    axios.post(apiServer+"/orders/otherPrdGrid_loadRoomNameList", {
                                                        itemId1: productInfo_otherPrdGridForm.getItem("AC003").getValue(),itemId2: productInfo_otherPrdGridForm.getItem("AC001").getValue(),itemId3: productInfo_otherPrdGridForm.getItem("AC002").getValue()
                                                    },headers).then((response3) =>{
                                                        if(response3.status === 200){
                                                            if(response3.data){
                                                                productInfo_otherPrdGridForm.getItem("AC005").setOptions(response3.data);
                                                                productInfo_otherPrdGridForm.getItem("AC005").setValue(response3.data[0].AC005);
                                                                productInfo_otherPrdGridForm.getItem("AC005").enable();

                                                                axios.get(apiServer+"/orders/loadSaleSourceList2",headers
                                                                ).then((response4) =>{
                                                                    if(response4.status === 200){
                                                                        if(response4.data){
                                                                            let productInfo_otherPrdGridForm_AC007 = productInfo_otherPrdGridForm.getItem("AC007").getWidget();
                                                                            if(response4.data.length > 1){
                                                                                productInfo_otherPrdGridForm_AC007.data.removeAll();
                                                                                productInfo_otherPrdGridForm_AC007.data.parse(response4.data);
                                                                                productInfo_otherPrdGridForm.getItem("AC007").setValue(response1.data[0].AC007);
                                                                                productInfo_otherPrdGridForm.getItem("AC103").setValue(response1.data[0].AC103);
                                                                                productInfo_otherPrdGridForm.getItem("total").setValue(response1.data[0].total);

                                                                                dhxProductInfo_otherPrdGridWindow1.show();
                                                                                dhxProgressWindow1.hide();
                                                                            }else{
                                                                                productInfo_otherPrdGridForm_AC007.data.removeAll();
                                                                                productInfo_otherPrdGridForm_AC007.data.parse([{
                                                                                    "id": "0",
                                                                                    "value": "---請選擇---",
                                                                                }]);
                                                                                productInfo_otherPrdGridForm.getItem("AC007").setValue("0");
                                                                                productInfo_otherPrdGridForm.getItem("AC103").setValue(response1.data[0].AC103);
                                                                                productInfo_otherPrdGridForm.getItem("total").setValue(response1.data[0].total);

                                                                                dhxProductInfo_otherPrdGridWindow1.show();
                                                                                dhxProgressWindow1.hide();
                                                                            }
                                                                        }else{
                                                                            dhxProgressWindow1.hide();
                                                                            DHXMessage({
                                                                                text: "連線異常，請重新整理",
                                                                                icon:"dxi dxi-close",
                                                                                css: "dhx_message--error",
                                                                                expire:5000
                                                                            });
                                                                        }
                                                                    }else{
                                                                        dhxProgressWindow1.hide();
                                                                        DHXMessage({
                                                                            text: "連線異常，請重新整理",
                                                                            icon:"dxi dxi-close",
                                                                            css: "dhx_message--error",
                                                                            expire:5000
                                                                        });
                                                                    }
                                                                })
                                                                .catch((error) => {
                                                                    dhxProgressWindow1.hide();
                                                                    DHXMessage({
                                                                        text: "連線異常，請重新整理",
                                                                        icon:"dxi dxi-close",
                                                                        css: "dhx_message--error",
                                                                        expire:5000
                                                                    });
                                                                });
                                                            }else{
                                                                dhxProgressWindow1.hide();
                                                                DHXMessage({
                                                                    text: "連線異常，請重新整理",
                                                                    icon:"dxi dxi-close",
                                                                    css: "dhx_message--danger",
                                                                    expire:5000
                                                                });
                                                            }
                                                        }else{
                                                            dhxProgressWindow1.hide();
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--danger",
                                                                expire:5000
                                                            });
                                                        }
                                                    })
                                                    .catch((error) => {
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--danger",
                                                            expire:5000
                                                        });
                                                    });
                                                }else{
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--danger",
                                                        expire:5000
                                                    });
                                                }
                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--danger",
                                                    expire:5000
                                                });
                                            }
                                        })
                                        .catch((error) => {
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--danger",
                                                expire:5000
                                            });
                                        });
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--danger",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--danger",
                                    expire:5000
                                });
                            });
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    },
                    "productInfo_otherPrdGrid_delete": function (e, data) {
                        if(canDelete){
                            axios.post(apiServer+"/orders/loadProductInfo_otherPrdGridFormData", {
                                itemId1: data.row.id
                            },headers).then((response) => {
                                if (response.status === 200) {
                                    otherPrdGrid_deleteForm.setValue(response.data[0])
                                }
                            });
                            otherPrdGrid_deleteForm.getItem("AC105").setValue("");
                            dhxProductInfo_otherPrdGridWindow2.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    }
                }
            }
        });

        const productInfo_otherPrdGridToolbar = new DHXToolbar(null, {
        });

        productInfo_otherPrdGridToolbar.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        dhxProductInfo_otherPrdGridWindow1.header.data.update("title", {value: "新增加價產品消費視窗"});
                        productInfo_otherPrdGridForm.getItem("AC001").setValue("0");
                        productInfo_otherPrdGridForm.getItem("AC002").setValue(orderForm.getItem("AA001").getValue());
                        productInfo_otherPrdGridForm.getItem("AC003").setValue(orderForm.getItem("AA002").getValue());
                        productInfo_otherPrdGridForm.getItem("AC004").setValue("0");
                        productInfo_otherPrdGridForm.getItem("AC004").disable();
                        productInfo_otherPrdGridForm.getItem("AC005").setValue("0");
                        productInfo_otherPrdGridForm.getItem("AC005").disable();
                        productInfo_otherPrdGridForm.getItem("AC103").disable();
                        productInfo_otherPrdGridForm.getItem("AC102").setValue("0");
                        productInfo_otherPrdGridForm.getItem("AC103").setValue("0");
                        productInfo_otherPrdGridForm.getItem("AC007").setValue("0");
                        productInfo_otherPrdGridForm.getItem("AC107").setValue("");
                        productInfo_otherPrdGridForm.getItem("remained").setValue("0");
                        productInfo_otherPrdGridForm.getItem("total").setValue("0");

                        dhxProgressWindow1.show();

                        axios.post(apiServer+"/orders/otherPrdGrid_otherProductName", {
                            itemId1: productInfo_otherPrdGridForm.getItem("AC003").getValue(),itemId2: productInfo_otherPrdGridForm.getItem("AC001").getValue()
                        },headers).then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    productInfo_otherPrdGridForm.getItem("AC004").setOptions(response.data);
                                    productInfo_otherPrdGridForm.getItem("AC004").enable();

                                    axios.post(apiServer+"/orders/otherPrdGrid_loadRoomNameList", {
                                        itemId1: productInfo_otherPrdGridForm.getItem("AC003").getValue(),itemId2: productInfo_otherPrdGridForm.getItem("AC001").getValue(),itemId3: productInfo_otherPrdGridForm.getItem("AC002").getValue()
                                    },headers).then((response2) =>{
                                        if(response2.status === 200){
                                            if(response2.data){
                                                productInfo_otherPrdGridForm.getItem("AC005").setOptions(response2.data);
                                                productInfo_otherPrdGridForm.getItem("AC005").enable();

                                                axios.get(apiServer+"/orders/loadSaleSourceList2",headers
                                                ).then((response3) =>{
                                                    if(response3.status === 200){
                                                        if(response3.data){
                                                            let productInfo_otherPrdGridForm_AC007 = productInfo_otherPrdGridForm.getItem("AC007").getWidget();
                                                            if(response3.data.length > 1){
                                                                productInfo_otherPrdGridForm_AC007.data.removeAll();
                                                                productInfo_otherPrdGridForm_AC007.data.parse(response3.data);
                                                                productInfo_otherPrdGridForm.getItem("AC007").setValue("0");
                                                                dhxProgressWindow1.hide();

                                                                dhxProductInfo_otherPrdGridWindow1.show();
                                                            }else{
                                                                productInfo_otherPrdGridForm_AC007.data.removeAll();
                                                                productInfo_otherPrdGridForm_AC007.data.parse([{
                                                                    "id": "0",
                                                                    "value": "---請選擇---",
                                                                }]);
                                                                productInfo_otherPrdGridForm.getItem("AC007").setValue("0");
                                                                dhxProgressWindow1.hide();

                                                                dhxProductInfo_otherPrdGridWindow1.show();
                                                            }
                                                        }else{
                                                            dhxProgressWindow1.hide();
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        }
                                                    }else{
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                })
                                                    .catch((error) => {
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    });
                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--danger",
                                                    expire:5000
                                                });
                                            }
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--danger",
                                                expire:5000
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--danger",
                                            expire:5000
                                        });
                                    });
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                            .catch((error) => {
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--danger",
                                    expire:5000
                                });
                            });
                    }else{
                        dhxProgressWindow1.hide();
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    break;
                default:
                    break;
            }
        });

        productInfo_otherPrdGridToolbar.data.parse(JSON.parse(JSON.stringify(productInfo_otherPrdGridToolbarConfig)));

        orderBasicSetTabLayout2.getCell("productInfo_otherPrdGridToolbar").attach(productInfo_otherPrdGridToolbar);

        //編輯訂單視窗_產品資訊_加價產品消費_新增/編輯加價產品消費視窗
        const dhxProductInfo_otherPrdGridWindow1 = new DHXWindow({
            title: "新增加價產品消費視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 500,
            footer: true,
            css:"dhxProductInfo_otherPrdGridWindow1"
        });

        dhxProductInfo_otherPrdGridWindow1.footer.data.add({type:"spacer"});
        dhxProductInfo_otherPrdGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxProductInfo_otherPrdGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxProductInfo_otherPrdGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(productInfo_otherPrdGridForm.getItem("AC004").getValue() === "" || productInfo_otherPrdGridForm.getItem("AC004").getValue() === "0"){
                        DHXMessage({
                            text: "請選擇加價產品",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(productInfo_otherPrdGridForm.getItem("AC005").getValue() === "" || productInfo_otherPrdGridForm.getItem("AC005").getValue() === "0"){
                        DHXMessage({
                            text: "請選擇房間",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(productInfo_otherPrdGridForm.getItem("AC103").getValue() === "" || productInfo_otherPrdGridForm.getItem("AC103").getValue() === 0){
                        DHXMessage({
                            text: "請填入數量",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    dhxProgressWindow1.show();
                    axios.post(apiServer+"/orders/updateProductInfo_checkRemained", {
                        itemId1: productInfo_otherPrdGridForm.getItem("AC004").getValue(),itemId2: productInfo_otherPrdGridForm.getItem("AC103").getValue(),itemId3: productInfo_otherPrdGridForm.getItem("AC001").getValue()
                    },headers).then((response1) =>{

                        if(response1.status === 200){
                            if(response1.data[0].result === 0){
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: response1.data[0].message,
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }else{

                                axios.post(apiServer+"/orders/updateProductInfo_otherPrdGridFormData",
                                    productInfo_otherPrdGridForm.getValue(),headers
                                ).then((response2) =>{
                                    if(response2.data[0].result){
                                        DHXMessage({
                                            text: "更新成功",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--success",
                                            expire:5000
                                        });

                                        axios.post(apiServer+"/orders/loadOtherPrdGrid", {
                                            itemId1: productInfo_otherPrdGridForm.getItem("AC002").getValue()
                                        },headers).then((data) =>{
                                            productInfo_otherPrdGrid.data.parse(data.data);
                                        });

                                        axios.post(apiServer+"/orders/loadOrderFormData", {
                                            itemId1: orderForm.getItem("AA001").getValue()
                                        },headers).then((response3) =>{
                                            if(response3.status === 200){
                                                if(response3.data){
                                                    orderForm.setValue(response3.data[0]);

                                                    dhxProductInfo_otherPrdGridWindow1.hide();
                                                    productInfo_otherPrdGridForm.clear();
                                                    productInfo_otherPrdGridForm.clear("validation");
                                                    reloadGrid();

                                                    if(response3.data[0].unpaid > 0){
                                                        productInfo_payLogGridToolbar.enable("sendNewPayLink");
                                                    }else{
                                                        productInfo_payLogGridToolbar.disable("sendNewPayLink");
                                                    }

                                                    orderForm.getItem("AA006").setOptions(response3.data[0].orderSourceItem);
                                                    orderForm.getItem("AA006").setValue(response3.data[0].AA006)
                                                    orderForm.getItem("AA008").setOptions(response3.data[0].saleSourceItem);
                                                    orderForm.getItem("AA008").setValue(response3.data[0].AA008)

                                                    isSaleSourceNeedChange = true;
                                                }else{
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }
                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }

                                        })
                                            .catch((error) => {
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            });
                                    }
                                })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxProductInfo_otherPrdGridWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const productInfo_otherPrdGridForm = new DHXForm(null,{
            rows: productInfo_otherPrdGridFormConfig
        });

        dhxProductInfo_otherPrdGridWindow1.attach(productInfo_otherPrdGridForm);

        productInfo_otherPrdGridForm.getItem("AC004").events.on("change", function(value) {
            if(productInfo_otherPrdGridForm.getItem("AC004").getValue() !== '0' && productInfo_otherPrdGridForm.getItem("AC004").getValue() !== ''){
                axios.post(apiServer+"/orders/otherPrdGrid_loadOtherPrdPrice", {
                    itemId1: productInfo_otherPrdGridForm.getItem("AC004").getValue()
                },headers).then((response) =>{
                    productInfo_otherPrdGridForm.getItem("AC102").setValue(response.data[0].price);
                    productInfo_otherPrdGridForm.getItem("remained").setValue(response.data[0].remained);
                    productInfo_otherPrdGridForm.getItem("total").setValue(response.data[0].price * productInfo_otherPrdGridForm.getItem("AC103").getValue());
                    productInfo_otherPrdGridForm.getItem("AC103").clearValidate();
                })
                    .catch((error) => {
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--danger",
                            expire:5000
                        });
                    });
                productInfo_otherPrdGridForm.getItem("AC103").enable();
            }else{
                productInfo_otherPrdGridForm.getItem("AC103").disable();
                productInfo_otherPrdGridForm.getItem("AC102").setValue("0");
                productInfo_otherPrdGridForm.getItem("AC103").setValue("0");
                productInfo_otherPrdGridForm.getItem("total").setValue("0");
            }
        });

        productInfo_otherPrdGridForm.getItem("AC103").events.on("keydown", function(value) {
            if(productInfo_otherPrdGridForm.getItem("AC103").getValue() == "0" || productInfo_otherPrdGridForm.getItem("AC103").getValue() == ""){

            }else{
                productInfo_otherPrdGridForm.getItem("total").setValue(productInfo_otherPrdGridForm.getItem("AC103").getValue() * productInfo_otherPrdGridForm.getItem("AC102").getValue());
            }
        });

        productInfo_otherPrdGridForm.getItem("AC103").events.on("change", function(value) {
            if(productInfo_otherPrdGridForm.getItem("AC103").getValue() == "0" || productInfo_otherPrdGridForm.getItem("AC103").getValue() == ""){

            }else{
                productInfo_otherPrdGridForm.getItem("total").setValue(productInfo_otherPrdGridForm.getItem("AC103").getValue() * productInfo_otherPrdGridForm.getItem("AC102").getValue());
            }
        });

        //編輯訂單視窗_產品資訊_加價產品消費_刪除加價產品消費視窗
        const dhxProductInfo_otherPrdGridWindow2 = new DHXWindow({
            title: "刪除加價產品消費視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 680,
            footer: true,
            css:"dhxProductInfo_otherPrdGridWindow2"
        });

        dhxProductInfo_otherPrdGridWindow2.footer.data.add({type:"spacer"});
        dhxProductInfo_otherPrdGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxProductInfo_otherPrdGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxProductInfo_otherPrdGridWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(otherPrdGrid_deleteForm.getItem("AC105").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    dhxProgressWindow1.show();
                    axios.post(apiServer+"/orders/deleteOtherPrd",
                        otherPrdGrid_deleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            reloadGrid();

                            axios.post(apiServer+"/orders/loadOtherPrdGrid", {
                                itemId1: productInfo_otherPrdGridForm.getItem("AC002").getValue()
                            },headers).then((data) =>{
                                productInfo_otherPrdGrid.data.parse(data.data);
                                otherPrdGrid_deleteForm.clear();
                                otherPrdGrid_deleteForm.setValue({"AC001": "0"});
                                otherPrdGrid_deleteForm.clear("validation");
                            });

                            axios.post(apiServer+"/orders/loadOrderFormData", {
                                itemId1: orderForm.getItem("AA001").getValue()
                            },headers).then((response2) =>{
                                if(response2.status == 200){
                                    if(response2.data){
                                        orderForm.setValue(response2.data[0]);
                                        if(response2.data[0].unpaid > 0){
                                            productInfo_payLogGridToolbar.enable("sendNewPayLink");
                                        }else{
                                            productInfo_payLogGridToolbar.disable("sendNewPayLink");
                                        }
                                        // if(response2.data[0].AA137 == "1"){
                                        //     orderForm.getItem("AA101_1").hide();
                                        //     orderForm.getItem("AA101_2").show();
                                        //     orderForm.getItem("AA101_2").setOptions(response2.data[0].orderSourceItem);
                                        //     orderForm.getItem("AA101_2").setValue(response2.data[0].AA006)
                                        // }else if(response2.data[0].AA137 == "2"){
                                        //     orderForm.getItem("AA101_1").show(response2.data[0].AA101);
                                        //     orderForm.getItem("AA101_2").setValue()
                                        //     orderForm.getItem("AA101_2").hide();
                                        // }

                                        orderForm.getItem("AA006").setOptions(response2.data[0].orderSourceItem);
                                        orderForm.getItem("AA006").setValue(response2.data[0].AA006)
                                        orderForm.getItem("AA008").setOptions(response2.data[0].saleSourceItem);
                                        orderForm.getItem("AA008").setValue(response2.data[0].AA008)

                                        isSaleSourceNeedChange = true;
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }

                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });

                            dhxProductInfo_otherPrdGridWindow2.hide();

                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxProductInfo_otherPrdGridWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let otherPrdGrid_deleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"otherPrdGrid_deleteFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "otherPrdGrid_deleteForm"},
            ]
        });

        const otherPrdGrid_deleteForm = new DHXForm(null,{
            rows: productInfo_otherPrdGridDeleteFormConfig
        });

        dhxProductInfo_otherPrdGridWindow2.attach(otherPrdGrid_deleteFormLayout);
        otherPrdGrid_deleteFormLayout.getCell("otherPrdGrid_deleteForm").attach(otherPrdGrid_deleteForm);

        dhxTabbar.getCell("productInfo").attach(orderBasicSetTabLayout2);
        orderBasicSetTabLayout2.getCell("productInfo_roomGrid").attach(productInfo_roomGrid);
        orderBasicSetTabLayout2.getCell("productInfo_otherPrdGrid").attach(productInfo_otherPrdGrid);

        //編輯訂單視窗_產品資訊_付款紀錄
        let productInfo_payLogGrid = new DHXGrid(null,{
            columns: productInfo_payLogGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "productInfo_payLogGrid_edit": function (e, data) {
                        if(canEdit){

                            productInfo_payLogGridForm.clear();

                            axios.get(apiServer+"/orders/loadPaymentMethodList", headers)
                                .then((response) =>{
                                    if(response.status == 200){
                                        productInfo_payLogGridForm.getItem("AH004").setOptions(response.data);
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });

                            dhxProductInfo_payLogGridWindow1.header.data.update("title", {value: "編輯付款紀錄視窗"});
                            axios.post(apiServer+"/orders/loadProductInfo_payLogGridFormData", {
                                itemId1: data.row.id
                            },headers).then((response1) =>{
                                if(response1.status === 200) {
                                    productInfo_payLogGridForm.setValue(response1.data[0]);
                                    dhxProductInfo_payLogGridWindow1.show();
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    },
                    "productInfo_payLogGrid_delete": function (e, data) {
                        if(canDelete){
                            axios.post(apiServer+"/orders/loadProductInfo_payLogGridFormData", {
                                itemId1: data.row.id
                            },headers).then((response) => {
                                if (response.status === 200) {
                                    payLogGrid_deleteForm.setValue(response.data[0])
                                }
                            });
                            dhxProductInfo_payLogGridWindow2.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    }
                }
            }
        });

        const productInfo_payLogGridToolbar = new DHXToolbar(null, {
        });

        productInfo_payLogGridToolbar.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        productInfo_payLogGridForm.clear();
                        dhxProductInfo_payLogGridWindow1.header.data.update("title", {value: "新增付款紀錄視窗"});
                        productInfo_payLogGridForm.setValue({"AH001":"0","AH002":orderForm.getItem("AA001").getValue(),"AH003":hotel});

                        axios.get(apiServer+"/orders/loadPaymentMethodList", headers)
                            .then((response) =>{
                                if(response.status == 200){
                                    productInfo_payLogGridForm.getItem("AH004").setOptions(response.data);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--danger",
                                    expire:5000
                                });
                            });

                        dhxProductInfo_payLogGridWindow1.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    break;
                case "sendNewPayLink":
                    productInfo_sendNewPayLinkForm.setValue({"AI002":orderForm.getItem("AA001").getValue(),"AI003":orderForm.getItem("AA002").getValue(),"AI106":orderForm.getItem("AA137").getValue()});

                    axios.post(apiServer+"/orders/loadItemDesc", {
                        itemId1: orderForm.getItem("AA001").getValue()
                    },headers).then((response) =>{
                        if(response.status === 200){
                            if (response.data){
                                productInfo_sendNewPayLinkForm.getItem("AI100").setValue(response.data[0].result);
                                productInfo_sendNewPayLinkForm.getItem("AI101").setValue("請在期限內付款");
                                productInfo_sendNewPayLinkForm.getItem("AI104").setValue(response.data[0].price);
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        });
                    dhxProductInfo_sendNewPayLinkFormWindow1.show();
                    break;
                default:
                    break;
            }
        });

        productInfo_payLogGridToolbar.data.parse(JSON.parse(JSON.stringify(productInfo_payLogGridToolbarConfig)));

        orderBasicSetTabLayout2.getCell("productInfo_payLogGridToolbar").attach(productInfo_payLogGridToolbar);
        orderBasicSetTabLayout2.getCell("productInfo_payLogGrid").attach(productInfo_payLogGrid);

        //編輯訂單視窗_產品資訊_付款紀錄_新增/編輯視窗
        const dhxProductInfo_payLogGridWindow1 = new DHXWindow({
            title: "新增付款紀錄視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 430,
            footer: true,
            css:"dhxProductInfo_payLogGridWindow1"
        });

        dhxProductInfo_payLogGridWindow1.footer.data.add({type:"spacer"});
        dhxProductInfo_payLogGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxProductInfo_payLogGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxProductInfo_payLogGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(productInfo_payLogGridForm.getItem("AH004").getValue() === "" || productInfo_payLogGridForm.getItem("AH004").getValue() === "0"){
                        DHXMessage({
                            text: "請選擇付款方式",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(productInfo_payLogGridForm.getItem("AH100").getValue() === ""){
                        DHXMessage({
                            text: "請填入付款金額",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    dhxProgressWindow1.show();

                    let productInfo_payLogGridFormValue = Object.assign({},productInfo_payLogGridForm.getValue(), {TMPId: "0"});
                    axios.post(apiServer+"/orders/updateProductInfo_payLogGridFormData",
                        productInfo_payLogGridFormValue,headers
                    ).then((response) =>{
                        if(response.data){
                            DHXMessage({
                                text: response.data[0].message,
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            reloadGrid();

                            axios.post(apiServer+"/orders/loadPayLogGrid", {
                                itemId1: orderForm.getItem("AA001").getValue()
                            },headers).then((data) =>{
                                productInfo_payLogGrid.data.removeAll();
                                productInfo_payLogGrid.data.parse(data.data);

                                dhxProductInfo_payLogGridWindow1.hide();
                                productInfo_payLogGridForm.clear();
                                productInfo_payLogGridForm.clear("validation");
                            });

                            axios.post(apiServer+"/orders/loadOrderFormData", {
                                itemId1: orderForm.getItem("AA001").getValue()
                            },headers).then((response2) =>{
                                if(response2.status === 200){
                                    if(response2.data){
                                        orderForm.setValue(response2.data[0]);
                                        if(response2.data[0].unpaid > 0){
                                            productInfo_payLogGridToolbar.enable("sendNewPayLink");
                                        }else{
                                            productInfo_payLogGridToolbar.disable("sendNewPayLink");
                                        }

                                        orderForm.getItem("AA006").setOptions(response2.data[0].orderSourceItem);
                                        orderForm.getItem("AA006").setValue(response2.data[0].AA006)
                                        orderForm.getItem("AA008").setOptions(response2.data[0].saleSourceItem);
                                        orderForm.getItem("AA008").setValue(response2.data[0].AA008)

                                        isSaleSourceNeedChange = true;
                                    }else{

                                    }
                                }

                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxProductInfo_payLogGridWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const productInfo_payLogGridForm = new DHXForm(null,{
            rows: productInfo_payLogGridFormConfig
        });

        dhxProductInfo_payLogGridWindow1.attach(productInfo_payLogGridForm);


        //編輯訂單視窗_產品資訊_加價產品消費_刪除付款紀錄視窗
        const dhxProductInfo_payLogGridWindow2 = new DHXWindow({
            title: "刪除付款紀錄視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 680,
            footer: true,
            css:"dhxProductInfo_payLogGridWindow2"
        });

        dhxProductInfo_payLogGridWindow2.footer.data.add({type:"spacer"});
        dhxProductInfo_payLogGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxProductInfo_payLogGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxProductInfo_payLogGridWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(payLogGrid_deleteForm.getItem("AH103").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    dhxProgressWindow1.show();
                    axios.post(apiServer+"/orders/deletepayLog",
                        payLogGrid_deleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            axios.post(apiServer+"/orders/loadPayLogGrid", {
                                itemId1: orderForm.getItem("AA001").getValue()
                            },headers).then((data) =>{
                                productInfo_payLogGrid.data.removeAll();
                                productInfo_payLogGrid.data.parse(data.data);

                                dhxProductInfo_payLogGridWindow2.hide();
                                productInfo_payLogGridForm.clear();
                                productInfo_payLogGridForm.clear("validation");
                            });

                            axios.post(apiServer+"/orders/loadOrderFormData", {
                                itemId1: orderForm.getItem("AA001").getValue()
                            },headers).then((response2) =>{
                                if(response2.status === 200){
                                    if(response2.data){
                                        orderForm.setValue(response2.data[0]);
                                        reloadGrid();
                                        if(response2.data[0].unpaid > 0){
                                            productInfo_payLogGridToolbar.enable("sendNewPayLink");
                                        }else{
                                            productInfo_payLogGridToolbar.disable("sendNewPayLink");
                                        }
                                        // if(response2.data[0].AA137 == "1"){
                                        //     orderForm.getItem("AA101_1").hide();
                                        //     orderForm.getItem("AA101_2").show();
                                        //     orderForm.getItem("AA101_2").setOptions(response2.data[0].orderSourceItem);
                                        //     orderForm.getItem("AA101_2").setValue(response2.data[0].AA006)
                                        // }else if(response2.data[0].AA137 == "2"){
                                        //     orderForm.getItem("AA101_1").show(response2.data[0].AA101);
                                        //     orderForm.getItem("AA101_2").setValue()
                                        //     orderForm.getItem("AA101_2").hide();
                                        // }

                                        orderForm.getItem("AA006").setOptions(response2.data[0].orderSourceItem);
                                        orderForm.getItem("AA006").setValue(response2.data[0].AA006)
                                        orderForm.getItem("AA008").setOptions(response2.data[0].saleSourceItem);
                                        orderForm.getItem("AA008").setValue(response2.data[0].AA008)

                                        isSaleSourceNeedChange = true;
                                    }else{

                                    }
                                }else{

                                }

                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxProductInfo_payLogGridWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let payLogGrid_deleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"payLogGrid_deleteFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "payLogGrid_deleteForm"},
            ]
        });

        const payLogGrid_deleteForm = new DHXForm(null,{
            rows: productInfo_payLogGridDeleteFormConfig
        });

        dhxProductInfo_payLogGridWindow2.attach(payLogGrid_deleteFormLayout);
        payLogGrid_deleteFormLayout.getCell("payLogGrid_deleteForm").attach(payLogGrid_deleteForm);

        //發送金流鏈結
        const dhxProductInfo_sendNewPayLinkFormWindow1 = new DHXWindow({
            title: "發送金流鏈結視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 500,
            footer: true,
            css:"dhxProductInfo_sendNewPayLinkFormWindow1"
        });

        dhxProductInfo_sendNewPayLinkFormWindow1.footer.data.add({type:"spacer"});
        dhxProductInfo_sendNewPayLinkFormWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxProductInfo_sendNewPayLinkFormWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootSend)));

        dhxProductInfo_sendNewPayLinkFormWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_SEND":
                    if(productInfo_sendNewPayLinkForm.getItem("AI100").getValue() === ""){
                        DHXMessage({
                            text: "請填寫商品敘述",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(productInfo_sendNewPayLinkForm.getItem("AI104").getValue() === ""){
                        DHXMessage({
                            text: "請填寫金額",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/orders/sendPayLink",
                        productInfo_sendNewPayLinkForm.getValue()
                        ,headers).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                DHXMessage({
                                    text: "發送成功",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });

                                productInfo_sendNewPayLinkForm.getItem("AI105").setValue(response.data[0].result);

                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxProductInfo_sendNewPayLinkFormWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const productInfo_sendNewPayLinkForm = new DHXForm(null,{
            rows: productInfo_sendNewPayLinkFormConfig
        });

        dhxProductInfo_sendNewPayLinkFormWindow1.attach(productInfo_sendNewPayLinkForm);

        //編輯訂單視窗_提醒設定
        let orderBasicSetTabLayout3 = new DHXLayout(null,{
            rows:[
                {id:"alertSetToolbar", height: "content", gravity: false},
                {id: "alertSetGrid"},
            ]
        });

        let alertSetGrid = new DHXGrid(null,{
            columns: alertSetGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers:{
                onclick:{
                    "alertSetGrid_edit":function(event,data){
                        if(canEdit){
                            dhxAlertSetGridWindow1.header.data.update("title", {value: "編輯提醒設定視窗"});

                            axios.post(apiServer+"/orders/loadAlertSetGridFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                alertSetGridForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });

                            dhxAlertSetGridWindow1.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "alertSetGrid_delete":function(event,data){
                        if(canDelete){
                            axios.post(apiServer+"/orders/loadAlertSetGridFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                alertSetGrid_deleteForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });
                            dhxAlertSetGridWindow2.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                }
            }
        });

        const alertSetToolbar = new DHXToolbar(null, {
        });

        alertSetToolbar.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        dhxAlertSetGridWindow1.header.data.update("title", {value: "新增提醒設定視窗"});
                        alertSetGridForm.setValue({"AE001":"0","AE002":hotel,"AE003":orderForm.getItem("AA001").getValue()})
                        dhxAlertSetGridWindow1.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }
                    break;
                default:
                    break;
            }
        });
        alertSetToolbar.data.parse(JSON.parse(JSON.stringify(alertSetGridToolbarConfig)));

        dhxTabbar.getCell("alertSet").attach(orderBasicSetTabLayout3);
        orderBasicSetTabLayout3.getCell("alertSetToolbar").attach(alertSetToolbar);
        orderBasicSetTabLayout3.getCell("alertSetGrid").attach(alertSetGrid);

        //編輯訂單視窗_提醒設定_新增/編輯提醒設定視窗
        const dhxAlertSetGridWindow1 = new DHXWindow({
            title: "新增提醒設定視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 360,
            footer: true,
            css:"dhxAlertSetGridWindow1"
        });

        dhxAlertSetGridWindow1.footer.data.add({type:"spacer"});
        dhxAlertSetGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxAlertSetGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxAlertSetGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(alertSetGridForm.getItem("AE100").getValue() === ""){
                        DHXMessage({
                            text: "請填寫提醒時間",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(alertSetGridForm.getItem("AE101").getValue() === ""){
                        DHXMessage({
                            text: "請填寫提醒內容",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/orders/updateAlertSetGridFormData",
                        alertSetGridForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            alertSetGrid.data.removeAll();

                            axios.post(apiServer+"/orders/loadAlertSetGrid", {
                                itemId1: orderForm.getItem("AA001").getValue()
                            },headers).then((data) =>{
                                alertSetGrid.data.removeAll();
                                alertSetGrid.data.parse(data.data);
                            });

                            dhxAlertSetGridWindow1.hide();
                            alertSetGridForm.clear();
                            alertSetGridForm.setValue({"AF001": "0","AA001": "0"});
                            alertSetGridForm.clear("validation");
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxAlertSetGridWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const alertSetGridForm = new DHXForm(null,{
            rows: alertSetGridFormConfig
        });

        dhxAlertSetGridWindow1.attach(alertSetGridForm);

        //編輯訂單視窗_提醒設定_刪除提醒設定視窗
        const dhxAlertSetGridWindow2 = new DHXWindow({
            title: "刪除提醒設定視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 620,
            footer: true,
            css: "dhxAlertSetGridWindow2"
        });

        dhxAlertSetGridWindow2.footer.data.add({type:"spacer"});
        dhxAlertSetGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxAlertSetGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxAlertSetGridWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    alertSetGrid_deleteForm.clear();
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(alertSetGrid_deleteForm.getItem("AE104").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/orders/deleteAlertSet",
                        alertSetGrid_deleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            alertSetGrid.data.removeAll();

                            axios.post(apiServer+"/orders/loadAlertSetGrid", {
                                itemId1: orderForm.getItem("AA001").getValue()
                            },headers).then((data) =>{
                                alertSetGrid.data.removeAll();
                                alertSetGrid.data.parse(data.data);
                            });

                            dhxAlertSetGridWindow2.hide();
                            alertSetGrid_deleteForm.clear();
                            alertSetGrid_deleteForm.setValue({"AE001": "0","AA001": "0"});
                            alertSetGrid_deleteForm.clear("validation");
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxAlertSetGridWindow2.hide();
                    alertSetGrid_deleteForm.clear();
                    alertSetGrid_deleteForm.getItem("alertSetGrid_deleteFormTitle").setValue("確定要刪除嗎？");
                    break;
                default:
                    break;
            }
        });

        let alertSetGrid_deleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"alertSetGrid_deleteFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                },
                {id: "alertSetGrid_deleteForm",css: "alertSetGrid_deleteForm"},
            ]
        });

        const alertSetGrid_deleteForm = new DHXForm(null,{
            rows: alertSetGrid_deleteFormConfig
        });

        dhxAlertSetGridWindow2.attach(alertSetGrid_deleteFormLayout);
        alertSetGrid_deleteFormLayout.getCell("alertSetGrid_deleteForm").attach(alertSetGrid_deleteForm);

        const dhxOrderWindow2 = new DHXWindow({
            title: "取消訂單視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 550,
            footer: true,
            css: "dhxOrderWindow2"
        });

        dhxOrderWindow2.footer.data.add({type:"spacer"});
        dhxOrderWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxOrderWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxOrderWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    orderDeleteForm.clear();
                    orderDeleteForm.clear("validation");
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    if(orderDeleteForm.getItem("AA005").getValue() === "0"){
                        DHXMessage({
                            text: "請選擇取消原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    dhxProgressWindow1.show();
                    axios.post(apiServer+"/orders/deleteOrder",
                        orderDeleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });
                            reloadGrid();
                            reloadStatistics();

                            dhxOrderWindow2.hide();
                            orderDeleteForm.clear();
                            orderDeleteForm.setValue({"AA001": "0"});
                            orderDeleteForm.clear("validation");
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxOrderWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let orderDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"orderDeleteFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "orderDeleteForm"},
            ]
        });

        const orderDeleteForm = new DHXForm(null,{
            rows: deleteFormConfig
        });

        dhxOrderWindow2.attach(orderDeleteFormLayout);
        orderDeleteFormLayout.getCell("orderDeleteForm").attach(orderDeleteForm);

        // const dhxCheckOutWindow1 = new DHXWindow({
        //     title: "退房視窗",
        //     modal: true,
        //     resizable: true,
        //     movable: true,
        //     closable:true,
        //     viewportOverflow:true,
        //     width:550,
        //     height: 350,
        //     footer: true,
        //     css: "dhxCheckOutWindow1"
        // });
        //
        // dhxCheckOutWindow1.footer.data.add({type:"spacer"});
        // dhxCheckOutWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        // dhxCheckOutWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootCleanRoom)));
        // dhxCheckOutWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootDontCleanRoom)));
        //
        // dhxCheckOutWindow1.footer.events.on("click",(itemId1) =>{
        //     switch (itemId1){
        //         case "WINDOW_FOOT_CLEAN_ROOM":
        //             axios.post(apiServer+"/dashboard/checkOut", {
        //                 itemId1:isCheckOutEarly,itemId2:orderId,itemId3:selectDate,itemId4:selectRoomId,itemId5:"1"
        //             },headers).then((response2) =>{
        //                 if(response2.data[0].result){
        //                     DHXMessage({
        //                         text: response2.data[0].message,
        //                         icon:"dxi dxi-close",
        //                         css: "dhx_message--success",
        //                         expire:5000
        //                     });
        //                     dhxProgressWindow1.show();
        //                     reloadGrid();
        //
        //                     reloadStatistics();
        //                 }else{
        //                     DHXMessage({
        //                         text: "連線異常，請重新整理",
        //                         icon:"dxi dxi-close",
        //                         css: "dhx_message--danger",
        //                         expire:5000
        //                     });
        //                 }
        //
        //             })
        //                 .catch((error) => {
        //                     DHXMessage({
        //                         text: "連線異常，請重新整理",
        //                         icon:"dxi dxi-close",
        //                         css: "dhx_message--danger",
        //                         expire:5000
        //                     });
        //                 });
        //             dhxCheckOutWindow1.hide();
        //             break;
        //         case "WINDOW_FOOT_DONT_CLEAN_ROOM":
        //             axios.post(apiServer+"/dashboard/checkOut", {
        //                 itemId1:isCheckOutEarly,itemId2:orderId,itemId3:selectDate,itemId4:selectRoomId,itemId5:"0"
        //             },headers).then((response2) =>{
        //                 if(response2.data[0].result){
        //                     DHXMessage({
        //                         text: response2.data[0].message,
        //                         icon:"dxi dxi-close",
        //                         css: "dhx_message--success",
        //                         expire:5000
        //                     });
        //                     dhxProgressWindow1.show();
        //                     reloadGrid();
        //
        //                     reloadStatistics();
        //                 }else{
        //                     DHXMessage({
        //                         text: "連線異常，請重新整理",
        //                         icon:"dxi dxi-close",
        //                         css: "dhx_message--danger",
        //                         expire:5000
        //                     });
        //                 }
        //
        //             })
        //                 .catch((error) => {
        //                     DHXMessage({
        //                         text: "連線異常，請重新整理",
        //                         icon:"dxi dxi-close",
        //                         css: "dhx_message--danger",
        //                         expire:5000
        //                     });
        //                 });
        //             dhxCheckOutWindow1.hide();
        //             break;
        //         case "WINDOW_FOOT_CLOSE":
        //             dhxCheckOutWindow1.hide();
        //             break;
        //         default:
        //             break;
        //     }
        // });

        dhxViewGridWindow1.attach(viewGridForm);

        const dhxFaultReportWindow1 = new DHXWindow({
            title: "新增故障通報視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 440,
            width: 635,
            footer: true,
            css: "faultReportWindow1",
        });

        dhxFaultReportWindow1.footer.data.add({type:"spacer"});
        dhxFaultReportWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxFaultReportWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxFaultReportWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    faultReportForm.clear();
                    faultReportForm.setValue({"AA001":"0","AA002":hotel,"AA003":"0","AA004":"0","AA108":"0","note":"此功能會扣掉庫存，請謹慎選取"});
                    faultReportForm.getItem("tip").setValue("(點擊輸入框進入選擇畫面)");
                    faultReportForm.clear("validation");
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    if(faultReportForm.getItem("AA103").getValue() == ""){
                        DHXMessage({
                            text: "請填寫通報日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(faultReportForm.getItem("AA006").getValue() == "0"){
                        DHXMessage({
                            text: "請選擇故障原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    dhxProgressWindow1.show();
                    axios.post(apiServer+"/dashboard/updateFaultReportData",
                        faultReportForm.getValue(),headers
                    ).then((response) =>{
                        if(response.status === 200){
                            if(response.data[0].result === 1){
                                DHXMessage({
                                    text: response.data[0].message,
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });
                                dhxFaultReportWindow1.hide();

                                faultReportForm.clear();
                                faultReportForm.setValue({"AA001":"0","AA002":hotel,"AA004":"0","AA108":"0","note":"此功能會扣掉庫存，請謹慎選取"});
                                reloadGrid();

                                reloadStatistics();

                            }else if(response.data[0].result === 9){
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: response.data[0].message,
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                    .catch((error) => {
                        dhxProgressWindow1.hide();
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxFaultReportWindow1.hide();
                    break;
                default:
                    break;
            }
        })

        const faultReportForm = new DHXForm(null,{
            rows: faultReportFormConfig
        });

        dhxFaultReportWindow1.attach(faultReportForm);

        // weekViewGridForm.getItem("reportDoneBtn").events.on("click", function(events) {
        //     dhxViewGridWindow1.hide();
        //
        //     faultReportDoneForm.clear();
        //     faultReportDoneForm.setValue({"AA001":orderId,"AA002":hotel,"AA109":Date.now()});
        //     dhxFaultReportWindow2.show();
        //
        // });

        const dhxFaultReportWindow2 = new DHXWindow({
            title: "故障處理回報",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 350,
            footer: true,
            css: "dhxFaultReportWindow3"
        });

        dhxFaultReportWindow2.footer.data.add({type:"spacer"});
        dhxFaultReportWindow2.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxFaultReportWindow2.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxFaultReportWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){

                case "WINDOW_FOOT_ACCEPT":
                    if(faultReportDoneForm.getItem("AA109").getValue() === ""){
                        DHXMessage({
                            text: "請填寫處理完成日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };
                    if(faultReportDoneForm.getItem("AA110").getValue() === ""){
                        DHXMessage({
                            text: "請填寫處理完成備註",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };
                    if(faultReportDoneForm.getItem("AA111").getValue() === true && faultReportDoneForm.getItem("AA112").getValue() === ""){
                        DHXMessage({
                            text: "請填寫打掃時間",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    dhxProgressWindow1.show();
                    axios.post(apiServer+"/faultReport/updateFaultReportDone",
                        faultReportDoneForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });
                            faultReportDoneForm.clear();
                            dhxFaultReportWindow2.hide();
                            faultReportDoneForm.setValue({"AA001": "0"});
                            reloadGrid();

                            reloadStatistics();

                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_WAIT":

                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxFaultReportWindow2.hide();
                    break;
                default:
                    break;
            }
        })

        const faultReportDoneForm = new DHXForm(null,{
            rows: faultReportDoneFormConfig
        });

        faultReportDoneForm.getItem("AA111").events.on("change", function(value) {
            faultReportDoneForm.getItem("AA112").setValue("");
            if(value === true){
                faultReportDoneForm.getItem("AA112").enable();
            }else{
                faultReportDoneForm.getItem("AA112").disable();
            }
        });

        dhxFaultReportWindow2.attach(faultReportDoneForm);


        const dhxReserveWindow1 = new DHXWindow({

            title: "新增保留視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height:480,
            width: 635,
            footer: true,
            css: "reserveWindow1",
        });

        dhxReserveWindow1.footer.data.add({type:"spacer"});
        dhxReserveWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxReserveWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxReserveWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":

                    if(reserveForm.getItem("AA103").getValue() == ""){
                        DHXMessage({
                            text: "請填寫通報日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(reserveForm.getItem("AA102").getValue().replace(/\s*/g,"") == ""){
                        DHXMessage({
                            text: "請填寫保留原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    dhxProgressWindow1.show();
                    axios.post(apiServer+"/reserve/updateReserve",
                        reserveForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: response.data[0].message,
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            reserveForm.clear();
                            reserveForm.setValue({"AA001":"0","AA002":hotel,"AA004":"0",'AA100':1,"AA108":"0","note":"此功能會扣掉庫存，請謹慎選取"});

                            dhxReserveWindow1.hide();
                            reloadGrid();


                        }else{

                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxReserveWindow1.hide();
                    break;
                default:
                    break;
            }
        })

        // i18n.setLocale("reserveForm", localeCal["de"]);

        const reserveForm = new DHXForm(null,{
            rows: reserveFormConfig
        });

        dhxReserveWindow1.attach(reserveForm);

        let checkInLayout1 = new DHXLayout(null,{
            rows:[
                {id:"checkIn_orderInfoGridTitle", height: "30px",html:"<span style='font-weight: bold'>訂單資訊</span>"},
                {id:"checkIn_orderInfoGrid", height: "100px"},
                {id:"checkIn_roomGridTitle", height: "30px",html:"<span style='font-weight: bold'>房間清單</span>"},
                {id:"checkIn_roomGrid", height: "300px"},
                {id:"checkIn_otherPrdGridTitle", height: "30px",html:"<span style='font-weight: bold'>加價產品消費清單</span>"},
                {id:"checkIn_otherPrdGrid",height: "250px"},
                {id:"checkIn_purchaserGridTitle", height: "30px",html:"<span style='font-weight: bold'>訂房人</span>"},
                {id:"checkIn_purchaserGrid", height: "100px"},
                {id:"checkIn_guestGridToolbar", height: "50px", css: "orderInfo_guestGridToolbar"},
                {id:"checkIn_guestGrid",height: "200px"},
                {id:"checkIn_payLogGridToolbar", height: "50px"},
                {id:"checkIn_payLogGrid", height: "300px"},
            ]
        });

        const dhxCheckInWindow = new DHXWindow({
            title: " ",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 680,
            width: 800,
            footer: true,
            css: "dhxCheckInWindow"
        });

        dhxCheckInWindow.footer.data.add({type: "spacer"});
        dhxCheckInWindow.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxCheckInWindow.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxCheckInWindow.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLOSE":
                    dhxCheckInWindow.hide();
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    dhxProgressWindow1.show();

                    axios.post(apiServer+"/dashboard/checkNeedPay",{
                        itemId1:orderId
                    },headers).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                if(response.data[0].result === 1){
                                    //若已付款

                                    canCheckInRoomArray = [];
                                    dhxCheckIn_canCheckInRoomGrid.data.removeAll();
                                    axios.post(apiServer+"/dashboard/loadCanCheckInRoomGrid",{
                                        itemId1:orderId,itemId2:selectDate
                                    },headers).then((response2) =>{
                                        if(response2.status === 200){
                                            if(response2.data){
                                                dhxCheckIn_canCheckInRoomGrid.data.parse(response2.data);
                                                dhxCheckIn_canCheckInRoomGridWindow1.attach(dhxCheckIn_canCheckInRoomGrid);
                                                dhxCheckIn_canCheckInRoomGridWindow1.show();
                                                dhxProgressWindow1.hide();

                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        }else{

                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }

                                    })
                                        .catch((error) => {
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        });


                                    // axios.post(apiServer+"/dashboard/checkIn",{
                                    //     itemId1:orderId,itemId2:selectDate,itemId3:selectRoomId
                                    // },headers).then((response2) =>{
                                    //     if(response2.status === 200){
                                    //         if(response2.data){
                                    //             DHXMessage({
                                    //                 text: response2.data[0].message,
                                    //                 icon:"dxi dxi-close",
                                    //                 css: "dhx_message--success",
                                    //                 expire:5000
                                    //             });
                                    //             reloadGrid();
                                    //
                                    //             reloadStatistics();
                                    //
                                    //         }else{
                                    //             dhxProgressWindow1.hide();
                                    //             DHXMessage({
                                    //                 text: "連線異常，請重新整理",
                                    //                 icon:"dxi dxi-close",
                                    //                 css: "dhx_message--error",
                                    //                 expire:5000
                                    //             });
                                    //         }
                                    //     }else{
                                    //
                                    //         dhxProgressWindow1.hide();
                                    //         DHXMessage({
                                    //             text: "連線異常，請重新整理",
                                    //             icon:"dxi dxi-close",
                                    //             css: "dhx_message--error",
                                    //             expire:5000
                                    //         });
                                    //     }
                                    //
                                    // })
                                    //     .catch((error) => {
                                    //         dhxProgressWindow1.hide();
                                    //         DHXMessage({
                                    //             text: "連線異常，請重新整理",
                                    //             icon:"dxi dxi-close",
                                    //             css: "dhx_message--error",
                                    //             expire:5000
                                    //         });
                                    //     });
                                    dhxCheckInWindow.hide();
                                }else{
                                    //若未付款或有退款
                                    dhxProgressWindow1.hide();
                                    DHXAlert({
                                        text: response.data[0].message,
                                        buttonsAlignment:"center",
                                        css: "checkIn_alert",
                                    });
                                }


                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{

                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    })
                    .catch((error) => {
                        dhxProgressWindow1.hide();
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    });


                    break;
                default:
                    break;
            }
        });

        let checkIn_orderInfoGrid = new DHXGrid(null,{
            columns: checkIn_orderInfoGridColumn,
            autoWidth: true,
            resizable: true,
        });

        // weekViewGridForm.getItem("checkInBtn").events.on("click", function(events) {
        //
        //     dhxViewGridWindow1.hide();
        //
        //     dhxCheckInWindow.header.data.update("title", {value: selectDate+"-"+roomName+" 入住資訊"});
        //
        //     axios.post(apiServer+"/dashboard/loadOrderInfoGrid",{itemId1:orderId},headers)
        //         .then((data) =>{
        //             checkIn_orderInfoGrid.data.removeAll();
        //             checkIn_orderInfoGrid.data.parse(data.data);
        //         });
        //
        //     checkIn_roomGrid.data.removeAll();
        //     axios.post(apiServer+"/orders/loadRoomGrid", {
        //         itemId1: orderId
        //     },headers).then((data) =>{
        //         checkIn_roomGrid.data.parse(data.data);
        //     });
        //
        //     checkIn_otherPrdGrid.data.removeAll();
        //     axios.post(apiServer+"/orders/loadOtherPrdGrid", {
        //         itemId1: orderId
        //     },headers).then((data) =>{
        //         checkIn_otherPrdGrid.data.parse(data.data);
        //     });
        //
        //     checkIn_purchaserGrid.data.removeAll();
        //     axios.post(apiServer+"/dashboard/loadPurchaserGrid", {
        //         itemId1: orderId
        //     },headers).then((data) =>{
        //         checkIn_purchaserGrid.data.parse(data.data);
        //     });
        //
        //     checkIn_guestGrid.data.removeAll();
        //     axios.post(apiServer+"/orders/loadGuestGrid", {
        //         itemId1: orderId
        //     },headers).then((data) =>{
        //         checkIn_guestGrid.data.parse(data.data);
        //     });
        //     dhxCheckInWindow.show();
        //
        // });

        checkInLayout1.getCell("checkIn_orderInfoGrid").attach(checkIn_orderInfoGrid);

        dhxCheckInWindow.attach(checkInLayout1);

        //編輯訂單視窗_產品資訊_房間
        let checkIn_roomGrid = new DHXGrid(null,{
            columns: checkIn_roomGridColumn,
            autoWidth: true,
            resizable: true,
            rowCss: function (row) {
                if(row.roomMatchStatus == "0"){
                    return "room_noRoom"
                }
            }
        });
        checkInLayout1.getCell("checkIn_roomGrid").attach(checkIn_roomGrid);

        //編輯訂單視窗_產品資訊_加價產品消費
        let checkIn_otherPrdGrid = new DHXGrid(null,{
            columns: checkIn_otherPrdGridColumn,
            autoWidth: true,
            resizable: true,
        });
        checkInLayout1.getCell("checkIn_otherPrdGrid").attach(checkIn_otherPrdGrid);

        //編輯訂單視窗_產品資訊_房間
        let checkIn_purchaserGrid = new DHXGrid(null,{
            columns: checkIn_purchaserGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "checkIn_purchaserGrid_edit": function (e, data) {
                        if(canEdit){
                            checkIn_purchaserGridForm.getItem("AA001").setValue(data.row.id);

                            axios.get(apiServer+"/orders/loadCountiesList",headers).then((response) =>{
                                checkIn_purchaserGridForm.getItem("AA004").setOptions(response.data);
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });

                            axios.post(apiServer+"/orders/loadOrderFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                checkIn_purchaserGridForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });
                            dhxCheckIn_purchaserGridWindow1.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                }
            }
        });

        //編輯訂單視窗_產品資訊_編輯訂房人視窗
        const dhxCheckIn_purchaserGridWindow1 = new DHXWindow({
            title: "編輯訂房人視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:700,
            height: 500,
            footer: true,
            css: "dhxCheckIn_purchaserGridWindow1"
        });

        dhxCheckIn_purchaserGridWindow1.footer.data.add({type:"spacer"});
        dhxCheckIn_purchaserGridWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxCheckIn_purchaserGridWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxCheckIn_purchaserGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":

                    if(checkIn_purchaserGridForm.getItem("AA106").getValue() === ""){
                        DHXMessage({
                            text: "請填入姓名",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    // if(checkIn_purchaserGridForm.getItem("AA109").getValue() === ""){
                    //     DHXMessage({
                    //         text: "請填入電話",
                    //         icon:"dxi dxi-close",
                    //         css: "dhx_message--error",
                    //         expire:5000
                    //     });
                    //     break;
                    // }

                    dhxProgressWindow1.show();

                    axios.post(apiServer+"/orders/updatePurchaser",
                        checkIn_purchaserGridForm.getValue(),
                        headers).then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    DHXMessage({
                                        text: response.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--success",
                                        expire:5000
                                    });

                                    checkIn_purchaserGridForm.clear();
                                    checkIn_purchaserGridForm.setValue({"AA001": "0"});

                                    dhxCheckIn_purchaserGridWindow1.hide();

                                    reloadGrid();

                                    axios.post(apiServer+"/dashboard/loadPurchaserGrid", {
                                        itemId1: orderId
                                    },headers).then((data) =>{
                                        checkIn_purchaserGrid.data.removeAll();
                                        checkIn_purchaserGrid.data.parse(data.data);
                                    });

                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{

                            }

                    })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxCheckIn_purchaserGridWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const checkIn_purchaserGridForm = new DHXForm(null,{
            rows: checkIn_purchaserGridFormConfig
        });

        dhxCheckIn_purchaserGridWindow1.attach(checkIn_purchaserGridForm);
        checkInLayout1.getCell("checkIn_purchaserGrid").attach(checkIn_purchaserGrid);



        // 編輯訂單視窗_訂單資訊_入住人
        let checkIn_guestGrid = new DHXGrid(null,{
            columns: checkIn_guestGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "checkIn_guestGrid_edit": function (e, data) {
                        if(canEdit){
                            checkIn_guestGridForm.clear();
                            dhxCheckIn_guestGridWindow1.header.data.update("title", {value: "編輯入住人視窗"});

                            checkIn_guestGridForm.getItem("AF001").setValue(data.row.id);

                            axios.post(apiServer+"/orders/loadGuestFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                checkIn_guestGridForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });

                            dhxCheckIn_guestGridWindow1.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    },
                    "checkIn_guestGrid_delete": function (e, data) {
                        if(canDelete){
                            checkIn_guestGrid_deleteForm.getItem("AF001").setValue(data.row.id);
                            axios.post(apiServer+"/orders/loadGuestFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                checkIn_guestGrid_deleteForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });
                            dhxCheckIn_guestGridWindow2.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            },
        });

        const checkIn_guestGridToolbar = new DHXToolbar(null, {
        });
        checkIn_guestGridToolbar.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){

                        checkIn_guestGridForm.clear();
                        dhxCheckIn_guestGridWindow1.header.data.update("title", {value: "新增入住人視窗"});
                        checkIn_guestGridForm.setValue({"AF001":"0","AF002":hotel,"AF003":orderId});
                        axios.get(apiServer+"/orders/loadCountiesList",headers)
                            .then((response) =>{
                                checkIn_guestGridForm.getItem("AF004").setOptions(response.data);
                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--danger",
                                    expire:5000
                                });
                            });
                        dhxCheckIn_guestGridWindow1.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }
                    break;
                default:
                    break;
            }
        });

        checkIn_guestGridToolbar.data.parse(JSON.parse(JSON.stringify(checkIn_guestGridToolbarConfig)));

        checkInLayout1.getCell("checkIn_guestGridToolbar").attach(checkIn_guestGridToolbar);

        //編輯訂單視窗_訂單資訊_入住人_新增/編輯入住人視窗
        const dhxCheckIn_guestGridWindow1 = new DHXWindow({
            title: "新增入住人視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:700,
            height: 500,
            footer: true,
            css: "dhxCheckIn_guestGridWindow1"
        });

        dhxCheckIn_guestGridWindow1.footer.data.add({type:"spacer"});
        dhxCheckIn_guestGridWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxCheckIn_guestGridWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxCheckIn_guestGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    if(checkIn_guestGridForm.getItem("AF109").getValue() === ""){
                        DHXMessage({
                            text: "請填入姓名",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    // if(checkIn_guestGridForm.getItem("AF115").getValue() === ""){
                    //     DHXMessage({
                    //         text: "請填入電話",
                    //         icon:"dxi dxi-close",
                    //         css: "dhx_message--error",
                    //         expire:5000
                    //     });
                    //     break;
                    // }

                    axios.post(apiServer+"/orders/updateGuestFormData",
                        checkIn_guestGridForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: response.data[0].message,
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            axios.post(apiServer+"/dashboard/loadGuestGrid", {
                                itemId1: orderId
                            },headers).then((data) =>{
                                checkIn_guestGrid.data.removeAll();
                                checkIn_guestGrid.data.parse(data.data);
                            });

                            checkIn_guestGridForm.clear();
                            checkIn_guestGridForm.setValue({"AF001": "0"});

                            dhxCheckIn_guestGridWindow1.hide();
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxCheckIn_guestGridWindow1.hide();
                    checkIn_guestGridForm.clear();
                    break;
                default:
                    break;
            }
        });

        const checkIn_guestGridForm = new DHXForm(null,{
            rows: checkIn_guestGridFormConfig
        });

        dhxCheckIn_guestGridWindow1.attach(checkIn_guestGridForm);

        //編輯訂單視窗_訂單資訊_入住人_刪除入住人視窗
        const dhxCheckIn_guestGridWindow2 = new DHXWindow({
            title: "刪除入住人視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 550,
            footer: true,
            css: "dhxCheckIn_guestGridWindow2"
        });

        dhxCheckIn_guestGridWindow2.footer.data.add({type:"spacer"});
        dhxCheckIn_guestGridWindow2.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxCheckIn_guestGridWindow2.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxCheckIn_guestGridWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    if(checkIn_guestGrid_deleteForm.getItem("AF125").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    checkIn_guestGrid_deleteFormParams = checkIn_guestGrid_deleteForm.getValue();
                    axios.post(apiServer+"/orders/deleteGuest",checkIn_guestGrid_deleteForm.getValue(),headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data[0].result > 0){
                                    DHXMessage({
                                        text: "更新成功",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--success",
                                        expire:5000
                                    });

                                    checkIn_guestGrid.data.removeAll();

                                    axios.post(apiServer+"/orders/loadGuestGrid", {
                                        itemId1: orderId
                                    },headers).then((data) =>{
                                        checkIn_guestGrid.data.removeAll();
                                        checkIn_guestGrid.data.parse(data.data);
                                    });

                                    dhxCheckIn_guestGridWindow2.hide();
                                    checkIn_guestGrid_deleteForm.clear();
                                    checkIn_guestGrid_deleteForm.setValue({"AF001": "0","AA001": "0"});
                                    checkIn_guestGrid_deleteForm.clear("validation");
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--danger",
                                    expire:5000
                                });
                            }

                        }).catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxCheckIn_guestGridWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let checkIn_guestGrid_deleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"checkIn_guestGrid_deleteFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "checkIn_guestGrid_deleteForm", css:"guestGrid_deleteForm"},
            ]
        });

        const checkIn_guestGrid_deleteForm = new DHXForm(null,{
            rows: checkIn_guestGridDeleteFormConfig
        });

        let checkIn_guestGrid_deleteFormParams = checkIn_guestGrid_deleteForm.getValue();
        dhxCheckIn_guestGridWindow2.attach(checkIn_guestGrid_deleteFormLayout);
        checkIn_guestGrid_deleteFormLayout.getCell("checkIn_guestGrid_deleteForm").attach(checkIn_guestGrid_deleteForm);

        checkInLayout1.getCell("checkIn_purchaserGrid").attach(checkIn_purchaserGrid);
        checkInLayout1.getCell("checkIn_guestGrid").attach(checkIn_guestGrid);

        //入住資訊視窗_付款紀錄
        let checkIn_payLogGrid = new DHXGrid(null,{
            columns: productInfo_payLogGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "productInfo_payLogGrid_edit": function (e, data) {
                        if(canEdit){

                            checkIn_payLogGridForm.clear();

                            axios.get(apiServer+"/orders/loadPaymentMethodList", headers)
                                .then((response) =>{
                                    if(response.status === 200){
                                        checkIn_payLogGridForm.getItem("AH004").setOptions(response.data);
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });

                            dhxCheckIn_payLogGridWindow1.header.data.update("title", {value: "編輯付款紀錄視窗"});
                            axios.post(apiServer+"/orders/loadProductInfo_payLogGridFormData", {
                                itemId1: data.row.id
                            },headers).then((response1) =>{
                                if(response1.status === 200) {
                                    checkIn_payLogGridForm.setValue(response1.data[0]);
                                    dhxCheckIn_payLogGridWindow1.show();
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    },
                    "productInfo_payLogGrid_delete": function (e, data) {
                        if(canDelete){
                            axios.post(apiServer+"/orders/loadProductInfo_payLogGridFormData", {
                                itemId1: data.row.id
                            },headers).then((response) => {
                                if (response.status === 200) {
                                    checkIn_payLogGrid_deleteForm.setValue(response.data[0])
                                }
                            });
                            dhxCheckIn_payLogGridWindow2.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    }
                }
            }
        });

        const checkIn_payLogGridToolbar = new DHXToolbar(null, {
        });

        checkIn_payLogGridToolbar.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        checkIn_payLogGridForm.clear();
                        dhxCheckIn_payLogGridWindow1.header.data.update("title", {value: "新增付款紀錄視窗"});
                        checkIn_payLogGridForm.setValue({"AH001":"0","AH002":orderId,"AH003":hotel});

                        axios.get(apiServer+"/orders/loadPaymentMethodList", headers)
                            .then((response) =>{
                                if(response.status === 200){
                                    checkIn_payLogGridForm.getItem("AH004").setOptions(response.data);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--danger",
                                    expire:5000
                                });
                            });

                        dhxCheckIn_payLogGridWindow1.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    break;
                case "sendNewPayLink":
                    checkIn_sendNewPayLinkForm.setValue({"AI002":orderId,"AI003":hotel,"AI106":orderSourceType});

                    axios.post(apiServer+"/orders/loadItemDesc", {
                        itemId1: orderForm.getItem("AA001").getValue()
                    },headers).then((response) =>{
                        if(response.status === 200){
                            if (response.data){
                                checkIn_sendNewPayLinkForm.getItem("AI100").setValue(response.data[0].result);
                                checkIn_sendNewPayLinkForm.getItem("AI101").setValue("請在期限內付款");
                                checkIn_sendNewPayLinkForm.getItem("AI104").setValue(response.data[0].price);
                            }
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        });
                    dhxCheckIn_sendNewPayLinkFormWindow1.show();
                    break;
                default:
                    break;
            }
        });

        checkIn_payLogGridToolbar.data.parse(JSON.parse(JSON.stringify(productInfo_payLogGridToolbarConfig)));

        checkInLayout1.getCell("checkIn_payLogGridToolbar").attach(checkIn_payLogGridToolbar);
        checkInLayout1.getCell("checkIn_payLogGrid").attach(checkIn_payLogGrid);

        //入住資訊視窗_付款紀錄
        const dhxCheckIn_payLogGridWindow1 = new DHXWindow({
            title: "新增付款紀錄視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 430,
            footer: true,
            css:"dhxCheckIn_payLogGridWindow1"
        });

        dhxCheckIn_payLogGridWindow1.footer.data.add({type:"spacer"});
        dhxCheckIn_payLogGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxCheckIn_payLogGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxCheckIn_payLogGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(checkIn_payLogGridForm.getItem("AH004").getValue() === "" || checkIn_payLogGridForm.getItem("AH004").getValue() === "0"){
                        DHXMessage({
                            text: "請選擇付款方式",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(checkIn_payLogGridForm.getItem("AH100").getValue() === ""){
                        DHXMessage({
                            text: "請填入付款金額",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    dhxProgressWindow1.show();

                    let checkIn_payLogGridFormValue = Object.assign({},checkIn_payLogGridForm.getValue(), {TMPId: "0"});
                    axios.post(apiServer+"/orders/updateProductInfo_payLogGridFormData",
                        checkIn_payLogGridFormValue,headers
                    ).then((response) =>{
                        if(response.data){
                            DHXMessage({
                                text: response.data[0].message,
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            reloadGrid();

                            axios.post(apiServer+"/orders/loadPayLogGrid", {
                                itemId1: orderId
                            },headers).then((data) =>{
                                checkIn_payLogGrid.data.removeAll();
                                checkIn_payLogGrid.data.parse(data.data);

                                dhxCheckIn_payLogGridWindow1.hide();
                                checkIn_payLogGridForm.clear();
                                checkIn_payLogGridForm.clear("validation");
                            });

                            axios.post(apiServer+"/dashboard/loadOrderInfoGrid",{itemId1:orderId},headers)
                                .then((data) =>{
                                    if(data.status === 200){
                                        if(data.data){
                                            checkIn_orderInfoGrid.data.removeAll();
                                            checkIn_orderInfoGrid.data.parse(data.data);
                                            if(data.data[0].unpaid > 0){
                                                checkIn_payLogGridToolbar.enable("sendNewPayLink");
                                            }else{
                                                checkIn_payLogGridToolbar.disable("sendNewPayLink");
                                            }
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });

                            // axios.post(apiServer+"/orders/loadOrderFormData", {
                            //     itemId1: orderForm.getItem("AA001").getValue()
                            // },headers).then((response2) =>{
                            //     if(response2.status === 200){
                            //         if(response2.data){
                            //             orderForm.setValue(response2.data[0]);
                            //             if(response2.data[0].unpaid > 0){
                            //                 checkIn_payLogGridToolbar.enable("sendNewPayLink");
                            //             }else{
                            //                 checkIn_payLogGridToolbar.disable("sendNewPayLink");
                            //             }
                            //             if(response2.data[0].AA137 == "1"){
                            //                 orderForm.getItem("AA101_1").hide();
                            //                 orderForm.getItem("AA101_2").show();
                            //                 orderForm.getItem("AA101_2").setOptions(response2.data[0].orderSourceItem);
                            //                 orderForm.getItem("AA101_2").setValue(response2.data[0].AA006)
                            //             }else if(response2.data[0].AA137 == "2"){
                            //                 orderForm.getItem("AA101_1").show(response2.data[0].AA101);
                            //                 orderForm.getItem("AA101_2").setValue()
                            //                 orderForm.getItem("AA101_2").hide();
                            //             }
                            //         }
                            //     }
                            //
                            // })
                            //     .catch((error) => {
                            //         console.log(error);
                            //     });
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                    .catch((error) => {
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxCheckIn_payLogGridWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const checkIn_payLogGridForm = new DHXForm(null,{
            rows: productInfo_payLogGridFormConfig
        });

        dhxCheckIn_payLogGridWindow1.attach(checkIn_payLogGridForm);


        //入住資訊視窗_刪除付款紀錄視窗
        const dhxCheckIn_payLogGridWindow2 = new DHXWindow({
            title: "刪除付款紀錄視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 680,
            footer: true,
            css:"dhxCheckIn_payLogGridWindow2"
        });

        dhxCheckIn_payLogGridWindow2.footer.data.add({type:"spacer"});
        dhxCheckIn_payLogGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxCheckIn_payLogGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxCheckIn_payLogGridWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(checkIn_payLogGrid_deleteForm.getItem("AH103").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    dhxProgressWindow1.show();
                    axios.post(apiServer+"/orders/deletepayLog",
                        checkIn_payLogGrid_deleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            axios.post(apiServer+"/orders/loadPayLogGrid", {
                                itemId1: orderId
                            },headers).then((data) =>{
                                checkIn_payLogGrid.data.removeAll();
                                checkIn_payLogGrid.data.parse(data.data);

                                dhxCheckIn_payLogGridWindow2.hide();
                                checkIn_payLogGridForm.clear();
                                checkIn_payLogGridForm.clear("validation");
                            });

                            axios.post(apiServer+"/dashboard/loadOrderInfoGrid",{itemId1:orderId},headers)
                                .then((data) =>{
                                    if(data.status === 200){
                                        if(data.data){
                                            dhxProgressWindow1.hide();
                                            checkIn_orderInfoGrid.data.removeAll();
                                            checkIn_orderInfoGrid.data.parse(data.data);
                                            if(data.data[0].unpaid > 0){
                                                checkIn_payLogGridToolbar.enable("sendNewPayLink");
                                            }else{
                                                checkIn_payLogGridToolbar.disable("sendNewPayLink");
                                            }
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxCheckIn_payLogGridWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let checkIn_payLogGrid_deleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"checkIn_payLogGrid_deleteFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "checkIn_payLogGrid_deleteForm"},
            ]
        });

        const checkIn_payLogGrid_deleteForm = new DHXForm(null,{
            rows: productInfo_payLogGridDeleteFormConfig
        });

        dhxCheckIn_payLogGridWindow2.attach(checkIn_payLogGrid_deleteFormLayout);
        checkIn_payLogGrid_deleteFormLayout.getCell("checkIn_payLogGrid_deleteForm").attach(checkIn_payLogGrid_deleteForm);

        //發送金流鏈結
        const dhxCheckIn_sendNewPayLinkFormWindow1 = new DHXWindow({
            title: "發送金流鏈結視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 500,
            footer: true,
            css:"dhxCheckIn_sendNewPayLinkFormWindow1"
        });

        dhxCheckIn_sendNewPayLinkFormWindow1.footer.data.add({type:"spacer"});
        dhxCheckIn_sendNewPayLinkFormWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxCheckIn_sendNewPayLinkFormWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootSend)));

        dhxCheckIn_sendNewPayLinkFormWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_SEND":
                    if(checkIn_sendNewPayLinkForm.getItem("AI100").getValue() === ""){
                        DHXMessage({
                            text: "請填寫商品敘述",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(checkIn_sendNewPayLinkForm.getItem("AI104").getValue() === ""){
                        DHXMessage({
                            text: "請填寫金額",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/orders/sendPayLink",
                        checkIn_sendNewPayLinkForm.getValue()
                        ,headers).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                DHXMessage({
                                    text: "發送成功",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });

                                checkIn_sendNewPayLinkForm.getItem("AI105").setValue(response.data[0].result);

                            }
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxCheckIn_sendNewPayLinkFormWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const checkIn_sendNewPayLinkForm = new DHXForm(null,{
            rows: productInfo_sendNewPayLinkFormConfig
        });

        dhxCheckIn_sendNewPayLinkFormWindow1.attach(checkIn_sendNewPayLinkForm);

        //換房視窗
        const changeRoomFormWindow1 = new DHXWindow({
            title: "換房視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 580,
            footer: true,
            css:"changeRoomFormWindow1"
        });

        changeRoomFormWindow1.footer.data.add({type:"spacer"});
        changeRoomFormWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        changeRoomFormWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        changeRoomFormWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    switch (bookingType) {
                        case 1:
                            changeRoom_roomForm.getItem("AG132").setValue("1");
                            if(changeRoom_roomForm.getItem("AG119").getValue() == ""){
                                DHXMessage({
                                    text: "請選擇入住日期",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(changeRoom_roomForm.getItem("AG120").getValue() == ""){
                                DHXMessage({
                                    text: "請選擇退房日期",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(Date.parse(changeRoom_roomForm.getItem("AG119").getValue()).valueOf() > Date.parse(changeRoom_roomForm.getItem("AG120").getValue()).valueOf()){
                                DHXMessage({
                                    text: "入住日期必須早於退房日期",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }


                            changeRoom_roomForm_AG004 = changeRoom_roomForm.getItem("AG004").getWidget();
                            if(changeRoom_roomForm_AG004.getValue() == "0"){
                                DHXMessage({
                                    text: "請選擇房型",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            changeRoom_roomForm_AG005 = changeRoom_roomForm.getItem("AG005").getWidget();
                            if(changeRoom_roomForm_AG005.getValue() == "0"){
                                DHXMessage({
                                    text: "請選擇房間名稱",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(changeRoom_roomForm.getItem("needReportFault").getValue() == true && changeRoom_roomForm.getItem("AA103").getValue() == ""){
                                DHXMessage({
                                    text: "請填寫通報日期",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(changeRoom_roomForm.getItem("needReportFault").getValue() == true && changeRoom_roomForm.getItem("AA104").getValue() == ""){
                                DHXMessage({
                                    text: "請填寫鎖定開始日期",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(changeRoom_roomForm.getItem("needReportFault").getValue() == true && changeRoom_roomForm.getItem("AA105").getValue() == ""){
                                DHXMessage({
                                    text: "請填寫鎖定結束日期",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(changeRoom_roomForm.getItem("needReportFault").getValue() == true && (changeRoom_roomForm.getItem("AA006").getValue() == "" || changeRoom_roomForm.getItem("AA006").getValue() == "0")){
                                DHXMessage({
                                    text: "請填寫故障原因",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            dhxProgressWindow1.show();

                            axios.post(apiServer+"/dashboard/changeRoom", {
                                itemId1: changeRoom_roomForm.getValue()
                            },headers).then((data) =>{
                                if(data.status === 200){
                                    if(data.data[0].result === 1){
                                        changeRoomFormWindow1.hide();
                                        reloadGrid();
                                        DHXMessage({
                                            text: data.data[0].message,
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--success",
                                            expire:5000
                                        });

                                    }else if(data.data[0].result === 9){
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: data.data[0].message,
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                };
                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                            break;

                        case 3:
                            changeRoom_restRoomForm.getItem("AG132").setValue("3");
                            if(changeRoom_restRoomForm.getItem("AG119").getValue() == ""){
                                DHXMessage({
                                    text: "請選擇入住日期",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(changeRoom_restRoomForm.getItem("AA103_1").getValue() == "" && changeRoom_restRoomForm.getItem("AA103_2").getValue() == "" ){
                                DHXMessage({
                                    text: "請設定分鐘或小時",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            changeRoom_restRoomForm_AG004 = changeRoom_restRoomForm.getItem("AG004").getWidget();
                            if(changeRoom_restRoomForm_AG004.getValue() == "0" || changeRoom_restRoomForm_AG004.getValue() == ""){
                                DHXMessage({
                                    text: "請選擇房型",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            changeRoom_restRoomForm_AG005 = changeRoom_restRoomForm.getItem("AG005").getWidget();
                            if(changeRoom_restRoomForm_AG005.getValue() == "0" || changeRoom_restRoomForm_AG005.getValue() == ""){
                                DHXMessage({
                                    text: "請選擇房間名稱",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(changeRoom_restRoomForm.getItem("needReportFault").getValue() == true && changeRoom_restRoomForm.getItem("AA103").getValue() == ""){
                                DHXMessage({
                                    text: "請填寫通報日期",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(changeRoom_restRoomForm.getItem("needReportFault").getValue() == true && changeRoom_restRoomForm.getItem("AA104").getValue() == ""){
                                DHXMessage({
                                    text: "請填寫鎖定開始日期",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(changeRoom_restRoomForm.getItem("needReportFault").getValue() == true && changeRoom_restRoomForm.getItem("AA105").getValue() == ""){
                                DHXMessage({
                                    text: "請填寫鎖定結束日期",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(changeRoom_restRoomForm.getItem("needReportFault").getValue() == true && (changeRoom_restRoomForm.getItem("AA006").getValue() == "" || changeRoom_restRoomForm.getItem("AA006").getValue() == "0")){
                                DHXMessage({
                                    text: "請填寫故障原因",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            dhxProgressWindow1.show();

                            axios.post(apiServer+"/dashboard/changeRoom", {
                                itemId1: changeRoom_restRoomForm.getValue()
                            },headers).then((data) =>{
                                if(data.status === 200){
                                    if(data.data[0].result === 1){
                                        changeRoomFormWindow1.hide();
                                        reloadGrid();
                                        DHXMessage({
                                            text: data.data[0].message,
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--success",
                                            expire:5000
                                        });

                                    }else if(data.data[0].result === 9){
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: data.data[0].message,
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                };
                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                            break;
                    }
                    break;
                case "WINDOW_FOOT_CLOSE":
                    changeRoomFormWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const changeRoom_roomForm = new DHXForm(null,{
            rows: changeRoom_roomFormConfig
        });

        changeRoom_roomForm.getItem("needReportFault").events.on("change", function(value) {
            if(value == true){
                axios.get(apiServer+"/faultReport/loadFaultTermList",headers)
                    .then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                changeRoom_roomForm.getItem("AA006").setOptions(response.data);
                                changeRoom_roomForm.clear("validation");
                                changeRoom_roomForm.getItem("AA006").setValue("0")
                                changeRoom_roomForm.getItem("roomName").enable();
                                changeRoom_roomForm.getItem("AA006").enable();
                                changeRoom_roomForm.getItem("AA103").enable();
                                changeRoom_roomForm.getItem("AA104").enable();
                                changeRoom_roomForm.getItem("AA105").enable();
                                changeRoom_roomForm.getItem("AA106").enable();
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                })
                    .catch((error) => {
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    });

            }else{
                changeRoom_roomForm.getItem("roomName").disable();
                changeRoom_roomForm.getItem("AA006").disable();
                changeRoom_roomForm.getItem("AA103").disable();
                changeRoom_roomForm.getItem("AA104").disable();
                changeRoom_roomForm.getItem("AA105").disable();
                changeRoom_roomForm.getItem("AA106").disable();
            }
        });

        changeRoom_roomForm.events.on("change", function(name, value) {
            switch (name){
                case "AG004":
                    if(isNeedFirstChangeEvent){
                        if(changeRoom_roomForm.getItem("AG004").getValue() != '0' && changeRoom_roomForm.getItem("AG004").getValue() != ''){
                            dhxProgressWindow1.show();
                            axios.post(apiServer+"/orders/EditLoadRoomList", {
                                itemId1: hotel,itemId2: changeRoom_roomForm.getItem("AG001").getValue(),itemId3: changeRoom_roomForm.getItem("AG119").getValue(),itemId4: changeRoom_roomForm.getItem("AG120").getValue(),itemId5: changeRoom_roomForm.getItem("AG004").getValue()
                            },headers).then((response3) =>{
                                if(response3.status === 200){
                                    if(response3.data){
                                        changeRoom_roomForm_AG005 = changeRoom_roomForm.getItem("AG005").getWidget();
                                        changeRoom_roomForm_AG005.data.removeAll();
                                        changeRoom_roomForm_AG005.data.parse(response3.data);
                                        changeRoom_roomForm.getItem("AG005").setValue("0");
                                        changeRoom_roomForm.getItem("AG005").enable();
                                        dhxProgressWindow1.hide();
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--danger",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--danger",
                                    expire:5000
                                });
                            });
                        }else{
                            // changeRoom_roomForm.getItem("AG005").disable();
                            // changeRoom_roomForm.getItem("AG005").setValue("0");
                        }
                    }else{

                    }
                    break;
            }
        });

        const changeRoom_restRoomForm = new DHXForm(null,{
            rows: changeRoom_restRoomFormConfig
        });

        changeRoom_restRoomForm.getItem("needReportFault").events.on("change", function(value) {
            if(value == true){
                axios.get(apiServer+"/faultReport/loadFaultTermList",headers)
                    .then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                changeRoom_restRoomForm.getItem("AA006").setOptions(response.data);
                                changeRoom_restRoomForm.clear("validation");
                                changeRoom_restRoomForm.getItem("AA006").setValue("0")
                                changeRoom_restRoomForm.getItem("roomName").enable();
                                changeRoom_restRoomForm.getItem("AA006").enable();
                                changeRoom_restRoomForm.getItem("AA103").enable();
                                changeRoom_restRoomForm.getItem("AA104").enable();
                                changeRoom_restRoomForm.getItem("AA105").enable();
                                changeRoom_restRoomForm.getItem("AA106").enable();
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                    .catch((error) => {
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    });

            }else{
                changeRoom_restRoomForm.getItem("roomName").disable();
                changeRoom_restRoomForm.getItem("AA006").disable();
                changeRoom_restRoomForm.getItem("AA103").disable();
                changeRoom_restRoomForm.getItem("AA104").disable();
                changeRoom_restRoomForm.getItem("AA105").disable();
                changeRoom_restRoomForm.getItem("AA106").disable();
            }
        });

        changeRoom_restRoomForm.events.on("change", function(name, value) {
            switch (name){
                case "AA103_1":
                    AA103 = 1;
                    AA104 = productInfo_restRoomGridForm.getItem("AA104_1").getValue();
                    if(value == ""){
                        productInfo_restRoomGridForm.getItem("AA104_1").disable();
                    }else{
                        productInfo_restRoomGridForm.getItem("AA104_1").enable();
                        productInfo_restRoomGridForm.getItem("AA104_2").disable();
                        productInfo_restRoomGridForm.getItem("AA103_2").clear();
                    }
                    break;
                case "AA103_2":
                    AA103 = 2;
                    AA104 = productInfo_restRoomGridForm.getItem("AA104_2").getValue();
                    if(value == ""){
                        productInfo_restRoomGridForm.getItem("AA104_2").disable();
                    }else{
                        productInfo_restRoomGridForm.getItem("AA104_1").disable();
                        productInfo_restRoomGridForm.getItem("AA104_2").enable();
                        productInfo_restRoomGridForm.getItem("AA103_1").clear();
                    }
                    break;
                case "AA104_1":
                    AA104 = value;
                    if(value == ""){
                    }else{

                    }
                    break;
                case "AA104_2":
                    AA104 = value;
                    if(value == ""){
                        productInfo_restRoomGridForm.getItem("AA104_2").disable();
                    }else{
                        productInfo_restRoomGridForm.getItem("AA104_1").disable();
                        productInfo_restRoomGridForm.getItem("AA104_2").enable();
                        productInfo_restRoomGridForm.getItem("AA103_1").clear();
                    }
                    break;
                case "AG004":
                    if(isNeedFirstChangeEventRest){
                        if(value == "0" || value == ""){
                            changeRoom_restRoomForm.getItem("AG005").disable();
                            changeRoom_restRoomForm.getItem("AG005").setValue("0");
                        }else{
                            changeRoom_restRoomForm.getItem("AG005").enable();
                            changeRoom_restRoomForm.getItem("AG005").setValue("0");

                            if(changeRoom_restRoomForm.getItem("AA103_1").getValue() == 1){
                                AA103 = 1;
                                AA104 = changeRoom_restRoomForm.getItem("AA104_1").getValue();
                                restTypeText = '分鐘';
                            }else{
                                AA103 = 2;
                                AA104 = changeRoom_restRoomForm.getItem("AA104_2").getValue();
                                restTypeText = '小時';
                            }

                            axios.post(apiServer+"/orders/EditloadRestRoomList", {
                                itemId1: hotel,itemId2: changeRoom_restRoomForm.getItem("AG001").getValue(),itemId3: changeRoom_restRoomForm.getItem("AG119").getValue(),itemId4:AA103,itemId5:AA104,itemId6:value
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        changeRoom_restRoomForm_AG005 = changeRoom_restRoomForm.getItem("AG005").getWidget();
                                        changeRoom_restRoomForm_AG005.data.removeAll();
                                        changeRoom_restRoomForm_AG005.data.parse(response.data);
                                        changeRoom_restRoomForm.getItem("AG005").setValue("0");
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                        }
                    }

                    break;
            }
        });



        // let dayString2 = '',totalString2 = '';
        //
        // newOrder_roomGridForm.events.on("change", function(name, value) {
        //     if(TMPId !== "0"){
        //         //新增
        //         switch (name){
        //             case "AG119":
        //             case "AG120":
        //                 let newOrder_roomGridForm_AG119 = newOrder_roomGridForm.getItem("AG119").getValue();
        //                 let newOrder_roomGridForm_AG120 = newOrder_roomGridForm.getItem("AG120").getValue();
        //                 if(newOrder_roomGridForm_AG119 != '' && newOrder_roomGridForm_AG120 != ''){
        //                     if(Date.parse(newOrder_roomGridForm_AG119).valueOf() > Date.parse(newOrder_roomGridForm_AG120).valueOf()){
        //                         DHXMessage({
        //                             text: "入住日期必須早於退房日期",
        //                             icon:"dxi dxi-close",
        //                             css: "dhx_message--error",
        //                             expire:5000
        //                         });
        //                     }else{
        //
        //                         if(isNeedFirstChangeEvent){
        //                             axios.post(apiServer+"/orders/loadRoomTypeList", {
        //                                 itemId1: hotel,itemId2: newOrder_roomGridForm.getItem("AG001").getValue(),itemId3: newOrder_roomGridForm.getItem("AG119").getValue(),itemId4: newOrder_roomGridForm.getItem("AG120").getValue(),itemId5:TMPId
        //                             },headers).then((response) =>{
        //                                 newOrder_roomGridForm_AG004 = newOrder_roomGridForm.getItem("AG004").getWidget();
        //                                 newOrder_roomGridForm_AG004.data.removeAll();
        //                                 newOrder_roomGridForm_AG004.data.parse(response.data);
        //                                 newOrder_roomGridForm.getItem("AG004").setValue("0");
        //                             })
        //                                 .catch((error) => {
        //                                     DHXMessage({
        //                                         text: "連線異常，請重新整理",
        //                                         icon:"dxi dxi-close",
        //                                         css: "dhx_message--error",
        //                                         expire:5000
        //                                     });
        //                                 });
        //                         }
        //
        //                         if(new Date(newOrder_roomGridForm.getItem("AG120").getValue()) == new Date(newOrder_roomGridForm.getItem("AG119").getValue())){
        //                             dayString2 = 1;
        //                         }else{
        //                             dayString2 = DateDiff(getDate(newOrder_roomGridForm.getItem("AG119").getValue()),getDate(newOrder_roomGridForm.getItem("AG120").getValue()));
        //                         }
        //
        //                         newOrder_roomGridForm.getItem("total").setValue("共 "+dayString2+" 晚");
        //
        //                         newOrder_roomGridForm.getItem("AG004").enable();
        //                         newOrder_roomGridForm.getItem("AG004").setValue("0");
        //                         newOrder_roomGridForm.getItem("AG005").disable();
        //                         newOrder_roomGridForm.getItem("AG005").setValue("0");
        //                         newOrder_roomGridForm.getItem("AG127").disable();
        //                         newOrder_roomGridForm.getItem("AG127").setValue("0");
        //                         newOrder_roomGridForm.getItem("AG116").disable();
        //                         newOrder_roomGridForm.getItem("AG116").setValue("0");
        //                     }
        //                 }else{
        //
        //                     newOrder_roomGridForm.getItem("total").setValue("");
        //                     newOrder_roomGridForm.getItem("AG004").disable();
        //                     newOrder_roomGridForm.getItem("AG004").setValue("0");
        //                     newOrder_roomGridForm.getItem("AG005").disable();
        //                     newOrder_roomGridForm.getItem("AG005").setValue("0");
        //                     newOrder_roomGridForm.getItem("AG127").disable();
        //                     newOrder_roomGridForm.getItem("AG127").setValue("0");
        //                     newOrder_roomGridForm.getItem("AG116").disable();
        //                     newOrder_roomGridForm.getItem("AG116").setValue("0");
        //                 }
        //                 break;
        //             case "AG004":
        //                 if(newOrder_roomGridForm.getItem("AG004").getValue() != '0' && newOrder_roomGridForm.getItem("AG004").getValue() != ''){
        //
        //                     if(isNeedFirstChangeEvent){
        //                         axios.post(apiServer+"/orders/loadRoomList", {
        //                             itemId1: hotel,itemId2: newOrder_roomGridForm.getItem("AG001").getValue(),itemId3: newOrder_roomGridForm.getItem("AG119").getValue(),itemId4: newOrder_roomGridForm.getItem("AG120").getValue(),itemId5: newOrder_roomGridForm.getItem("AG004").getValue(),itemId6: TMPId
        //                         },headers).then((response) =>{
        //                             newOrder_roomGridForm_AG005 = newOrder_roomGridForm.getItem("AG005").getWidget();
        //                             newOrder_roomGridForm_AG005.data.removeAll();
        //                             newOrder_roomGridForm_AG005.data.parse(response.data);
        //                         })
        //                             .catch((error) => {
        //                                 DHXMessage({
        //                                     text: "連線異常，請重新整理",
        //                                     icon:"dxi dxi-close",
        //                                     css: "dhx_message--error",
        //                                     expire:5000
        //                                 });
        //                             });
        //                     }
        //                     newOrder_roomGridForm.getItem("AG005").enable();
        //                     newOrder_roomGridForm.getItem("AG005").setValue("0");
        //                     newOrder_roomGridForm.getItem("AG127").disable();
        //                     newOrder_roomGridForm.getItem("AG127").setValue("0");
        //                     newOrder_roomGridForm.getItem("AG116").disable();
        //                     newOrder_roomGridForm.getItem("AG116").setValue("0");
        //                 }else{
        //                     newOrder_roomGridForm.getItem("AG005").disable();
        //                     newOrder_roomGridForm.getItem("AG005").setValue("0");
        //                     newOrder_roomGridForm.getItem("AG127").disable();
        //                     newOrder_roomGridForm.getItem("AG127").setValue("0");
        //                     newOrder_roomGridForm.getItem("AG116").disable();
        //                     newOrder_roomGridForm.getItem("AG116").setValue("0");
        //                 }
        //                 break;
        //             case "AG005":
        //                 if(newOrder_roomGridForm.getItem("AG005").getValue() != '0' && newOrder_roomGridForm.getItem("AG005").getValue() != ''){
        //                     if(isNeedFirstChangeEvent){
        //                         axios.post(apiServer+"/orders/loadRoomPrices", {
        //                             itemId1: hotel,itemId2: newOrder_roomGridForm.getItem("AG001").getValue(),itemId3: newOrder_roomGridForm.getItem("AG119").getValue(),itemId4: newOrder_roomGridForm.getItem("AG120").getValue(),itemId5: newOrder_roomGridForm.getItem("AG004").getValue(),itemId6: newOrder_roomGridForm.getItem("AG005").getValue()
        //                         },headers).then((response) =>{
        //                             newOrder_roomGridForm.getItem("AG127").setValue(response.data);
        //                         })
        //                             .catch((error) => {
        //                                 DHXMessage({
        //                                     text: "連線異常，請重新整理",
        //                                     icon:"dxi dxi-close",
        //                                     css: "dhx_message--error",
        //                                     expire:5000
        //                                 });
        //                             });
        //                     }
        //                     newOrder_roomGridForm.getItem("AG127").enable();
        //                     newOrder_roomGridForm.getItem("AG116").enable();
        //                 }else{
        //                 }
        //                 break;
        //             case "AG116":
        //             case "AG127":
        //                 newOrder_roomGridForm.getItem("total").setValue("共 "+dayString2+" 晚，"+(Number(newOrder_roomGridForm.getItem("AG127").getValue())+Number(newOrder_roomGridForm.getItem("AG116").getValue()))+"元");
        //                 break;
        //         }
        //     }
        // });
        //
        // let newOrder_roomGridFormCheckInDate = newOrder_roomGridForm.getItem("AG119").getWidget();
        // let newOrder_roomGridFormCheckOutDate = newOrder_roomGridForm.getItem("AG120").getWidget();
        //
        // newOrder_roomGridFormCheckInDate.link(newOrder_roomGridFormCheckOutDate)


        // changeRoomFormWindow1.attach(changeRoomForm);

        // changeRoomFormWindow1.show();

        let reloadStatistics = ()=>{
            axios.post(apiServer+"/dashboard/loadStatistics", {
                itemId1:hotel
            },headers).then((response) =>{
                if(response.status === 200){
                    if(response.data){
                        status1 = document.getElementById("status1");
                        status2 = document.getElementById("status2");
                        status3 = document.getElementById("status3");
                        status4 = document.getElementById("status4");
                        status5 = document.getElementById("status5");
                        status6 = document.getElementById("status6");
                        status7 = document.getElementById("status7");
                        // status8 = document.getElementById("status8");
                        status9 = document.getElementById("status9");
                        status10 = document.getElementById("status10");

                        status1.innerText = "空房 " + response.data[0].status1 + " 間";
                        status2.innerText = "待入住 " + response.data[0].status2 + " 間";
                        status3.innerText = "已入住 " + response.data[0].status3 + " 間";
                        status5.innerText = "故障 " + response.data[0].status5 + " 間";
                        status6.innerText = "保留 " + response.data[0].status6 + " 間";
                        status7.innerText = "待打掃 " + response.data[0].status7 + " 間";
                        // status8.innerText = "打掃中 " + response.data[0].status8 + " 間";
                        status9.innerText = "總房數 " + response.data[0].status9 + " 間";
                        status10.innerText = "未開賣 " + response.data[0].status10 + " 間";
                    }else{
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                }else{
                    DHXMessage({
                        text: "連線異常，請重新整理",
                        icon:"dxi dxi-close",
                        css: "dhx_message--error",
                        expire:5000
                    });
                }
            })
            .catch((error) => {
                DHXMessage({
                    text: "連線異常，請重新整理",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            });
        };

        let reloadGrid = ()=>{
            switch (activeTabId){
                case "dayView":
                    dhxDayViewGrid.data.removeAll();
                    dayViewCurrentDate = getDate(toolbar1.getState("toolbar1_startTime"));
                    axios.post(apiServer+"/dashboard/loadDayViewGridTitle",{
                        itemId1:dayViewCurrentDate
                    },headers).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                if(dayViewGridSpanTmp != ""){
                                    dayViewGridSpanTmp.map((item)=>{
                                        dhxDayViewGrid.removeSpan(item.row,item.column);
                                    })
                                }

                                dhxDayViewGrid.setColumns(response.data);
                                axios.post(apiServer+"/dashboard/loadDayViewGrid",{
                                    itemId1:dayViewCurrentDate,itemId2:hotel
                                },headers)
                                .then((response2) =>{
                                    if(response2.status === 200){
                                        if(response2.data){
                                            dhxDayViewGrid.data.removeAll();
                                            dhxDayViewGrid.data.parse(response2.data);

                                            axios.post(apiServer+"/dashboard/loadDayViewGridSpan",{
                                                itemId1:dayViewCurrentDate,itemId2:hotel
                                            },headers)
                                            .then((response3) =>{
                                                if(response3.status === 200){
                                                    if(response3.data){
                                                        dayViewGridSpanTmp = response3.data;
                                                        setTimeout(()=>{
                                                            response3.data.map((item)=>{
                                                                dhxDayViewGrid.addSpan(item);
                                                                dhxProgressWindow1.hide();
                                                            })
                                                        },1000)
                                                    }else{
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--danger",
                                                            expire:5000
                                                        });
                                                    }
                                                }else{
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--danger",
                                                        expire:5000
                                                    });
                                                }

                                            })
                                            .catch((error) => {
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--danger",
                                                    expire:5000
                                                });
                                            });
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--danger",
                                                expire:5000
                                            });
                                        }

                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--danger",
                                            expire:5000
                                        });
                                    }

                                })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--danger",
                                    expire:5000
                                });
                            }
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        }
                    })
                    .catch((error) => {
                        dhxProgressWindow1.hide();
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--danger",
                            expire:5000
                        });
                    });
                    break;

                case "weekView":
                    dhxWeekViewGrid.data.removeAll();
                    weekViewCurrentDate = getDate(toolbar2.getState("toolbar2_startTime"));
                    axios.post(apiServer+"/dashboard/loadWeekViewGridTitle",{
                        itemId1:weekViewCurrentDate
                    },headers).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                dhxWeekViewGrid.setColumns(response.data);

                                axios.post(apiServer+"/dashboard/loadWeekViewGrid",{
                                    itemId1:weekViewCurrentDate,itemId2:hotel
                                },headers)
                                    .then((response2) =>{
                                        if(response2.status === 200){
                                            if(response2.data){
                                                dhxWeekViewGrid.data.parse(response2.data);
                                                dhxProgressWindow1.hide();
                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--danger",
                                                    expire:5000
                                                });
                                            }
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--danger",
                                                expire:5000
                                            });
                                        }

                                    })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--danger",
                                            expire:5000
                                        });
                                    });
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--danger",
                                    expire:5000
                                });
                            }
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        }
                    })
                    .catch((error) => {
                        dhxProgressWindow1.hide();
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--danger",
                            expire:5000
                        });
                    });
                    break;

                case "monthView":
                    dhxMonthViewGrid.data.removeAll();
                    monthViewCurrentMonth = getDate(toolbar3.getState("toolbar3_startTime"));
                    axios.post(apiServer+"/dashboard/loadMonthViewGridTitle", {
                        itemId1:monthViewCurrentMonth
                    },headers).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                dhxMonthViewGrid.setColumns(response.data);

                                axios.post(apiServer+"/dashboard/loadMonthViewGrid", {
                                    itemId1:monthViewCurrentMonth,itemId2:hotel
                                },headers).then((response2) =>{
                                    if(response2.status === 200){
                                        dhxMonthViewGrid.data.parse(response2.data);
                                        dhxProgressWindow1.hide();
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--danger",
                                            expire:5000
                                        });
                                    }

                                })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--danger",
                                    expire:5000
                                });
                            }
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        }
                    })
                    .catch((error) => {
                        dhxProgressWindow1.hide();
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--danger",
                            expire:5000
                        });
                    });
                    break;
            }
        }

        // let timeoutID = window.setInterval(( () =>{
        //     // axios.post(apiServer+"/dashboard/loadMonthViewGridTitle", {
        //     //     itemId1:monthViewCurrentMonth
        //     // },headers).then((response) =>{
        //     //     if(response.status === 200){
        //     //         dhxMonthViewGrid.setColumns(response.data);
        //     //
        //     //         axios.post(apiServer+"/dashboard/loadMonthViewGrid", {
        //     //             itemId1:monthViewCurrentMonth,itemId2:hotel
        //     //         },headers).then((response2) =>{
        //     //             if(response2.status === 200){
        //     //                 dhxMonthViewGrid.data.removeAll();
        //     //                 dhxMonthViewGrid.data.parse(response2.data);
        //     //             }else{
        //     //                 console.log("Server Error")
        //     //             }
        //     //
        //     //         })
        //     //             .catch((error) => {
        //     //                 console.log(error);
        //     //             });
        //     //     }else{
        //     //         console.log("Server Error")
        //     //     }
        //     //
        //     // })
        //     //     .catch((error) => {
        //     //         console.log(error);
        //     //     });
        //
        //     if(pathname == "/dashboard"){
        //         dhxProgressWindow1.show();
        //         reloadGrid();
        //         reloadStatistics();
        //
        //         //提醒
        //         axios.post(apiServer+"/dashboard/loadRemindGrid",{itemId1:hotel},headers)
        //             .then((response) =>{
        //                 if(response.data){
        //                     remindGrid.data.removeAll();
        //                     remindGrid.data.parse(response.data);
        //
        //                     let messageCount = 0;
        //
        //                     response.data.map((data)=>{
        //                         if(!data.isChecked){
        //                             messageCount++;
        //                         }
        //                     })
        //
        //                     if(messageCount != 0){
        //
        //                         DHXMessage({
        //                             text: "共有 "+messageCount+" 筆提醒待確認",
        //                             icon:"dxi dxi-close",
        //                             css: "dhx_message--error remindMessage",
        //                             expire:10000
        //                         });
        //                     }
        //
        //                 }else{
        //                     console.log("Server Error")
        //                 }
        //
        //             })
        //             .catch((error) => {
        //                 console.log(error);
        //             });
        //     }
        //
        //
        // }), 30000);



        axios.post(apiServer+"/dashboard/loadStatistics", {
            itemId1:hotel
        },headers).then((response) =>{
            if(response.status === 200){
                if(response.data[0]){

                    status1 = document.getElementById("status1");
                    status2 = document.getElementById("status2");
                    status3 = document.getElementById("status3");
                    status4 = document.getElementById("status4");
                    status5 = document.getElementById("status5");
                    status6 = document.getElementById("status6");
                    status7 = document.getElementById("status7");
                    // status8 = document.getElementById("status8");
                    status9 = document.getElementById("status9");
                    status10 = document.getElementById("status10");

                    status1.innerText = "空房 " + response.data[0].status1 + " 間";
                    status2.innerText = "待入住 " + response.data[0].status2 + " 間";
                    status3.innerText = "已入住 " + response.data[0].status3 + " 間";
                    status5.innerText = "故障 " + response.data[0].status5 + " 間";
                    status6.innerText = "保留 " + response.data[0].status6 + " 間";
                    status7.innerText = "待打掃 " + response.data[0].status7 + " 間";
                    // status8.innerText = "打掃中 " + response.data[0].status8 + " 間";
                    status9.innerText = "總房數 " + response.data[0].status9 + " 間";
                    status10.innerText = "未開賣 " + response.data[0].status10 + " 間";
                }else{
                    // DHXMessage({
                    //     text: "連線異常，請重新整理",
                    //     icon:"dxi dxi-close",
                    //     css: "dhx_message--error",
                    //     expire:5000
                    // });
                };
            }else{
                // DHXMessage({
                //     text: "連線異常，請重新整理",
                //     icon:"dxi dxi-close",
                //     css: "dhx_message--error",
                //     expire:5000
                // });
            }
        })
        .catch((error) => {
            // DHXMessage({
            //     text: "連線異常，請重新整理",
            //     icon:"dxi dxi-close",
            //     css: "dhx_message--error",
            //     expire:5000
            // });
        });

        const remindGridLayout = new DHXLayout(null,{
            rows:[
                {id:"remindGridToolbar", height: "content"},
                {id: "remindGrid", height:"600px"},
            ]
        });

        const toolbar4 = new DHXToolbar(null, {});
        toolbar4.events.on("click", function(id,e){
            switch (id) {
                case "reload":
                    axios.post(apiServer+"/dashboard/loadRemindGrid",{itemId1:hotel},headers)
                        .then((response) =>{
                            if(response.data){
                                remindGrid.data.removeAll();
                                remindGrid.data.parse(response.data);
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--danger",
                                    expire:5000
                                });
                            }

                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        });
                    break;
                case "search":
                    if(toolbar4.getState("toolbar4_endTime") === "" || toolbar4.getState("toolbar4_endTime") === "" || toolbar4.getState("toolbar4_endTime") === undefined || toolbar4.getState("toolbar4_endTime") === undefined){
                        DHXMessage({
                            text: "請選擇日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    let startDateValue = new Date(toolbar4.getState("toolbar4_startTime"));
                    let year1 = startDateValue.getFullYear();
                    let month1 = startDateValue.getMonth()+1;
                    let day1 = startDateValue.getDate();

                    if (day1 < 10) {
                        day1 = '0' + day1;
                    }

                    if (month1 < 10) {
                        month1 = `0${month1}`;
                    }

                    let format1 = `${year1}-${month1}-${day1}`;

                    let endDateValue = new Date(toolbar4.getState("toolbar4_endTime"));
                    let year2 = endDateValue.getFullYear();
                    let month2 = endDateValue.getMonth()+1;
                    let day2 = endDateValue.getDate();

                    if (day2 < 10) {
                        day2 = '0' + day2;
                    }

                    if (month2 < 10) {
                        month2 = `0${month2}`;
                    }

                    let format2 = `${year2}-${month2}-${day2}`;

                    if(format1>format2){
                        DHXMessage({
                            text: "開始日期不得大於結束日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    remindGrid.data.removeAll();
                    axios.post(apiServer+"/dashboard/searchRemindGrid", {
                        itemId1:hotel,itemId2: format1, itemId3: format2,
                    },headers).then((data) =>{
                        remindGrid.data.parse(data.data);
                    });
                    break;
                default:
                    break;
            }
        });
        toolbar4.data.parse(JSON.parse(JSON.stringify(toolbar4Config)));

        const remindGrid = new DHXGrid(null,{
            columns: remindGridColumn,
            autoWidth: true,
            resizable: true,
        });

        axios.post(apiServer+"/dashboard/loadRemindGrid",{itemId1:hotel},headers)
            .then((response) =>{
                if(response.status === 200){
                    if(response.data){
                        remindGrid.data.removeAll();
                        remindGrid.data.parse(response.data);
                    }else{
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                }else{
                    DHXMessage({
                        text: "連線異常，請重新整理",
                        icon:"dxi dxi-close",
                        css: "dhx_message--error",
                        expire:5000
                    });
                }
            })
            .catch((error) => {
                DHXMessage({
                    text: "連線異常，請重新整理",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            });

        remindGrid.events.on("cellClick", function(row,column,e){
            if(canEdit){

                if(column.id == "isChecked"){
                    if(e.target.checked != undefined){
                        axios.post(apiServer+"/dashboard/updateCheckRemind", {
                            itemId1: row.id,itemId2:e.target.checked
                        },headers).then((response) =>{
                            if(response.data){
                                axios.post(apiServer+"/dashboard/loadRemindGrid",{itemId1:hotel},headers)
                                    .then((response) =>{
                                        if(response.data){
                                            remindGrid.data.removeAll();
                                            remindGrid.data.parse(response.data);
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--danger",
                                                expire:5000
                                            });
                                        }

                                    })
                                    .catch((error) => {
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--danger",
                                            expire:5000
                                        });
                                    });
                            }
                        })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--danger",
                                    expire:5000
                                });
                            });
                    }

                }
            }else{
                DHXMessage({
                    text: "請先確認擁有編輯的權限",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            }
        });

        remindGridLayout.getCell("remindGridToolbar").attach(toolbar4);
        remindGridLayout.getCell("remindGrid").attach(remindGrid);
        dashboardTab.getCell("remindGridLayout").attach(remindGridLayout);


        //交班
        const dhxHandOverGridLayout = new DHXLayout(null,{
            rows:[
                {id:"handOverGridToolbar", height: "content"},
                {id:"handOverGridLayout", height: "600px"},
            ]
        });

        const toolbar5 = new DHXToolbar(null, {
        });
        toolbar5.events.on("click", function(id,e){
            switch (id) {
                case "reload":
                    axios.post(apiServer+"/handOver/loadHandOverGrid",{
                        itemId1:hotel,itemId2:0
                    },headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    handOverGrid.data.removeAll();
                                    handOverGrid.data.parse(response.data);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "add":
                    handOverForm.clear();

                    axios.post(apiServer+"/userProfileSet/loadUserInfo", {
                        itemId1:JSON.parse(localStorage.getItem("digital_token"))
                    },headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    handOverForm.getItem("name").setValue(response.data[0].userName);

                                    handOverForm.setValue({"AA001":"0","AA002":hotel,"AA100":0});
                                    dhxHandOverWindow1.show();
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                default:
                    break;
            }
        });
        toolbar5.data.parse(JSON.parse(JSON.stringify(toolbar5Config)));

        const handOverGrid = new DHXGrid(null,{
            columns: handOverGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "handOverGrid_edit": function (e, data) {
                        if(canEdit){
                            handOverForm.clear();
                            dhxHandOverWindow1.header.data.update("title", {value: "編輯櫃台交班視窗"});
                            dhxHandOverWindow1.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: true });
                            dhxHandOverWindow1.footer.data.update("WINDOW_FOOT_ACCEPT", { disabled: false });

                            axios.post(apiServer+"/handOver/loadHandOverFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        handOverForm.setValue(response.data[0]);
                                        dhxHandOverWindow1.show();
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "handOverGrid_delete": function (e, data) {
                        if(canDelete){
                            handOverDeleteForm.clear();

                            axios.post(apiServer+"/handOver/loadHandOverFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        handOverDeleteForm.getItem("deleteFormTitle").setValue("確定要刪除嗎?");
                                        handOverDeleteForm.setValue(response.data[0]);
                                        dhxHandOverWindow2.show();
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });

        handOverGrid.events.on("cellClick", function(row,column,e){
            if(canEdit){
                if(column.id == "isChecked"){
                    if(e.target.checked != undefined){
                        dhxProgressWindow1.show();
                        axios.post(apiServer+"/handOver/doneHandOver", {
                            itemId1: row.id,itemId2:e.target.checked
                        },headers).then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    axios.post(apiServer+"/handOver/loadHandOverGrid",{
                                        itemId1:hotel,itemId2:0
                                    },headers)
                                        .then((response2) =>{
                                            if(response2.status === 200){
                                                if(response2.data){
                                                    dhxProgressWindow1.hide();
                                                    handOverGrid.data.removeAll();
                                                    handOverGrid.data.parse(response2.data);
                                                }else{
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }
                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        })
                                        .catch((error) => {
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        });
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }

                        })
                            .catch((error) => {
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                    }
                }
            }else{
                dhxProgressWindow1.hide();
                DHXMessage({
                    text: "請先確認擁有編輯的權限",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            }
        });

        axios.post(apiServer+"/handOver/loadHandOverGrid",{
            itemId1:hotel,itemId2:0
        },headers)
            .then((response) =>{
                if(response.status === 200){
                    if(response.data){
                        handOverGrid.data.removeAll();
                        handOverGrid.data.parse(response.data);
                    }else{
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                }else{
                    DHXMessage({
                        text: "連線異常，請重新整理",
                        icon:"dxi dxi-close",
                        css: "dhx_message--error",
                        expire:5000
                    });
                }
            })
            .catch((error) => {
                DHXMessage({
                    text: "連線異常，請重新整理",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            });

        dashboardTab.getCell("handOverLayout").attach(dhxHandOverGridLayout);
        dhxHandOverGridLayout.getCell("handOverGridToolbar").attach(toolbar5);
        dhxHandOverGridLayout.getCell("handOverGridLayout").attach(handOverGrid);

        const dhxHandOverWindow1 = new DHXWindow({
            title: "新增交班視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 540,
            width: 500,
            footer: true,
            css: "dhxHandOverWindow1"
        });

        dhxHandOverWindow1.footer.data.add({type:"spacer"});
        dhxHandOverWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxHandOverWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClear)));
        dhxHandOverWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxHandOverWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    handOverForm.clear();
                    handOverForm.clear("validation");
                    handOverForm.setValue({"AA001":"0","AA002":hotel,"AA100":0,"AA103":1});
                    break;

                case "WINDOW_FOOT_ACCEPT":

                    if(handOverForm.getItem("AA103").getValue() === ""){
                        DHXMessage({
                            text: "請選擇交班隸屬",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };
                    if(handOverForm.getItem("AA101").getValue().trim() === ""){
                        DHXMessage({
                            text: "請填入交班內容",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(handOverForm.getItem("AA100").getValue() === ""){
                        DHXMessage({
                            text: "請選擇是否完成",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(handOverForm.getItem("AA100").getValue() == "" && handOverForm.getItem("AA102").getValue() == ""){
                        DHXMessage({
                            text: "請填寫完成時間",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/handOver/updateHandOverGridFormData",
                        handOverForm.getValue(),headers
                    ).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                DHXMessage({
                                    text: response.data[0].message,
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });

                                handOverForm.clear();
                                handOverForm.setValue({"AA001":"0","AA002":hotel,"AA100":0,"AA103":1});
                                axios.post(apiServer+"/handOver/loadHandOverGrid",{
                                    itemId1:hotel,itemId2:0
                                },headers)
                                    .then((response2) =>{
                                        if(response2.status === 200){
                                            if(response2.data){
                                                dhxHandOverWindow1.hide();
                                                handOverGrid.data.removeAll();
                                                handOverGrid.data.parse(response2.data);
                                            }else{
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxHandOverWindow1.hide();
                    break;
                default:
                    break;
            }
        })

        const handOverForm = new DHXForm(null,{
            rows: handOverFormConfig
        });

        handOverForm.getItem("AA100").events.on("change", function(value) {
            if(value == "1"){
                handOverForm.getItem("AA102").enable();
            }else if(value == "0"){
                handOverForm.getItem("AA102").disable();
                handOverForm.getItem("AA102").clear();
            }
        });

        handOverForm.events.on("click", function (name, e){
            switch (name){
                case "send":
                    dhxHandOverWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        dhxHandOverWindow1.attach(handOverForm);

        const dhxHandOverWindow2 = new DHXWindow({
            title: "刪除交班視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 630,
            footer: true,
            css: "dhxHandOverWindow2"
        });

        dhxHandOverWindow2.footer.data.add({type:"spacer"});
        dhxHandOverWindow2.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxHandOverWindow2.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxHandOverWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    handOverDeleteForm.clear();
                    handOverDeleteForm.getItem("status").setValue("1");
                    handOverDeleteForm.clear("validation");
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(handOverDeleteForm.getItem("AA104").getValue().trim() === ""){
                        DHXMessage({
                            text: "請填入刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/handOver/deleteHandOver",handOverDeleteForm.getValue(),headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    DHXMessage({
                                        text: "更新成功",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--success",
                                        expire:5000
                                    });

                                    axios.post(apiServer+"/handOver/loadHandOverGrid",{
                                        itemId1:hotel,itemId2:0
                                    },headers)
                                        .then((response) =>{
                                            if(response.status === 200){
                                                if(response.data){
                                                    handOverGrid.data.removeAll();
                                                    handOverGrid.data.parse(response.data);
                                                    dhxHandOverWindow2.hide();
                                                }else{
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }
                                            }else{
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        })
                                        .catch((error) => {
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        });
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--danger",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxHandOverWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let handOverDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"handOverDeleteFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "handOverDeleteForm"},
            ]
        });

        const handOverDeleteForm = new DHXForm(null,{
            rows: handOverDeleteFormConfig
        });

        dhxHandOverWindow2.attach(handOverDeleteFormLayout);
        handOverDeleteFormLayout.getCell("handOverDeleteForm").attach(handOverDeleteForm);

        const keywordSearchTemplate = ({orderSource, orderSourceNo, name, phoneNumber,orderStatus,price }) => (`
            <div style="margin: 10px 0">
                訂單來源：${orderSource} 訂單編號：${orderSourceNo}<br/>
                姓名：${name}<br/>
                電話：${phoneNumber}<br/>
                ${orderStatus} 金額${price}
            </div>
        `);

        let dhxKeywordSearchDataview = new DHXDataview(null, {
            itemsInRow: 1,
            // gap: 10,
            css: "keywordSearchDataview",
            template: keywordSearchTemplate,
        });

        dhxKeywordSearchDataview.events.on("click", function(id, e,room){

            if(canEdit){
                dhxProgressWindow1.show();

                dhxTabbar.setActive("orderInfo");

                orderForm.clear();

                orderForm.getItem("AA001").setValue(id);

                isSaleSourceNeedChange = false;

                axios.post(apiServer+"/orders/loadOrderFormData", {
                    itemId1: id
                },headers).then((response) =>{
                    if(response.status === 200){
                        if(response.data){
                            orderForm.setValue(response.data[0]);
                            if(response.data[0].unpaid > 0){
                                productInfo_payLogGridToolbar.enable("sendNewPayLink");
                            }else{
                                productInfo_payLogGridToolbar.disable("sendNewPayLink");
                            }

                            orderForm.getItem("AA006").setOptions(response.data[0].orderSourceItem);
                            orderForm.getItem("AA006").setValue(response.data[0].AA006)
                            orderForm.getItem("AA008").setOptions(response.data[0].saleSourceItem);
                            orderForm.getItem("AA008").setValue(response.data[0].AA008)

                            isSaleSourceNeedChange = true;

                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }else{
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }

                })
                    .catch((error) => {
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    });

                setTimeout(()=>{
                    productInfo_otherPrdGridForm.getItem("AC002").setValue(orderForm.getItem("AA001").getValue());
                    productInfo_otherPrdGridForm.getItem("AC003").setValue(orderForm.getItem("AA002").getValue());

                    orderInfo_purchaserGrid.data.removeAll();
                    axios.post(apiServer+"/orders/loadPurchaserGrid", {
                        itemId1: id
                    },headers).then((data) =>{
                        orderInfo_purchaserGrid.data.parse(data.data);
                    });

                    orderInfo_guestGrid.data.removeAll();
                    axios.post(apiServer+"/orders/loadGuestGrid", {
                        itemId1: id
                    },headers).then((data) =>{
                        orderInfo_guestGrid.data.parse(data.data);
                    });

                    productInfo_roomGrid.data.removeAll();
                    axios.post(apiServer+"/orders/loadRoomGrid", {
                        itemId1: id
                    },headers).then((data) =>{
                        productInfo_roomGrid.data.parse(data.data);
                        dhxOrderWindow1.show();
                        dhxProgressWindow1.hide();
                    });

                    alertSetGrid.data.removeAll();
                    axios.post(apiServer+"/orders/loadAlertSetGrid", {
                        itemId1: id
                    },headers).then((data) =>{
                        alertSetGrid.data.parse(data.data);
                    });

                    productInfo_payLogGrid.data.removeAll();
                    axios.post(apiServer+"/orders/loadPayLogGrid", {
                        itemId1: id
                    },headers).then((data) =>{
                        productInfo_payLogGrid.data.parse(data.data);
                    });

                    productInfo_otherPrdGrid.data.removeAll();
                    axios.post(apiServer+"/orders/loadOtherPrdGrid", {
                        itemId1: id
                    },headers).then((data) =>{
                        productInfo_otherPrdGrid.data.parse(data.data);
                    });
                },500);

            }else{
                DHXMessage({
                    text: "請先確認擁有編輯的權限",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            }
        });

        const dhxKeywordSearchWindow1 = new DHXWindow({
            title: " ",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 540,
            width: 500,
            footer: true,
            css: "dhxKeywordSearchWindow1"
        });

        dhxKeywordSearchWindow1.footer.data.add({type:"spacer"});
        dhxKeywordSearchWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));

        dhxKeywordSearchWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    handOverForm.clear();
                    handOverForm.clear("validation");
                    handOverForm.setValue({"AA001":"0","AA002":hotel,"AA100":0,"AA103":1});
                    break;

                case "WINDOW_FOOT_ACCEPT":

                    if(handOverForm.getItem("AA103").getValue() === ""){
                        DHXMessage({
                            text: "請選擇交班隸屬",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };
                    if(handOverForm.getItem("AA101").getValue().trim() === ""){
                        DHXMessage({
                            text: "請填入交班內容",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(handOverForm.getItem("AA100").getValue() === ""){
                        DHXMessage({
                            text: "請選擇是否完成",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(handOverForm.getItem("AA100").getValue() == "" && handOverForm.getItem("AA102").getValue() == ""){
                        DHXMessage({
                            text: "請填寫完成時間",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/handOver/updateHandOverGridFormData",
                        handOverForm.getValue(),headers
                    ).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                DHXMessage({
                                    text: response.data[0].message,
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });

                                handOverForm.clear();
                                handOverForm.setValue({"AA001":"0","AA002":hotel,"AA100":0,"AA103":1});
                                axios.post(apiServer+"/handOver/loadHandOverGrid",{
                                    itemId1:hotel,itemId2:0
                                },headers)
                                    .then((response2) =>{
                                        if(response2.status === 200){
                                            if(response2.data){
                                                dhxHandOverWindow1.hide();
                                                handOverGrid.data.removeAll();
                                                handOverGrid.data.parse(response2.data);
                                            }else{
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxKeywordSearchWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        let dhxCheckIn_canCheckInRoomGrid = new DHXGrid(null,{
            columns: checkIn_canCheckInRoomGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers:{
                onclick:{

                }
            }
        });

        dhxCheckIn_canCheckInRoomGrid.events.on("cellClick", function(row,column,e){
            if(canEdit){
                if(column.id == "isChecked"){
                    if(e.target.checked != undefined){
                        let checkedResult = e.target.checked;
                        let checkedId = row.roomId;

                        if(checkedResult){
                            if(canCheckInRoomArray.indexOf(checkedId) == -1){
                                canCheckInRoomArray.push(checkedId);
                            }
                        }else{
                            canCheckInRoomArray = canCheckInRoomArray.filter((id)=>{
                                return id != checkedId
                            });
                        }
                    }
                }
            }else{
                DHXMessage({
                    text: "請先確認擁有編輯的權限",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            }
        });

        const dhxCheckIn_canCheckInRoomGridWindow1 = new DHXWindow({
            title: " ",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 440,
            width: 650,
            footer: true,
            css: "dhxCheckIn_canCheckInRoomGridWindow1"
        });

        dhxCheckIn_canCheckInRoomGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLOSE":
                    dhxCheckIn_canCheckInRoomGridWindow1.hide();
                    break;

                case "WINDOW_FOOT_ACCEPT":

                    if(canEdit){
                        if(canCheckInRoomArray.length === 0){
                            DHXMessage({
                                text: "請選取要入住的房間",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }else{
                            dhxProgressWindow1.show();
                            axios.post(apiServer+"/dashboard/checkIn",{
                                itemId1:orderId,itemId2:selectDate,itemId3:canCheckInRoomArray
                            },headers).then((response2) =>{
                                if(response2.status === 200){
                                    if(response2.data){
                                        dhxCheckIn_canCheckInRoomGridWindow1.hide();
                                        reloadGrid();

                                        reloadStatistics();

                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }
                    }else{
                        dhxProgressWindow1.hide();
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                    break;

                default:
                    break;
            }
        });

        dhxCheckIn_canCheckInRoomGridWindow1.footer.data.add({type:"spacer"});
        dhxCheckIn_canCheckInRoomGridWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxCheckIn_canCheckInRoomGridWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        //載入可退房
        let dhxCheckOut_canCheckOutRoomGrid = new DHXGrid(null,{
            columns: checkOut_canCheckOutRoomGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers:{
                onclick:{
                    "cell__template": function(e, data) {

                        if(data.col.id == "isChecked"){

                            if(e.target.checked != undefined){
                                // console.log(data.row.id,data.col.id,e.target.checked);

                                if(e.target.checked){
                                    let findId = canCheckOutRoomArray.find((item, index, array)=>{
                                        return item.roomId == data.row.roomId
                                    });

                                    console.log(findId);

                                    if(findId == undefined){
                                        canCheckOutRoomArray.push({
                                            roomId:data.row.roomId,
                                            isClean:data.row.isClean,
                                            isCheckOutEarly:data.row.isCheckOutEarly
                                        });
                                    }else{
                                        canCheckOutRoomArray.find((item, index, array)=>{
                                            if(item.roomId == data.row.roomId){
                                                item.isClean = data.row.isClean
                                            };
                                        });
                                    }
                                }else{
                                    canCheckOutRoomArray = canCheckOutRoomArray.filter((item, index, array)=>{
                                        return item.roomId != data.row.roomId;
                                    });
                                }
                            }
                            console.log(canCheckOutRoomArray);

                        }else if(data.col.id == "isClean"){

                            if(e.target.checked != undefined){

                                dhxCheckOut_canCheckOutRoomGrid.data.forEach(row => {
                                    // console.log(row,data)
                                    dhxCheckOut_canCheckOutRoomGrid.data.update(row.id, {
                                        [data.col.id]: e.target.checked,
                                    });
                                });

                                canCheckOutRoomArray.find((item, index, array)=>{
                                    if(item.roomId == data.row.roomId){
                                        item.isClean = data.row.isClean
                                    };
                                });

                                console.log(canCheckOutRoomArray);
                            }
                        }
                    }
                }
            }
        });

        const dhxCheckOut_canCheckOutRoomGridWindow1 = new DHXWindow({
            title: " ",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 440,
            width: 538,
            footer: true,
            css: "dhxCheckOut_canCheckOutRoomGridWindow1"
        });

        dhxCheckOut_canCheckOutRoomGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLOSE":
                    dhxCheckOut_canCheckOutRoomGridWindow1.hide();
                    break;

                case "WINDOW_FOOT_ACCEPT":
                    if(canEdit){
                        if(canCheckOutRoomArray.length === 0){
                            DHXMessage({
                                text: "請選取要退房的房間",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }else{
                            dhxProgressWindow1.show();
                            axios.post(apiServer+"/dashboard/checkOut",{
                                itemId1:orderId,itemId2:selectDate,itemId3:canCheckOutRoomArray
                            },headers).then((response2) =>{
                                if(response2.status === 200){
                                    if(response2.data){
                                        dhxCheckOut_canCheckOutRoomGridWindow1.hide();
                                        reloadGrid();

                                        reloadStatistics();

                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }
                    }else{
                        dhxProgressWindow1.hide();
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                    break;

                default:
                    break;
            }
        });

        dhxCheckOut_canCheckOutRoomGridWindow1.footer.data.add({type:"spacer"});
        dhxCheckOut_canCheckOutRoomGridWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxCheckOut_canCheckOutRoomGridWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        // dhxCheckOut_canCheckOutRoomGridWindow1.show();

        return () => {
            dashboardTab && dashboardTab.destructor();
            // clearInterval(timeoutID);
        };

    },[canAccess,canEdit,canDelete,canPrint,hotel]);

    return (
        <DashboardLayout>
            <DashboardNavbar onHotelChange={changeHotelHandler}/>
            {canAccess &&
                <MDBox py={2}>
                    <MDBox mb={2}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} lg={12}>
                                <Card>
                                    {/*<CardHeader classes={{"paddingBottom":"0"}} title={"即時狀態表"}/>*/}
                                    <div>
                                        <div style={{display:"inline-block"}} className={"status_text"}>即時狀態表&nbsp;&nbsp;&nbsp;&nbsp;今日</div>
                                        <div
                                            id={"status9"}
                                            className={"status"}
                                            style={{}}>
                                            -
                                        </div>
                                        <div
                                            id={"status1"}
                                            className={"status"}
                                            style={{}}
                                            // ref={status1}
                                        >
                                            -
                                        </div>
                                        <div
                                            id={"status2"}
                                            className={"status"}
                                            style={{
                                                "backgroundColor": "#9aef9b",
                                            }}>
                                            -
                                        </div>
                                        <div
                                            id={"status3"}
                                            className={"status"}
                                            style={{
                                                "backgroundColor": "#efe19a",
                                            }}>
                                            -
                                        </div>
                                        <div
                                            id={"status5"}
                                            className={"status"}
                                            style={{
                                                "backgroundColor": "#5402a4",
                                                "color": "white"
                                            }}>
                                            -
                                        </div>
                                        <div
                                            id={"status6"}
                                            className={"status"}
                                            style={{
                                                "backgroundColor": "#efe19a",
                                            }}>
                                            -
                                        </div>
                                        <div
                                            id={"status7"}
                                            className={"status"}
                                            style={{
                                                "backgroundColor": "#563f3f",
                                                "color": "white"
                                            }}>
                                            -
                                        </div>
                                        <div
                                            id={"status10"}
                                            className={"status"}
                                            style={{
                                                "backgroundColor": "#a4a4a4",
                                                "color": "white"
                                            }}>
                                            -
                                        </div>
                                    </div>
                                    <MDBox py={1} px={1}>
                                        <div ref={DHXDashboardTab}></div>
                                    </MDBox>
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDBox>
            }

            {!canAccess && !isLoading &&
            <MDAlert color={"error"}>請確認是否擁有檢視的權限</MDAlert>
            }

            {isLoading &&
            <MDAlert color={"warning"}>權限確認中，請稍後...</MDAlert>
            }
        </DashboardLayout>
    );
}

export default Dashboard;
