import React, {useEffect, useRef, useState} from 'react';
import DashboardNavbar from "../../../pages/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import MDAlert from "components/MDAlert";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {CardHeader} from "@mui/material";
import DashboardLayout from "../../../pages/LayoutContainers/DashboardLayout";
import {
    Tree as DHXTree,
    Layout as DHXLayout,
    Toolbar as DHXToolbar,
    Grid as DHXGrid,
    Window as DHXWindow,
    Form as DHXForm,
    message as DHXMessage,
    Tabbar as DHXTabbar,
} from 'dhx-suite';
import 'dhx-suite/codebase/suite.min.css'
import {quesAndAnsGridColumn} from "./componentsConfig/quesAndAnsGridColumn";
import {quesAndAnsFormConfig ,quesAndAnsDeleteFormConfig} from "./componentsConfig/quesAndAnsFormConfig";
import {questionClassGridColumn} from "./componentsConfig/questionClassGridColumn";
import {questionClassFormConfig ,questionClassDeleteFormConfig} from "./componentsConfig/questionClassFormConfig";
import {WindowFootAccept, WindowFootClear, WindowFootClose} from "./componentsConfig/windowConfig";
import {toolbar1Config,toolbar2Config} from "./componentsConfig/toolbarConfig";
import axios from "axios";
import './style.css';
import Image_error from '../../../assets/images/error.png'
import Image_warning from '../../../assets/images/warning.png'
import api_url from "../../../context/UrlContext.js"
import {useNavigate} from "react-router-dom";


const QuesAndAnsSetting = props => {

    const apiServer = api_url;
    const headers = {headers:{authorization: "Bearer "+JSON.parse(localStorage.getItem("digital_token"))}};
    const navigate = useNavigate();

    const [canAccess ,setCanAccess] = useState(false);
    const [canEdit ,setCanEdit] = useState(false);
    const [canDelete ,setCanDelete] = useState(false);
    const [canPrint ,setCanPrint] = useState(false);

    //飯店切換
    const [hotel, setHotel] = useState("0");

    const changeHotelHandler = (hotel) => {
        setHotel(hotel);
        hotelsListId = hotel;
    };

    const [isLoading,setIsloading] = useState(true);

    const DHXMainProductsList = useRef(null);
    const DHXHotelsList = useRef(null);
    const DHXQuesAndAnsSettingTab = useRef(null);

    let mainProductListId = "0";
    let hotelsListId = "0";
    let productTypeName = "";
    let hotelName = "";

    useEffect(() => {

        axios.post(apiServer+"/checkLoginState",{itemId1:JSON.parse(localStorage.getItem("digital_token"))},headers)
            .then((response)=>{
                if(response.data == 0){
                    navigate('/login');
                }else{
                    axios.post(apiServer+"/userProfileSet/loadPermiss",{itemId1:26},headers)
                        .then((response2)=>{
                            if(response2.status == 200){
                                let p = response2.data.split(',');

                                (p[0] == 1)?setCanAccess(true):setCanAccess(false);
                                (p[1] == 1)?setCanEdit(true):setCanEdit(false);
                                (p[2] == 1)?setCanDelete(true):setCanDelete(false);
                                (p[3] == 1)?setCanPrint(true):setCanPrint(false);

                                setIsloading(false);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });

        //問題類別設定
        const quesAndAnsSettingTab = new DHXTabbar(DHXQuesAndAnsSettingTab.current,{
            views: [
                {tab: "問題類別設定", id: "quesClassSet"},
                {tab: "常見問題設定", id: "quesAndAnsSet"},
            ],
        });

        quesAndAnsSettingTab.setActive("quesAndAnsSet");

        const quesClassSetLayout = new DHXLayout(null,{
            rows:[
                {id:"quesClassSetToolbar", height: "content"},
                {id: "questionClassGrid", height:"600px"},
            ]
        });

        const toolbar1 = new DHXToolbar(null, {});
        toolbar1.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){

                        questionClassForm.clear();
                        questionClassForm.setValue({"DA001": "0", "DA002": hotel, "DA100":999});

                        quesClassSetWindow.header.data.update("title", {value: "新增問題類別設定視窗"});
                        quesClassSetWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: false });

                        quesClassSetWindow.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                    break;
                case "reload":
                    axios.post(apiServer+"/quesAndAns/loadQuestionClassGrid",{itemId1:hotel},headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    questionClassGrid.data.removeAll();
                                    questionClassGrid.data.parse(response.data);
                                }
                            }else{
                                console.log("Server Error")
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    break;
                default:
                    break;
            }
        });

        toolbar1.data.parse(JSON.parse(JSON.stringify(toolbar1Config)));

        const questionClassGrid = new DHXGrid(null,{
            columns: questionClassGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "questionClassGrid_edit": function (e, data) {
                        if(canEdit){
                            quesClassSetWindow.header.data.update("title", {value: "編輯問題類別設定視窗"});
                            quesClassSetWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: true });

                            axios.post(apiServer+"/quesAndAns/loadQuestionClassFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                questionClassForm.setValue(response.data[0]);
                                quesClassSetWindow.show();
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "questionClassGrid_delete": function (e, data) {
                        if(canDelete){
                            questionClassDeleteForm.clear();
                            // questionClassDeleteForm.getItem("deleteFormTitle").setValue("確定要刪除嗎?");

                            axios.post(apiServer+"/quesAndAns/loadQuestionClassFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                questionClassDeleteForm.setValue( response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                            quesClassSetWindow3.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });

        axios.post(apiServer+"/quesAndAns/loadQuestionClassGrid",{itemId1:hotel},headers)
            .then((response) =>{
                if(response.status === 200){
                    if(response.data){
                        questionClassGrid.data.removeAll();
                        questionClassGrid.data.parse(response.data);
                    }
                }else{
                    console.log("Server Error")
                }
            })
            .catch((error) => {
                console.log(error);
            });

        quesClassSetLayout.getCell("quesClassSetToolbar").attach(toolbar1);
        quesClassSetLayout.getCell("questionClassGrid").attach(questionClassGrid);
        quesAndAnsSettingTab.getCell("quesClassSet").attach(quesClassSetLayout);

        const quesClassSetWindow = new DHXWindow({
            title: "新增問題類別設定視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 280,
            width: 300,
            footer: true,
            css: "quesClassSetWindow"
        });

        quesClassSetWindow.footer.data.add({type:"spacer"});
        quesClassSetWindow.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        quesClassSetWindow.footer.data.add( JSON.parse( JSON.stringify(WindowFootClear)));
        quesClassSetWindow.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        quesClassSetWindow.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":

                    questionClassForm.clear();
                    questionClassForm.setValue({"DA001": "0", "DA002": hotel, "DA100":999});

                    break;
                case "WINDOW_FOOT_ACCEPT":

                    if(questionClassForm.getItem("DA101").getValue().trim() === ""){
                        DHXMessage({
                            text: "請填入問題類別名稱",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/quesAndAns/checkQuestionClassName",
                        questionClassForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result !== 0){
                            DHXMessage({
                                text: "問題類別名稱重複",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }else{

                            axios.post(apiServer+"/quesAndAns/updateQuestionClass",
                                questionClassForm.getValue(),headers
                            ).then((response2) =>{
                                if(response2.data.length !== 0){
                                    DHXMessage({
                                        text: response2.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--success",
                                        expire:5000
                                    });

                                    questionClassForm.clear();
                                    questionClassForm.setValue({"DA001": "0", "DA002": hotel, "DA100":999});

                                    axios.post(apiServer+"/quesAndAns/loadQuestionClassGrid",{itemId1:hotel},headers)
                                        .then((response3) =>{
                                            if(response3.status === 200){
                                                if(response3.data){
                                                    quesClassSetWindow.hide();
                                                    questionClassGrid.data.removeAll();
                                                    questionClassGrid.data.parse(response3.data);
                                                }
                                            }else{
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        })
                                        .catch((error) => {
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        });
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    questionClassForm.clear();
                    questionClassForm.setValue({"DA001": "0", "DA002": hotel, "DA100":999});
                    quesClassSetWindow.hide();
                    break;
                default:
                    break;
            }
        });

        const questionClassForm = new DHXForm(null,{
            rows: questionClassFormConfig
        });

        quesClassSetWindow.attach(questionClassForm);

        const quesClassSetWindow3 = new DHXWindow({
            title: "刪除問題類別視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 600,
            width: 600,
            footer: true,
            css: "quesClassSetWindow3"
        });

        quesClassSetWindow3.footer.data.add({type:"spacer"});
        quesClassSetWindow3.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        quesClassSetWindow3.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        quesClassSetWindow3.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":

                    if(questionClassDeleteForm.getItem("DA102").getValue().trim() === ""){
                        DHXMessage({
                            text: "請填入刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    axios.post(apiServer+"/quesAndAns/deleteQuestionClass",
                        questionClassDeleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0]){
                            DHXMessage({
                                text: response.data[0].message,
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });
                            questionClassDeleteForm.clear();
                            questionClassDeleteForm.setValue({"DA001": "0"});

                            axios.post(apiServer+"/quesAndAns/loadQuestionClassGrid",{itemId1:hotel},headers)
                                .then((response2) =>{
                                    if(response2.status === 200){
                                        if (response2.data){
                                            quesClassSetWindow3.hide();
                                            questionClassGrid.data.removeAll();
                                            questionClassGrid.data.parse(response2.data);
                                        }
                                    }else{
                                        console.log("Server Error")
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    questionClassDeleteForm.clear();
                    questionClassDeleteForm.setValue({"DA001": "0"});
                    quesClassSetWindow3.hide();
                    break;
                default:
                    break;
            }
        });

        let questionClassDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id: "questionClassDeleteFormImg",
                    height: 200,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                },
                {id: "questionClassDeleteForm"},
            ]
        });

        const questionClassDeleteForm = new DHXForm(null,{
            rows: questionClassDeleteFormConfig
        });

        quesClassSetWindow3.attach(questionClassDeleteFormLayout);
        questionClassDeleteFormLayout.getCell("questionClassDeleteForm").attach(questionClassDeleteForm);

        //常見問題設定
        const quesAndAnsSetLayout = new DHXLayout(null,{
            rows:[
                {id:"quesAndAnsSetToolbar", height: "content"},
                {id: "quesAndAnsGrid", height:"600px"},
            ]
        });

        const toolbar2 = new DHXToolbar(null, {});
        toolbar2.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){

                        quesAndAnsForm.clear();
                        quesAndAnsForm.setValue({"DB001": "0", "DB002": hotel, "DB102":999});

                        quesAndAnsWindow.header.data.update("title", {value: "新增常見問題設定視窗"});
                        quesAndAnsWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: false });

                        axios.post(apiServer+"/quesAndAns/loadQuestionClassList", {
                            itemId1: hotel
                        },headers).then((response) =>{
                            quesAndAnsForm.getItem("DB003").setOptions(response.data);
                        })
                            .catch((error) => {
                                console.log(error);
                            });

                        quesAndAnsWindow.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                    break;
                case "reload":
                    axios.post(apiServer+"/quesAndAns/loadQuesAndAnsGrid",{itemId1:hotel},headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    quesAndAnsGrid.data.removeAll();
                                    quesAndAnsGrid.data.parse(response.data);
                                }
                            }else{
                                console.log("Server Error")
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    break;
                default:
                    break;
            }
        });

        toolbar2.data.parse(JSON.parse(JSON.stringify(toolbar2Config)));

        const quesAndAnsGrid = new DHXGrid(null,{
            columns: quesAndAnsGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "quesAndAnsGrid_edit": function (e, data) {
                        if(canEdit){
                            quesAndAnsWindow.header.data.update("title", {value: "編輯常見問題設定視窗"});
                            quesAndAnsWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: true });

                            axios.post(apiServer+"/quesAndAns/loadQuesAndAnsFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){

                                        quesAndAnsForm.setValue(response.data[0]);
                                        axios.post(apiServer+"/quesAndAns/loadQuestionClassList", {
                                            itemId1: hotel
                                        },headers).then((response2) =>{
                                            quesAndAnsForm.getItem("DB003").setOptions(response2.data);
                                            quesAndAnsForm.getItem("DB003").setValue(response.data[0].DB003);
                                            quesAndAnsWindow.show();
                                        })
                                            .catch((error) => {
                                                console.log(error);
                                            });

                                    }
                                }
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "quesAndAnsGrid_delete": function (e, data) {
                        if(canDelete){
                            quesAndAnsDeleteForm.clear();
                            // quesAndAnsDeleteForm.getItem("deleteFormTitle").setValue("確定要刪除嗎?");

                            axios.post(apiServer+"/quesAndAns/loadQuesAndAnsFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                quesAndAnsDeleteForm.setValue( response.data[0]);
                                quesAndAnsWindow3.show();
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });

        axios.post(apiServer+"/quesAndAns/loadQuesAndAnsGrid",{itemId1:hotel},headers)
            .then((response) =>{
                if(response.status === 200){
                    if(response.data){
                        quesAndAnsGrid.data.removeAll();
                        quesAndAnsGrid.data.parse(response.data);
                    }
                }else{
                    console.log("Server Error")
                }
            })
            .catch((error) => {
                console.log(error);
            });

        quesAndAnsSetLayout.getCell("quesAndAnsSetToolbar").attach(toolbar2);
        quesAndAnsSetLayout.getCell("quesAndAnsGrid").attach(quesAndAnsGrid);
        quesAndAnsSettingTab.getCell("quesAndAnsSet").attach(quesAndAnsSetLayout);

        const quesAndAnsWindow = new DHXWindow({
            title: "新增常見問題設定視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 510,
            width: 500,
            footer: true,
            css: "quesAndAnsWindow"
        });

        quesAndAnsWindow.footer.data.add({type:"spacer"});
        quesAndAnsWindow.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        quesAndAnsWindow.footer.data.add( JSON.parse( JSON.stringify(WindowFootClear)));
        quesAndAnsWindow.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        quesAndAnsWindow.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":

                    quesAndAnsForm.clear();
                    quesAndAnsForm.setValue({"DB001": "0", "DB002": hotel, "DB102":999});

                    break;
                case "WINDOW_FOOT_ACCEPT":

                    if(quesAndAnsForm.getItem("DB003").getValue() === "" || quesAndAnsForm.getItem("DB003").getValue() === "0"){
                        DHXMessage({
                            text: "請選擇問題類別名稱",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(quesAndAnsForm.getItem("DB102").getValue().trim() === ""){
                        DHXMessage({
                            text: "請填入問題排序",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(quesAndAnsForm.getItem("DB100").getValue().trim() === ""){
                        DHXMessage({
                            text: "請填入問題",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(quesAndAnsForm.getItem("DB101").getValue().trim() === ""){
                        DHXMessage({
                            text: "請填入答案",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/quesAndAns/checkQuesAndAns",
                        quesAndAnsForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result !== 0){
                            DHXMessage({
                                text: "問題重複",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }else{

                            axios.post(apiServer+"/quesAndAns/updateQuesAndAns",
                                quesAndAnsForm.getValue(),headers
                            ).then((response2) =>{
                                if(response2.data){
                                    DHXMessage({
                                        text: response2.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--success",
                                        expire:5000
                                    });

                                    quesAndAnsForm.clear();
                                    quesAndAnsForm.setValue({"DB001": "0", "DB002": hotel, "DB102":999});

                                    axios.post(apiServer+"/quesAndAns/loadQuesAndAnsGrid",{itemId1:hotel},headers)
                                        .then((response3) =>{
                                            if(response3.status === 200){
                                                if(response3.data){
                                                    quesAndAnsWindow.hide();
                                                    quesAndAnsGrid.data.removeAll();
                                                    quesAndAnsGrid.data.parse(response3.data);
                                                }
                                            }else{
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        })
                                        .catch((error) => {
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        });
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    quesAndAnsForm.clear();
                    quesAndAnsForm.setValue({"DB001": "0", "DB002": hotel, "DB102":999});
                    quesAndAnsWindow.hide();
                    break;
                default:
                    break;
            }
        });

        const quesAndAnsForm = new DHXForm(null,{
            rows: quesAndAnsFormConfig
        });

        quesAndAnsWindow.attach(quesAndAnsForm);

        const quesAndAnsWindow3 = new DHXWindow({
            title: "刪除問題視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 540,
            width: 450,
            footer: true,
            css: "quesAndAnsWindow3"
        });

        quesAndAnsWindow3.footer.data.add({type:"spacer"});
        quesAndAnsWindow3.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        quesAndAnsWindow3.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        quesAndAnsWindow3.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":

                    if(quesAndAnsDeleteForm.getItem("DB103").getValue().trim() === ""){
                        DHXMessage({
                            text: "請填入刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    axios.post(apiServer+"/quesAndAns/deleteQuesAndAns",
                        quesAndAnsDeleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0]){
                            DHXMessage({
                                text: response.data[0].message,
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });
                            quesAndAnsDeleteForm.clear();
                            quesAndAnsDeleteForm.setValue({"DB001": "0"});

                            axios.post(apiServer+"/quesAndAns/loadQuesAndAnsGrid",{itemId1:hotel},headers)
                                .then((response2) =>{
                                    if(response2.status === 200){
                                        if (response2.data){
                                            quesAndAnsWindow3.hide();
                                            quesAndAnsGrid.data.removeAll();
                                            quesAndAnsGrid.data.parse(response2.data);
                                        }
                                    }else{
                                        console.log("Server Error")
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
            }
        });

        let quesAndAnsDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id: "quesAndAnsDeleteFormImg",
                    height: "content",
                    html: "<div style='margin-bottom: 1em;'><div style='text-align: center;'><img width='30%' src="+Image_error+" /></div><div class='deleteFormTitle'>確定要刪除嗎?</div></div>",
                },
                {id: "quesAndAnsDeleteForm"},
            ]
        });

        const quesAndAnsDeleteForm = new DHXForm(null,{
            rows: quesAndAnsDeleteFormConfig
        });

        quesAndAnsWindow3.attach(quesAndAnsDeleteFormLayout);
        quesAndAnsDeleteFormLayout.getCell("quesAndAnsDeleteForm").attach(quesAndAnsDeleteForm);

        return () => {
            quesAndAnsSettingTab && quesAndAnsSettingTab.destructor();
        };
    }, [canAccess,canEdit,canDelete,canPrint,hotel]);

    return (
        <DashboardLayout>
            <DashboardNavbar onHotelChange={changeHotelHandler}/>
            {canAccess &&
            <MDBox py={3}>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={12}>
                        <Card>
                            <CardHeader title={"常見問題設定"}/>
                            <MDBox py={1} px={1}>
                                <div ref={DHXQuesAndAnsSettingTab}></div>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>

            </MDBox>
            }

            {!canAccess && !isLoading &&
            <MDAlert color={"error"}>請確認是否擁有檢視的權限</MDAlert>
            }

            {isLoading &&
            <MDAlert color={"warning"}>權限確認中，請稍後...</MDAlert>
            }
        </DashboardLayout>
    );
};

export default QuesAndAnsSetting;
