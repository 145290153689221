export const serviceTypeListGridColumn = [
    { width:80,id: "action", gravity: 1.5, header: [{ text: "動作", align: "center" }],
        htmlEnable: true, align: "center" },
    { width:80, id: "item_no", header: [{ text: "項次" }] },
    // { minWidth: 100, id: "hotel_service_type_code", header: [{ text: "Main Service Type Code" }] },
    { width: 150, id: "hotel_service_type_name", header: [
        { text: "提供服務類別名稱" },
            { content: "inputFilter"}
        ] },
    { width:200, id: "created_by", header: [{ text: "建立人員" },{content: "inputFilter"}] },
    { width:200, id: "created_at", header: [{ text: "建立時間" },{content: "inputFilter"}] },
    { width:200, id: "updated_by", header: [{ text: "更新人員" },{content: "inputFilter"}] },
    { width:200, id: "updated_at", header: [{ text: "更新時間" },{content: "inputFilter"}] },
]