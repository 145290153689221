export const handOverGridColumn = [
    { id: "isChecked", width:80, header: [{ text: "是否完成" }],htmlEnable: true,
        tooltip: false,
        template: function (text, row, col) {
            return "<div class='cell__template' style='width: 100%;display: flex;justify-content: center;'><input type='checkbox' style='height: 1.2em;width: 1.2em;'" + (text == true ? "checked" : "") + " ></div>";
        },type: "boolean" },
    { id: "action", header: [{ text: "動作" }],tooltip: false,
        htmlEnable: true, align: "center", width:80},
    { id: "item_no", width:80, header: [{ text: "項次" }],tooltip: false },
    { width: 100, id: "handOver_status", header: [{ text: "狀態" },{content: "selectFilter"}],
        htmlEnable: true,tooltip: false,
        mark:function (e){
            return e == "已完成"?"use":"nouse";
        }
    },
    { width: 100, id: "handOver_type", header: [{ text: "交班隸屬" },{content: "inputFilter"}],tooltip: false},
    { width: 200, id: "handOver_content", header: [{ text: "交班內容" },{content: "inputFilter"}],tooltip: false},
    { width: 200, id: "created_at", header: [{ text: "交班時間" },{content: "inputFilter"}],tooltip: false },
    { width: 150, id: "created_by", header: [{ text: "交班人員" },{content: "selectFilter"}],tooltip: false },
    { width: 200, id: "handOver_finishTime", header: [{ text: "完成時間" },{content: "inputFilter"}],tooltip: false},
    { width: 150, id: "updated_by", header: [{ text: "更新人員" },{content: "selectFilter"}],tooltip: false },
    { width: 200, id: "updated_at", header: [{ text: "更新時間" },{content: "inputFilter"}],tooltip: false },
]