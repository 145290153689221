import i18next from "i18next";
import i18n from "i18next";


export const toolbar1Config = [
    {id: "reload",
        type: "button",
        circle: true,
        value: "重新整理",
        size: "small",
        icon: "fa-solid fa-rotate-right",
        // full: true
    },
    {id: "add",
        type: "button",
        circle: true,
        value: "新增房型",
        size: "small",
        icon: "fa-solid fa-plus",
        // full: true
    },

]