export const hotelLockSetFormConfig = [
    {type: "input", name: "AG001", hidden: true, value: "0"},
    {
        type: "input",
        label: "大樓門鎖密碼：",
        placeholder: "請輸入大樓密碼，若不需設定請為空",
        name: "AG100",
    },{
        type: "text",
        value: "若不需設定大樓門鎖密碼請設定為空值或按清除",
        name: "hotelLockSetFormTip",
        css: "hotelLockSetFormTip"
    },
];