export const pricesSetGridColumn = [
    { id: "action", header: [{ text: "動作" }],
        htmlEnable: true, align: "center", width:80 },
    { id: "item_no", width:80, header: [{ text: "項次" }] },
    { id: "hotel_name", width:120, header: [{ text: "飯店名稱" },{content: "selectFilter"}]},
    { id: "room_type", width:120, header: [{ text: "房型" },{content: "selectFilter"}]},
    { id: "status", width:100 , header: [{ text: "價格狀態" },{content: "selectFilter"}],
        htmlEnable: true,template: function (e) {
            let str = "";
            if(e === "未過期"){
                str = "<span style='font-weight: 800;color: green'>未過期</span>";
            }else{
                str = "<span style='font-weight: 800;color: red'>已過期</span>";
            }
            return str;
        }
    },
    { id: "start_date", width:200, header: [{ text: "開始日期" },{content: "inputFilter"}]},
    { id: "end_date", width:200, header: [{ text: "結束日期" },{content: "inputFilter"}]},
    { id: "note", width:200, header: [{ text: "備註" },{content: "inputFilter"}]},
    { width:200, id: "created_by", header: [{ text: "建立人員" },{content: "inputFilter"}] },
    { width:200, id: "created_at", header: [{ text: "建立時間" },{content: "inputFilter"}] },
    { width:200, id: "updated_by", header: [{ text: "更新人員" },{content: "inputFilter"}] },
    { width:200, id: "updated_at", header: [{ text: "更新時間" },{content: "inputFilter"}] },
]