import React, {useEffect, useRef, useState} from 'react';
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import Sidenav from "pages/Sidenav";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import {
    Grid as DHXGrid,
    Window as DHXWindow,
    Layout as DHXLayout,
    Toolbar as DHXToolbar,
    Form as DHXForm,
    message as DHXMessage,
} from "dhx-suite";
import "dhx-suite/codebase/suite.min.css";
import {CardHeader} from "@mui/material";
import {handOverGridColumn} from "./componentsConfig/handOverGridColumn";
import {toolbar1Config} from "./componentsConfig/toolbar1Config";
import {handOverFormConfig} from "./componentsConfig/handOverFormConfig";
import {WindowFootAccept, WindowFootClear, WindowFootClose} from "./componentsConfig/windowConfig";
import './style.css';
import {deleteFormConfig} from "./componentsConfig/deleteFormConfig";
import Image_error from '../../../assets/images/error.png';
import axios from "axios";
import api_url from "../../../context/UrlContext.js"
import {useNavigate} from "react-router-dom";
import MDAlert from "../../../components/MDAlert";
import Progress from "../../../assets/images/icons8-iphone-spinner.gif";

const PublicWorksHandOverSetting = () => {

    const apiServer = api_url;
    const headers = {headers:{authorization: "Bearer "+JSON.parse(localStorage.getItem("digital_token"))}};
    const navigate = useNavigate();

    const [canAccess ,setCanAccess] = useState('');
    const [canEdit ,setCanEdit] = useState('');
    const [canDelete ,setCanDelete] = useState('');
    const [canPrint ,setCanPrint] = useState('');

    //飯店切換
    const [hotel, setHotel] = useState("0");

    const changeHotelHandler = (hotel) => {
        setHotel(hotel);
    };

    const [isLoading,setIsloading] = useState(true);

    const DHXHandOverGridLayout = useRef(null);

    useEffect(()=>{

        axios.post(apiServer+"/checkLoginState",{itemId1:JSON.parse(localStorage.getItem("digital_token"))})
            .then((response)=>{
                if(response.data == 0){
                    //沒有登入就跳轉到登入頁面
                    navigate('/login');
                }else{
                    axios.post(apiServer+"/userProfileSet/loadPermiss",{itemId1:36},headers)
                        .then((response2)=>{
                            if(response2.status == 200){
                                let p = response2.data.split(',');

                                (p[0] == 1)?setCanAccess(true):setCanAccess(false);
                                (p[1] == 1)?setCanEdit(true):setCanEdit(false);
                                (p[2] == 1)?setCanDelete(true):setCanDelete(false);
                                (p[3] == 1)?setCanPrint(true):setCanPrint(false);

                                setIsloading(false);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });

        const dhxProgressWindow1 = new DHXWindow({
            modal: true,
            resizable: true,
            closable:false,
            height: 150,
            width:150,
            html:"<div style='text-align: center;'><img src="+Progress+" /><br/><span>資料載入中</span></div>",
            css: "dhxProgressWindow1"
        });

        const dhxHandOverGridLayout = new DHXLayout(DHXHandOverGridLayout.current,{
            rows:[
                {id:"handOverGridToolbar", height: "content"},
                {id:"handOverGridLayout", height: "500px"},
            ]
        });

        const toolbar1 = new DHXToolbar(null, {
        });
        toolbar1.events.on("click", function(id,e){
            switch (id) {
                case "reload":
                    axios.post(apiServer+"/handOver/loadHandOverGrid",{
                        itemId1:hotel,itemId2:3
                    },headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    handOverGrid.data.removeAll();
                                    handOverGrid.data.parse(response.data);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "add":
                    handOverForm.clear();

                    axios.post(apiServer+"/userProfileSet/loadUserInfo", {
                        itemId1:JSON.parse(localStorage.getItem("digital_token"))
                    },headers)
                    .then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                handOverForm.getItem("name").setValue(response.data[0].userName);

                                handOverForm.setValue({"AA001":"0","AA002":hotel,"AA100":0,"AA103":3});
                                dhxHandOverWindow1.show();
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                    .catch((error) => {
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    });
                    break;
                default:
                    break;
            }
        });
        toolbar1.data.parse(JSON.parse(JSON.stringify(toolbar1Config)));

        const handOverGrid = new DHXGrid(null,{
            columns: handOverGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "handOverGrid_edit": function (e, data) {
                        if(canEdit){
                            handOverForm.clear();
                            dhxHandOverWindow1.header.data.update("title", {value: "編輯工務交班視窗"});
                            dhxHandOverWindow1.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: true });
                            dhxHandOverWindow1.footer.data.update("WINDOW_FOOT_ACCEPT", { disabled: false });

                            axios.post(apiServer+"/handOver/loadHandOverFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        handOverForm.setValue(response.data[0]);
                                        dhxHandOverWindow1.show();
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "handOverGrid_delete": function (e, data) {
                        if(canDelete){
                            handOverDeleteForm.clear();

                            axios.post(apiServer+"/handOver/loadHandOverFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        handOverDeleteForm.getItem("deleteFormTitle").setValue("確定要刪除嗎?");
                                        handOverDeleteForm.setValue(response.data[0]);
                                        dhxHandOverWindow2.show();
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });

        handOverGrid.events.on("cellClick", function(row,column,e){
            if(canEdit){
                if(column.id == "isChecked"){
                    if(e.target.checked != undefined){
                        dhxProgressWindow1.show();
                        axios.post(apiServer+"/handOver/doneHandOver", {
                            itemId1: row.id,itemId2:e.target.checked
                        },headers).then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    axios.post(apiServer+"/handOver/loadHandOverGrid",{
                                        itemId1:hotel,itemId2:3
                                    },headers)
                                    .then((response2) =>{
                                        if(response2.status === 200){
                                            if(response2.data){
                                                dhxProgressWindow1.hide();
                                                handOverGrid.data.removeAll();
                                                handOverGrid.data.parse(response2.data);
                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }

                        })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    }
                }
            }else{
                dhxProgressWindow1.hide();
                DHXMessage({
                    text: "請先確認擁有編輯的權限",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            }
        });

        axios.post(apiServer+"/handOver/loadHandOverGrid",{
            itemId1:hotel,itemId2:3
        },headers)
        .then((response) =>{
            if(response.status === 200){
                if(response.data){
                    handOverGrid.data.removeAll();
                    handOverGrid.data.parse(response.data);
                }else{
                    DHXMessage({
                        text: "連線異常，請重新整理",
                        icon:"dxi dxi-close",
                        css: "dhx_message--error",
                        expire:5000
                    });
                }
            }else{
                DHXMessage({
                    text: "連線異常，請重新整理",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            }
        })
        .catch((error) => {
            DHXMessage({
                text: "連線異常，請重新整理",
                icon:"dxi dxi-close",
                css: "dhx_message--error",
                expire:5000
            });
        });

        dhxHandOverGridLayout.getCell("handOverGridToolbar").attach(toolbar1);
        dhxHandOverGridLayout.getCell("handOverGridLayout").attach(handOverGrid);

        const dhxHandOverWindow1 = new DHXWindow({
            title: "新增交班視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 450,
            width: 500,
            footer: true,
            css: "dhxHandOverWindow1"
        });

        dhxHandOverWindow1.footer.data.add({type:"spacer"});
        dhxHandOverWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxHandOverWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClear)));
        dhxHandOverWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxHandOverWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    handOverForm.clear();
                    // handOverForm.getItem("status").setValue("1");
                    handOverForm.clear("validation");
                    handOverForm.setValue({"AA001":"0","AA002":hotel,"AA100":0,"AA103":3});
                    break;

                case "WINDOW_FOOT_ACCEPT":
                    if(handOverForm.getItem("AA101").getValue().trim() === ""){
                        DHXMessage({
                            text: "請填入交班內容",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(handOverForm.getItem("AA101").getValue() === ""){
                        DHXMessage({
                            text: "請選擇是否完成",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/handOver/updateHandOverGridFormData",
                        handOverForm.getValue(),headers
                    ).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                DHXMessage({
                                    text: response.data[0].message,
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });

                                handOverForm.clear();
                                handOverForm.setValue({"AA001":"0","AA002":hotel,"AA100":0,"AA103":3});
                                axios.post(apiServer+"/handOver/loadHandOverGrid",{
                                    itemId1:hotel,itemId2:3
                                },headers)
                                .then((response2) =>{
                                    if(response2.status === 200){
                                        if(response2.data){
                                            dhxHandOverWindow1.hide();
                                            handOverGrid.data.removeAll();
                                            handOverGrid.data.parse(response2.data);
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    })
                    .catch((error) => {
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxHandOverWindow1.hide();
                    break;
                default:
                    break;
            }
        })

        const handOverForm = new DHXForm(null,{
            rows: handOverFormConfig
        });

        handOverForm.getItem("AA100").events.on("change", function(value) {
            if(value == "1"){
                handOverForm.getItem("AA102").enable();
            }else if(value == "0"){
                handOverForm.getItem("AA102").disable();
                handOverForm.getItem("AA102").clear();
            }
        });

        handOverForm.events.on("click", function (name, e){
            switch (name){
                case "send":
                    dhxHandOverWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        dhxHandOverWindow1.attach(handOverForm);

        const dhxHandOverWindow2 = new DHXWindow({
            title: "刪除交班視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 630,
            footer: true,
            css: "dhxHandOverWindow2"
        });

        dhxHandOverWindow2.footer.data.add({type:"spacer"});
        dhxHandOverWindow2.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxHandOverWindow2.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxHandOverWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    handOverDeleteForm.clear();
                    handOverDeleteForm.getItem("status").setValue("1");
                    handOverDeleteForm.clear("validation");
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(handOverDeleteForm.getItem("AA104").getValue().trim() === ""){
                        DHXMessage({
                            text: "請填入刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/handOver/deleteHandOver",handOverDeleteForm.getValue(),headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    DHXMessage({
                                        text: "更新成功",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--success",
                                        expire:5000
                                    });

                                    axios.post(apiServer+"/handOver/loadHandOverGrid",{
                                        itemId1:hotel,itemId2:1
                                    },headers)
                                        .then((response) =>{
                                            if(response.status === 200){
                                                if(response.data){
                                                    handOverGrid.data.removeAll();
                                                    handOverGrid.data.parse(response.data);
                                                    dhxHandOverWindow2.hide();
                                                }else{
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }
                                            }else{
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        })
                                        .catch((error) => {
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        });
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--danger",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxHandOverWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let handOverDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"handOverDeleteFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "handOverDeleteForm"},
            ]
        });

        const handOverDeleteForm = new DHXForm(null,{
            rows: deleteFormConfig
        });

        dhxHandOverWindow2.attach(handOverDeleteFormLayout);
        handOverDeleteFormLayout.getCell("handOverDeleteForm").attach(handOverDeleteForm);

        return () =>{
            dhxHandOverGridLayout && dhxHandOverGridLayout.destructor();
        }

    },[canAccess,canEdit,canDelete,canPrint,hotel]);


    return (
        <DashboardLayout>
            <DashboardNavbar onHotelChange={changeHotelHandler}/>
            {canAccess &&
                <MDBox py={2}>
                    <MDBox mb={2}>
                        <Grid container>
                            <Grid item xs={12} lg={12}>
                                <Card>
                                    <CardHeader title={"工務交班管理"}/>
                                    <MDBox py={1} px={1}>
                                        <div ref={DHXHandOverGridLayout}></div>
                                    </MDBox>
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDBox>
            }

            {!canAccess && !isLoading &&
            <MDAlert color={"error"}>請確認是否擁有檢視的權限</MDAlert>
            }

            {isLoading &&
            <MDAlert color={"warning"}>權限確認中，請稍後...</MDAlert>
            }
        </DashboardLayout>
    );
};

export default PublicWorksHandOverSetting;