export const unitsSetListGridColumn = [
    { width: 120, id: "action", header: [{ text: "動作", align: "center" }], htmlEnable: true, align: "center" },
    { width: 80, id: "item_no", header: [{ text: "項次" }] },
    { width: 150, id: "units_name", header: [{ text: "單位名稱" },{content: "inputFilter"}] },
    { width: 100, id: "status", header: [{ text: "狀態" },{content: "selectFilter"}] },
    { width: 150, id: "stop_reason", header: [{ text: "停用原因" },{content: "inputFilter"}] },
    { width:200, id: "created_by", header: [{ text: "建立人員" },{content: "inputFilter"}] },
    { width:200, id: "created_at", header: [{ text: "建立時間" },{content: "inputFilter"}] },
    { width:200, id: "updated_by", header: [{ text: "更新人員" },{content: "inputFilter"}] },
    { width:200, id: "updated_at", header: [{ text: "更新時間" },{content: "inputFilter"}] },
]