export const userFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {
        cols:[
            {type: "input", label: "帳號*", name: "AA102", width: "49.7%", css:"rightMargin",disabled:false},
            {type: "input", inputType: "password", label: "密碼*", name: "AA103", width: "49.7%", css:"rightMargin"},
        ]
    },
    {
        cols:[
            {type: "input", label: "姓名*", name: "AA101", width: "32.7%", css:"rightMargin"},
            {type: "input", label: "身分證", name: "AA109", width: "32.7%", css:"rightMargin"},
            {type: "input", label: "員工編號", name: "AA100", width: "32.7%", css:"rightMargin",placeholder:"系統自動產生",disabled: true},
        ]
    },
    {
        cols:[
            {type: "input", label: "Email", name: "AA104", width: "49.7%", css:"rightMargin"},
            {type: "input", label: "手機號碼", name: "AA105", width: "49.7%", css:"rightMargin"},
        ]
    },
    {
        cols:[
            {type: "input", label: "Line ID", name: "AA106", width: "49.7%", css:"rightMargin"},
            {type: "input", label: "WeChat ID", name: "AA107", width: "49.7%", css:"rightMargin"},
        ]
    },
    {
        type: "textarea",
        label: "備註",
        height: 100,
        name: "AA108",
    },
    {
        type: "radioGroup",
        label: "狀態",
        value: "1",
        options: {
            cols: [
                {
                    type: "radioButton",
                    text: "啟用中",
                    value: "1",
                    checked: true,
                },
                {
                    type: "radioButton",
                    text: "停用中",
                    value: "0"
                },
            ]
        },
        name: "AA022",
    },
    {
        type: "textarea",
        placeholder: "停用原因",
        height: 100,
        name: "AA110",
    },
];

export const userStopFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要停用嗎?",
        name: "stopFormTitle",
        css:"stopFormTitle"
    },
    {
        cols:[
            {
                type: "text",
                label: "使用者姓名：",
                labelPosition: "left",
                value:"",
                name: "AA101",
                css:"stopFormName"
            },
            {
                type: "text",
                label: "使用者帳號：",
                labelPosition: "left",
                value:"",
                name: "AA102",
                css:"stopFormName"
            },
        ]
    },
    {
        type: "textarea",
        value: "",
        label: "*停用原因：",
        placeholder: "",
        height: 150,
        name: "AA110",
    },
];

export const userDeleteFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要刪除嗎?",
        name: "deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        cols:[
            {
                type: "text",
                label: "使用者姓名：",
                labelPosition: "left",
                value:"",
                name: "AA101",
                css:"stopFormName"
            },
            {
                type: "text",
                label: "使用者帳號：",
                labelPosition: "left",
                value:"",
                name: "AA102",
                css:"stopFormName"
            },
        ]
    },
    {
        type: "textarea",
        label: "*刪除原因：",
        required: true,
        value: "",
        height: 150,
        name: "AA111",
    },
];