export const smartBookingFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {type: "input", name: "AA002", hidden: true, value: "0"},
    {type: "input", name: "AA003", hidden: true, value: "0"},
    {
        type: "checkbox",
        text: "供跨館別聯賣",
        name: "AA109",
    },
    {
        cols: [
            {
                type: "input",
                label: "*專案名稱：",
                css:"rightMargin",
                width: "49.7%",
                value: "",
                name: "AA100",
            },
            {
                type: "input",
                label: "*專案價格：",
                css:"rightMargin",
                placeholder: "",
                width: "49.7%",
                inputType: "number",
                name: "AA110"
            },
        ],
    },
    {
        type: "combo",
        multiselection: true,
        label: "*主要產品：",
        labelPosition: "top",
        name: "mainPrd",
        template : ({ group, value, root }) => {
            const isRoot = root && "list-item__root" || "";
            return `
                <div
                    class="list-item ${isRoot}"
                    data-label="${group}">
                    ${value}
                </div>
            `;
        },
    },
    {
        type: "combo",
        multiselection: true,
        label: "加購產品：",
        labelPosition: "top",
        name: "otherPrd",
    },
    {
        cols: [
            {
                type: "datepicker",
                label: "*專案販售開始日期：",
                timePicker: true,
                timeFormat: 24,
                dateFormat: "%Y-%m-%d %H:%i",
                valueFormat:"string",
                width: "49.7%",
                css:"rightMargin",
                name: "AA101",
            },
            {
                type: "datepicker",
                label: "*專案販售結束日期：",
                timePicker: true,
                timeFormat: 24,
                dateFormat: "%Y-%m-%d %H:%i",
                valueFormat:"string",
                width: "49.7%",
                name: "AA102",
            },
        ],
    },
    {
        cols: [
            {
                type: "datepicker",
                label: "*專案可用開始日期：",
                timePicker: true,
                timeFormat: 24,
                dateFormat: "%Y-%m-%d %H:%i",
                valueFormat:"string",
                width: "49.7%",
                css:"rightMargin",
                name: "AA105",
            },
            {
                type: "datepicker",
                label: "*專案可用結束日期：",
                timePicker: true,
                timeFormat: 24,
                dateFormat: "%Y-%m-%d %H:%i",
                valueFormat:"string",
                width: "49.7%",
                name: "AA106",
            },
        ],
    },
    {
        type: "text",
        label: "*專案單位：",
        labelPosition: "left",
        css:"rightMargin",
        name: "AA103Title",
    },
    {
        cols:[
            {
                width:"24.8%",
                cols:[
                    {
                        type: "radioGroup",
                        height:35,
                        width: 22,
                        options: {
                            cols: [
                                {
                                    type: "radioButton",
                                    value: "1",
                                },
                            ]
                        },
                        name: "AA103_1",
                        css:"smartBookingForm_Radio",
                    },
                    {
                        type: "select",
                        disabled: true,
                        width: 50,
                        options: [
                            {value: "30",content: "30"},
                            {value: "60",content: "60"},
                            {value: "90",content: "90"},
                            {value: "120",content: "120"},
                            {value: "150",content: "150"},
                            {value: "180",content: "180"},
                            {value: "210",content: "210"},
                            {value: "240",content: "240"},
                            {value: "270",content: "270"},
                            {value: "300",content: "300"},
                            {value: "330",content: "330"},
                            {value: "360",content: "360"},
                            {value: "390",content: "390"},
                            {value: "420",content: "420"},
                            {value: "450",content: "450"},
                            {value: "480",content: "480"},
                        ],
                        name: "AA104_1",
                    },
                    {
                        type: "text",
                        value:"分鐘",
                        css:"rightMargin",
                        name: "AA104_text1",
                    },
                ]
            },
            {
                width:"24.8%",
                cols:[
                    {
                        type: "radioGroup",
                        height:35,
                        width: 22,
                        options: {
                            cols: [
                                {
                                    type: "radioButton",
                                    value: "1",
                                },
                            ]
                        },
                        name: "AA103_2",
                        css:"smartBookingForm_Radio",
                    },
                    {
                        type: "select",
                        disabled: true,
                        width: 50,
                        options: [
                            {value: "1",content: "1"},
                            {value: "2",content: "2"},
                            {value: "3",content: "3"},
                            {value: "4",content: "4"},
                            {value: "5",content: "5"},
                            {value: "6",content: "6"},
                            {value: "7",content: "7"},
                            {value: "8",content: "8"},
                        ],
                        name: "AA104_2",
                    },
                    {
                        type: "text",
                        value:"小時",
                        css:"rightMargin",
                        name: "AA104_text2",
                    },
                ]
            },
            {
                width:"24.8%",
                cols:[
                    {
                        type: "radioGroup",
                        height:35,
                        width: 22,
                        options: {
                            cols: [
                                {
                                    type: "radioButton",
                                    value: "1",
                                },
                            ]
                        },
                        name: "AA103_3",
                        css:"smartBookingForm_Radio",
                    },
                    {
                        type: "select",
                        disabled: true,
                        width: 50,
                        options: [
                            {value: "1",content: "1"},
                            {value: "2",content: "2"},
                            {value: "3",content: "3"},
                            {value: "4",content: "4"},
                            {value: "5",content: "5"},
                            {value: "6",content: "6"},
                            {value: "7",content: "7"},
                            {value: "8",content: "8"},
                            {value: "9",content: "9"},
                            {value: "10",content: "10"},
                            {value: "11",content: "11"},
                            {value: "12",content: "12"},
                            {value: "13",content: "13"},
                            {value: "14",content: "14"},
                            {value: "15",content: "15"},
                            {value: "16",content: "16"},
                            {value: "17",content: "17"},
                            {value: "18",content: "18"},
                            {value: "19",content: "19"},
                            {value: "20",content: "20"},
                            {value: "21",content: "21"},
                            {value: "22",content: "22"},
                            {value: "23",content: "23"},
                            {value: "24",content: "24"},
                            {value: "25",content: "25"},
                            {value: "26",content: "26"},
                            {value: "27",content: "27"},
                            {value: "28",content: "28"},
                            {value: "29",content: "29"},
                            {value: "30",content: "30"},
                            {value: "31",content: "31"},
                        ],
                        name: "AA104_3",
                    },
                    {
                        type: "text",
                        value:"天",
                        css:"rightMargin",
                        name: "AA104_text3",
                    },
                ]
            },
            {
                width:"24.8%",
                cols:[
                    {
                        type: "radioGroup",
                        height:35,
                        width: 22,
                        options: {
                            cols: [
                                {
                                    type: "radioButton",
                                    value: "1",
                                },
                            ]
                        },
                        name: "AA103_4",
                        css:"smartBookingForm_Radio",
                    },
                    {
                        type: "select",
                        disabled: true,
                        width: 50,
                        options: [
                            {value: "1",content: "1"},
                            {value: "2",content: "2"},
                            {value: "3",content: "3"},
                            {value: "4",content: "4"},
                            {value: "5",content: "5"},
                            {value: "6",content: "6"},
                            {value: "7",content: "7"},
                            {value: "8",content: "8"},
                            {value: "9",content: "9"},
                            {value: "10",content: "10"},
                            {value: "11",content: "11"},
                            {value: "12",content: "12"},
                            {value: "13",content: "13"},
                            {value: "14",content: "14"},
                            {value: "15",content: "15"},
                            {value: "16",content: "16"},
                            {value: "17",content: "17"},
                            {value: "18",content: "18"},
                            {value: "19",content: "19"},
                            {value: "20",content: "20"},
                            {value: "21",content: "21"},
                            {value: "22",content: "22"},
                            {value: "23",content: "23"},
                            {value: "24",content: "24"},
                        ],
                        name: "AA104_4",
                    },
                    {
                        type: "text",
                        value:"月",
                        css:"rightMargin",
                        name: "AA104_text4",
                    },
                ]
            },
        ]
    },
    {
        type: "textarea",
        label: "*專案介紹：",
        value: "",
        height: 100,
        name: "AA107",
    },
    {
        type: "textarea",
        label: "*專案使用規則：",
        value: "",
        height: 100,
        name: "AA108",
    },
];
