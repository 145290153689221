export const lostPropertyFormConfig = [
    {type: "input", name: "AK001", hidden: true, value: "0"},
    {
        type: "text",
        value: "",
        name: "describe",
        css:"reportLosyPropertyFormTitle"
    },
    {
        type: "text",
        value: "是否需要通報遺失物?",
        name: "reportLosyPropertyFormTitle",
        css:"reportLosyPropertyFormTitle"
    },
    {
        type: "textarea",
        label: "*遺失物內容：",
        value: "",
        height: 150,
        name: "AK103",
    },
]