export const hotelVerbSetDeleteFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要刪除嗎?",
        name: "deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        type: "text",
        label: "飯店名稱：",
        labelPosition: "left",
        value:"",
        name: "AA101",
        css:"deleteFormName"
    },
    {
        type: "textarea",
        label: "*刪除原因：",
        value: "",
        height: 150,
        name: "AA115",
    },
];

export const hotelMaintainTeamDeleteFormConfig = [
    {type: "input", name: "AC001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要刪除嗎?",
        name: "deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        type: "text",
        label: "維修片語類型：",
        labelPosition: "left",
        value:"",
        name: "internal_external",
        css:"deleteFormName"
    },
    {
        type: "text",
        label: "維修片語名稱：",
        labelPosition: "left",
        value:"",
        name: "maintain_team_name",
        css:"deleteFormName"
    },
    {
        type: "textarea",
        label: "*刪除原因：",
        value: "",
        height: 150,
        name: "AC102",
    },
];

export const hotelFaultSetDeleteFormConfig = [
    {type: "input", name: "AB001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要刪除嗎?",
        name: "deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        type: "text",
        label: "故障片語名稱：",
        labelPosition: "left",
        value:"",
        name: "AB100",
        css:"deleteFormName"
    },
    {
        type: "textarea",
        label: "*刪除原因：",
        value: "",
        height: 150,
        name: "AB101",
    },
];

export const hotelBookingCancelDeleteFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要刪除嗎?",
        name: "deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        type: "text",
        label: "取消訂單片語名稱：",
        labelPosition: "left",
        value:"",
        name: "AA100",
        css:"deleteFormName"
    },
    {
        type: "textarea",
        label: "*刪除原因：",
        value: "",
        height: 150,
        name: "AA101",
    },
];

export const mainProductsSetDeleteFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要刪除嗎?",
        name: "deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        cols:[
            {
                type: "text",
                label: "主要產品代號：",
                labelPosition: "left",
                value:"",
                width: "49.7%",
                name: "AA100",
                css:"deleteFormName"
            },
            {
                type: "text",
                label: "主要產品名稱：",
                labelPosition: "left",
                value:"",
                width: "49.7%",
                name: "AA101",
                css:"deleteFormName"
            },
        ],
    },
    {
        type: "textarea",
        label: "*刪除原因：",
        value: "",
        height: 150,
        name: "AA107",
    },
];

export const serviceTypeDeleteFormConfig = [
    {type: "input", name: "AE001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要刪除嗎?",
        name: "deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        type: "text",
        label: "提供服務類別名稱：",
        labelPosition: "left",
        value:"",
        name: "AE101",
        css:"deleteFormName"
    },
    {
        type: "textarea",
        label: "*刪除原因：",
        value: "",
        height: 150,
        name: "AE103",
    },
];

export const serviceSubTypeDeleteFormConfig = [
    {type: "input", name: "AE001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要刪除嗎?",
        name: "deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        cols:[
            {
                type: "text",
                label: "提供服務類別名稱：",
                labelPosition: "left",
                value:"",
                name: "type",
                width: "49.7%",
                css:"deleteFormName"
            },
            {
                type: "text",
                label: "提供服務項目名稱：",
                labelPosition: "left",
                value:"",
                name: "subType",
                width: "49.7%",
                css:"deleteFormName"
            },
        ],
    },

    {
        type: "textarea",
        label: "*刪除原因：",
        value: "",
        height: 150,
        name: "AE103",
    },
];

export const unitsSetDeleteFormConfig = [
    {type: "input", name: "AD001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要刪除嗎?",
        name: "deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        type: "text",
        label: "單位名稱：",
        labelPosition: "left",
        value:"",
        name: "AD100",
        css:"deleteFormName"
    },
    {
        type: "textarea",
        label: "*刪除原因：",
        value: "",
        height: 150,
        name: "AD102",
    },
];

export const orderSourceSetDeleteFormConfig = [
    {type: "input", name: "AF001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要刪除嗎?",
        name: "deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        type: "text",
        label: "訂單來源名稱：",
        labelPosition: "left",
        value:"",
        name: "AF100",
        css:"deleteFormName"
    },
    {
        type: "textarea",
        label: "*刪除原因：",
        value: "",
        height: 150,
        name: "AF102",
    },
];

export const orderSubSourceSetDeleteFormConfig = [
    {type: "input", name: "AF001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要刪除嗎?",
        name: "deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        type: "text",
        label: "銷售來源名稱：",
        labelPosition: "left",
        value:"",
        name: "AF100",
        css:"deleteFormName"
    },
    {
        type: "textarea",
        label: "*刪除原因：",
        value: "",
        height: 150,
        name: "AF102",
    },
];