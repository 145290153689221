
export const hotelVerbSetGridColumn = [
    { id: "action",header: [{ text: "動作", align: "center" }],
        htmlEnable: true, align: "center", width:100,
    },
    { minWidth: 80, width:100, id: "item_no", header: [{ text: "項次" }] },
    { minWidth: 80, width:120, id: "hotel_status", header: [{ text: "狀態" },{content: "selectFilter"}] },
    { minWidth: 125, width:100, id: "hotel_number", header: [{ text: "飯店編號" },{content: "inputFilter"}] },
    { minWidth: 125, width:150, id: "hotel_hotelnabe", header: [{ text: "HotelNabe飯店編號" },{content: "inputFilter"}] },
    { minWidth: 125, width:150, id: "hotel_name", header: [{ text: "飯店名稱" },{content: "inputFilter"}] },
    { minWidth: 125, width:100, id: "hotel_tel", header: [{ text: "飯店電話" },{content: "inputFilter"}] },
    { minWidth: 125, width:100, id: "lineId", header: [{ text: "客服Line ID" },{content: "inputFilter"}] },
    { minWidth: 125, width:80, id: "hotel_country", header: [{ text: "國家" },{content: "inputFilter"}] },
    { minWidth: 125, width:80, id: "hotel_area", header: [{ text: "區域" },{content: "inputFilter"}] },
    { minWidth: 125, width:200, id: "hotel_address", header: [{ text: "飯店地址" },{content: "inputFilter"}] },
    { minWidth: 125, width:100, id: "hotel_type", header: [{ text: "飯店類型" },{content: "selectFilter"}] },
    { minWidth: 125, width:200, id: "business_hours", header: [{ text: "營業時間" },{content: "selectFilter"}] },
    { minWidth: 125, width:200, id: "hotel_stopReason", header: [{ text: "停用原因" },{content: "selectFilter"}] },
    { width:200, id: "created_by", header: [{ text: "建立人員" },{content: "inputFilter"}] },
    { width:200, id: "created_at", header: [{ text: "建立時間" },{content: "inputFilter"}] },
    { width:200, id: "updated_by", header: [{ text: "更新人員" },{content: "inputFilter"}] },
    { width:200, id: "updated_at", header: [{ text: "更新時間" },{content: "inputFilter"}] },
]