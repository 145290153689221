export const orderFormConfig = [
    { type: "input", name: "AA001", hidden: true, value: "0" },
    { type: "input", name: "AA002", hidden: true, value: "0" },
    { type: "input", name: "AA137", hidden: true, value: "0" },
    { type: "input", name: "AA138", hidden: true, value: "0" },
    // {
    //     css: "row1_title",
    //     rows: [
    //         {
    //             type: "text",
    //             value:"訂單資訊",
    //             width:80,
    //             name: "row1_title",
    //         },
    //     ]
    // },
    {
        css: "row1",
        rows: [
            {
                cols: [
                    {
                        type: "text",
                        label: "訂單編號：",
                        labelPosition: "top",
                        width: 173,
                        css:"rightMargin",
                        name: "AA128"
                    },
                    {
                        type: "select",
                        label: "訂單來源：",
                        labelPosition: "top",
                        width: 173,
                        options: [
                            {
                                value: "0",
                                content: "---請選擇---",
                            },
                        ],
                        css:"rightMargin",
                        name: "AA006"
                    },
                    {
                        type: "select",
                        label: "銷售來源：",
                        labelPosition: "top",
                        width: 173,
                        options: [
                            {
                                value: "0",
                                content: "---請選擇---",
                            },
                        ],
                        css:"rightMargin",
                        name: "AA008"
                    },
                    {
                        type: "input",
                        label: "訂單來源編號：",
                        labelPosition: "top",
                        width: 173,
                        css:"rightMargin",
                        name: "AA102"
                    },
                ],
            },
            {
                cols: [
                    {
                        type: "text",
                        label: "訂購日期：",
                        labelPosition: "top",
                        width: 173,
                        css:"rightMargin",
                        name: "AA100"
                    },
                    {
                        type: "text",
                        label: "訂單總金額：",
                        labelPosition: "top",
                        width: 173,
                        css:"rightMargin",
                        name: "AA136"
                    },
                    {
                        type: "input",
                        label: "發票號碼：",
                        labelPosition: "top",
                        width: 173,
                        css:"rightMargin",
                        name: "AA121"
                    },
                    {
                        type: "text",
                        label: "訂單來源備註：",
                        labelPosition: "top",
                        width: 173,
                        css:"rightMargin",
                        name: "AA134"
                    },
                ],
            },
        ],
    },
    {
        type: "textarea",
        label: "訂單備註：",
        height: 150,
        name: "AA124",
        css: "orderFormNote",
    },
];