export const deleteFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {
        type: "text",
        value: "確定要刪除嗎?",
        name: "deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        type: "text",
        label:"保留對象：",
        labelPosition: "left",
        name: "AA101",
        css:"deleteFormName"
    },
    {
        type: "text",
        label:"保留原因：",
        labelPosition: "left",
        name: "AA102",
        css:"deleteFormName"
    },
    {
        type: "textarea",
        label: "*刪除原因：",
        value: "",
        height: 150,
        name: "AA107",
    },
];