export const alertSetGridColumn = [
    {
        id: "action", header: [{ text: "動作", align: "center" }],
        htmlEnable: true, align: "center", width:80,
    },
    { id: "item_no", width:60, header: [{ text: "項次" }] },
    { id: "alert_time", width:150, header: [{ text: "提醒時間" }] },
    { id: "alert_content", width:250, header: [{ text: "提醒內容" }] },
    { width:120, id: "created_by", header: [{ text: "建立人員" }] },
    { width:150, id: "created_at", header: [{ text: "建立時間" }] },
    { width:120, id: "updated_by", header: [{ text: "更新人員" }] },
    { width:150, id: "updated_at", header: [{ text: "更新時間" }] },
];