export const otherPrdBasSetGridFormConfig = [
    {type: "input", name: "CA001", hidden: true, value: "0"},
    {type: "input", name: "CA002", hidden: true, value: "0"},
    {
        cols:[
            {
                type: "input",
                label: "*加價產品名稱：",
                // required: true,
                css:"rightMargin",
                width: "32.9%",
                placeholder: "",
                name: "CA101"
            },
            {
                type: "input",
                label: "加價產品編號：",
                // required: true,
                width: "32.9%",
                css:"rightMargin",
                placeholder: "未填寫由系統自動產生",
                name: "CA100"
            },
            {
                type: "text",
                label: "飯店名稱：",
                // required: true,
                width: "32.9%",
                css:"rightMargin",
                placeholder: "",
                name: "hotelName"
            },
        ],
    },
    {
        cols:[
            {
                type: "input",
                label: "*價格：",
                labelPosition: "top",
                // required: true,
                css:"rightMargin",
                width: "32.9%",
                placeholder: "",
                name: "CA102"
            },
            {
                type: "select",
                label: "*單位：",
                labelPosition: "top",
                // required: true,
                css:"rightMargin",
                width: "32.9%",
                value: "0",
                options: [
                    {
                        value: "0",
                        content: "---請選擇---",
                        // disabled: true,
                    },
                ],
                name: "CA003",
            },
            {
                type: "input",
                label: "*庫存：",
                labelPosition: "top",
                // required: true,
                css:"rightMargin",
                value: "1",
                inputType:"number",
                min:0,
                width: "32.9%",
                placeholder: "",
                name: "CA103"
            },
        ],
    },
    {
        cols:[
            {
                type: "radioGroup",
                // required: true,
                label: "*狀態：",
                width: "66%",
                value: "1",
                options: {
                    cols: [
                        {
                            type: "radioButton",
                            text: "啟用",
                            value: "1",
                            checked: true,
                        },
                        {
                            type: "radioButton",
                            text: "停用",
                            value: "0"
                        },
                    ]
                },
                name: "CA022",
            },
            {
                label: " ",
                width: "33%",
                type: "checkbox",
                text: "顯示在消費者清單",
                name: "CA110",
                css: "noLabel",
                checked: true
            }
        ]
    },
    {
        type: "textarea",
        value: "",
        label: "*停用原因：",
        placeholder: "",
        height: 150,
        disabled: true,
        name: "CA108",
    },
];