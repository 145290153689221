export const productInfo_sendNewPayLinkFormConfig = [
    { type: "input", name: "AI002", hidden: true, value: "0" },
    { type: "input", name: "AI003", hidden: true, value: "0" },
    { type: "input", name: "AI106", hidden: true, value: "0" },
    { type: "input", label: "*金額：", placeholder:"請填入金額", inputType:"number", width: "100%",name: "AI104"
    },
    {
        type: "text",
        label: "產生連結網址：",
        width:"100%",
        name:"AI105"
    },
    {
        type: "textarea",
        label: "*商品描述：",
        height:"200px",
        width:"100%",
        name:"AI100"
    },
    {
        type: "textarea",
        label: "給對方的備註：(限30字元)",
        height:"200px",
        width:"100%",
        name:"AI101"
    },
];