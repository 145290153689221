export const faultReportGridColumn = [
    { id: "action", header: [{ text: "動作" }],
        htmlEnable: true, align: "center", width:100,
    },
    { id: "item_no", width:80, header: [{ text: "項次" }] },
    { width: 120,id: "hotel_name",header: [{ text: "飯店名稱" },{ content: "inputFilter"}] },
    { width:250, id: "fault_object", header: [{ text: "故障對象" },{ content: "inputFilter"}]},
    { id: "fault_progress", width:100, header: [{ text: "處理狀態" },{content: "selectFilter"}],htmlEnable: true,
        template: function (e) {
            if(e === 1){
                return '<span style="color: green;font-weight: 800;">已處理</span>';
            }else{
                return '<span style="color: red;font-weight: 800;">未處理</span>';
            }
        }
    },
    { id: "photo", width:50, header: [{ text: "照片" }],htmlEnable: true,
    },
    { width:300, id: "fault_reason", header: [{ text: "故障原因" },{ content: "inputFilter"}]},
    { width:250, id: "fault_reportDate", header: [{ text: "通報日期" },{ content: "inputFilter"}]},
    { width:250, id: "fault_lockStartDate", header: [{ text: "鎖定開始日期" },{ content: "inputFilter"}]},
    { width:250, id: "fault_lockEndDate", header: [{ text: "鎖定結束日期" },{ content: "inputFilter"}]},
    { width:300, id: "fault_note", header: [{ text: "備註" },{ content: "inputFilter"}]},
    { width:250, id: "fault_reportDoneDate", header: [{ text: "故障處理完成日期" },{ content: "inputFilter"}]},
    { width:300, id: "fault_reportDoneDateNote", header: [{ text: "故障處理完成備註" },{ content: "inputFilter"}]},
    { width:200, id: "created_by", header: [{ text: "建立人員" },{content: "selectFilter"}] },
    { width:200, id: "created_at", header: [{ text: "建立時間" },{ content: "inputFilter"}] },
    { width:200, id: "updated_by", header: [{ text: "更新人員" },{content: "selectFilter"}] },
    { width:200, id: "updated_at", header: [{ text: "更新時間" },{ content: "inputFilter"}] },
]