export const salesReportGridColumn = [
    { width: 130,id: "saleDate", header: [{ text: "交易日期" }] },
    { width: 150,id: "hotel", header: [ { text: "館別" }] },
    { width: 150,id: "orderResource",header: [{ text: "訂單來源" },{content: "inputFilter"}] },
    { width: 150,id: "room", header: [{ text: "房號" },{content: "inputFilter"}] },
    { width: 200,id: "OTAOrderNo",header: [{ text: "OTA編號" },{content: "inputFilter"}] },
    { width: 250,id: "orderNo",header: [{ text: "訂單編號" },{content: "inputFilter"}] },
    { width: 200,id: "payMethod",header: [{ text: "收款方式" },{content: "selectFilter"}] },
    { width: 150,id: "price",align:"right",header: [{ text: "銷售額" }] },
    { width: 150,id: "month",header: [{ text: "月份" }] },
]